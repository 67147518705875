import i18next from 'i18next'
import ColorHash from 'color-hash'
import {
	CANCELLED_SHIPMENT_STATUS,
	CHARGE_TYPE,
	PICK_UP_TIME_OPTIONS,
	SHIPMENT_STATUS,
	WEIGHT_UNIT
} from '../constants/strings'
import moment from 'moment'

/**
 * Converts an amount to a formatted locale currency (BDT) string.
 * @param {number} currency
 * @returns {string} `currency` in formatted locale string with a fixed precision of 2 decimal places
 */
export function getFormattedCurrency (currency) {
	let value = currency ? Number.parseFloat(currency) : 0.0
	value = isNaN(value) ? 0.0 : value

	return toLocaleString(value, {
		style: 'currency',
		currency: 'BDT',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})
	// return Number.parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Converts a number to a formatted locale number.
 * @param {number} number
 * @returns {string} `number` in formatted locale string with a maximum precision of 2 decimal places
 */
export function getFormattedNumber (number) {
	let value = number ? Number.parseFloat(number) : 0.0
	value = isNaN(value) ? 0.0 : value

	return toLocaleString(value, {
		maximumFractionDigits: 2
	})
}

/**
 * Converts a number or Date to a formatted string locale
 * @param {number|Date} value
 * @param {Intl.NumberFormatOptions} options — An object that contains one or more properties that specify comparison options.
 */
export function toLocaleString (value, options = {}) {
	return value.toLocaleString(i18next.languages, options)
}

export function getTitleCase (text) {
	if (!text) return ''
	return text
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

/**
 * Returns appropriate address according to selected locale/language OR empty string if none found
 * @param {*} location Object containing addressLocale property with locale values or address property
 * @returns string
 */
export function getAddressLocale (location) {
	if (!location)
		return ''
	const address = location.address ? location.address : ''
	const addressLocale = location.addressLocale ? location.addressLocale[i18next.language] : ''

	return addressLocale ? addressLocale : address
}

export function getShortId (id) {
	return id.substring(0, 9).toUpperCase()
}

export function getPathSuffix (path) {
	const start = path.lastIndexOf('/') + 1
	const end = path.indexOf('?')

	if (start > -1) {
		if (end > -1)
			return path.substring(start, end)

		return path.substring(start)
	}
	return ''
}

/**
 * Returns boolean by matching truck plate against a regular expression
 * @param {string} truckPlate
 */
export function isValidTruckPlate (truckPlate) {
	// This is subject to change when we become more sure about the specifics of the first two parts
	const regex = /^[A-Z]+-[A-Z]{1,3}-[\d]{2}-[\d]{4}$/
	const match = truckPlate.match(regex)

	if (match)
		return true

	return false
}

export const getUnitRate = (flatRate, unit) => {
	const TONNE_TO_KG = 1000
	const TONNE_TO_LBS = 2204.62
	switch (unit) {
		case WEIGHT_UNIT.KG:
			return flatRate / TONNE_TO_KG
		case WEIGHT_UNIT.LBS:
			return flatRate / TONNE_TO_LBS
		default:
			return flatRate
	}
}

export const getCharge = (shipment, chargeType) => {
	const charges = shipment?.transaction?.charges

	if (!charges || charges.length === 0)
		return null

	return charges.find((charge) => charge.type === chargeType)
}

export const getFormattedCharges = (shipment) => {
	const charges = shipment?.transaction?.charges

	if (!charges || charges.length === 0)
		return []

	const fareAmount = shipment.status === CANCELLED_SHIPMENT_STATUS ? 0 : shipment.fareAmount

	const vat = getCharge(shipment, CHARGE_TYPE.VAT)
	const ait = getCharge(shipment, CHARGE_TYPE.AIT)
	let demurrageAmount = 0
	let calculatedVat = 0

	charges.forEach(charge => {
		switch (charge.type) {
			case CHARGE_TYPE.DEMURRAGE:
				demurrageAmount = charge.amount
			// eslint-disable-next-line no-fallthrough
			case CHARGE_TYPE.CANCELLATION:
			case CHARGE_TYPE.EXTRA:
				charge.formatted = {
					amount: charge.amount
				}
				charge.formatted.value = getFormattedCurrency(charge.amount)
				break
			default:
				break
		}
		return charge
	})

	if (vat) {
		vat.formatted = {
			amount: (fareAmount + demurrageAmount) * vat.amount / 100,
			calculation: `(${getFormattedNumber(fareAmount)} + ${getFormattedNumber(demurrageAmount)}) x` +
				`${getFormattedNumber(vat.amount)}%`
		}
		vat.formatted.value = getFormattedCurrency(vat.formatted.amount)
		calculatedVat = vat.formatted.amount
	}

	if (ait) {
		ait.formatted = {
			amount: (fareAmount + demurrageAmount - calculatedVat) * ait.amount / 100,
			calculation: `(${getFormattedNumber(fareAmount)} + ${getFormattedNumber(demurrageAmount)} - ` +
				`${getFormattedNumber(calculatedVat)}) x ${getFormattedNumber(ait.amount)}%`
		}
		ait.formatted.value = getFormattedCurrency(ait.formatted.amount)
	}

	return charges

}

export function getFormattedStartDate (dateTime) {
	const startDate = moment(dateTime)
	const format = 'dddd, MMMM DD, YYYY'
	const startTime = PICK_UP_TIME_OPTIONS[Math.floor(startDate.hour()/6)].label

	return {
		date: {
			value: startDate,
			label: startDate.format(format)
		},
		time: {
			value: startDate.hour(),
			label: startTime
		}
	}
}

export const getShipmentTotal = (shipment) => {
	let total = shipment.status === SHIPMENT_STATUS.CANCELLED ? 0 : +shipment.fareAmount

	getFormattedCharges(shipment).forEach((charge) => {
		if (shipment.status === SHIPMENT_STATUS.CANCELLED && charge.type !== CHARGE_TYPE.CANCELLATION) {
			return
		}
		total += +charge.formatted.amount
	})

	return +total
}

export const getHexColor = (str='') => {
	return new ColorHash().hex(str)
}

export const moveCaretAtEnd = (e) => {
	const value = e.target.value
	e.target.value = ''
	e.target.value = value
}
