export const FETCH_SHIPMENTS_REQUEST = 'FETCH_SHIPMENTS_REQUEST'
export const FETCH_SHIPMENTS_FAILURE = 'FETCH_SHIPMENTS_FAILURE'
export const FETCH_SHIPMENTS_SUCCESS = 'FETCH_SHIPMENTS_SUCCESS'
export const FETCH_MORE_SHIPMENTS_REQUEST = 'FETCH_MORE_SHIPMENTS_REQUEST'
export const FETCH_MORE_SHIPMENTS_FAILURE = 'FETCH_MORE_SHIPMENTS_FAILURE'
export const FETCH_MORE_SHIPMENTS_SUCCESS = 'FETCH_MORE_SHIPMENTS_SUCCESS'
export const FETCH_REQUESTED_SHIPMENTS_REQUEST = 'FETCH_REQUESTED_SHIPMENTS_REQUEST'
export const FETCH_REQUESTED_SHIPMENTS_SUCCESS = 'FETCH_REQUESTED_SHIPMENTS_SUCCESS'
export const FETCH_REQUESTED_SHIPMENTS_FAILURE = 'FETCH_REQUESTED_SHIPMENTS_FAILURE'
export const FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST = 'FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST'
export const FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS = 'FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE = 'FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE'
export const FETCH_SCHEDULED_SHIPMENTS_REQUEST = 'FETCH_SCHEDULED_SHIPMENTS_REQUEST'
export const FETCH_SCHEDULED_SHIPMENTS_SUCCESS = 'FETCH_SCHEDULED_SHIPMENTS_SUCCESS'
export const FETCH_SCHEDULED_SHIPMENTS_FAILURE = 'FETCH_SCHEDULED_SHIPMENTS_FAILURE'
export const FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST = 'FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST'
export const FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS = 'FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE = 'FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE'
export const FETCH_ASSIGNED_SHIPMENTS_REQUEST = 'FETCH_ASSIGNED_SHIPMENTS_REQUEST'
export const FETCH_ASSIGNED_SHIPMENTS_SUCCESS = 'FETCH_ASSIGNED_SHIPMENTS_SUCCESS'
export const FETCH_ASSIGNED_SHIPMENTS_FAILURE = 'FETCH_ASSIGNED_SHIPMENTS_FAILURE'
export const FETCH_MORE_ASSIGNED_SHIPMENTS_REQUEST = 'FETCH_MORE_ASSIGNED_SHIPMENTS_REQUEST'
export const FETCH_MORE_ASSIGNED_SHIPMENTS_SUCCESS = 'FETCH_MORE_ASSIGNED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_ASSIGNED_SHIPMENTS_FAILURE = 'FETCH_MORE_ASSIGNED_SHIPMENTS_FAILURE'
export const FETCH_UNASSIGNED_SHIPMENTS_REQUEST = 'FETCH_UNASSIGNED_SHIPMENTS_REQUEST'
export const FETCH_UNASSIGNED_SHIPMENTS_SUCCESS = 'FETCH_UNASSIGNED_SHIPMENTS_SUCCESS'
export const FETCH_UNASSIGNED_SHIPMENTS_FAILURE = 'FETCH_UNASSIGNED_SHIPMENTS_FAILURE'
export const FETCH_MORE_UNASSIGNED_SHIPMENTS_REQUEST = 'FETCH_MORE_UNASSIGNED_SHIPMENTS_REQUEST'
export const FETCH_MORE_UNASSIGNED_SHIPMENTS_SUCCESS = 'FETCH_MORE_UNASSIGNED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_UNASSIGNED_SHIPMENTS_FAILURE = 'FETCH_MORE_UNASSIGNED_SHIPMENTS_FAILURE'
export const FETCH_ACTIVE_SHIPMENTS_REQUEST = 'FETCH_ACTIVE_SHIPMENTS_REQUEST'
export const FETCH_ACTIVE_SHIPMENTS_SUCCESS = 'FETCH_ACTIVE_SHIPMENTS_SUCCESS'
export const FETCH_ACTIVE_SHIPMENTS_FAILURE = 'FETCH_ACTIVE_SHIPMENTS_FAILURE'
export const FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST = 'FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST'
export const FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS = 'FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS'
export const FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE = 'FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE'
export const FETCH_COMPLETED_SHIPMENTS_REQUEST = 'FETCH_COMPLETED_SHIPMENTS_REQUEST'
export const FETCH_COMPLETED_SHIPMENTS_SUCCESS = 'FETCH_COMPLETED_SHIPMENTS_SUCCESS'
export const FETCH_COMPLETED_SHIPMENTS_FAILURE = 'FETCH_COMPLETED_SHIPMENTS_FAILURE'
export const FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST = 'FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST'
export const FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS = 'FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE = 'FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE'
export const FETCH_CANCELLED_SHIPMENTS_REQUEST = 'FETCH_CANCELLED_SHIPMENTS_REQUEST'
export const FETCH_CANCELLED_SHIPMENTS_SUCCESS = 'FETCH_CANCELLED_SHIPMENTS_SUCCESS'
export const FETCH_CANCELLED_SHIPMENTS_FAILURE = 'FETCH_CANCELLED_SHIPMENTS_FAILURE'
export const FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST = 'FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST'
export const FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS = 'FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE = 'FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE'
export const FETCH_EXPIRED_SHIPMENTS_REQUEST = 'FETCH_EXPIRED_SHIPMENTS_REQUEST'
export const FETCH_EXPIRED_SHIPMENTS_SUCCESS = 'FETCH_EXPIRED_SHIPMENTS_SUCCESS'
export const FETCH_EXPIRED_SHIPMENTS_FAILURE = 'FETCH_EXPIRED_SHIPMENTS_FAILURE'
export const FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST = 'FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST'
export const FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS = 'FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE = 'FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE'
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_MORE_USERS_REQUEST = 'FETCH_MORE_USERS_REQUEST'
export const FETCH_MORE_USERS_SUCCESS = 'FETCH_MORE_USERS_SUCCESS'
export const FETCH_MORE_USERS_FAILURE = 'FETCH_MORE_USERS_FAILURE'
export const FETCH_SHIPPER_USERS_REQUEST = 'FETCH_SHIPPER_USERS_REQUEST'
export const FETCH_SHIPPER_USERS_SUCCESS = 'FETCH_SHIPPER_USERS_SUCCESS'
export const FETCH_SHIPPER_USERS_FAILURE = 'FETCH_SHIPPER_USERS_FAILURE'
export const FETCH_MORE_SHIPPER_USERS_REQUEST = 'FETCH_MORE_SHIPPER_USERS_REQUEST'
export const FETCH_MORE_SHIPPER_USERS_SUCCESS = 'FETCH_MORE_SHIPPER_USERS_SUCCESS'
export const FETCH_MORE_SHIPPER_USERS_FAILURE = 'FETCH_MORE_SHIPPER_USERS_FAILURE'
export const FETCH_CARRIER_USERS_REQUEST = 'FETCH_CARRIER_USERS_REQUEST'
export const FETCH_CARRIER_USERS_SUCCESS = 'FETCH_CARRIER_USERS_SUCCESS'
export const FETCH_CARRIER_USERS_FAILURE = 'FETCH_CARRIER_USERS_FAILURE'
export const FETCH_MORE_CARRIER_USERS_REQUEST = 'FETCH_MORE_CARRIER_USERS_REQUEST'
export const FETCH_MORE_CARRIER_USERS_SUCCESS = 'FETCH_MORE_CARRIER_USERS_SUCCESS'
export const FETCH_MORE_CARRIER_USERS_FAILURE = 'FETCH_MORE_CARRIER_USERS_FAILURE'
export const FETCH_DRIVER_USERS_REQUEST = 'FETCH_DRIVER_USERS_REQUEST'
export const FETCH_DRIVER_USERS_SUCCESS = 'FETCH_DRIVER_USERS_SUCCESS'
export const FETCH_DRIVER_USERS_FAILURE = 'FETCH_DRIVER_USERS_FAILURE'
export const FETCH_MORE_DRIVER_USERS_REQUEST = 'FETCH_MORE_DRIVER_USERS_REQUEST'
export const FETCH_MORE_DRIVER_USERS_SUCCESS = 'FETCH_MORE_DRIVER_USERS_SUCCESS'
export const FETCH_MORE_DRIVER_USERS_FAILURE = 'FETCH_MORE_DRIVER_USERS_FAILURE'
export const FETCH_AGENT_USERS_REQUEST = 'FETCH_AGENT_USERS_REQUEST'
export const FETCH_AGENT_USERS_SUCCESS = 'FETCH_AGENT_USERS_SUCCESS'
export const FETCH_AGENT_USERS_FAILURE = 'FETCH_AGENT_USERS_FAILURE'
export const FETCH_MORE_AGENT_USERS_REQUEST = 'FETCH_MORE_AGENT_USERS_REQUEST'
export const FETCH_MORE_AGENT_USERS_SUCCESS = 'FETCH_MORE_AGENT_USERS_SUCCESS'
export const FETCH_MORE_AGENT_USERS_FAILURE = 'FETCH_MORE_AGENT_USERS_FAILURE'
export const FETCH_DISPATCHER_USERS_REQUEST = 'FETCH_DISPATCHER_USERS_REQUEST'
export const FETCH_DISPATCHER_USERS_SUCCESS = 'FETCH_DISPATCHER_USERS_SUCCESS'
export const FETCH_DISPATCHER_USERS_FAILURE = 'FETCH_DISPATCHER_USERS_FAILURE'
export const FETCH_MORE_DISPATCHER_USERS_REQUEST = 'FETCH_MORE_DISPATCHER_USERS_REQUEST'
export const FETCH_MORE_DISPATCHER_USERS_SUCCESS = 'FETCH_MORE_DISPATCHER_USERS_SUCCESS'
export const FETCH_MORE_DISPATCHER_USERS_FAILURE = 'FETCH_MORE_DISPATCHER_USERS_FAILURE'
export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST'
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS'
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE'
export const FETCH_MORE_REPORTS_REQUEST = 'FETCH_MORE_REPORTS_REQUEST'
export const FETCH_MORE_REPORTS_SUCCESS = 'FETCH_MORE_REPORTS_SUCCESS'
export const FETCH_MORE_REPORTS_FAILURE = 'FETCH_MORE_REPORTS_FAILURE'
export const FETCH_RATES_REQUEST = 'FETCH_RATES_REQUEST'
export const FETCH_RATES_SUCCESS = 'FETCH_RATES_SUCCESS'
export const FETCH_RATES_FAILURE = 'FETCH_RATES_FAILURE'
export const FETCH_MORE_RATES_REQUEST = 'FETCH_MORE_RATES_REQUEST'
export const FETCH_MORE_RATES_SUCCESS = 'FETCH_MORE_RATES_SUCCESS'
export const FETCH_MORE_RATES_FAILURE = 'FETCH_MORE_RATES_FAILURE'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const FETCH_CURRENT_RATE_REQUEST = 'FETCH_CURRENT_RATE_REQUEST'
export const FETCH_CURRENT_RATE_SUCCESS = 'FETCH_CURRENT_RATE_SUCCESS'
export const FETCH_CURRENT_RATE_FAILURE = 'FETCH_CURRENT_RATE_FAILURE'
export const UPDATE_RATE_REQUEST = 'UPDATE_RATE_REQUEST'
export const UPDATE_RATE_SUCCESS = 'UPDATE_RATE_SUCCESS'
export const UPDATE_RATE_FAILURE = 'UPDATE_RATE_FAILURE'
export const ADD_RATE_REQUEST = 'ADD_RATE_REQUEST'
export const ADD_RATE_SUCCESS = 'ADD_RATE_SUCCESS'
export const ADD_RATE_FAILURE = 'ADD_RATE_FAILURE'
export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST'
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE'
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS'
export const FETCH_MORE_TRANSACTIONS_REQUEST = 'FETCH_MORE_TRANSACTIONS_REQUEST'
export const FETCH_MORE_TRANSACTIONS_FAILURE = 'FETCH_MORE_TRANSACTIONS_FAILURE'
export const FETCH_MORE_TRANSACTIONS_SUCCESS = 'FETCH_MORE_TRANSACTIONS_SUCCESS'
export const FETCH_BOOKINGS_REQUEST = 'FETCH_BOOKINGS_REQUEST'
export const FETCH_BOOKINGS_FAILURE = 'FETCH_BOOKINGS_FAILURE'
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS'
export const FETCH_MORE_BOOKINGS_REQUEST = 'FETCH_MORE_BOOKINGS_REQUEST'
export const FETCH_MORE_BOOKINGS_FAILURE = 'FETCH_MORE_BOOKINGS_FAILURE'
export const FETCH_MORE_BOOKINGS_SUCCESS = 'FETCH_MORE_BOOKINGS_SUCCESS'
export const FETCH_CONTRACTED_RATES_REQUEST = 'FETCH_CONTRACTED_RATES_REQUEST'
export const FETCH_CONTRACTED_RATES_SUCCESS = 'FETCH_CONTRACTED_RATES_SUCCESS'
export const FETCH_CONTRACTED_RATES_FAILURE = 'FETCH_CONTRACTED_RATES_FAILURE'
export const FETCH_BOUNDING_SHIPMENTS_REQUEST = 'FETCH_BOUNDING_SHIPMENTS_REQUEST'
export const FETCH_BOUNDING_SHIPMENTS_FAILURE = 'FETCH_BOUNDING_SHIPMENTS_FAILURE'
export const FETCH_BOUNDING_SHIPMENTS_SUCCESS = 'FETCH_BOUNDING_SHIPMENTS_SUCCESS'
export const FETCH_GLOBAL_COUNTS_REQUEST = 'FETCH_GLOBAL_COUNTS_REQUEST'
export const FETCH_GLOBAL_COUNTS_FAILURE = 'FETCH_GLOBAL_COUNTS_FAILURE'
export const FETCH_GLOBAL_COUNTS_SUCCESS = 'FETCH_GLOBAL_COUNTS_SUCCESS'
export const FETCH_GLOBAL_WEEKLY_COUNTS_REQUEST = 'FETCH_GLOBAL_WEEKLY_COUNTS_REQUEST'
export const FETCH_GLOBAL_WEEKLY_COUNTS_FAILURE = 'FETCH_GLOBAL_WEEKLY_COUNTS_FAILURE'
export const FETCH_GLOBAL_WEEKLY_COUNTS_SUCCESS = 'FETCH_GLOBAL_WEEKLY_COUNTS_SUCCESS'
export const FETCH_GLOBAL_MONTHLY_COUNTS_REQUEST = 'FETCH_GLOBAL_MONTHLY_COUNTS_REQUEST'
export const FETCH_GLOBAL_MONTHLY_COUNTS_FAILURE = 'FETCH_GLOBAL_MONTHLY_COUNTS_FAILURE'
export const FETCH_GLOBAL_MONTHLY_COUNTS_SUCCESS = 'FETCH_GLOBAL_MONTHLY_COUNTS_SUCCESS'
export const FETCH_GLOBAL_HISTORY_REQUEST = 'FETCH_GLOBAL_HISTORY_REQUEST'
export const FETCH_GLOBAL_HISTORY_FAILURE = 'FETCH_GLOBAL_HISTORY_FAILURE'
export const FETCH_GLOBAL_HISTORY_SUCCESS = 'FETCH_GLOBAL_HISTORY_SUCCESS'
export const FETCH_GLOBAL_FARES_REQUEST = 'FETCH_GLOBAL_FARES_REQUEST'
export const FETCH_GLOBAL_FARES_FAILURE = 'FETCH_GLOBAL_FARES_FAILURE'
export const FETCH_GLOBAL_FARES_SUCCESS = 'FETCH_GLOBAL_FARES_SUCCESS'
export const FETCH_RATE_HISTORY_REQUEST = 'FETCH_RATE_HISTORY_REQUEST'
export const FETCH_RATE_HISTORY_SUCCESS = 'FETCH_RATE_HISTORY_SUCCESS'
export const FETCH_RATE_HISTORY_FAILURE = 'FETCH_RATE_HISTORY_FAILURE'
export const FETCH_SHIPPER_HISTORY_REQUEST = 'FETCH_GLOBAL_SHIPPER_HISTORY_REQUEST'
export const FETCH_SHIPPER_HISTORY_FAILURE = 'FETCH_GLOBAL_SHIPPER_HISTORY_FAILURE'
export const FETCH_SHIPPER_HISTORY_SUCCESS = 'FETCH_GLOBAL_SHIPPER_HISTORY_SUCCESS'
export const FETCH_RECENTLY_ACTIVE_SHIPPERS_REQUEST = 'FETCH_RECENTLY_ACTIVE_SHIPPERS_REQUEST'
export const FETCH_RECENTLY_ACTIVE_SHIPPERS_FAILURE = 'FETCH_RECENTLY_ACTIVE_SHIPPERS_FAILURE'
export const FETCH_RECENTLY_ACTIVE_SHIPPERS_SUCCESS = 'FETCH_RECENTLY_ACTIVE_SHIPPERS_SUCCESS'
export const OVERRIDE_SHIPMENT_STATUS_REQUEST = 'OVERRIDE_SHIPMENT_STATUS_REQUEST'
export const OVERRIDE_SHIPMENT_STATUS_SUCCESS = 'OVERRIDE_SHIPMENT_STATUS_SUCCESS'
export const OVERRIDE_SHIPMENT_STATUS_FAILURE = 'OVERRIDE_SHIPMENT_STATUS_FAILURE'
export const ADD_CARRIER_TO_SHIPMENT_REQUEST = 'ADD_CARRIER_TO_SHIPMENT_REQUEST'
export const ADD_CARRIER_TO_SHIPMENT_SUCCESS = 'ADD_CARRIER_TO_SHIPMENT_SUCCESS'
export const ADD_CARRIER_TO_SHIPMENT_FAILURE = 'ADD_CARRIER_TO_SHIPMENT_FAILURE'
export const SORT_USERS = 'SORT_USERS'
export const FETCH_USER_SHIPMENTS_REQUEST = 'FETCH_USER_SHIPMENTS_REQUEST'
export const FETCH_USER_SHIPMENTS_FAILURE = 'FETCH_USER_SHIPMENTS_FAILURE'
export const FETCH_USER_SHIPMENTS_SUCCESS = 'FETCH_USER_SHIPMENTS_SUCCESS'
export const FETCH_MORE_USER_SHIPMENTS_REQUEST = 'FETCH_MORE_USER_SHIPMENTS_REQUEST'
export const FETCH_MORE_USER_SHIPMENTS_FAILURE = 'FETCH_MORE_USER_SHIPMENTS_FAILURE'
export const FETCH_MORE_USER_SHIPMENTS_SUCCESS = 'FETCH_MORE_USER_SHIPMENTS_SUCCESS'
export const FETCH_BOUNDING_USERS_REQUEST = 'FETCH_BOUNDING_USERS_REQUEST '
export const FETCH_BOUNDING_USERS_SUCCESS = 'FETCH_BOUNDING_USERS_SUCCESS'
export const FETCH_BOUNDING_USERS_FAILURE = 'FETCH_BOUNDING_USERS_FAILURE'
export const FETCH_MORE_CONTRACTED_RATES_REQUEST = 'FETCH_MORE_CONTRACTED_RATES_REQUEST'
export const FETCH_MORE_CONTRACTED_RATES_SUCCESS = 'FETCH_MORE_CONTRACTED_RATES_SUCCESS'
export const FETCH_MORE_CONTRACTED_RATES_FAILURE = 'FETCH_MORE_CONTRACTED_RATES_FAILURE'
export const SEARCH_RATES_REQUEST = 'SEARCH_RATES_REQUEST'
export const SEARCH_RATES_SUCCESS = 'SEARCH_RATES_SUCCESS'
export const SEARCH_RATES_FAILURE = 'SEARCH_RATES_FAILURE'
export const FETCH_CONTRACTS_REQUEST = 'FETCH_CONTRACTS_REQUEST'
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS'
export const FETCH_CONTRACTS_FAILURE = 'FETCH_CONTRACTS_FAILURE'
export const FETCH_MORE_CONTRACTS_REQUEST = 'FETCH_MORE_CONTRACTS_REQUEST'
export const FETCH_MORE_CONTRACTS_SUCCESS = 'FETCH_MORE_CONTRACTS_SUCCESS'
export const FETCH_MORE_CONTRACTS_FAILURE = 'FETCH_MORE_CONTRACTS_FAILURE'
export const FETCH_CONTRACT_REQUEST = 'FETCH_CONTRACT_REQUEST'
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS'
export const FETCH_CONTRACT_FAILURE = 'FETCH_CONTRACT_FAILURE'
export const ADD_CONTRACT_REQUEST = 'ADD_CONTRACT_REQUEST'
export const ADD_CONTRACT_SUCCESS = 'ADD_CONTRACT_SUCCESS'
export const ADD_CONTRACT_FAILURE = 'ADD_CONTRACT_FAILURE'
export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST'
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS'
export const UPDATE_CONTRACT_FAILURE = 'UPDATE_CONTRACT_FAILURE'
export const UPDATE_CONTRACT_STATUS_REQUEST = 'UPDATE_CONTRACT_STATUS_REQUEST'
export const UPDATE_CONTRACT_STATUS_SUCCESS = 'UPDATE_CONTRACT_STATUS_SUCCESS'
export const UPDATE_CONTRACT_STATUS_FAILURE = 'UPDATE_CONTRACT_STATUS_FAILURE'
export const FETCH_WATCHING_SHIPMENTS_REQUEST = 'FETCH_WATCHING_SHIPMENTS_REQUEST'
export const FETCH_WATCHING_SHIPMENTS_SUCCESS = 'FETCH_WATCHING_SHIPMENTS_SUCCESS'
export const FETCH_WATCHING_SHIPMENTS_FAILURE = 'FETCH_WATCHING_SHIPMENTS_FAILURE'
export const FETCH_MORE_WATCHING_SHIPMENTS_REQUEST = 'FETCH_MORE_WATCHING_SHIPMENTS_REQUEST'
export const FETCH_MORE_WATCHING_SHIPMENTS_SUCCESS = 'FETCH_MORE_WATCHING_SHIPMENTS_SUCCESS'
export const FETCH_MORE_WATCHING_SHIPMENTS_FAILURE = 'FETCH_MORE_WATCHING_SHIPMENTS_FAILURE'
export const WATCH_SHIPMENT_REQUEST = 'WATCH_SHIPMENT_REQUEST'
export const WATCH_SHIPMENT_SUCCESS = 'WATCH_SHIPMENT_SUCCESS'
export const WATCH_SHIPMENT_FAILURE = 'WATCH_SHIPMENT_FAILURE'
export const FETCH_ADMIN_ASSIGNED_SHIPMENTS_REQUEST = 'FETCH_ADMIN_ASSIGNED_SHIPMENTS_REQUEST'
export const FETCH_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS = 'FETCH_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS'
export const FETCH_ADMIN_ASSIGNED_SHIPMENTS_FAILURE = 'FETCH_ADMIN_ASSIGNED_SHIPMENTS_FAILURE'
export const FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_REQUEST = 'FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_REQUEST'
export const FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS = 'FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_FAILURE = 'FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_FAILURE'
export const ASSIGN_SHIPMENT_REQUEST = 'ASSIGN_SHIPMENT_REQUEST'
export const ASSIGN_SHIPMENT_SUCCESS = 'ASSIGN_SHIPMENT_SUCCESS'
export const ASSIGN_SHIPMENT_FAILURE = 'ASSIGN_SHIPMENT_FAILURE'
export const MASS_ASSIGN_SHIPMENTS_REQUEST = 'MASS_ASSIGN_SHIPMENTS_REQUEST'
export const MASS_ASSIGN_SHIPMENTS_SUCCESS = 'MASS_ASSIGN_SHIPMENTS_SUCCESS'
export const MASS_ASSIGN_SHIPMENTS_FAILURE = 'MASS_ASSIGN_SHIPMENTS_FAILURE'
export const UPDATE_STAT_COUNTS = 'UPDATE_STAT_COUNTS'
export const FETCH_PENDING_CONTRACTS_REQUEST = 'FETCH_PENDING_CONTRACTS_REQUEST'
export const FETCH_PENDING_CONTRACTS_SUCCESS = 'FETCH_PENDING_CONTRACTS_SUCCESS'
export const FETCH_PENDING_CONTRACTS_FAILURE = 'FETCH_PENDING_CONTRACTS_FAILURE'
export const FETCH_MORE_PENDING_CONTRACTS_REQUEST = 'FETCH_MORE_PENDING_CONTRACTS_REQUEST'
export const FETCH_MORE_PENDING_CONTRACTS_SUCCESS = 'FETCH_MORE_PENDING_CONTRACTS_SUCCESS'
export const FETCH_MORE_PENDING_CONTRACTS_FAILURE = 'FETCH_MORE_PENDING_CONTRACTS_FAILURE'
export const FETCH_BILLS_REQUEST = 'FETCH_BILLS_REQUEST'
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS'
export const FETCH_BILLS_FAILURE = 'FETCH_BILLS_FAILURE'
export const FETCH_MORE_BILLS_REQUEST = 'FETCH_MORE_BILLS_REQUEST'
export const FETCH_MORE_BILLS_SUCCESS = 'FETCH_MORE_BILLS_SUCCESS'
export const FETCH_MORE_BILLS_FAILURE = 'FETCH_MORE_BILLS_FAILURE'
export const CREATE_BILL_REQUEST = 'CREATE_BILL_REQUEST'
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS'
export const CREATE_BILL_FAILURE = 'CREATE_BILL_FAILURE'
export const FETCH_CONTRACTED_SHIPPERS_REQUEST = 'FETCH_CONTRACTED_SHIPPERS_REQUEST'
export const FETCH_CONTRACTED_SHIPPERS_SUCCESS = 'FETCH_CONTRACTED_SHIPPERS_SUCCESS'
export const FETCH_CONTRACTED_SHIPPERS_FAILURE = 'FETCH_CONTRACTED_SHIPPERS_FAILURE'
export const FETCH_SHIPPER_CONTRACT_RATES_REQUEST = 'FETCH_SHIPPER_CONTRACT_RATES_REQUEST'
export const FETCH_SHIPPER_CONTRACT_RATES_SUCCESS = 'FETCH_SHIPPER_CONTRACT_RATES_SUCCESS'
export const FETCH_SHIPPER_CONTRACT_RATES_FAILURE = 'FETCH_SHIPPER_CONTRACT_RATES_FAILURE'
export const ADD_CHARGE_REQUEST = 'ADD_CHARGE_REQUEST'
export const ADD_CHARGE_SUCCESS = 'ADD_CHARGE_SUCCESS'
export const ADD_CHARGE_FAILURE = 'ADD_CHARGE_FAILURE'
export const REMOVE_CHARGE_REQUEST = 'REMOVE_CHARGE_REQUEST'
export const REMOVE_CHARGE_SUCCESS = 'REMOVE_CHARGE_SUCCESS'
export const REMOVE_CHARGE_FAILURE = 'REMOVE_CHARGE_FAILURE'
export const FETCH_ADMIN_NOTIFICATIONS_REQUEST = 'FETCH_ADMIN_NOTIFICATIONS_REQUEST'
export const FETCH_ADMIN_NOTIFICATIONS_FAILURE = 'FETCH_ADMIN_NOTIFICATIONS_FAILURE'
export const FETCH_ADMIN_NOTIFICATIONS_SUCCESS = 'FETCH_ADMIN_NOTIFICATIONS_SUCCESS'
export const FETCH_MORE_ADMIN_NOTIFICATIONS_REQUEST = 'FETCH_MORE_ADMIN_NOTIFICATIONS_REQUEST'
export const FETCH_MORE_ADMIN_NOTIFICATIONS_FAILURE = 'FETCH_MORE_ADMIN_NOTIFICATIONS_FAILURE'
export const FETCH_MORE_ADMIN_NOTIFICATIONS_SUCCESS = 'FETCH_MORE_ADMIN_NOTIFICATIONS_SUCCESS'
export const ADD_ADMIN_NOTIFICATION = 'ADD_ADMIN_NOTIFICATION'
export const CLEAR_ADMIN_NOTIFICATIONS = 'CLEAR_ADMIN_NOTIFICATIONS'
export const UPDATE_ADMIN_NOTIFICATION = 'UPDATE_ADMIN_NOTIFICATION'
export const READ_ADMIN_NOTIFICATION = 'READ_ADMIN_NOTIFICATION'
export const ADD_LOCATION_REQUEST = 'ADD_LOCATION_REQUEST'
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS'
export const ADD_LOCATION_FAILURE = 'ADD_LOCATION_FAILURE'
export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST'
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS'
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE'
export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE'
export const FETCH_REVENUE_REQUEST = 'FETCH_REVENUE_REQUEST'
export const FETCH_REVENUE_FAILURE = 'FETCH_REVENUE_FAILURE'
export const FETCH_REVENUE_SUCCESS = 'FETCH_REVENUE_SUCCESS'
export const SEARCH_BILLS_REQUEST = 'SEARCH_BILLS_REQUEST'
export const SEARCH_BILLS_SUCCESS = 'SEARCH_BILLS_SUCCESS'
export const SEARCH_BILLS_FAILURE = 'SEARCH_BILLS_FAILURE'
export const SEARCH_MORE_BILLS_REQUEST = 'SEARCH_MORE_BILLS_REQUEST'
export const SEARCH_MORE_BILLS_SUCCESS = 'SEARCH_MORE_BILLS_SUCCESS'
export const SEARCH_MORE_BILLS_FAILURE = 'SEARCH_MORE_BILLS_FAILURE'
export const FETCH_ALL_COMPANIES_REQUEST = 'FETCH_ALL_COMPANIES_REQUEST'
export const FETCH_ALL_COMPANIES_SUCCESS = 'FETCH_ALL_COMPANIES_SUCCESS'
export const FETCH_ALL_COMPANIES_FAILURE = 'FETCH_ALL_COMPANIES_FAILURE'
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE'
export const FETCH_MORE_COMPANIES_REQUEST = 'FETCH_MORE_COMPANIES_REQUEST'
export const FETCH_MORE_COMPANIES_SUCCESS = 'FETCH_MORE_COMPANIES_SUCCESS'
export const FETCH_MORE_COMPANIES_FAILURE = 'FETCH_MORE_COMPANIES_FAILURE'
export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST'
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS'
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE'
export const FETCH_ANNUAL_DASHBOARD_REQUEST = 'FETCH_ANNUAL_DASHBOARD_REQUEST'
export const FETCH_ANNUAL_DASHBOARD_SUCCESS = 'FETCH_ANNUAL_DASHBOARD_SUCCESS'
export const FETCH_ANNUAL_DASHBOARD_FAILURE = 'FETCH_ANNUAL_DASHBOARD_FAILURE'
export const FETCH_BILL_REQUEST = 'FETCH_BILL_REQUEST'
export const FETCH_BILL_SUCCESS = 'FETCH_BILL_SUCCESS'
export const FETCH_BILL_FAILURE = 'FETCH_BILL_FAILURE'
export const DELETE_BILL_REQUEST = 'DELETE_BILL_REQUEST'
export const DELETE_BILL_SUCCESS = 'DELETE_BILL_SUCCESS'
export const DELETE_BILL_FAILURE = 'DELETE_BILL_FAILURE'
export const DELETE_CONTRACT_REQUEST = 'DELETE_CONTRACT_REQUEST'
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS'
export const DELETE_CONTRACT_FAILURE = 'DELETE_CONTRACT_FAILURE'
export const FETCH_USERS_DASHBOARD_REQUEST = 'FETCH_USERS_DASHBOARD_REQUEST'
export const FETCH_USERS_DASHBOARD_SUCCESS = 'FETCH_USERS_DASHBOARD_SUCCESS'
export const FETCH_USERS_DASHBOARD_FAILURE = 'FETCH_USERS_DASHBOARD_FAILURE'
export const FETCH_EVENTS_DASHBOARD_REQUEST = 'FETCH_EVENTS_DASHBOARD_REQUEST'
export const FETCH_EVENTS_DASHBOARD_SUCCESS = 'FETCH_EVENTS_DASHBOARD_SUCCESS'
export const FETCH_EVENTS_DASHBOARD_FAILURE = 'FETCH_EVENTS_DASHBOARD_FAILURE'
export const SEARCH_COMPANIES_REQUEST = 'SEARCH_COMPANIES_REQUEST'
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS'
export const SEARCH_COMPANIES_FAILURE = 'SEARCH_COMPANIES_FAILURE'
export const SEARCH_MORE_COMPANIES_REQUEST = 'SEARCH_MORE_COMPANIES_REQUEST'
export const SEARCH_MORE_COMPANIES_SUCCESS = 'SEARCH_MORE_COMPANIES_SUCCESS'
export const SEARCH_MORE_COMPANIES_FAILURE = 'SEARCH_MORE_COMPANIES_FAILURE'
export const SEARCH_CONTRACTS_REQUEST = 'SEARCH_CONTRACTS_REQUEST'
export const SEARCH_CONTRACTS_SUCCESS = 'SEARCH_CONTRACTS_SUCCESS'
export const SEARCH_CONTRACTS_FAILURE = 'SEARCH_CONTRACTS_FAILURE'
export const SEARCH_MORE_CONTRACTS_REQUEST = 'SEARCH_MORE_CONTRACTS_REQUEST'
export const SEARCH_MORE_CONTRACTS_SUCCESS = 'SEARCH_MORE_CONTRACTS_SUCCESS'
export const SEARCH_MORE_CONTRACTS_FAILURE = 'SEARCH_MORE_CONTRACTS_FAILURE'
export const FETCH_BOOKING_SHIPMENTS_REQUEST = 'FETCH_BOOKING_SHIPMENTS_REQUEST'
export const FETCH_BOOKING_SHIPMENTS_SUCCESS = 'FETCH_BOOKING_SHIPMENTS_SUCCESS'
export const FETCH_BOOKING_SHIPMENTS_FAILURE = 'FETCH_BOOKING_SHIPMENTS_FAILURE'
export const LISTEN_TO_VENDOR_REQUESTS = 'LISTEN_TO_VENDOR_REQUESTS'
export const UPDATE_VENDOR_REQUEST = 'UPDATE_VENDOR_REQUEST'
export const FETCH_DAILY_SUMMARY_REQUEST = 'FETCH_DAILY_SUMMARY_REQUEST'
export const FETCH_DAILY_SUMMARY_SUCCESS = 'FETCH_DAILY_SUMMARY_SUCCESS'
export const FETCH_DAILY_SUMMARY_FAILURE = 'FETCH_DAILY_SUMMARY_FAILURE'
export const FETCH_MONTHLY_GENERAL_ANALYTICS_REQUEST = 'FETCH_MONTHLY_GENERAL_ANALYTICS_REQUEST'
export const FETCH_MONTHLY_GENERAL_ANALYTICS_SUCCESS = 'FETCH_MONTHLY_GENERAL_ANALYTICS_SUCCESS'
export const FETCH_MONTHLY_GENERAL_ANALYTICS_FAILURE = 'FETCH_MONTHLY_GENERAL_ANALYTICS_FAILURE'
export const FETCH_MONTHLY_OPERATIONS_ANALYTICS_REQUEST = 'FETCH_MONTHLY_OPERATIONS_ANALYTICS_REQUEST'
export const FETCH_MONTHLY_OPERATIONS_ANALYTICS_SUCCESS = 'FETCH_MONTHLY_OPERATIONS_ANALYTICS_SUCCESS'
export const FETCH_MONTHLY_OPERATIONS_ANALYTICS_FAILURE = 'FETCH_MONTHLY_OPERATIONS_ANALYTICS_FAILURE'
export const FETCH_MONTHLY_VENDOR_ANALYTICS_REQUEST = 'FETCH_MONTHLY_VENDOR_ANALYTICS_REQUEST'
export const FETCH_MONTHLY_VENDOR_ANALYTICS_SUCCESS = 'FETCH_MONTHLY_VENDOR_ANALYTICS_SUCCESS'
export const FETCH_MONTHLY_VENDOR_ANALYTICS_FAILURE = 'FETCH_MONTHLY_VENDOR_ANALYTICS_FAILURE'
export const FETCH_ANNUAL_TRENDS_ANALYTICS_REQUEST = 'FETCH_ANNUAL_TRENDS_ANALYTICS_REQUEST'
export const FETCH_ANNUAL_TRENDS_ANALYTICS_SUCCESS = 'FETCH_ANNUAL_TRENDS_ANALYTICS_SUCCESS'
export const FETCH_ANNUAL_TRENDS_ANALYTICS_FAILURE = 'FETCH_ANNUAL_TRENDS_ANALYTICS_FAILURE'
export const FETCH_REQUESTED_BOOKINGS_REQUEST = 'FETCH_REQUESTED_BOOKINGS_REQUEST'
export const FETCH_REQUESTED_BOOKINGS_FAILURE = 'FETCH_REQUESTED_BOOKINGS_FAILURE'
export const FETCH_REQUESTED_BOOKINGS_SUCCESS = 'FETCH_REQUESTED_BOOKINGS_SUCCESS'
export const FETCH_MORE_REQUESTED_BOOKINGS_REQUEST = 'FETCH_MORE_REQUESTED_BOOKINGS_REQUEST'
export const FETCH_MORE_REQUESTED_BOOKINGS_FAILURE = 'FETCH_MORE_REQUESTED_BOOKINGS_FAILURE'
export const FETCH_MORE_REQUESTED_BOOKINGS_SUCCESS = 'FETCH_MORE_REQUESTED_BOOKINGS_SUCCESS'
export const FETCH_ONGOING_BOOKINGS_REQUEST = 'FETCH_ONGOING_BOOKINGS_REQUEST'
export const FETCH_ONGOING_BOOKINGS_FAILURE = 'FETCH_ONGOING_BOOKINGS_FAILURE'
export const FETCH_ONGOING_BOOKINGS_SUCCESS = 'FETCH_ONGOING_BOOKINGS_SUCCESS'
export const FETCH_MORE_ONGOING_BOOKINGS_REQUEST = 'FETCH_MORE_ONGOING_BOOKINGS_REQUEST'
export const FETCH_MORE_ONGOING_BOOKINGS_FAILURE = 'FETCH_MORE_ONGOING_BOOKINGS_FAILURE'
export const FETCH_MORE_ONGOING_BOOKINGS_SUCCESS = 'FETCH_MORE_ONGOING_BOOKINGS_SUCCESS'
