import React from 'react'
import './loading-page.scss'
import Spinner from 'react-spinkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import PropTypes from 'prop-types'

const LoadingPage = ({ t }) => {

	return (
		<div className='loading-page'>
			<div className='loading-container'>
				<Spinner
					style={{ width: '75px', height: '75px' }}
					color='#109BF8'
					name='double-bounce'
					fadeIn='none'
				/>
				<h3>{t('loading')}...</h3>
			</div>
			<p className='copyright'>
				{t('copyright', { year: new Date().getFullYear() })}
			</p>
		</div>
	)
}

LoadingPage.propTypes = {
	t: PropTypes.func
}

export default compose(
	withTranslation()
)(LoadingPage)
