import {
	FETCH_TRUCKS_REQUEST,
	FETCH_TRUCKS_SUCCESS,
	FETCH_TRUCKS_FAILURE,
	FETCH_MORE_TRUCKS_REQUEST,
	FETCH_MORE_TRUCKS_SUCCESS,
	FETCH_MORE_TRUCKS_FAILURE,
	ADD_TRUCK_REQUEST,
	ADD_TRUCK_SUCCESS,
	ADD_TRUCK_FAILURE,
	FETCH_ACTIVATED_TRUCKS_REQUEST,
	FETCH_ACTIVATED_TRUCKS_SUCCESS,
	FETCH_ACTIVATED_TRUCKS_FAILURE,
	FETCH_MORE_ACTIVATED_TRUCKS_FAILURE,
	FETCH_MORE_ACTIVATED_TRUCKS_REQUEST,
	FETCH_MORE_ACTIVATED_TRUCKS_SUCCESS,
	FETCH_DEACTIVATED_TRUCKS_REQUEST,
	FETCH_DEACTIVATED_TRUCKS_SUCCESS,
	FETCH_DEACTIVATED_TRUCKS_FAILURE,
	FETCH_MORE_DEACTIVATED_TRUCKS_FAILURE,
	FETCH_MORE_DEACTIVATED_TRUCKS_REQUEST,
	FETCH_MORE_DEACTIVATED_TRUCKS_SUCCESS,
	UPDATE_TRUCK_REQUEST,
	UPDATE_TRUCK_SUCCESS,
	UPDATE_TRUCK_FAILURE,
	ASSIGN_TO_TRUCK_REQUEST,
	ASSIGN_TO_TRUCK_SUCCESS,
	ASSIGN_TO_TRUCK_FAILURE,
	UNASSIGN_FROM_TRUCK_REQUEST,
	UNASSIGN_FROM_TRUCK_SUCCESS,
	UNASSIGN_FROM_TRUCK_FAILURE,
	SEARCH_TRUCKS_REQUEST,
	SEARCH_TRUCKS_SUCCESS,
	SEARCH_TRUCKS_FAILURE,
	SEARCH_MORE_TRUCKS_REQUEST,
	SEARCH_MORE_TRUCKS_SUCCESS,
	SEARCH_MORE_TRUCKS_FAILURE,
	FETCH_AVAILABLE_TRUCKS_FAILURE,
	FETCH_AVAILABLE_TRUCKS_REQUEST,
	FETCH_AVAILABLE_TRUCKS_SUCCESS,
	FETCH_MORE_AVAILABLE_TRUCKS_REQUEST,
	FETCH_MORE_AVAILABLE_TRUCKS_FAILURE,
	FETCH_MORE_AVAILABLE_TRUCKS_SUCCESS,
	FETCH_UNAVAILABLE_TRUCKS_REQUEST,
	FETCH_UNAVAILABLE_TRUCKS_FAILURE,
	FETCH_UNAVAILABLE_TRUCKS_SUCCESS,
	FETCH_MORE_UNAVAILABLE_TRUCKS_REQUEST,
	FETCH_MORE_UNAVAILABLE_TRUCKS_FAILURE,
	FETCH_MORE_UNAVAILABLE_TRUCKS_SUCCESS
} from './action-types'
import API from '../../utils/api'
import {
	ACTIVATED_TRUCK_STATUS,
	DEACTIVATED_TRUCK_STATUS
} from '../../constants/strings'

function getAllTrucks (status, type, carrierId, lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId? FETCH_MORE_TRUCKS_REQUEST : FETCH_TRUCKS_REQUEST })
		return API.getTrucks(status, type, carrierId, null, null, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId? FETCH_MORE_TRUCKS_SUCCESS : FETCH_TRUCKS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId? FETCH_MORE_TRUCKS_FAILURE : FETCH_TRUCKS_FAILURE,
					payload: error
				})
			)
	}
}

function getActivatedTrucks (status, carrierId, lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_ACTIVATED_TRUCKS_REQUEST : FETCH_ACTIVATED_TRUCKS_REQUEST })
		return API.getTrucks(ACTIVATED_TRUCK_STATUS, null, carrierId, null, null, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId? FETCH_MORE_ACTIVATED_TRUCKS_SUCCESS : FETCH_ACTIVATED_TRUCKS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId? FETCH_MORE_ACTIVATED_TRUCKS_FAILURE : FETCH_ACTIVATED_TRUCKS_FAILURE,
					payload: error
				})
			)
	}
}

function getDeactivatedTrucks (status, carrierId, lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_DEACTIVATED_TRUCKS_REQUEST : FETCH_DEACTIVATED_TRUCKS_REQUEST })
		return API.getTrucks(DEACTIVATED_TRUCK_STATUS, null, carrierId, null, null, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_DEACTIVATED_TRUCKS_SUCCESS : FETCH_DEACTIVATED_TRUCKS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId? FETCH_MORE_DEACTIVATED_TRUCKS_FAILURE : FETCH_DEACTIVATED_TRUCKS_FAILURE,
					payload: error
				})
			)
	}
}

function addTruck (truckData, carrierId) {
	return (dispatch) => {
		dispatch({ type: ADD_TRUCK_REQUEST })
		return API.addTruck(truckData, carrierId)
			.then(
				response => dispatch({
					type: ADD_TRUCK_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_TRUCK_FAILURE,
					payload: error
				})
			)
	}
}

function updateTruck (id, truckData, carrierId) {
	return (dispatch) => {
		dispatch({ type: UPDATE_TRUCK_REQUEST })
		return API.updateTruck(id, truckData, carrierId)
			.then(
				response => dispatch({
					type: UPDATE_TRUCK_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_TRUCK_FAILURE,
					payload: error
				})
			)
	}
}

function assignToTruck (id, truckData) {
	return (dispatch) => {
		dispatch({ type: ASSIGN_TO_TRUCK_REQUEST })
		return API.assignTruck(id, truckData)
			.then(
				response => dispatch({
					type: ASSIGN_TO_TRUCK_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ASSIGN_TO_TRUCK_FAILURE,
					payload: error
				})
			)
	}
}

function unassignFromTruck (id) {
	return (dispatch) => {
		dispatch({ type: UNASSIGN_FROM_TRUCK_REQUEST })
		return API.unassignTruck(id)
			.then(
				response => dispatch({
					type: UNASSIGN_FROM_TRUCK_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UNASSIGN_FROM_TRUCK_FAILURE,
					payload: error
				})
			)
	}
}

function searchTrucks (searchTerm, truckType, pageNumber) {
	return (dispatch) => {
		dispatch({ type: pageNumber? SEARCH_MORE_TRUCKS_REQUEST : SEARCH_TRUCKS_REQUEST })
		return API.searchTrucks(searchTerm, truckType, pageNumber)
			.then(
				response => dispatch({
					type: pageNumber? SEARCH_MORE_TRUCKS_SUCCESS : SEARCH_TRUCKS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber? SEARCH_MORE_TRUCKS_FAILURE : SEARCH_TRUCKS_FAILURE,
					payload: error
				})
			)
	}
}

function searchCarrierTrucks (searchTerm, pageNumber, status) {
	return (dispatch) => {
		dispatch({ type: pageNumber? SEARCH_MORE_TRUCKS_REQUEST : SEARCH_TRUCKS_REQUEST })
		return API.searchCarrierTrucks(searchTerm, pageNumber, status)
			.then(
				response => dispatch({
					type: pageNumber? SEARCH_MORE_TRUCKS_SUCCESS : SEARCH_TRUCKS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber? SEARCH_MORE_TRUCKS_FAILURE : SEARCH_TRUCKS_FAILURE,
					payload: error
				})
			)
	}
}

function getAvailableTrucks (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_AVAILABLE_TRUCKS_REQUEST : FETCH_AVAILABLE_TRUCKS_REQUEST })
		return API.getAvailableTrucks(true, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_AVAILABLE_TRUCKS_SUCCESS : FETCH_AVAILABLE_TRUCKS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_AVAILABLE_TRUCKS_FAILURE : FETCH_AVAILABLE_TRUCKS_FAILURE,
					payload: error
				})
			)
	}
}

function getUnavailableTrucks (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_UNAVAILABLE_TRUCKS_REQUEST : FETCH_UNAVAILABLE_TRUCKS_REQUEST })
		return API.getAvailableTrucks(false, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_UNAVAILABLE_TRUCKS_SUCCESS : FETCH_UNAVAILABLE_TRUCKS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_UNAVAILABLE_TRUCKS_FAILURE : FETCH_UNAVAILABLE_TRUCKS_FAILURE,
					payload: error
				})
			)
	}
}

export {
	getAllTrucks,
	getActivatedTrucks,
	getDeactivatedTrucks,
	addTruck,
	updateTruck,
	assignToTruck,
	unassignFromTruck,
	searchTrucks,
	searchCarrierTrucks,
	getAvailableTrucks,
	getUnavailableTrucks
}
