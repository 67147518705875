import { combineReducers } from 'redux'
import {
	SET_AUTH_USER,
	SET_PERMISSIONS,
	SET_USER_LOGIN,
	UPDATE_USER_PASSWORD_SUCCESS
} from './action-types'
import {
	BOOK_SHIPMENT_SUCCESS
} from '../shipments/action-types'

const user = (state = null, { type, payload }) => {
	switch (type) {
		case SET_AUTH_USER:
			return payload
		case BOOK_SHIPMENT_SUCCESS:
			return {
				...state,
				defaultTruck: payload.truck,
				defaultItemType: payload.itemType
			}
		default:
			return state
	}
}

const permissions = (state = { }, { type, payload }) => {
	switch (type) {
		case SET_PERMISSIONS:
			return payload
		default:
			return state
	}
}

const userLogin = (state = null, { type, payload }) => {
	switch (type) {
		case SET_USER_LOGIN:
			return payload
		case UPDATE_USER_PASSWORD_SUCCESS:
			return {
				email: payload.email,
				password: payload.password
			}
		default:
			return state
	}
}

const authentication = combineReducers({
	user,
	userLogin,
	permissions
})

export default authentication

export const getAuthUser = state => state.user
export const getUserLogin = state => state.userLogin
export const getPermissions = state => state.permissions
