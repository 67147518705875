import { combineReducers } from 'redux'
import {
	FETCH_CHATS_REQUEST,
	FETCH_CHATS_SUCCESS,
	FETCH_CHATS_FAILURE,
	FETCH_MESSAGES_REQUEST,
	FETCH_MESSAGES_SUCCESS,
	FETCH_MESSAGES_FAILURE,
	UPDATE_CHAT_SUCCESS,
	ADD_CHAT_SUCCESS,
	ADD_MESSAGE_SUCCESS,
	SEND_MESSAGE_REQUEST,
	UPDATE_CHAT_COUNT_SUCCESS,
	FETCH_MORE_CHATS_FAILURE,
	FETCH_MORE_CHATS_REQUEST,
	FETCH_MORE_CHATS_SUCCESS,
	FETCH_CONVERSATIONS_SUCCESS,
	FETCH_MORE_CONVERSATIONS_SUCCESS,
	UPDATE_CONVERSATION_SUCCESS,
	FETCH_CONVERSATION_MESSAGES_SUCCESS,
	FETCH_MORE_CONVERSATION_MESSAGES_SUCCESS,
	ADD_CONVERSATION_MESSAGE_SUCCESS,
	ADD_CONVERSATION_SUCCESS,
	UPDATE_CONVERSATION_COUNT_SUCCESS,
	CREATE_CONVERSATION_MESSAGE_REQUEST,
	CREATE_CONVERSATION_SUCCESS
} from './action-types'
import {
	FETCH_SHIPMENT_SUCCESS
} from '../shipments/action-types'
import {
	FETCH_DRIVER_SUCCESS
} from '../drivers/action-types'

const unreadChatCount = (state = 0, { type, payload }) => {
	switch (type) {
		case UPDATE_CHAT_COUNT_SUCCESS:
			return payload
		default:
			return state
	}
}

const chats = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CHATS_REQUEST:
		case FETCH_CHATS_FAILURE:
		case FETCH_MORE_CHATS_REQUEST:
		case FETCH_MORE_CHATS_FAILURE:
			return state
		case FETCH_CHATS_SUCCESS:
			return payload.chats
		case FETCH_MORE_CHATS_SUCCESS:
			return [...state, ...payload.chats]
		case ADD_CHAT_SUCCESS: {
			const chat = state.find(chat => chat.id === payload.id)
			if (!chat) {
				return [payload, ...state]
			} else {
				return state
			}
		}
		case UPDATE_CHAT_SUCCESS:
			return state.map(chat => chat.id === payload.id
				? payload
				: chat
			)
		case FETCH_SHIPMENT_SUCCESS:
			return state.map(chat => chat.shipmentId === payload.id ?
				{
					...chat,
					shipment: payload
				}
				: chat
			)
		case FETCH_DRIVER_SUCCESS:
			return state.map(chat => chat.driverId === payload.id ?
				{
					...chat,
					driver: payload
				}
				: chat
			)
		default:
			return state
	}
}

const isFetchingChats = (state=false, { type }) => {
	switch (type) {
		case FETCH_CHATS_REQUEST:
			return true
		case FETCH_CHATS_SUCCESS:
		case FETCH_CHATS_FAILURE:
			return false
		default:
			return state
	}
}
const isFetchingMoreChats = (state=false, { type }) => {
	switch (type) {
		case FETCH_MORE_CHATS_REQUEST:
			return true
		case FETCH_MORE_CHATS_SUCCESS:
		case FETCH_MORE_CHATS_FAILURE:
			return false
		default:
			return state
	}
}

// A mapped object where the key is the chat ID and the value is the array of messages
const messages = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_MESSAGES_REQUEST:
		case FETCH_MESSAGES_FAILURE:
			return state
		case FETCH_MESSAGES_SUCCESS:
			if (payload.messages.length !== 0) {
				const message = payload.messages[0]
				const newState = { ...state }
				newState[message.chatId] = payload.messages
				return newState
			} else {
				return state
			}
		case ADD_MESSAGE_SUCCESS:
		case SEND_MESSAGE_REQUEST:
			if (state[payload.chatId]) {
				const messages = state[payload.chatId]
				const newState = { ...state }
				newState[payload.chatId] = [payload, ...messages]
				return newState
			} else {
				const newState = { ...state }
				newState[payload.chatId] = [payload]
				return newState
			}
		default:
			return state
	}
}

const conversations = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CONVERSATIONS_SUCCESS:
			return payload.conversations
		case FETCH_MORE_CONVERSATIONS_SUCCESS:
			return [...state, ...payload.conversations]
		case UPDATE_CONVERSATION_SUCCESS:
			return state.map(conversation => conversation.id === payload.id
				? payload
				: conversation
			)
		case ADD_CONVERSATION_SUCCESS: {
			const newState = [...state]
			const index = newState.findIndex(conversation => conversation.id === payload.id)
			if (index !== -1) {
				newState.splice(index, 1)
				return [payload, ...newState]
			} else {
				return [payload, ...state]
			}
		}
		default:
			return state
	}
}

// A mapped object where the key is the conversation ID and the value is the array of messages
const conversationMessages = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_CONVERSATION_MESSAGES_SUCCESS: {
			const newState = { ...state }
			newState[payload.conversationId] = payload.messages
			return newState
		}
		case FETCH_MORE_CONVERSATION_MESSAGES_SUCCESS: {
			const messages = state[payload.conversationId]
			const newState = { ...state }
			newState[payload.conversationId] = [...payload.messages, ...messages]
			return newState
		}
		case ADD_CONVERSATION_MESSAGE_SUCCESS: {
			if (state[payload.conversationId]) {
				const newState = { ...state }
				const messages = [...state[payload.conversationId]]
				const index = messages.findIndex(message => message.nonce && payload.nonce && message.nonce === payload.nonce)
				if (index > -1) {
					messages[index] = payload
					newState[payload.conversationId] = messages
				} else {
					newState[payload.conversationId] = [payload, ...messages]
				}
				return newState
			} else {
				const newState = { ...state }
				newState[payload.conversationId] = [payload]
				return newState
			}
		}
		case CREATE_CONVERSATION_MESSAGE_REQUEST: {
			if (state[payload.conversationId]) {
				const messages = state[payload.conversationId]
				const newState = { ...state }
				newState[payload.conversationId] = [payload, ...messages]
				return newState
			} else {
				const newState = { ...state }
				newState[payload.conversationId] = [payload]
				return newState
			}
		}
		case CREATE_CONVERSATION_SUCCESS: {
			const newState = { ...state }
			newState[payload.id] = [payload.lastMessage]
			return newState
		}
		default:
			return state
	}
}

const unreadConversationCount = (state = 0, { type, payload }) => {
	switch (type) {
		case UPDATE_CONVERSATION_COUNT_SUCCESS:
			return payload
		default:
			return state
	}
}

const messaging = combineReducers({
	messages,
	chats,
	unreadChatCount,
	isFetchingChats,
	isFetchingMoreChats,
	conversations,
	conversationMessages,
	unreadConversationCount
})

export default messaging

export const getMessages = state => state.messages
export const getChats = state => state.chats
export const getIsChatsRead = state => state.unreadChatCount <= 0
export const getIsFetchingChats = state => state.isFetchingChats
export const getIsFetchingMoreChats = state => state.isFetchingMoreChats
export const getIsConversationsRead = state => state.unreadConversationCount <= 0
export const getConversations = state => state.conversations
export const getConversationMessages = state => state.conversationMessages
