export const FETCH_DRIVERS_REQUEST = 'FETCH_DRIVERS_REQUEST'
export const FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS'
export const FETCH_DRIVERS_FAILURE = 'FETCH_DRIVERS_FAILURE'
export const ADD_DRIVER_REQUEST = 'ADD_DRIVER_REQUEST'
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS'
export const ADD_DRIVER_FAILURE = 'ADD_DRIVER_FAILURE'
export const DELETE_DRIVER_REQUEST = 'DELETE_DRIVER_REQUEST'
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS'
export const DELETE_DRIVER_FAILURE = 'DELETE_DRIVER_FAILURE'
export const UPDATE_DRIVER_REQUEST = 'UPDATE_DRIVER_REQUEST'
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS'
export const UPDATE_DRIVER_FAILURE = 'UPDATE_DRIVER_FAILURE'
export const FETCH_NEW_DRIVERS_REQUEST = 'FETCH_NEW_DRIVERS_REQUEST'
export const FETCH_NEW_DRIVERS_SUCCESS = 'FETCH_NEW_DRIVERS_SUCCESS'
export const FETCH_NEW_DRIVERS_FAILURE = 'FETCH_NEW_DRIVERS_FAILURE'
export const FETCH_APPROVED_DRIVERS_REQUEST = 'FETCH_APPROVED_DRIVERS_REQUEST'
export const FETCH_APPROVED_DRIVERS_SUCCESS = 'FETCH_APPROVED_DRIVERS_SUCCESS'
export const FETCH_APPROVED_DRIVERS_FAILURE = 'FETCH_APPROVED_DRIVERS_FAILURE'
export const FETCH_DECLINED_DRIVERS_REQUEST = 'FETCH_DECLINED_DRIVERS_REQUEST'
export const FETCH_DECLINED_DRIVERS_SUCCESS = 'FETCH_DECLINED_DRIVERS_SUCCESS'
export const FETCH_DECLINED_DRIVERS_FAILURE = 'FETCH_DECLINED_DRIVERS_FAILURE'
export const FETCH_FREE_DRIVERS_REQUEST = 'FETCH_FREE_DRIVERS_REQUEST'
export const FETCH_FREE_DRIVERS_SUCCESS = 'FETCH_FREE_DRIVERS_SUCCESS'
export const FETCH_FREE_DRIVERS_FAILURE = 'FETCH_FREE_DRIVERS_FAILURE'
export const FETCH_BUSY_DRIVERS_REQUEST = 'FETCH_BUSY_DRIVERS_REQUEST'
export const FETCH_BUSY_DRIVERS_SUCCESS = 'FETCH_BUSY_DRIVERS_SUCCESS'
export const FETCH_BUSY_DRIVERS_FAILURE = 'FETCH_BUSY_DRIVERS_FAILURE'
export const FETCH_INACTIVE_DRIVERS_REQUEST = 'FETCH_INACTIVE_DRIVERS_REQUEST'
export const FETCH_INACTIVE_DRIVERS_SUCCESS = 'FETCH_INACTIVE_DRIVERS_SUCCESS'
export const FETCH_INACTIVE_DRIVERS_FAILURE = 'FETCH_INACTIVE_DRIVERS_FAILURE'
export const FETCH_DRIVER_TASKS_REQUEST = 'FETCH_DRIVER_TASKS_REQUEST'
export const FETCH_DRIVER_TASKS_SUCCESS = 'FETCH_DRIVER_TASKS_SUCCESS'
export const FETCH_DRIVER_TASKS_FAILURE = 'FETCH_DRIVER_TASKS_FAILURE'
export const UPDATE_DRIVER_LOCATION_REQUEST = 'UPDATE_DRIVER_LOCATION_REQUEST'
export const UPDATE_DRIVER_LOCATION_SUCCESS = 'UPDATE_DRIVER_LOCATION_SUCCESS'
export const UPDATE_DRIVER_LOCATION_FAILURE = 'UPDATE_DRIVER_LOCATION_FAILURE'
export const UPDATE_DRIVER_STATUS_REQUEST = 'UPDATE_DRIVER_STATUS_REQUEST'
export const UPDATE_DRIVER_STATUS_SUCCESS = 'UPDATE_DRIVER_STATUS_SUCCESS'
export const UPDATE_DRIVER_STATUS_FAILURE = 'UPDATE_DRIVER_STATUS_FAILURE'
export const UPDATE_DRIVER_LOCATION = 'UPDATE_DRIVER_LOCATION'
export const FETCH_DRIVER_REQUEST = 'FETCH_DRIVER_REQUEST'
export const FETCH_DRIVER_SUCCESS = 'FETCH_DRIVER_SUCCESS'
export const FETCH_DRIVER_FAILURE = 'FETCH_DRIVER_FAILURE'
