import { combineReducers } from 'redux'
import {
	BOOK_SHIPMENT_REQUEST,
	BOOK_SHIPMENT_SUCCESS,
	BOOK_SHIPMENT_FAILURE,
	FETCH_ACTIVE_SHIPMENTS_REQUEST,
	FETCH_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_ACTIVE_SHIPMENTS_FAILURE,
	FETCH_SCHEDULED_SHIPMENTS_REQUEST,
	FETCH_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_SCHEDULED_SHIPMENTS_FAILURE,
	FETCH_REQUESTED_SHIPMENTS_REQUEST,
	FETCH_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_REQUESTED_SHIPMENTS_FAILURE,
	FETCH_COMPLETED_SHIPMENTS_REQUEST,
	FETCH_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_COMPLETED_SHIPMENTS_FAILURE,
	FETCH_CANCELLED_SHIPMENTS_REQUEST,
	FETCH_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_CANCELLED_SHIPMENTS_FAILURE,
	FETCH_EXPIRED_SHIPMENTS_REQUEST,
	FETCH_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_EXPIRED_SHIPMENTS_FAILURE,
	FETCH_SHIPMENT_REQUEST,
	FETCH_SHIPMENT_SUCCESS,
	FETCH_SHIPMENT_FAILURE,
	FETCH_CARRIER_SHIPMENTS_REQUEST,
	FETCH_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_CARRIER_SHIPMENTS_FAILURE,
	FETCH_ASSIGNED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_ASSIGNED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS,
	ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS,
	FETCH_SCHEDULED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_SCHEDULED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS,
	ACCEPT_SHIPMENT_SUCCESS,
	FETCH_COMPLETED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_COMPLETED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_COMPLETED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_BOOKING_REQUEST,
	FETCH_BOOKING_SUCCESS,
	FETCH_BOOKING_FAILURE,
	CANCEL_SHIPMENT_SUCCESS,
	CANCEL_BOOKING_SUCCESS,
	UPDATE_SHIPMENT,
	ACCEPT_SHIPMENT_FOR_DRIVER_SUCCESS,
	DECLINE_SHIPMENT_FOR_DRIVER_SUCCESS,
	UPDATE_SHIPMENT_DRIVER_STATUS_SUCCESS,
	FETCH_TRANSACTION_SUCCESS,
	UPDATE_TRANSACTION_SUCCESS,
	FETCH_LOADBOARD_FAILURE,
	FETCH_LOADBOARD_REQUEST,
	FETCH_LOADBOARD_SUCCESS,
	SEARCH_SHIPMENTS_REQUEST,
	SEARCH_SHIPMENTS_SUCCESS,
	SEARCH_SHIPMENTS_FAILURE,
	FETCH_SHIPPER_BOOKINGS_FAILURE,
	FETCH_SHIPPER_BOOKINGS_REQUEST,
	FETCH_SHIPPER_BOOKINGS_SUCCESS,
	FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_MORE_SHIPPER_BOOKINGS_SUCCESS,
	MODIFY_REQUESTED_SHIPMENTS_FAILURE,
	MODIFY_REQUESTED_SHIPMENTS_REQUEST,
	MODIFY_REQUESTED_SHIPMENTS_SUCCESS,
	SEARCH_MORE_SHIPMENTS_SUCCESS,
	SEARCH_MORE_SHIPMENTS_REQUEST,
	SEARCH_MORE_SHIPMENTS_FAILURE,
	RENEW_SHIPMENT_SUCCESS,
	UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS,
	UPDATE_BOOKING_FAILURE,
	UPDATE_BOOKING_REQUEST,
	UPDATE_BOOKING_SUCCESS,
	UPDATE_SHIPMENT_DETAIL_REQUEST,
	UPDATE_SHIPMENT_DETAIL_SUCCESS,
	SEARCH_TRANSACTIONS_SUCCESS,
	SEARCH_TRANSACTIONS_REQUEST,
	SEARCH_TRANSACTIONS_FAILURE,
	UPDATE_TRANSACTION_REQUEST,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_FAILURE,
	UPDATE_SHIPMENT_PAYMENT_REQUEST,
	UPDATE_SHIPMENT_PAYMENT_SUCCESS,
	UPDATE_SHIPMENT_PAYMENT_FAILURE,
	UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST,
	CONFIRM_VENDOR_PRICING_REQUEST,
	CONFIRM_VENDOR_PRICING_FAILURE,
	UPDATE_BOOKING,
	SEARCH_MORE_BOOKINGS_SUCCESS,
	SEARCH_MORE_BOOKINGS_REQUEST,
	SEARCH_MORE_BOOKINGS_FAILURE,
	SEARCH_BOOKINGS_FAILURE,
	SEARCH_BOOKINGS_REQUEST,
	SEARCH_BOOKINGS_SUCCESS
} from './action-types'
import { ADD_NOTIFICATION } from '../user/action-types'
import {
	OVERRIDE_SHIPMENT_STATUS_SUCCESS,
	WATCH_SHIPMENT_SUCCESS,
	WATCH_SHIPMENT_REQUEST,
	ASSIGN_SHIPMENT_SUCCESS,
	MASS_ASSIGN_SHIPMENTS_SUCCESS,
	ASSIGN_SHIPMENT_REQUEST,
	FETCH_BOOKING_SHIPMENTS_SUCCESS
} from '../admin/action-types'
import {
	DRIVER_ACCEPTED_EVENT_TYPE,
	CANCELLED_SHIPMENT_STATUS
} from '../../constants/strings'
import { getUnitRate } from '../../utils'

const activeShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_ACTIVE_SHIPMENTS_REQUEST:
		case FETCH_ACTIVE_SHIPMENTS_FAILURE:
			return state
		case FETCH_ACTIVE_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		default:
			return state
	}
}

const scheduledShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SCHEDULED_SHIPMENTS_REQUEST:
		case FETCH_SCHEDULED_SHIPMENTS_FAILURE:
			return state
		case FETCH_SCHEDULED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		default:
			return state
	}
}

const requestedShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_REQUESTED_SHIPMENTS_REQUEST:
		case FETCH_REQUESTED_SHIPMENTS_FAILURE:
			return state
		case FETCH_REQUESTED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case RENEW_SHIPMENT_SUCCESS:
			return [...state, payload]
		case CANCEL_SHIPMENT_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case CANCEL_BOOKING_SUCCESS:
			return state.filter(shipment => shipment.bookingId !== payload)
		default:
			return state
	}
}

const completedShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_COMPLETED_SHIPMENTS_REQUEST:
		case FETCH_COMPLETED_SHIPMENTS_FAILURE:
			return state
		case FETCH_COMPLETED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		default:
			return state
	}
}

const cancelledShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CANCELLED_SHIPMENTS_REQUEST:
		case FETCH_CANCELLED_SHIPMENTS_FAILURE:
			return state
		case FETCH_CANCELLED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case CANCEL_SHIPMENT_SUCCESS:
			return [ payload, ...state ]
		default:
			return state
	}
}

const expiredShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_EXPIRED_SHIPMENTS_REQUEST:
		case FETCH_EXPIRED_SHIPMENTS_FAILURE:
			return state
		case RENEW_SHIPMENT_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case FETCH_EXPIRED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		default:
			return state
	}
}

const isBookingShipment = (state = false, { type }) => {
	switch (type) {
		case BOOK_SHIPMENT_REQUEST:
			return true
		case BOOK_SHIPMENT_SUCCESS:
		case BOOK_SHIPMENT_FAILURE:
			return false
		default:
			return state
	}
}

// eslint-disable-next-line no-unused-vars
const isFetchingShipments = (state = false, { type }) => {
	switch (type) {
		case FETCH_ACTIVE_SHIPMENTS_REQUEST:
		case FETCH_SCHEDULED_SHIPMENTS_REQUEST:
		case FETCH_REQUESTED_SHIPMENTS_REQUEST:
		case FETCH_COMPLETED_SHIPMENTS_REQUEST:
		case FETCH_CANCELLED_SHIPMENTS_REQUEST:
		case FETCH_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_SCHEDULED_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_ASSIGNED_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_COMPLETED_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_EXPIRED_SHIPMENTS_REQUEST:
		case FETCH_LOADBOARD_REQUEST:
			return true
		case FETCH_CANCELLED_SHIPMENTS_SUCCESS:
		case FETCH_CANCELLED_SHIPMENTS_FAILURE:
		case FETCH_ACTIVE_SHIPMENTS_SUCCESS:
		case FETCH_ACTIVE_SHIPMENTS_FAILURE:
		case FETCH_COMPLETED_SHIPMENTS_SUCCESS:
		case FETCH_COMPLETED_SHIPMENTS_FAILURE:
		case FETCH_REQUESTED_SHIPMENTS_SUCCESS:
		case FETCH_REQUESTED_SHIPMENTS_FAILURE:
		case FETCH_SCHEDULED_SHIPMENTS_SUCCESS:
		case FETCH_SCHEDULED_SHIPMENTS_FAILURE:
		case FETCH_EXPIRED_SHIPMENTS_SUCCESS:
		case FETCH_EXPIRED_SHIPMENTS_FAILURE:
		case FETCH_ASSIGNED_CARRIER_SHIPMENTS_FAILURE:
		case FETCH_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS:
		case FETCH_COMPLETED_CARRIER_SHIPMENTS_FAILURE:
		case FETCH_COMPLETED_CARRIER_SHIPMENTS_SUCCESS:
		case FETCH_CARRIER_SHIPMENTS_FAILURE:
		case FETCH_CARRIER_SHIPMENTS_SUCCESS:
		case FETCH_SCHEDULED_CARRIER_SHIPMENTS_FAILURE:
		case FETCH_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS:
		case FETCH_LOADBOARD_SUCCESS:
		case FETCH_LOADBOARD_FAILURE:
			return false
		default:
			return state
	}
}

const isFetchingShipment = (state = false, { type }) => {
	switch (type) {
		case FETCH_SHIPMENT_REQUEST:
			return true
		case FETCH_SHIPMENT_SUCCESS:
		case FETCH_SHIPMENT_FAILURE:
			return false
		default:
			return state
	}
}

const isFetchingBooking = (state = false, { type }) => {
	switch (type) {
		case FETCH_BOOKING_REQUEST:
			return true
		case FETCH_BOOKING_SUCCESS:
		case FETCH_BOOKING_FAILURE:
			return false
		default:
			return state
	}
}

const isUpdatingBooking = (state = false, { type }) => {
	switch (type) {
		case UPDATE_BOOKING_REQUEST:
			return true
		case UPDATE_BOOKING_SUCCESS:
		case UPDATE_BOOKING_FAILURE:
			return false
		default:
			return state
	}
}

const isModifyingRequestedShipments = (state = false, { type }) => {
	switch (type) {
		case MODIFY_REQUESTED_SHIPMENTS_REQUEST:
			return true
		case MODIFY_REQUESTED_SHIPMENTS_SUCCESS:
		case MODIFY_REQUESTED_SHIPMENTS_FAILURE:
			return false
		default:
			return state
	}
}

// A map that caches booking details where the key is the ID of the booking and the value is the shipment data itself.
const bookingDetails = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_BOOKING_SUCCESS:
		case MODIFY_REQUESTED_SHIPMENTS_SUCCESS:
		case CANCEL_BOOKING_SUCCESS:
		case UPDATE_BOOKING:
		case UPDATE_BOOKING_SUCCESS: {
			const booking = state[payload.id] ? state[payload.id] : {}
			const newBooking = Object.assign(booking, payload)
			const newState = { ...state }
			newState[payload.id] = newBooking
			return newState
		}
		case CONFIRM_VENDOR_PRICING_REQUEST: {
			const booking = state[payload.id]
			if (booking) {
				const shipmentCounts = booking.shipmentCounts
				if (shipmentCounts) {
					if (payload.truckQuantity > shipmentCounts.requestedShipmentsCount) {
						return state
					}
					if (shipmentCounts.requestedShipmentsCount - payload.truckQuantity >= 0) {
						shipmentCounts.requestedShipmentsCount -= payload.truckQuantity
					} else {
						shipmentCounts.requestedShipmentsCount = 0
					}
					shipmentCounts.scheduledShipmentsCount += payload.truckQuantity
				}
				const newState = { ...state }
				newState[payload.id] = booking
				return newState
			} else {
				return state
			}
		}
		case CONFIRM_VENDOR_PRICING_FAILURE: {
			const booking = state[payload.id]
			if (booking) {
				const shipmentCounts = booking.shipmentCounts
				if (shipmentCounts) {
					if (payload.truckQuantity > shipmentCounts.requestedShipmentsCount) {
						return state
					}
					shipmentCounts.requestedShipmentsCount += payload.truckQuantity
					if (shipmentCounts.scheduledShipmentsCount - payload.truckQuantity >= 0) {
						shipmentCounts.scheduledShipmentsCount -= payload.truckQuantity
					} else {
						shipmentCounts.scheduledShipmentsCount = 0
					}
				}
				const newState = { ...state }
				newState[payload.id] = booking
				return newState
			} else {
				return state
			}
		}
		case FETCH_SHIPMENT_SUCCESS:
		case UPDATE_SHIPMENT: {
			const booking = state[payload.bookingId]
			if (booking && booking.shipments) {
				booking.shipments = booking.shipments.map(shipment => shipment.id === payload.id ? { ...shipment, ...payload } : shipment)
				const newState = { ...state }
				newState[payload.id] = booking
				return newState
			} else {
				return state
			}
		}
		case FETCH_BOOKING_REQUEST:
		case UPDATE_BOOKING_REQUEST:
		case FETCH_BOOKING_FAILURE:
		case UPDATE_BOOKING_FAILURE:
		case MODIFY_REQUESTED_SHIPMENTS_FAILURE:
		case MODIFY_REQUESTED_SHIPMENTS_REQUEST:
			return state
		default:
			return state
	}
}

// A map that caches shipment details where the key is the ID of the shipment and the value is the shipment data itself.
const shipmentDetails = (state = {}, { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			payload.shipments.map(payloadShipment => {
				if (state[payloadShipment.id]) {
					delete state[payloadShipment.id].assignee
					state[payloadShipment.id] = Object.assign(state[payloadShipment.id], payloadShipment)
				}
				return payloadShipment
			})
			return state
		}
		case FETCH_SHIPMENT_SUCCESS:
		case CANCEL_SHIPMENT_SUCCESS:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case RENEW_SHIPMENT_SUCCESS:
		case ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS:
		case ACCEPT_SHIPMENT_SUCCESS:
		case ACCEPT_SHIPMENT_FOR_DRIVER_SUCCESS:
		case DECLINE_SHIPMENT_FOR_DRIVER_SUCCESS:
		case UPDATE_SHIPMENT_DRIVER_STATUS_SUCCESS:
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
		case WATCH_SHIPMENT_SUCCESS:
		case UPDATE_SHIPMENT_DETAIL_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
		case UPDATE_TRANSACTION_SUCCESS:
		case UPDATE_SHIPMENT: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newShipment = { ...shipment, ...payload }
			const newState = { ...state }
			newState[payload.id] = newShipment
			return newState
		}
		case FETCH_SHIPMENT_REQUEST:
		case FETCH_SHIPMENT_FAILURE:
			return state
		case CANCEL_BOOKING_SUCCESS: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newShipment = Object.assign(shipment, payload)
			newShipment.status = CANCELLED_SHIPMENT_STATUS
			const newState = { ...state }
			newState[payload.id] = newShipment
			return newState
		}
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newShipment = { ...shipment }
			if (payload.vendorAmount) {
				newShipment.vendorAmount = payload.vendorAmount
				const newState = { ...state }
				newState[payload.id] = newShipment
				return newState
			} else {
				return state
			}
		}
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newShipment = { ...shipment }
			if (payload.fareAmount) {
				newShipment.fareAmount = payload.fareAmount
				if (newShipment.contractedRate) {
					newShipment.contractedRate = null
				}
				const newState = { ...state }
				newState[payload.id] = newShipment
				return newState
			} else {
				return state
			}
		}
		case UPDATE_SHIPMENT_PAYMENT_REQUEST: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newShipment = { ...shipment }
			if (payload.vendorAdvancePayment) {
				newShipment.vendorAdvancePayment = payload.vendorAdvancePayment
				const newState = { ...state }
				newState[payload.id] = newShipment
				return newState
			} else if (payload.fareAdvancePayment) {
				newShipment.fareAdvancePayment = payload.fareAdvancePayment
				const newState = { ...state }
				newState[payload.id] = newShipment
				return newState
			} else if (payload.extraCost) {
				newShipment.extraCost = payload.extraCost
				const newState = { ...state }
				newState[payload.id] = newShipment
				return newState
			} else {
				return state
			}
		}
		case WATCH_SHIPMENT_REQUEST: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newShipment = { ...shipment }
			if (payload.watch) {
				if (!newShipment.watchers) {
					newShipment.watchers = []
				}
				newShipment.watchers.push(payload.userId)
			} else {
				if (newShipment.watchers) {
					const index = newShipment.watchers.indexOf(payload.userId)
					if (index > -1) {
						newShipment.watchers.splice(index, 1)
					}
				}
			}
			const newState = { ...state }
			newState[payload.id] = newShipment
			return newState
		}
		case UPDATE_SHIPMENT_DETAIL_REQUEST: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newShipment = { ...shipment }
			if (payload.weight !== shipment.weight) {
				newShipment.weight = payload.weight
				const rate = newShipment.contractedRate
				if (rate) {
					newShipment.fareAmount = newShipment.weight * getUnitRate(rate.flatRate, newShipment.weightUnit)
				}
			}
			if (payload.itemQuantity !== shipment.itemQuantity) {
				newShipment.itemQuantity = payload.itemQuantity
			}
			const newState = { ...state }
			newState[payload.id] = newShipment
			return newState
		}
		case UPDATE_TRANSACTION_REQUEST: {
			const newShipment = { ...state[payload.id] }
			if (!newShipment) {
				return state
			}
			if (newShipment.transaction) {
				if (payload.paymentMethod) {
					newShipment.transaction.paymentMethod = payload.paymentMethod
				}
				if (payload.challanNumber) {
					newShipment.transaction.challanNumber = payload.challanNumber
				}
			}
			const newState = { ...state }
			newState[payload.id] = newShipment
			return newState
		}
		case ASSIGN_SHIPMENT_REQUEST: {
			const shipment = state[payload.id] ? state[payload.id] : {}
			const newState = { ...state }
			if (payload.assign) {
				newState[payload.id] = {
					...shipment,
					assignee: {
						id: payload.uid
					}
				}
			} else {
				newState[payload.id] = {
					...shipment,
					assignee: null
				}
			}
			return newState
		}
		default:
			return state
	}
}

const transaction = (state = null, { type, payload }) => {
	switch (type) {
		case FETCH_TRANSACTION_SUCCESS:
			return payload
		case UPDATE_TRANSACTION_SUCCESS:
			return {
				...state,
				status: payload.status
			}
		default:
			return state
	}
}

const requestedCarrierShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_CARRIER_SHIPMENTS_FAILURE:
			return state
		case FETCH_CARRIER_SHIPMENTS_SUCCESS:
			return payload.shipments
		case ACCEPT_SHIPMENT_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case FETCH_MORE_CARRIER_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		default:
			return state
	}
}

const scheduledCarrierShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SCHEDULED_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_SCHEDULED_CARRIER_SHIPMENTS_FAILURE:
			return state
		case FETCH_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case ACCEPT_SHIPMENT_SUCCESS:
			return [...state, payload]
		case ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id
				? payload
				: shipment
			)
		case ADD_NOTIFICATION:
			if (payload.type === DRIVER_ACCEPTED_EVENT_TYPE) {
				return state.filter(shipment => shipment.id !== payload.shipmentId)
			}
			return state
		default:
			return state
	}
}

const assignedCarrierShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_ASSIGNED_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_ASSIGNED_CARRIER_SHIPMENTS_FAILURE:
			return state
		case FETCH_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS:
			return [payload, ...state]
		default:
			return state
	}
}

const completedCarrierShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_COMPLETED_CARRIER_SHIPMENTS_REQUEST:
		case FETCH_COMPLETED_CARRIER_SHIPMENTS_FAILURE:
			return state
		case FETCH_COMPLETED_CARRIER_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		default:
			return state
	}
}

const loadboardShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_LOADBOARD_FAILURE:
		case FETCH_LOADBOARD_REQUEST:
			return state
		case FETCH_LOADBOARD_SUCCESS:
			return payload.shipments
		case ACCEPT_SHIPMENT_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		default:
			return state
	}
}

const matchingShipments = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_SHIPMENTS_REQUEST:
		case SEARCH_SHIPMENTS_FAILURE:
			return []
		case SEARCH_SHIPMENTS_SUCCESS:
			return payload.shipments
		case SEARCH_MORE_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		default:
			return state
	}
}

// eslint-disable-next-line no-unused-vars
const isSearchingShipments = (state = false, { type }) => {
	switch (type) {
		case SEARCH_SHIPMENTS_REQUEST:
			return true
		default:
			return false
	}
}

const matchingTransactions = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_TRANSACTIONS_REQUEST:
		case SEARCH_TRANSACTIONS_FAILURE:
			return []
		case SEARCH_TRANSACTIONS_SUCCESS:
			return payload.shipments
		default:
			return state
	}
}

// eslint-disable-next-line no-unused-vars
const isSearchingTransactions = (state = false, { type }) => {
	switch (type) {
		case SEARCH_TRANSACTIONS_REQUEST:
			return true
		default:
			return false
	}
}

const isSearchingMoreShipments = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_SHIPMENTS_REQUEST:
			return true
		case SEARCH_MORE_SHIPMENTS_FAILURE:
		case SEARCH_MORE_SHIPMENTS_SUCCESS:
			return false
		default:
			return state
	}
}

const shipperBookings = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_BOOKINGS_FAILURE:
		case FETCH_SHIPPER_BOOKINGS_REQUEST:
			return state
		case FETCH_SHIPPER_BOOKINGS_SUCCESS:
			return payload.bookings
		case FETCH_MORE_SHIPPER_BOOKINGS_SUCCESS:
			return [...state, ...payload.bookings]
		default:
			return state
	}
}

const matchingBookings = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_BOOKINGS_REQUEST:
		case SEARCH_BOOKINGS_FAILURE:
			return []
		case SEARCH_BOOKINGS_SUCCESS:
			return payload.bookings
		case SEARCH_MORE_BOOKINGS_SUCCESS:
			return [...state, ...payload.bookings]
		case FETCH_BOOKING_SHIPMENTS_SUCCESS:
			return state.map(booking => {
				if (booking.id === payload.bookingId) {
					return {
						shipments: payload.shipments,
						...booking
					}
				} else {
					return booking
				}
			})
		default:
			return state
	}
}


const isSearchingBookings = (state = false, { type }) => {
	switch (type) {
		case SEARCH_BOOKINGS_REQUEST:
			return true
		case SEARCH_BOOKINGS_SUCCESS:
		case SEARCH_BOOKINGS_FAILURE:
			return false
		default:
			return state
	}
}

const isSearchingMoreBookings = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_BOOKINGS_REQUEST:
			return true
		case SEARCH_MORE_BOOKINGS_FAILURE:
		case SEARCH_MORE_BOOKINGS_SUCCESS:
			return false
		default:
			return state
	}
}

const isFetchingShipperBookings = (state = false, { type }) => {
	switch (type) {
		case FETCH_SHIPPER_BOOKINGS_REQUEST:
			return true
		case FETCH_SHIPPER_BOOKINGS_SUCCESS:
		case FETCH_SHIPPER_BOOKINGS_FAILURE:
			return false
		default:
			return state
	}
}

const isUpdatingVendorAmount = (state = false, { type }) => {
	switch (type) {
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return true
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_FAILURE:
			return false
		default:
			return state
	}
}

const isUpdatingAdvancePayment = (state = false, { type }) => {
	switch (type) {
		case UPDATE_SHIPMENT_PAYMENT_REQUEST:
			return true
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_FAILURE:
			return false
		default:
			return state
	}
}

const shipments = combineReducers({
	activeShipments,
	scheduledShipments,
	requestedShipments,
	completedShipments,
	cancelledShipments,
	expiredShipments,
	isBookingShipment,
	isFetchingShipments,
	isFetchingShipment,
	shipmentDetails,
	requestedCarrierShipments,
	scheduledCarrierShipments,
	assignedCarrierShipments,
	completedCarrierShipments,
	isFetchingBooking,
	bookingDetails,
	loadboardShipments,
	matchingShipments,
	isSearchingShipments,
	shipperBookings,
	isFetchingShipperBookings,
	isModifyingRequestedShipments,
	transaction,
	isSearchingMoreShipments,
	isUpdatingBooking,
	matchingTransactions,
	isSearchingTransactions,
	isUpdatingVendorAmount,
	isUpdatingAdvancePayment,
	isSearchingBookings,
	matchingBookings,
	isSearchingMoreBookings
})

export default shipments

export const getActiveShipments = state => state.activeShipments
export const getScheduledShipments = state => state.scheduledShipments
export const getRequestedShipments = state => state.requestedShipments
export const getCompletedShipments = state => state.completedShipments
export const getCancelledShipments = state => state.cancelledShipments
export const getExpiredShipments = state => state.expiredShipments
export const getIsBookingShipment = state => state.isBookingShipment
export const getIsFetchingShipments = state => state.isFetchingShipments
export const getIsFetchingShipment = state => state.isFetchingShipment
export const getShipmentDetails = state => state.shipmentDetails
export const getRequestedCarrierShipments = state => state.requestedCarrierShipments
export const getScheduledCarrierShipments = state => state.scheduledCarrierShipments
export const getAssignedCarrierShipments = state => state.assignedCarrierShipments
export const getCompletedCarrierShipments = state => state.completedCarrierShipments
export const getIsFetchingBooking = state => state.isFetchingBooking
export const getBookingDetails = state => state.bookingDetails
export const getLoadboardShipments = state => state.loadboardShipments
export const getMatchingShipments = state => state.matchingShipments
export const getIsSearchingShipments = state => state.isSearchingShipments
export const getIsSearchingMoreShipments = state => state.isSearchingMoreShipments
export const getShipperBookings = state => state.shipperBookings
export const getIsFetchingShipperBookings = state => state.isFetchingShipperBookings
export const getIsModifyingRequestedShipments = state => state.isModifyingRequestedShipments
export const getTransaction = state => state.transaction
export const getIsUpdatingBooking = state => state.isUpdatingBooking
export const getMatchingTransactions = state => state.matchingTransactions
export const getIsSearchingTransactions = state => state.isSearchingTransactions
export const getIsSearchingBookings = state => state.isSearchingBookings
export const getIsSearchingMoreBookings = state => state.isSearchingMoreBookings
export const getMatchingBookings = state => state.matchingBookings
