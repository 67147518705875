import './views/styles/styles.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import configureStore, { history } from './configureStore'
import App from './views/app'
import Firebase, { FirebaseContext } from './views/components/firebase'
import './i18next'

const store = configureStore()

const rootElement = document.getElementById('root')
function render (Component) {
	ReactDOM.render(
		<Provider store={store}>
			<FirebaseContext.Provider value={new Firebase()}>
				<Router history={history}>
					<HelmetProvider>
						<Component />
					</HelmetProvider>
				</Router>
			</FirebaseContext.Provider>
		</Provider>,
		rootElement
	)
}
render(App)
