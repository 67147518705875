import enResource from '../translations/en.json'
import bnResource from '../translations/bn.json'

/**
 * Language Codes
 */
/** English */
const EN = 'en'
/** Bengali */
const BN = 'bn'

/**
 * Default/Fallback language code
 */
const DEFAULT = EN

const language = {
	list: [
		EN,
		BN
	],
	codes: {
		EN,
		BN,
		DEFAULT
	},
	resources: {
		en: enResource,
		bn: bnResource
	}
}

Object.freeze(language)

export default language
