import {
	FETCH_SHIPMENTS_REQUEST,
	FETCH_SHIPMENTS_SUCCESS,
	FETCH_SHIPMENTS_FAILURE,
	FETCH_MORE_SHIPMENTS_REQUEST,
	FETCH_MORE_SHIPMENTS_SUCCESS,
	FETCH_MORE_SHIPMENTS_FAILURE,
	FETCH_REQUESTED_SHIPMENTS_REQUEST,
	FETCH_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_REQUESTED_SHIPMENTS_FAILURE,
	FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST,
	FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE,
	FETCH_SCHEDULED_SHIPMENTS_REQUEST,
	FETCH_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_SCHEDULED_SHIPMENTS_FAILURE,
	FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST,
	FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE,
	FETCH_ASSIGNED_SHIPMENTS_REQUEST,
	FETCH_ASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_ASSIGNED_SHIPMENTS_FAILURE,
	FETCH_MORE_ASSIGNED_SHIPMENTS_REQUEST,
	FETCH_MORE_ASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_MORE_ASSIGNED_SHIPMENTS_FAILURE,
	FETCH_UNASSIGNED_SHIPMENTS_REQUEST,
	FETCH_UNASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_UNASSIGNED_SHIPMENTS_FAILURE,
	FETCH_MORE_UNASSIGNED_SHIPMENTS_REQUEST,
	FETCH_MORE_UNASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_MORE_UNASSIGNED_SHIPMENTS_FAILURE,
	FETCH_ACTIVE_SHIPMENTS_REQUEST,
	FETCH_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_ACTIVE_SHIPMENTS_FAILURE,
	FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST,
	FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE,
	FETCH_COMPLETED_SHIPMENTS_REQUEST,
	FETCH_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_COMPLETED_SHIPMENTS_FAILURE,
	FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST,
	FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE,
	FETCH_CANCELLED_SHIPMENTS_REQUEST,
	FETCH_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_CANCELLED_SHIPMENTS_FAILURE,
	FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST,
	FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE,
	FETCH_EXPIRED_SHIPMENTS_REQUEST,
	FETCH_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_EXPIRED_SHIPMENTS_FAILURE,
	FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST,
	FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE,
	FETCH_USERS_REQUEST,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAILURE,
	FETCH_MORE_USERS_REQUEST,
	FETCH_MORE_USERS_SUCCESS,
	FETCH_MORE_USERS_FAILURE,
	FETCH_SHIPPER_USERS_REQUEST,
	FETCH_SHIPPER_USERS_SUCCESS,
	FETCH_SHIPPER_USERS_FAILURE,
	FETCH_MORE_SHIPPER_USERS_REQUEST,
	FETCH_MORE_SHIPPER_USERS_SUCCESS,
	FETCH_MORE_SHIPPER_USERS_FAILURE,
	FETCH_CARRIER_USERS_REQUEST,
	FETCH_CARRIER_USERS_SUCCESS,
	FETCH_CARRIER_USERS_FAILURE,
	FETCH_MORE_CARRIER_USERS_REQUEST,
	FETCH_MORE_CARRIER_USERS_SUCCESS,
	FETCH_MORE_CARRIER_USERS_FAILURE,
	FETCH_DRIVER_USERS_REQUEST,
	FETCH_DRIVER_USERS_SUCCESS,
	FETCH_DRIVER_USERS_FAILURE,
	FETCH_MORE_DRIVER_USERS_REQUEST,
	FETCH_MORE_DRIVER_USERS_SUCCESS,
	FETCH_MORE_DRIVER_USERS_FAILURE,
	FETCH_RATES_REQUEST,
	FETCH_RATES_SUCCESS,
	FETCH_RATES_FAILURE,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILURE,
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILURE,
	FETCH_CURRENT_RATE_REQUEST,
	FETCH_CURRENT_RATE_SUCCESS,
	FETCH_CURRENT_RATE_FAILURE,
	UPDATE_RATE_REQUEST,
	UPDATE_RATE_SUCCESS,
	UPDATE_RATE_FAILURE,
	ADD_RATE_REQUEST,
	ADD_RATE_SUCCESS,
	ADD_RATE_FAILURE,
	FETCH_TRANSACTIONS_REQUEST,
	FETCH_TRANSACTIONS_SUCCESS,
	FETCH_TRANSACTIONS_FAILURE,
	FETCH_MORE_TRANSACTIONS_REQUEST,
	FETCH_MORE_TRANSACTIONS_SUCCESS,
	FETCH_MORE_TRANSACTIONS_FAILURE,
	FETCH_BOOKINGS_REQUEST,
	FETCH_BOOKINGS_SUCCESS,
	FETCH_BOOKINGS_FAILURE,
	FETCH_MORE_BOOKINGS_REQUEST,
	FETCH_MORE_BOOKINGS_SUCCESS,
	FETCH_MORE_BOOKINGS_FAILURE,
	FETCH_CONTRACTED_RATES_REQUEST,
	FETCH_CONTRACTED_RATES_SUCCESS,
	FETCH_CONTRACTED_RATES_FAILURE,
	FETCH_BOUNDING_SHIPMENTS_REQUEST,
	FETCH_BOUNDING_SHIPMENTS_SUCCESS,
	FETCH_BOUNDING_SHIPMENTS_FAILURE,
	FETCH_GLOBAL_COUNTS_REQUEST,
	FETCH_GLOBAL_COUNTS_SUCCESS,
	FETCH_GLOBAL_COUNTS_FAILURE,
	FETCH_GLOBAL_HISTORY_REQUEST,
	FETCH_GLOBAL_HISTORY_SUCCESS,
	FETCH_GLOBAL_HISTORY_FAILURE,
	FETCH_GLOBAL_FARES_REQUEST,
	FETCH_GLOBAL_FARES_SUCCESS,
	FETCH_GLOBAL_FARES_FAILURE,
	FETCH_RATE_HISTORY_REQUEST,
	FETCH_RATE_HISTORY_SUCCESS,
	FETCH_RATE_HISTORY_FAILURE,
	OVERRIDE_SHIPMENT_STATUS_REQUEST,
	OVERRIDE_SHIPMENT_STATUS_SUCCESS,
	OVERRIDE_SHIPMENT_STATUS_FAILURE,
	FETCH_USER_SHIPMENTS_REQUEST,
	FETCH_USER_SHIPMENTS_SUCCESS,
	FETCH_USER_SHIPMENTS_FAILURE,
	FETCH_MORE_USER_SHIPMENTS_REQUEST,
	FETCH_MORE_USER_SHIPMENTS_SUCCESS,
	FETCH_MORE_USER_SHIPMENTS_FAILURE,
	FETCH_AGENT_USERS_FAILURE,
	FETCH_AGENT_USERS_REQUEST,
	FETCH_AGENT_USERS_SUCCESS,
	FETCH_DISPATCHER_USERS_FAILURE,
	FETCH_DISPATCHER_USERS_REQUEST,
	FETCH_DISPATCHER_USERS_SUCCESS,
	FETCH_MORE_AGENT_USERS_FAILURE,
	FETCH_MORE_AGENT_USERS_REQUEST,
	FETCH_MORE_AGENT_USERS_SUCCESS,
	FETCH_MORE_DISPATCHER_USERS_FAILURE,
	FETCH_MORE_DISPATCHER_USERS_REQUEST,
	FETCH_MORE_DISPATCHER_USERS_SUCCESS,
	FETCH_BOUNDING_USERS_REQUEST,
	FETCH_BOUNDING_USERS_SUCCESS,
	FETCH_BOUNDING_USERS_FAILURE,
	FETCH_MORE_RATES_FAILURE,
	FETCH_MORE_RATES_REQUEST,
	FETCH_MORE_RATES_SUCCESS,
	FETCH_MORE_CONTRACTED_RATES_FAILURE,
	FETCH_MORE_CONTRACTED_RATES_REQUEST,
	FETCH_MORE_CONTRACTED_RATES_SUCCESS,
	SEARCH_RATES_REQUEST,
	SEARCH_RATES_SUCCESS,
	SEARCH_RATES_FAILURE,
	FETCH_CONTRACTS_REQUEST,
	FETCH_CONTRACTS_SUCCESS,
	FETCH_CONTRACTS_FAILURE,
	FETCH_MORE_CONTRACTS_REQUEST,
	FETCH_MORE_CONTRACTS_SUCCESS,
	FETCH_MORE_CONTRACTS_FAILURE,
	FETCH_CONTRACT_REQUEST,
	FETCH_CONTRACT_SUCCESS,
	FETCH_CONTRACT_FAILURE,
	ADD_CONTRACT_REQUEST,
	ADD_CONTRACT_SUCCESS,
	ADD_CONTRACT_FAILURE,
	UPDATE_CONTRACT_REQUEST,
	UPDATE_CONTRACT_SUCCESS,
	UPDATE_CONTRACT_FAILURE,
	FETCH_WATCHING_SHIPMENTS_REQUEST,
	FETCH_WATCHING_SHIPMENTS_SUCCESS,
	FETCH_WATCHING_SHIPMENTS_FAILURE,
	WATCH_SHIPMENT_REQUEST,
	WATCH_SHIPMENT_SUCCESS,
	WATCH_SHIPMENT_FAILURE,
	FETCH_ADMIN_ASSIGNED_SHIPMENTS_REQUEST,
	FETCH_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_ADMIN_ASSIGNED_SHIPMENTS_FAILURE,
	ASSIGN_SHIPMENT_REQUEST,
	ASSIGN_SHIPMENT_SUCCESS,
	ASSIGN_SHIPMENT_FAILURE,
	MASS_ASSIGN_SHIPMENTS_REQUEST,
	MASS_ASSIGN_SHIPMENTS_SUCCESS,
	MASS_ASSIGN_SHIPMENTS_FAILURE,
	UPDATE_STAT_COUNTS,
	FETCH_PENDING_CONTRACTS_REQUEST,
	FETCH_PENDING_CONTRACTS_SUCCESS,
	FETCH_PENDING_CONTRACTS_FAILURE,
	FETCH_MORE_PENDING_CONTRACTS_REQUEST,
	FETCH_MORE_PENDING_CONTRACTS_SUCCESS,
	FETCH_MORE_PENDING_CONTRACTS_FAILURE,
	FETCH_BILLS_REQUEST,
	FETCH_BILLS_SUCCESS,
	FETCH_BILLS_FAILURE,
	FETCH_MORE_BILLS_REQUEST,
	FETCH_MORE_BILLS_SUCCESS,
	FETCH_MORE_BILLS_FAILURE,
	CREATE_BILL_REQUEST,
	CREATE_BILL_SUCCESS,
	CREATE_BILL_FAILURE,
	FETCH_CONTRACTED_SHIPPERS_REQUEST,
	FETCH_CONTRACTED_SHIPPERS_SUCCESS,
	FETCH_CONTRACTED_SHIPPERS_FAILURE,
	FETCH_SHIPPER_CONTRACT_RATES_REQUEST,
	FETCH_SHIPPER_CONTRACT_RATES_SUCCESS,
	FETCH_SHIPPER_CONTRACT_RATES_FAILURE,
	ADD_CHARGE_REQUEST,
	ADD_CHARGE_SUCCESS,
	ADD_CHARGE_FAILURE,
	REMOVE_CHARGE_REQUEST,
	REMOVE_CHARGE_SUCCESS,
	REMOVE_CHARGE_FAILURE,
	FETCH_ADMIN_NOTIFICATIONS_REQUEST,
	FETCH_ADMIN_NOTIFICATIONS_FAILURE,
	FETCH_ADMIN_NOTIFICATIONS_SUCCESS,
	FETCH_MORE_ADMIN_NOTIFICATIONS_FAILURE,
	FETCH_MORE_ADMIN_NOTIFICATIONS_REQUEST,
	FETCH_MORE_ADMIN_NOTIFICATIONS_SUCCESS,
	ADD_ADMIN_NOTIFICATION,
	UPDATE_ADMIN_NOTIFICATION,
	READ_ADMIN_NOTIFICATION,
	FETCH_SHIPPER_HISTORY_REQUEST,
	FETCH_SHIPPER_HISTORY_SUCCESS,
	FETCH_SHIPPER_HISTORY_FAILURE,
	FETCH_RECENTLY_ACTIVE_SHIPPERS_REQUEST,
	FETCH_RECENTLY_ACTIVE_SHIPPERS_FAILURE,
	FETCH_RECENTLY_ACTIVE_SHIPPERS_SUCCESS,
	FETCH_GLOBAL_WEEKLY_COUNTS_REQUEST,
	FETCH_GLOBAL_MONTHLY_COUNTS_REQUEST,
	FETCH_GLOBAL_WEEKLY_COUNTS_SUCCESS,
	FETCH_GLOBAL_MONTHLY_COUNTS_SUCCESS,
	FETCH_GLOBAL_WEEKLY_COUNTS_FAILURE,
	FETCH_GLOBAL_MONTHLY_COUNTS_FAILURE,
	FETCH_MORE_WATCHING_SHIPMENTS_REQUEST,
	FETCH_MORE_WATCHING_SHIPMENTS_SUCCESS,
	FETCH_MORE_WATCHING_SHIPMENTS_FAILURE,
	FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_REQUEST,
	FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_FAILURE,
	FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS,
	ADD_LOCATION_REQUEST,
	ADD_LOCATION_SUCCESS,
	ADD_LOCATION_FAILURE,
	UPDATE_LOCATION_REQUEST,
	UPDATE_LOCATION_SUCCESS,
	UPDATE_LOCATION_FAILURE,
	DELETE_LOCATION_REQUEST,
	DELETE_LOCATION_SUCCESS,
	DELETE_LOCATION_FAILURE,
	FETCH_REVENUE_REQUEST,
	FETCH_REVENUE_SUCCESS,
	FETCH_REVENUE_FAILURE,
	SEARCH_BILLS_FAILURE,
	SEARCH_BILLS_REQUEST,
	SEARCH_BILLS_SUCCESS,
	SEARCH_MORE_BILLS_FAILURE,
	SEARCH_MORE_BILLS_REQUEST,
	SEARCH_MORE_BILLS_SUCCESS,
	FETCH_ALL_COMPANIES_FAILURE,
	FETCH_ALL_COMPANIES_REQUEST,
	FETCH_ALL_COMPANIES_SUCCESS,
	FETCH_DASHBOARD_REQUEST,
	FETCH_DASHBOARD_SUCCESS,
	FETCH_DASHBOARD_FAILURE,
	FETCH_REPORTS_REQUEST,
	FETCH_REPORTS_SUCCESS,
	FETCH_REPORTS_FAILURE,
	FETCH_MORE_REPORTS_REQUEST,
	FETCH_MORE_REPORTS_SUCCESS,
	FETCH_MORE_REPORTS_FAILURE,
	FETCH_ANNUAL_DASHBOARD_REQUEST,
	FETCH_ANNUAL_DASHBOARD_SUCCESS,
	FETCH_ANNUAL_DASHBOARD_FAILURE,
	FETCH_BILL_FAILURE,
	FETCH_BILL_REQUEST,
	FETCH_BILL_SUCCESS,
	DELETE_CONTRACT_FAILURE,
	DELETE_CONTRACT_REQUEST,
	DELETE_CONTRACT_SUCCESS,
	FETCH_USERS_DASHBOARD_REQUEST,
	FETCH_USERS_DASHBOARD_SUCCESS,
	FETCH_USERS_DASHBOARD_FAILURE,
	FETCH_COMPANIES_SUCCESS,
	FETCH_COMPANIES_FAILURE,
	FETCH_COMPANIES_REQUEST,
	FETCH_MORE_COMPANIES_SUCCESS,
	FETCH_MORE_COMPANIES_FAILURE,
	FETCH_MORE_COMPANIES_REQUEST,
	FETCH_EVENTS_DASHBOARD_REQUEST,
	FETCH_EVENTS_DASHBOARD_SUCCESS,
	FETCH_EVENTS_DASHBOARD_FAILURE,
	UPDATE_CONTRACT_STATUS_FAILURE,
	UPDATE_CONTRACT_STATUS_REQUEST,
	UPDATE_CONTRACT_STATUS_SUCCESS,
	SEARCH_COMPANIES_FAILURE,
	SEARCH_COMPANIES_REQUEST,
	SEARCH_COMPANIES_SUCCESS,
	SEARCH_MORE_COMPANIES_FAILURE,
	SEARCH_MORE_COMPANIES_REQUEST,
	SEARCH_MORE_COMPANIES_SUCCESS,
	SEARCH_MORE_CONTRACTS_REQUEST,
	SEARCH_MORE_CONTRACTS_SUCCESS,
	SEARCH_MORE_CONTRACTS_FAILURE,
	SEARCH_CONTRACTS_REQUEST,
	SEARCH_CONTRACTS_SUCCESS,
	SEARCH_CONTRACTS_FAILURE,
	FETCH_BOOKING_SHIPMENTS_REQUEST,
	FETCH_BOOKING_SHIPMENTS_SUCCESS,
	FETCH_BOOKING_SHIPMENTS_FAILURE,
	DELETE_BILL_REQUEST,
	DELETE_BILL_SUCCESS,
	DELETE_BILL_FAILURE,
	ADD_CARRIER_TO_SHIPMENT_REQUEST,
	ADD_CARRIER_TO_SHIPMENT_SUCCESS,
	ADD_CARRIER_TO_SHIPMENT_FAILURE,
	LISTEN_TO_VENDOR_REQUESTS,
	UPDATE_VENDOR_REQUEST,
	FETCH_DAILY_SUMMARY_REQUEST,
	FETCH_DAILY_SUMMARY_SUCCESS,
	FETCH_DAILY_SUMMARY_FAILURE,
	FETCH_MONTHLY_GENERAL_ANALYTICS_REQUEST,
	FETCH_MONTHLY_GENERAL_ANALYTICS_SUCCESS,
	FETCH_MONTHLY_GENERAL_ANALYTICS_FAILURE,
	FETCH_MONTHLY_OPERATIONS_ANALYTICS_REQUEST,
	FETCH_MONTHLY_OPERATIONS_ANALYTICS_SUCCESS,
	FETCH_MONTHLY_OPERATIONS_ANALYTICS_FAILURE,
	FETCH_MONTHLY_VENDOR_ANALYTICS_REQUEST,
	FETCH_MONTHLY_VENDOR_ANALYTICS_SUCCESS,
	FETCH_MONTHLY_VENDOR_ANALYTICS_FAILURE,
	FETCH_ANNUAL_TRENDS_ANALYTICS_REQUEST,
	FETCH_ANNUAL_TRENDS_ANALYTICS_SUCCESS,
	FETCH_ANNUAL_TRENDS_ANALYTICS_FAILURE,
	FETCH_REQUESTED_BOOKINGS_REQUEST,
	FETCH_ONGOING_BOOKINGS_REQUEST,
	FETCH_REQUESTED_BOOKINGS_SUCCESS,
	FETCH_ONGOING_BOOKINGS_SUCCESS,
	FETCH_MORE_REQUESTED_BOOKINGS_SUCCESS,
	FETCH_MORE_ONGOING_BOOKINGS_SUCCESS,
	FETCH_MORE_REQUESTED_BOOKINGS_FAILURE,
	FETCH_MORE_ONGOING_BOOKINGS_FAILURE,
	FETCH_ONGOING_BOOKINGS_FAILURE,
	FETCH_REQUESTED_BOOKINGS_FAILURE,
	FETCH_MORE_REQUESTED_BOOKINGS_REQUEST,
	FETCH_MORE_ONGOING_BOOKINGS_REQUEST
} from './action-types'
import API from '../../utils/api'
import {
	REQUESTED_SHIPMENT_STATUS,
	SCHEDULED_SHIPMENT_STATUS,
	ASSIGNED_SHIPMENT_STATUS,
	ACTIVE_SHIPMENT_STATUS,
	COMPLETED_SHIPMENT_STATUS,
	CANCELLED_SHIPMENT_STATUS,
	UNASSIGNED_SHIPMENT_STATUS,
	CARRIER_TYPE,
	SHIPPER_TYPE,
	DRIVER_TYPE,
	AGENT_TYPE,
	DISPATCHER_TYPE,
	EXPIRED_SHIPMENT_STATUS
} from '../../constants/strings'

function fetchShipments (lastDocumentId, sortingInfo, assigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_SHIPMENTS_REQUEST })
		return API.getAllShipments(
			lastDocumentId,
			null,
			null,
			null,
			sortingInfo,
			null,
			null,
			assigned
		)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_SHIPMENTS_SUCCESS : FETCH_SHIPMENTS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPMENTS_FAILURE : FETCH_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchUserShipments (lastDocumentId, userId) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_USER_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_USER_SHIPMENTS_REQUEST })
		return API.getAllShipments(lastDocumentId, null, null, null, { }, userId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_USER_SHIPMENTS_SUCCESS : FETCH_USER_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_USER_SHIPMENTS_FAILURE : FETCH_USER_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchRequestedShipments (lastDocumentId, sortingInfo, assigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_REQUESTED_SHIPMENTS_REQUEST })
		return API.getAllShipments(
			lastDocumentId,
			REQUESTED_SHIPMENT_STATUS,
			null,
			null,
			sortingInfo,
			null,
			null,
			assigned
		)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS : FETCH_REQUESTED_SHIPMENTS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE : FETCH_REQUESTED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchScheduledShipments (lastDocumentId, sortingInfo, assigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_SCHEDULED_SHIPMENTS_REQUEST })
		return API.getAllShipments(
			lastDocumentId,
			SCHEDULED_SHIPMENT_STATUS,
			null,
			null,
			sortingInfo,
			null,
			null,
			assigned
		)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS : FETCH_SCHEDULED_SHIPMENTS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE : FETCH_SCHEDULED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAssignedShipments (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_ASSIGNED_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_ASSIGNED_SHIPMENTS_REQUEST })
		return API.getAllShipments(lastDocumentId, ASSIGNED_SHIPMENT_STATUS, null, null, sortingInfo)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_ASSIGNED_SHIPMENTS_SUCCESS : FETCH_ASSIGNED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_ASSIGNED_SHIPMENTS_FAILURE : FETCH_ASSIGNED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchUnassignedShipments (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_UNASSIGNED_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_UNASSIGNED_SHIPMENTS_REQUEST })
		return API.getAllShipments(lastDocumentId, UNASSIGNED_SHIPMENT_STATUS, null, null, sortingInfo)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_UNASSIGNED_SHIPMENTS_SUCCESS : FETCH_UNASSIGNED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_UNASSIGNED_SHIPMENTS_FAILURE : FETCH_UNASSIGNED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchActiveShipments (lastDocumentId, sortingInfo, assigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_ACTIVE_SHIPMENTS_REQUEST })
		return API.getAllShipments(
			lastDocumentId,
			ACTIVE_SHIPMENT_STATUS,
			null,
			null,
			sortingInfo,
			null,
			null,
			assigned
		)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS : FETCH_ACTIVE_SHIPMENTS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE : FETCH_ACTIVE_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCompletedShipments (lastDocumentId, sortingInfo, all, assigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_COMPLETED_SHIPMENTS_REQUEST })
		return API.getAllShipments(
			lastDocumentId,
			COMPLETED_SHIPMENT_STATUS,
			null,
			null,
			sortingInfo,
			null,
			all,
			assigned
		)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS : FETCH_COMPLETED_SHIPMENTS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE : FETCH_COMPLETED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCancelledShipments (lastDocumentId, sortingInfo, assigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_CANCELLED_SHIPMENTS_REQUEST })
		return API.getAllShipments(
			lastDocumentId,
			CANCELLED_SHIPMENT_STATUS,
			null,
			null,
			sortingInfo,
			null,
			null,
			assigned
		)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS : FETCH_CANCELLED_SHIPMENTS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE : FETCH_CANCELLED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchExpiredShipments (lastDocumentId, sortingInfo, assigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST }) : dispatch({ type: FETCH_EXPIRED_SHIPMENTS_REQUEST })
		return API.getAllShipments(
			lastDocumentId,
			EXPIRED_SHIPMENT_STATUS,
			null,
			null,
			sortingInfo,
			null,
			null,
			assigned
		)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS : FETCH_EXPIRED_SHIPMENTS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE : FETCH_EXPIRED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchWatchingShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_WATCHING_SHIPMENTS_REQUEST : FETCH_WATCHING_SHIPMENTS_REQUEST })
		return API.getWatchingShipments(lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_WATCHING_SHIPMENTS_SUCCESS : FETCH_WATCHING_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_WATCHING_SHIPMENTS_FAILURE : FETCH_WATCHING_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBookingShipments (bookingId) {
	return (dispatch) => {
		dispatch({ type: FETCH_BOOKING_SHIPMENTS_REQUEST })
		return API.getBookingShipments(bookingId)
			.then(
				response => dispatch({
					type: FETCH_BOOKING_SHIPMENTS_SUCCESS,
					payload: {
						...response,
						bookingId
					}
				}),
				error => dispatch({
					type: FETCH_BOOKING_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAdminAssignedShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_REQUEST : FETCH_ADMIN_ASSIGNED_SHIPMENTS_REQUEST })
		return API.getAssignedShipments(lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS : FETCH_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_FAILURE : FETCH_ADMIN_ASSIGNED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchUsers (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_USERS_REQUEST }) : dispatch({ type: FETCH_USERS_REQUEST })
		return API.getUsers(undefined, lastDocumentId, sortingInfo ? sortingInfo : {})
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_USERS_SUCCESS : FETCH_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_USERS_FAILURE : FETCH_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperUsers (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_SHIPPER_USERS_REQUEST }) : dispatch({ type: FETCH_SHIPPER_USERS_REQUEST })
		return API.getUsers(SHIPPER_TYPE, lastDocumentId, sortingInfo)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_USERS_SUCCESS : FETCH_SHIPPER_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_USERS_FAILURE : FETCH_SHIPPER_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCarrierUsers (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_CARRIER_USERS_REQUEST }) : dispatch({ type: FETCH_CARRIER_USERS_REQUEST })
		return API.getUsers(CARRIER_TYPE, lastDocumentId, sortingInfo)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_CARRIER_USERS_SUCCESS : FETCH_CARRIER_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CARRIER_USERS_FAILURE : FETCH_CARRIER_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchDriverUsers (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_DRIVER_USERS_REQUEST }) : dispatch({ type: FETCH_DRIVER_USERS_REQUEST })
		return API.getUsers(DRIVER_TYPE, lastDocumentId, sortingInfo)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_DRIVER_USERS_SUCCESS : FETCH_DRIVER_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_DRIVER_USERS_FAILURE : FETCH_DRIVER_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAgentUsers (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_AGENT_USERS_REQUEST }) : dispatch({ type: FETCH_AGENT_USERS_REQUEST })
		return API.getUsers(AGENT_TYPE, lastDocumentId, sortingInfo)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_AGENT_USERS_SUCCESS : FETCH_AGENT_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_AGENT_USERS_FAILURE : FETCH_AGENT_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchDispatcherUsers (lastDocumentId, sortingInfo) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_DISPATCHER_USERS_REQUEST }) : dispatch({ type: FETCH_DISPATCHER_USERS_REQUEST })
		return API.getUsers(DISPATCHER_TYPE, lastDocumentId, sortingInfo)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_DISPATCHER_USERS_SUCCESS : FETCH_DISPATCHER_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_DISPATCHER_USERS_FAILURE : FETCH_DISPATCHER_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function updateUser (id, userData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_REQUEST })
		return API.updateUser(id, userData)
			.then(
				response => dispatch({
					type: UPDATE_USER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_USER_FAILURE,
					payload: error
				})
			)
	}
}

function fetchUser (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_USER_REQUEST })
		return API.getUserById(id)
			.then(
				response => dispatch({
					type: FETCH_USER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_USER_FAILURE,
					payload: error
				})
			)
	}
}

function fetchReports (authUser, lastDocumentId = null) {
	return (dispatch) => {
		const api = () => authUser.type === SHIPPER_TYPE ? API.getCompanyReports(lastDocumentId) : API.getReports(lastDocumentId)
		lastDocumentId ?
			dispatch({ type: FETCH_MORE_REPORTS_REQUEST }) : dispatch({ type: FETCH_REPORTS_REQUEST })
		return api()
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_REPORTS_SUCCESS : FETCH_REPORTS_SUCCESS,
						payload: response
					})
					return response
				},
				error => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_REPORTS_FAILURE : FETCH_REPORTS_FAILURE,
						payload: error
					})
					return error
				}
			)
	}
}

function fetchRates (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_RATES_REQUEST : FETCH_RATES_REQUEST })
		return API.getRates(null, null, null, null, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_RATES_SUCCESS : FETCH_RATES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_RATES_FAILURE : FETCH_RATES_FAILURE,
					payload: error
				})
			)
	}
}

function fetchContractedRates (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_CONTRACTED_RATES_REQUEST : FETCH_CONTRACTED_RATES_REQUEST })
		return API.getRates('contracted', null, null, null, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONTRACTED_RATES_SUCCESS : FETCH_CONTRACTED_RATES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONTRACTED_RATES_FAILURE : FETCH_CONTRACTED_RATES_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCurrentRate () {
	return (dispatch) => {
		dispatch({ type: FETCH_CURRENT_RATE_REQUEST })
		return API.getCurrentRate()
			.then(
				response => dispatch({
					type: FETCH_CURRENT_RATE_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_CURRENT_RATE_FAILURE,
					payload: error
				})
			)
	}
}

function updateRate (id, rateData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_RATE_REQUEST })
		return API.updateRate(id, rateData)
			.then(
				response => dispatch({
					type: UPDATE_RATE_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_RATE_FAILURE,
					payload: error
				})
			)
	}
}

function addRate (rateData) {
	return (dispatch) => {
		dispatch({ type: ADD_RATE_REQUEST })
		return API.addRate(rateData)
			.then(
				response => dispatch({
					type: ADD_RATE_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_RATE_FAILURE,
					payload: error
				})
			)
	}
}

function fetchTransactions (lastDocumentId, sortingInfo, isAssigned) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_TRANSACTIONS_REQUEST }) : dispatch({ type: FETCH_TRANSACTIONS_REQUEST })
		return API.getAllTransactions(lastDocumentId, sortingInfo, isAssigned)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_TRANSACTIONS_SUCCESS : FETCH_TRANSACTIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_TRANSACTIONS_FAILURE : FETCH_TRANSACTIONS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBookings (lastDocumentId, sortingInfo, assigned, status) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_BOOKINGS_REQUEST }) : dispatch({ type: FETCH_BOOKINGS_REQUEST })
		return API.getBookings(lastDocumentId, sortingInfo, assigned, status)
			.then(
				response => {
					dispatch({
						type: lastDocumentId ? FETCH_MORE_BOOKINGS_SUCCESS : FETCH_BOOKINGS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response, true))
				},
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_BOOKINGS_FAILURE : FETCH_BOOKINGS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBookingsByStatus (lastDocumentId, assigned, status) {
	return (dispatch) => {
		// NOTE: Pagination is currently not possible using `lastDocumentId` for the new API for bookings by status
		const isFetchingMore = !!lastDocumentId
		const isRequestedShipment = status === REQUESTED_SHIPMENT_STATUS

		dispatch({ type: isFetchingMore ?
			isRequestedShipment ? FETCH_MORE_REQUESTED_BOOKINGS_REQUEST : FETCH_MORE_ONGOING_BOOKINGS_REQUEST :
			isRequestedShipment ? FETCH_REQUESTED_BOOKINGS_REQUEST : FETCH_ONGOING_BOOKINGS_REQUEST })
		return API.getBookings(lastDocumentId, { filter: undefined, desc: undefined }, assigned, status)
			.then(
				response => {
					dispatch({
						type: isFetchingMore ?
							isRequestedShipment ? FETCH_MORE_REQUESTED_BOOKINGS_SUCCESS : FETCH_MORE_ONGOING_BOOKINGS_SUCCESS :
							isRequestedShipment ? FETCH_REQUESTED_BOOKINGS_SUCCESS : FETCH_ONGOING_BOOKINGS_SUCCESS,
						payload: response
					})
					dispatch(listenToVendorRequests(response, true))
				},
				error => dispatch({
					type: isFetchingMore ?
						isRequestedShipment ? FETCH_MORE_REQUESTED_BOOKINGS_FAILURE : FETCH_MORE_ONGOING_BOOKINGS_FAILURE :
						isRequestedShipment ? FETCH_REQUESTED_BOOKINGS_FAILURE : FETCH_ONGOING_BOOKINGS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBoundingShipments (NELatLng, SWLatLng, status) {
	return (dispatch) => {
		dispatch({ type: FETCH_BOUNDING_SHIPMENTS_REQUEST })
		return API.getAllShipments(null, status, NELatLng, SWLatLng, { })
			.then(
				response => dispatch({
					type: FETCH_BOUNDING_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_BOUNDING_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBoundingUsers (NELatLng, SWLatLng, type) {
	return (dispatch) => {
		dispatch({ type: FETCH_BOUNDING_USERS_REQUEST })
		return API.getUsers(type, null, { }, NELatLng, SWLatLng)
			.then(
				response => dispatch({
					type: FETCH_BOUNDING_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_BOUNDING_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchGlobalCounts () {
	return (dispatch) => {
		dispatch({ type: FETCH_GLOBAL_COUNTS_REQUEST })
		return API.getGlobalCounts()
			.then(
				response => dispatch({
					type: FETCH_GLOBAL_COUNTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_GLOBAL_COUNTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCumulativeGlobalCounts (interval) {
	return (dispatch) => {
		dispatch({ type: interval === 'week' ? FETCH_GLOBAL_WEEKLY_COUNTS_REQUEST : interval === 'month' ? FETCH_GLOBAL_MONTHLY_COUNTS_REQUEST : FETCH_GLOBAL_COUNTS_REQUEST })
		return API.getCumulativeShipmentCounts(interval)
			.then(
				response => dispatch({
					type: interval === 'week' ? FETCH_GLOBAL_WEEKLY_COUNTS_SUCCESS : interval === 'month' ? FETCH_GLOBAL_MONTHLY_COUNTS_SUCCESS : FETCH_GLOBAL_COUNTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: interval === 'week' ? FETCH_GLOBAL_WEEKLY_COUNTS_FAILURE : interval === 'month' ? FETCH_GLOBAL_MONTHLY_COUNTS_FAILURE : FETCH_GLOBAL_COUNTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchGlobalFares () {
	return (dispatch) => {
		dispatch({ type: FETCH_GLOBAL_FARES_REQUEST })
		return API.getGlobalFares()
			.then(
				response => dispatch({
					type: FETCH_GLOBAL_FARES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_GLOBAL_FARES_FAILURE,
					payload: error
				})
			)
	}
}

function fetchGlobalHistory () {
	return (dispatch) => {
		dispatch({ type: FETCH_GLOBAL_HISTORY_REQUEST })
		return API.getGlobalHistory()
			.then(
				response => dispatch({
					type: FETCH_GLOBAL_HISTORY_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_GLOBAL_HISTORY_FAILURE,
					payload: error
				})
			)
	}
}

function fetchRecentlyActiveShippers () {
	return (dispatch) => {
		dispatch({ type: FETCH_RECENTLY_ACTIVE_SHIPPERS_REQUEST })
		return API.getRecentlyActiveShippers()
			.then(
				response => dispatch({
					type: FETCH_RECENTLY_ACTIVE_SHIPPERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_RECENTLY_ACTIVE_SHIPPERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperHistory (userId) {
	return (dispatch) => {
		dispatch({ type: FETCH_SHIPPER_HISTORY_REQUEST })
		return API.getGlobalHistory(userId)
			.then(
				response => dispatch({
					type: FETCH_SHIPPER_HISTORY_SUCCESS,
					payload: {
						id: userId,
						...response
					}
				}),
				error => dispatch({
					type: FETCH_SHIPPER_HISTORY_FAILURE,
					payload: error
				})
			)
	}
}

function fetchRateHistory (id, days) {
	return (dispatch) => {
		dispatch({ type: FETCH_RATE_HISTORY_REQUEST })
		return API.getRatesHistory(id, days)
			.then(
				response => dispatch({
					type: FETCH_RATE_HISTORY_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_RATE_HISTORY_FAILURE,
					payload: error
				})
			)
	}
}

function overrideShipmentStatus (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: OVERRIDE_SHIPMENT_STATUS_REQUEST })
		return API.overrideShipmentStatus(id, shipmentData)
			.then(
				response => dispatch({
					type: OVERRIDE_SHIPMENT_STATUS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: OVERRIDE_SHIPMENT_STATUS_FAILURE,
					payload: error
				})
			)
	}
}

function scheduleShipment (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: OVERRIDE_SHIPMENT_STATUS_REQUEST })
		return API.scheduleShipment(id, shipmentData)
			.then(
				response => dispatch({
					type: OVERRIDE_SHIPMENT_STATUS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: OVERRIDE_SHIPMENT_STATUS_FAILURE,
					payload: error
				})
			)
	}
}

function addCarrierToShipment (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: ADD_CARRIER_TO_SHIPMENT_REQUEST })
		return API.addCarrierToShipment(id, shipmentData)
			.then(
				response => dispatch({
					type: ADD_CARRIER_TO_SHIPMENT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_CARRIER_TO_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function searchRates (searchTerm, truckType, from, to) {
	return (dispatch) => {
		dispatch({ type: SEARCH_RATES_REQUEST })
		return API.searchRates(searchTerm, truckType, from, to)
			.then(
				response => dispatch({
					type: SEARCH_RATES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: SEARCH_RATES_FAILURE,
					payload: error
				})
			)
	}
}

function fetchContracts (status, lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_CONTRACTS_REQUEST : FETCH_CONTRACTS_REQUEST })
		return API.fetchContracts(status, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONTRACTS_SUCCESS : FETCH_CONTRACTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONTRACTS_FAILURE : FETCH_CONTRACTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchPendingContracts (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_PENDING_CONTRACTS_REQUEST : FETCH_PENDING_CONTRACTS_REQUEST })
		return API.fetchContracts('pending', lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_PENDING_CONTRACTS_SUCCESS : FETCH_PENDING_CONTRACTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_PENDING_CONTRACTS_FAILURE : FETCH_PENDING_CONTRACTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchContract (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_CONTRACT_REQUEST })
		return API.fetchContract(id)
			.then(
				response => dispatch({
					type: FETCH_CONTRACT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_CONTRACT_FAILURE,
					payload: error
				})
			)
	}
}

function updateContract (id, contractData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_CONTRACT_REQUEST })
		return API.updateContract(id, contractData)
			.then(
				response => dispatch({
					type: UPDATE_CONTRACT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_CONTRACT_FAILURE,
					payload: error
				})
			)
	}
}

function updateContractStatus (id, status) {
	return (dispatch) => {
		dispatch({ type: UPDATE_CONTRACT_STATUS_REQUEST })
		return API.updateContractStatus(id, status)
			.then(
				response => dispatch({
					type: UPDATE_CONTRACT_STATUS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_CONTRACT_STATUS_FAILURE,
					payload: error
				})
			)
	}
}

function addContract (contractData) {
	return (dispatch) => {
		dispatch({ type: ADD_CONTRACT_REQUEST })
		return API.addContract(contractData)
			.then(
				response => dispatch({
					type: ADD_CONTRACT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_CONTRACT_FAILURE,
					payload: error
				})
			)
	}
}

function watchShipment (id, shipmentData, userId) {
	return (dispatch) => {
		dispatch({ type: WATCH_SHIPMENT_REQUEST, payload: { watch: shipmentData.watch, userId } })
		return API.watchShipment(id, shipmentData)
			.then(
				response => dispatch({
					type: WATCH_SHIPMENT_SUCCESS,
					payload: {
						...response,
						watch: shipmentData.watch
					}
				}),
				error => dispatch({
					type: WATCH_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function assignShipment (id, shipmentData, uid) {
	return (dispatch) => {
		dispatch({ type: ASSIGN_SHIPMENT_REQUEST, payload: { assign: shipmentData.assign, id, uid } })
		return API.assignShipmentToAdmin(id, shipmentData)
			.then(
				response => dispatch({
					type: ASSIGN_SHIPMENT_SUCCESS,
					payload: {
						...response,
						watch: shipmentData.watch
					}
				}),
				error => dispatch({
					type: ASSIGN_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function massAssignShipments (shipperId, assignData, authUserId) {
	return (dispatch) => {
		dispatch({ type: MASS_ASSIGN_SHIPMENTS_REQUEST })
		return API.massAssignShipmentsToAdmin(shipperId, assignData)
			.then(
				response => dispatch({
					type: MASS_ASSIGN_SHIPMENTS_SUCCESS,
					payload: {
						...response,
						authUserId
					}
				}),
				error => dispatch({
					type: MASS_ASSIGN_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function updateStatCounts (counts) {
	return (dispatch) => {
		dispatch({ type: UPDATE_STAT_COUNTS, payload: counts })
	}
}

function fetchBills (status, lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_BILLS_REQUEST : FETCH_BILLS_REQUEST })
		return API.fetchBills(status, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_BILLS_SUCCESS : FETCH_BILLS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_BILLS_FAILURE : FETCH_BILLS_FAILURE,
					payload: error
				})
			)
	}
}

function deleteBill (billId) {
	return (dispatch) => {
		dispatch({ type:  DELETE_BILL_REQUEST })
		return API.deleteBill(billId)
			.then(
				response => dispatch({
					type: DELETE_BILL_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: DELETE_BILL_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBill (billId) {
	return (dispatch) => {
		dispatch({ type:  FETCH_BILL_REQUEST })
		return API.fetchBill(billId)
			.then(
				response => dispatch({
					type: FETCH_BILL_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_BILL_FAILURE,
					payload: error
				})
			)
	}
}

function searchBills (searchTerm, pageNumber, filters) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_BILLS_REQUEST : SEARCH_BILLS_REQUEST })
		return API.searchBills(searchTerm, pageNumber, filters)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_BILLS_SUCCESS : SEARCH_BILLS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_BILLS_FAILURE : SEARCH_BILLS_FAILURE,
					payload: error
				})
			)
	}
}

function createBill (billData) {
	return (dispatch) => {
		dispatch({ type: CREATE_BILL_REQUEST })
		return API.createBill(billData)
			.then(
				response => Promise.resolve(dispatch({
					type: CREATE_BILL_SUCCESS,
					payload: response
				})),
				error => Promise.reject(dispatch({
					type: CREATE_BILL_FAILURE,
					payload: error
				}))
			)
	}
}

function fetchContractedShippers () {
	return (dispatch) => {
		dispatch({ type: FETCH_CONTRACTED_SHIPPERS_REQUEST })
		return API.fetchShippersWithContracts()
			.then(
				response => dispatch({
					type: FETCH_CONTRACTED_SHIPPERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_CONTRACTED_SHIPPERS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperContractRates (shipperId) {
	return (dispatch) => {
		dispatch({ type: FETCH_SHIPPER_CONTRACT_RATES_REQUEST })
		return API.fetchShipperContractRates(shipperId)
			.then(
				response => dispatch({
					type: FETCH_SHIPPER_CONTRACT_RATES_SUCCESS,
					payload: {
						...response,
						shipperId
					}
				}),
				error => dispatch({
					type: FETCH_SHIPPER_CONTRACT_RATES_FAILURE,
					payload: error
				})
			)
	}
}

function addCharge (transactionId, transactionData) {
	return (dispatch) => {
		dispatch({ type: ADD_CHARGE_REQUEST })
		return API.updateTransactionCharge(transactionId, transactionData)
			.then(
				response => dispatch({
					type: ADD_CHARGE_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_CHARGE_FAILURE,
					payload: error
				})
			)
	}
}

function removeCharge (transactionId, transactionData) {
	return (dispatch) => {
		dispatch({ type: REMOVE_CHARGE_REQUEST })
		return API.updateTransactionCharge(transactionId, transactionData)
			.then(
				response => dispatch({
					type: REMOVE_CHARGE_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: REMOVE_CHARGE_FAILURE,
					payload: error
				})
			)
	}
}

function fetchNotifications (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId? FETCH_MORE_ADMIN_NOTIFICATIONS_REQUEST : FETCH_ADMIN_NOTIFICATIONS_REQUEST })
		return API.fetchAdminNotifications(lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId? FETCH_MORE_ADMIN_NOTIFICATIONS_SUCCESS : FETCH_ADMIN_NOTIFICATIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId? FETCH_MORE_ADMIN_NOTIFICATIONS_FAILURE : FETCH_ADMIN_NOTIFICATIONS_FAILURE,
					payload: error
				})
			)
	}
}

function addNotification (notification) {
	return (dispatch) => {
		dispatch({ type: ADD_ADMIN_NOTIFICATION, payload: notification })
	}
}

function updateNotification (notification) {
	return (dispatch) => {
		dispatch({ type: UPDATE_ADMIN_NOTIFICATION, payload: notification })
	}
}

function readNotification (notification) {
	return (dispatch) => {
		dispatch({ type: READ_ADMIN_NOTIFICATION, payload: notification })
	}
}

function addLocation (locationData) {
	return (dispatch) => {
		dispatch({ type: ADD_LOCATION_REQUEST })
		return API.addLocation(locationData)
			.then(
				response => dispatch({
					type: ADD_LOCATION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_LOCATION_FAILURE,
					payload: error
				})
			)
	}
}

function updateLocation (id, locationData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_LOCATION_REQUEST, payload: { id, label: locationData.label } })
		return API.updateLocation(id, locationData)
			.then(
				response => dispatch({
					type: UPDATE_LOCATION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_LOCATION_FAILURE,
					payload: error
				})
			)
	}
}

function deleteLocation (id) {
	return (dispatch) => {
		dispatch({ type: DELETE_LOCATION_REQUEST, payload: { id } })
		return API.deleteLocation(id)
			.then(
				response => dispatch({
					type: DELETE_LOCATION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: DELETE_LOCATION_FAILURE,
					payload: error
				})
			)
	}
}

function preferCarrier (carrierId, dispatcherId) {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_REQUEST, payload: { id: carrierId, dispatcherId } })
		return API.preferCarrier(carrierId)
			.then(
				response => dispatch({
					type: UPDATE_USER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_USER_FAILURE,
					payload: error
				})
			)
	}
}

function fetchRevenue (interval) {
	return (dispatch) => {
		dispatch({ type: FETCH_REVENUE_REQUEST })
		return API.getRevenueHistory(interval)
			.then(
				response => dispatch({
					type: FETCH_REVENUE_SUCCESS,
					payload: { interval, ...response }
				}),
				error => dispatch({
					type: FETCH_REVENUE_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAllCompanies () {
	return (dispatch) => {
		dispatch({ type: FETCH_ALL_COMPANIES_REQUEST })
		return API.getCompanies(true)
			.then(
				response => dispatch({
					type: FETCH_ALL_COMPANIES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_ALL_COMPANIES_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCompanies (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_COMPANIES_REQUEST : FETCH_COMPANIES_REQUEST })
		return API.getCompanies(false, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_COMPANIES_SUCCESS : FETCH_COMPANIES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_COMPANIES_FAILURE : FETCH_COMPANIES_FAILURE,
					payload: error
				})
			)
	}
}

function fetchDashboard (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_DASHBOARD_REQUEST, payload: { month } })
		return API.getDashboard(true, month)
			.then(
				response => dispatch({
					type: FETCH_DASHBOARD_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_DASHBOARD_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAnnualDashboard () {
	return (dispatch) => {
		dispatch({ type: FETCH_ANNUAL_DASHBOARD_REQUEST })
		return API.getAnnualDashboard()
			.then(
				response => dispatch({
					type: FETCH_ANNUAL_DASHBOARD_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_ANNUAL_DASHBOARD_FAILURE,
					payload: error
				})
			)
	}
}


function deleteContract (id) {
	return (dispatch) => {
		dispatch({ type: DELETE_CONTRACT_REQUEST })
		return API.deleteContract(id)
			.then(
				response => dispatch({
					type: DELETE_CONTRACT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: DELETE_CONTRACT_FAILURE,
					payload: error
				})
			)
	}
}

function fetchUsersDashboard (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_USERS_DASHBOARD_REQUEST, payload: { month } })
		return API.getUsersDashboard(month)
			.then(
				response => dispatch({
					type: FETCH_USERS_DASHBOARD_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_USERS_DASHBOARD_FAILURE,
					payload: error
				})
			)
	}
}

function fetchEventsDashboard (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_EVENTS_DASHBOARD_REQUEST, payload: { month } })
		return API.getEventsDashboard(month)
			.then(
				response => dispatch({
					type: FETCH_EVENTS_DASHBOARD_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_EVENTS_DASHBOARD_FAILURE,
					payload: error
				})
			)
	}
}

function searchCompanies (searchTerm, pageNumber) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_COMPANIES_REQUEST : SEARCH_COMPANIES_REQUEST })
		return API.searchCompanies(searchTerm, pageNumber)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_COMPANIES_SUCCESS : SEARCH_COMPANIES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_COMPANIES_FAILURE : SEARCH_COMPANIES_FAILURE,
					payload: error
				})
			)
	}
}

function searchContracts (searchTerm, filters, pageNumber) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_CONTRACTS_REQUEST : SEARCH_CONTRACTS_REQUEST })
		return API.searchContracts(searchTerm, filters, pageNumber)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_CONTRACTS_SUCCESS : SEARCH_CONTRACTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_CONTRACTS_FAILURE : SEARCH_CONTRACTS_FAILURE,
					payload: error
				})
			)
	}
}

function listenToVendorRequests (response, isBooking) {
	return (dispatch, getState) => {
		const { admin } = getState()
		const items = isBooking ? response.bookings : response.shipments
		const vendorRequests = { }
		for (const item of items) {
			const bookingId = isBooking ? item.id : item.bookingId
			if (!admin.vendorRequests[bookingId] && !vendorRequests[bookingId]) {
				vendorRequests[bookingId] = { }
			}
		}
		dispatch({ type: LISTEN_TO_VENDOR_REQUESTS, payload: vendorRequests })
	}
}

function updateVendorRequest (vendorRequest) {
	return (dispatch) => {
		dispatch({ type: UPDATE_VENDOR_REQUEST, payload: vendorRequest })
	}
}

function fetchDailySummary () {
	return (dispatch) => {
		dispatch({ type: FETCH_DAILY_SUMMARY_REQUEST })
		return API.fetchDailySummary()
			.then(
				response => dispatch({
					type: FETCH_DAILY_SUMMARY_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_DAILY_SUMMARY_FAILURE,
					payload: error
				})
			)
	}
}

function fetchMonthlyGeneralAnalytics (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_MONTHLY_GENERAL_ANALYTICS_REQUEST, payload: month })
		return API.fetchMonthlyGeneralAnalytics(month)
			.then(
				response => dispatch({
					type: FETCH_MONTHLY_GENERAL_ANALYTICS_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_MONTHLY_GENERAL_ANALYTICS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchMonthlyOperationsAnalytics (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_MONTHLY_OPERATIONS_ANALYTICS_REQUEST, payload: month })
		return API.fetchMonthlyOperationsAnalytics(month)
			.then(
				response => dispatch({
					type: FETCH_MONTHLY_OPERATIONS_ANALYTICS_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_MONTHLY_OPERATIONS_ANALYTICS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchMonthlyVendorAnalytics (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_MONTHLY_VENDOR_ANALYTICS_REQUEST, payload: month })
		return API.fetchMonthlyVendorAnalytics(month)
			.then(
				response => dispatch({
					type: FETCH_MONTHLY_VENDOR_ANALYTICS_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_MONTHLY_VENDOR_ANALYTICS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAnnualTrendsAnalytics () {
	return (dispatch) => {
		dispatch({ type: FETCH_ANNUAL_TRENDS_ANALYTICS_REQUEST })
		return API.fetchAnnualTrendsAnalytics()
			.then(
				response => dispatch({
					type: FETCH_ANNUAL_TRENDS_ANALYTICS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_ANNUAL_TRENDS_ANALYTICS_FAILURE,
					payload: error
				})
			)
	}
}

export {
	fetchShipments,
	fetchRequestedShipments,
	fetchScheduledShipments,
	fetchAssignedShipments,
	fetchUnassignedShipments,
	fetchActiveShipments,
	fetchCompletedShipments,
	fetchCancelledShipments,
	fetchExpiredShipments,
	fetchWatchingShipments,
	fetchBookingShipments,
	fetchUsers,
	fetchShipperUsers,
	fetchCarrierUsers,
	fetchDriverUsers,
	fetchRates,
	fetchReports,
	fetchContractedRates,
	updateUser,
	fetchUser,
	fetchCurrentRate,
	updateRate,
	addRate,
	fetchTransactions,
	fetchBookings,
	fetchBookingsByStatus,
	fetchBoundingShipments,
	fetchGlobalCounts,
	fetchGlobalHistory,
	fetchGlobalFares,
	fetchRateHistory,
	overrideShipmentStatus,
	fetchUserShipments,
	scheduleShipment,
	addCarrierToShipment,
	fetchAgentUsers,
	fetchDispatcherUsers,
	fetchBoundingUsers,
	searchRates,
	fetchContracts,
	fetchPendingContracts,
	fetchContract,
	addContract,
	updateContract,
	watchShipment,
	fetchAdminAssignedShipments,
	assignShipment,
	massAssignShipments,
	updateStatCounts,
	fetchBills,
	searchBills,
	createBill,
	fetchContractedShippers,
	fetchShipperContractRates,
	addCharge,
	removeCharge,
	fetchNotifications,
	addNotification,
	updateNotification,
	readNotification,
	fetchShipperHistory,
	fetchRecentlyActiveShippers,
	fetchCumulativeGlobalCounts,
	addLocation,
	deleteLocation,
	updateLocation,
	preferCarrier,
	fetchRevenue,
	fetchAllCompanies,
	fetchCompanies,
	fetchDashboard,
	fetchAnnualDashboard,
	fetchBill,
	deleteBill,
	deleteContract,
	fetchUsersDashboard,
	fetchEventsDashboard,
	updateContractStatus,
	searchCompanies,
	searchContracts,
	updateVendorRequest,
	fetchDailySummary,
	fetchMonthlyGeneralAnalytics,
	fetchMonthlyOperationsAnalytics,
	fetchMonthlyVendorAnalytics,
	fetchAnnualTrendsAnalytics
}
