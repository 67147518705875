import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withFirebase } from '../firebase'
import { connect } from 'react-redux'
import { messageActions } from '../../../redux-modules/messages'
import API from '../../../utils/api'
import {
	getAuthenticationUser
} from '../../../reducers'

export const ConversationContext = React.createContext()

const withConversation = Component => {
	class WithConversation extends React.Component {
		constructor (props) {
			super(props)
			this.state = {
				selectedConversations: [],
				messageMetadata: {} // Key is the receiverId
			}
		}

		listenForConversations = () => {
			this.props.firebase.userConversations(this.props.authUser.id, querySnapshot => {
				querySnapshot.docChanges().forEach(change => {
					if (change.type === 'added' || change.type === 'modified') {
						const conversation = change.doc.data()
						conversation.createdAt = conversation.createdAt.toDate()
						conversation.updatedAt = conversation.updatedAt.toDate()
						this.props.addConversation(conversation)
					}
				})
			})
		}

		detachConversations = () => {
			this.props.firebase.detachUserConversations()
		}

		openConversation = (receiver, conversation, metadata) => {
			const selectedConversations = [...this.state.selectedConversations]
			const index = selectedConversations.findIndex(selectedConversation => selectedConversation.memberData[receiver.id])
			if (metadata) {
				const messageMetadata = { ...this.state.messageMetadata }
				messageMetadata[receiver.id] = metadata
				this.setState({ messageMetadata })
			}
			if (index !== -1) {
				return
			}
			if (this.state.selectedConversations.length === 2) {
				selectedConversations.pop()
			}
			if (conversation) {
				selectedConversations.push(conversation)
				this.getConversationMessages(conversation.id)
				this.setState({ selectedConversations })
			} else {
				// There are situations where we only have access to the receiver's UID and need to retrieve more info on the user to display.
				// We check if a user type exists and retrieve more info.
				if (receiver.type) {
					// Create an empty conversation initially.
					this.setUserInfoForSelectedConversations(receiver, selectedConversations)
				} else {
					API.getReceiver(receiver.id)
						.then(receiver => {
							this.setUserInfoForSelectedConversations(receiver, selectedConversations)
						})
				}
			}
		}

		setUserInfoForSelectedConversations = (receiver, selectedConversations) => {
			const members = { }
			members[this.props.authUser.id] = true
			members[receiver.id] = true
			const memberData = { }
			memberData[this.props.authUser.id] = this.props.authUser
			memberData[receiver.id] = receiver
			selectedConversations.push({
				receiverId: receiver.id,
				members,
				memberData
			})
			this.findConversationFromReceiver(receiver.id)
			this.setState({ selectedConversations })
		}

		findConversationFromReceiver = receiverId => {
			return API.getConversations(undefined, receiverId)
				.then(response => {
					const conversations = response.conversations
					if (conversations && conversations.length > 0) {
						const conversationResponse = conversations[0]
						this.getConversationMessages(conversationResponse.id)
						let selectedConversations = [...this.state.selectedConversations]
						selectedConversations = selectedConversations.map(conversation => {
							if (!conversation.id && conversationResponse.members[conversation.receiverId]) {
								return conversationResponse
							} else {
								return conversation
							}
						})
						this.setState({ selectedConversations })
					}
				})
		}

		closeConversation = (receiver, conversation) => {
			this.removeMessageMetadata(receiver.id)
			let selectedConversations = [...this.state.selectedConversations]
			if (conversation) {
				selectedConversations = selectedConversations.filter(selectedConversation => selectedConversation.id !== conversation.id)
			} else {
				const index = selectedConversations.findIndex(selectedConversation => selectedConversation.memberData[receiver.id])
				selectedConversations.splice(index, 1)
			}
			this.setState({ selectedConversations })
		}

		removeMessageMetadata = receiverId => {
			const messageMetadata = { ...this.state.messageMetadata }
			delete messageMetadata[receiverId]
			this.setState({ messageMetadata })
		}

		sendMessage = (conversationId, receiverId, message, metadata, onConversationCreated) => {
			const messageData = {
				senderId: this.props.authUser.id,
				receiverId,
				message,
				metadata,
				nonce: this.props.firebase.getConversationMessageId(this.props.authUser.id),
				createdAt: new Date()
			}
			this.removeMessageMetadata(receiverId)
			if (conversationId) {
				this.props.createConversationMessage(conversationId, messageData)
			} else {
				this.props.createConversation(messageData)
					.then(response => {
						const conversation = response.payload
						if (conversation) {
							this.listenForMessages(conversation.id)
							if (onConversationCreated) {
								onConversationCreated(conversation)
							}
						}
					})
			}
		}

		getReceiver = conversation => {
			if (!conversation) return undefined
			const receivers = Object.values(conversation.memberData).filter(user => user.id !== this.props.authUser.id)
			const receiver = receivers[0]
			return receiver
		}

		getConversationMessages = conversationId => {
			this.props.getConversationMessages(conversationId)
			this.listenForMessages(conversationId)
		}

		listenForMessages = conversationId => {
			this.props.firebase.conversationMessages(conversationId, querySnapshot => {
				querySnapshot.docChanges().forEach(change => {
					if (change.type === 'added' || change.type === 'modified') {
						const message = change.doc.data()
						message.createdAt = message.createdAt.toDate()
						this.props.addConversationMessage(conversationId, message)
					}
				})
			})
		}

		detachMessages = conversationId => {
			this.props.firebase.detachConversationMessages(conversationId)
		}

		readConversation = conversation => {
			this.props.firebase.readConversation(this.props.authUser.id, conversation)
			this.props.updateConversation(conversation)
		}

		render () {
			const {
				getReceiver,
				openConversation,
				closeConversation,
				listenForConversations,
				detachConversations,
				sendMessage,
				getConversationMessages,
				listenForMessages,
				detachMessages,
				readConversation,
				removeMessageMetadata
			} = this
			return (
				<ConversationContext.Provider value={{
					...this.state,
					getReceiver,
					openConversation,
					closeConversation,
					listenForConversations,
					detachConversations,
					sendMessage,
					getConversationMessages,
					listenForMessages,
					detachMessages,
					readConversation,
					removeMessageMetadata
				}}
				>
					<Component {...this.props} />
				</ConversationContext.Provider>
			)
		}
	}

	WithConversation.propTypes = {
		authUser: PropTypes.object,
		firebase: PropTypes.object,
		getConversations: PropTypes.func,
		getConversationMessages: PropTypes.func,
		createConversation: PropTypes.func,
		createConversationMessage: PropTypes.func,
		addConversation: PropTypes.func,
		addConversationMessage: PropTypes.func,
		updateConversation: PropTypes.func
	}

	const mapStateToProps = (state) => ({
		authUser: getAuthenticationUser(state)
	})

	const mapDispatchToProps = {
		getConversations: messageActions.fetchConversations,
		getConversationMessages: messageActions.fetchConversationMessages,
		createConversation: messageActions.createConversation,
		createConversationMessage: messageActions.createConversationMessage,
		addConversation: messageActions.addConversation,
		addConversationMessage: messageActions.addConversationMessage,
		updateConversation: messageActions.updateConversation
	}

	return compose(
		withRouter,
		withFirebase,
		connect(mapStateToProps, mapDispatchToProps)
	)(WithConversation)
}

export default withConversation
