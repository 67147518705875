export const FIND_USER_FETCH_REQUEST = 'FIND_USER_FETCH_REQUEST'
export const FIND_USER_FETCH_SUCCESS = 'FIND_USER_FETCH_SUCCESS'
export const FIND_USER_FETCH_FAILURE = 'FIND_USER_FETCH_FAILURE'
export const FETCH_DELIVERY_LOCATIONS_REQUEST = 'FETCH_DELIVERY_LOCATIONS_REQUEST'
export const FETCH_DELIVERY_LOCATIONS_SUCCESS = 'FETCH_DELIVERY_LOCATIONS_SUCCESS'
export const FETCH_DELIVERY_LOCATIONS_FAILURE = 'FETCH_DELIVERY_LOCATIONS_FAILURE'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const READ_NOTIFICATION = 'READ_NOTIFICATION'
export const UPDATE_FILTER_REQUEST = 'UPDATE_FILTER_REQUEST'
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS'
export const UPDATE_FILTER_FAILURE = 'UPDATE_FILTER_FAILURE'
export const FETCH_FILTER_REQUEST = 'FETCH_FILTER_REQUEST'
export const FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS'
export const FETCH_FILTER_FAILURE = 'FETCH_FILTER_FAILURE'
export const ADD_BANK_DETAIL_REQUEST = 'ADD_BANK_DETAIL_REQUEST'
export const ADD_BANK_DETAIL_SUCCESS = 'ADD_BANK_DETAIL_SUCCESS'
export const ADD_BANK_DETAIL_FAILURE = 'ADD_BANK_DETAIL_FAILURE'
export const FETCH_BANK_DETAILS_REQUEST = 'FETCH_BANK_DETAIL_REQUEST'
export const FETCH_BANK_DETAILS_SUCCESS = 'FETCH_BANK_DETAIL_SUCCESS'
export const FETCH_BANK_DETAILS_FAILURE = 'FETCH_BANK_DETAIL_FAILURE'
export const FETCH_BANK_DETAIL_REQUEST = 'FETCH_BANK_DETAIL_REQUEST'
export const FETCH_BANK_DETAIL_SUCCESS = 'FETCH_BANK_DETAIL_SUCCESS'
export const FETCH_BANK_DETAIL_FAILURE = 'FETCH_BANK_DETAIL_FAILURE'
export const UPDATE_BANK_DETAIL_REQUEST = 'UPDATE_BANK_DETAIL_REQUEST'
export const UPDATE_BANK_DETAIL_SUCCESS = 'UPDATE_BANK_DETAIL_SUCCESS'
export const UPDATE_BANK_DETAIL_FAILURE = 'UPDATE_BANK_DETAIL_FAILURE'
export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE'
export const SEARCH_MORE_USERS_REQUEST = 'SEARCH_MORE_USERS_REQUEST'
export const SEARCH_MORE_USERS_SUCCESS = 'SEARCH_MORE_USERS_SUCCESS'
export const SEARCH_MORE_USERS_FAILURE = 'SEARCH_MORE_USERS_FAILURE'
export const FETCH_SHIPPER_SHIPMENT_COUNTS_REQUEST = 'FETCH_SHIPPER_SHIPMENT_COUNTS_REQUEST'
export const FETCH_SHIPPER_SHIPMENT_COUNTS_SUCCESS = 'FETCH_SHIPPER_SHIPMENT_COUNTS_SUCCESS'
export const FETCH_SHIPPER_SHIPMENT_COUNTS_FAILURE = 'FETCH_SHIPPER_SHIPMENT_COUNTS_FAILURE'
export const FETCH_SHIPPER_SHIPMENT_HISTORY_REQUEST = 'FETCH_SHIPPER_SHIPMENT_HISTORY_REQUEST'
export const FETCH_SHIPPER_SHIPMENT_HISTORY_SUCCESS = 'FETCH_SHIPPER_SHIPMENT_HISTORY_SUCCESS'
export const FETCH_SHIPPER_SHIPMENT_HISTORY_FAILURE = 'FETCH_SHIPPER_SHIPMENT_HISTORY_FAILURE'
export const FETCH_CARRIER_COUNTS_REQUEST = 'FETCH_CARRIER_COUNTS_REQUEST'
export const FETCH_CARRIER_COUNTS_SUCCESS = 'FETCH_CARRIER_COUNTS_SUCCESS'
export const FETCH_CARRIER_COUNTS_FAILURE = 'FETCH_CARRIER_COUNTS_FAILURE'
export const UPDATE_NOTIFICATION_COUNT_SUCCESS = 'UPDATE_NOTIFICATION_COUNT_SUCCESS'
export const UPDATE_NOTIFICATIONS_INFO_SUCCESS = 'UPDATE_NOTIFICATION_INFO_SUCCESS'
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST'
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_MORE_NOTIFICATIONS_REQUEST = 'FETCH_MORE_NOTIFICATIONS_REQUEST'
export const FETCH_MORE_NOTIFICATIONS_FAILURE = 'FETCH_MORE_NOTIFICATIONS_FAILURE'
export const FETCH_MORE_NOTIFICATIONS_SUCCESS = 'FETCH_MORE_NOTIFICATIONS_SUCCESS'
export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST'
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE'
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS'
export const FETCH_MORE_TRANSACTIONS_FAILURE = 'FETCH_MORE_TRANSACTIONS_FAILURE'
export const FETCH_MORE_TRANSACTIONS_REQUEST = 'FETCH_MORE_TRANSACTIONS_REQUEST'
export const FETCH_MORE_TRANSACTIONS_SUCCESS = 'FETCH_MORE_TRANSACTIONS_SUCCESS'
export const FETCH_TRUCK_TYPES_SUCCESS =  'FETCH_TRUCK_TYPES_SUCCESS'
export const FETCH_TRUCK_TYPES_REQUEST =  'FETCH_TRUCK_TYPES_REQUEST'
export const FETCH_TRUCK_TYPES_FAILURE =  'FETCH_TRUCK_TYPES_FAILURE'
export const FETCH_ITEM_TYPES_SUCCESS =  'FETCH_ITEM_TYPES_SUCCESS'
export const FETCH_ITEM_TYPES_REQUEST =  'FETCH_ITEM_TYPES_REQUEST'
export const FETCH_ITEM_TYPES_FAILURE =  'FETCH_ITEM_TYPES_FAILURE'
export const FETCH_BANK_OPTIONS_SUCCESS =  'FETCH_BANK_OPTIONS_SUCCESS'
export const FETCH_BANK_OPTIONS_REQUEST =  'FETCH_BANK_OPTIONS_REQUEST'
export const FETCH_BANK_OPTIONS_FAILURE = 'FETCH_BANK_OPTIONS_FAILURE'
export const FETCH_TRUCK_BRANDS_MODELS_REQUEST = 'FETCH_TRUCK_BRANDS_MODELS_REQUEST'
export const FETCH_TRUCK_BRANDS_MODELS_SUCCESS = 'FETCH_TRUCK_BRANDS_MODELS_SUCCESS'
export const FETCH_TRUCK_BRANDS_MODELS_FAILURE = 'FETCH_TRUCK_BRANDS_MODELS_FAILURE'
export const ADD_SHIPPER_QUOTATION_REQUEST = 'ADD_SHIPPER_QUOTATION_REQUEST'
export const ADD_SHIPPER_QUOTATION_SUCCESS = 'ADD_SHIPPER_QUOTATION_SUCCESS'
export const ADD_SHIPPER_QUOTATION_FAILURE = 'ADD_SHIPPER_QUOTATION_FAILURE'
export const FETCH_SHIPPER_CONTRACTS_REQUEST = 'FETCH_SHIPPER_CONTRACTS_REQUEST'
export const FETCH_SHIPPER_CONTRACTS_SUCCESS = 'FETCH_SHIPPER_CONTRACTS_SUCCESS'
export const FETCH_SHIPPER_CONTRACTS_FAILURE = 'FETCH_SHIPPER_CONTRACTS_FAILURE'
export const FETCH_MORE_SHIPPER_CONTRACTS_REQUEST = 'FETCH_MORE_SHIPPER_CONTRACTS_REQUEST'
export const FETCH_MORE_SHIPPER_CONTRACTS_SUCCESS = 'FETCH_MORE_SHIPPER_CONTRACTS_SUCCESS'
export const FETCH_MORE_SHIPPER_CONTRACTS_FAILURE = 'FETCH_MORE_SHIPPER_CONTRACTS_FAILURE'
export const FETCH_SHIPPER_QUOTATIONS_REQUEST = 'FETCH_SHIPPER_QUOTATIONS_REQUEST'
export const FETCH_SHIPPER_QUOTATIONS_SUCCESS = 'FETCH_SHIPPER_QUOTATIONS_SUCCESS'
export const FETCH_SHIPPER_QUOTATIONS_FAILURE = 'FETCH_SHIPPER_QUOTATIONS_FAILURE'
export const FETCH_MORE_SHIPPER_QUOTATIONS_REQUEST = 'FETCH_MORE_SHIPPER_QUOTATIONS_REQUEST'
export const FETCH_MORE_SHIPPER_QUOTATIONS_SUCCESS = 'FETCH_MORE_SHIPPER_QUOTATIONS_SUCCESS'
export const FETCH_MORE_SHIPPER_QUOTATIONS_FAILURE = 'FETCH_MORE_SHIPPER_QUOTATIONS_FAILURE'
export const UPDATE_USER_STATS = 'UPDATE_USER_STATS'
export const FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_REQUEST = 'FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_REQUEST'
export const FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_SUCCESS = 'FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_SUCCESS'
export const FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_FAILURE = 'FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_FAILURE'
export const FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_REQUEST = 'FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_REQUEST'
export const FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_SUCCESS = 'FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_SUCCESS'
export const FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_FAILURE = 'FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_FAILURE'
export const FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_REQUEST = 'FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_REQUEST'
export const FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_SUCCESS = 'FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_SUCCESS'
export const FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_FAILURE = 'FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_FAILURE'
export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE'
export const FETCH_MORE_LOCATIONS_REQUEST = 'FETCH_MORE_LOCATIONS_REQUEST'
export const FETCH_MORE_LOCATIONS_SUCCESS = 'FETCH_MORE_LOCATIONS_SUCCESS'
export const FETCH_MORE_LOCATIONS_FAILURE = 'FETCH_MORE_LOCATIONS_FAILURE'
export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST'
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const READ_ALL_NOTIFICATIONS_REQUEST = 'READ_ALL_NOTIFICATIONS_REQUEST'
export const READ_ALL_NOTIFICATIONS_FAILURE = 'READ_ALL_NOTIFICATIONS_FAILURE'
export const READ_ALL_NOTIFICATIONS_SUCCESS = 'READ_ALL_NOTIFICATIONS_SUCCESS'
export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST'
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS'
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE'
export const FETCH_LOCATIONS_DASHBOARD_REQUEST = 'FETCH_LOCATIONS_DASHBOARD_REQUEST'
export const FETCH_LOCATIONS_DASHBOARD_SUCCESS = 'FETCH_LOCATIONS_DASHBOARD_SUCCESS'
export const FETCH_LOCATIONS_DASHBOARD_FAILURE = 'FETCH_LOCATIONS_DASHBOARD_FAILURE'
export const FETCH_SHIPPER_BILLS_REQUEST = 'FETCH_SHIPPER_BILLS_REQUEST'
export const FETCH_SHIPPER_BILLS_SUCCESS = 'FETCH_SHIPPER_BILLS_SUCCESS'
export const FETCH_SHIPPER_BILLS_FAILURE = 'FETCH_SHIPPER_BILLS_FAILURE'
export const FETCH_MORE_SHIPPER_BILLS_REQUEST = 'FETCH_MORE_SHIPPER_BILLS_REQUEST'
export const FETCH_MORE_SHIPPER_BILLS_SUCCESS = 'FETCH_MORE_SHIPPER_BILLS_SUCCESS'
export const FETCH_MORE_SHIPPER_BILLS_FAILURE = 'FETCH_MORE_SHIPPER_BILLS_FAILURE'
export const SEARCH_SHIPPER_BILLS_REQUEST = 'SEARCH_SHIPPER_BILLS_REQUEST'
export const SEARCH_SHIPPER_BILLS_SUCCESS = 'SEARCH_SHIPPER_BILLS_SUCCESS'
export const SEARCH_SHIPPER_BILLS_FAILURE = 'SEARCH_SHIPPER_BILLS_FAILURE'
export const SEARCH_MORE_SHIPPER_BILLS_REQUEST = 'SEARCH_MORE_SHIPPER_BILLS_REQUEST'
export const SEARCH_MORE_SHIPPER_BILLS_SUCCESS = 'SEARCH_MORE_SHIPPER_BILLS_SUCCESS'
export const SEARCH_MORE_SHIPPER_BILLS_FAILURE = 'SEARCH_MORE_SHIPPER_BILLS_FAILURE'
export const SEARCH_COMPANY_LOCATIONS_REQUEST = 'SEARCH_COMPANY_LOCATIONS_REQUEST'
export const SEARCH_COMPANY_LOCATIONS_SUCCESS = 'SEARCH_COMPANY_LOCATIONS_SUCCESS'
export const SEARCH_COMPANY_LOCATIONS_FAILURE = 'SEARCH_COMPANY_LOCATIONS_FAILURE'
export const SEARCH_MORE_COMPANY_LOCATIONS_REQUEST = 'SEARCH_MORE_COMPANY_LOCATIONS_REQUEST'
export const SEARCH_MORE_COMPANY_LOCATIONS_SUCCESS = 'SEARCH_MORE_COMPANY_LOCATIONS_SUCCESS'
export const SEARCH_MORE_COMPANY_LOCATIONS_FAILURE = 'SEARCH_MORE_COMPANY_LOCATIONS_FAILURE'
export const UPDATE_SHIPMENTS_FILTER = 'UPDATE_SHIPMENTS_FILTER'
export const UPDATE_BOOKINGS_FILTER = 'UPDATE_BOOKINGS_FILTER'
