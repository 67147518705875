import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withFirebase } from '../firebase'
import { authenticationActions } from '../../../redux-modules/authentication'
import { Routes } from '../../../constants/routes'
import {
	getUserLogin
} from '../../../reducers'
import API from '../../../utils/api'

const withAuthentication = Component => {
	class WithAuthentication extends React.Component {

		componentDidMount () {
			this.listener = this.props.firebase.onAuthUserListener(
				async () => {
					const user = await API.getUser()
					localStorage.setItem('authUser', JSON.stringify(user))
					this.props.onSetAuthUser(user)
					this.requestNotificationsPermissions()
				},
				() => {
					localStorage.removeItem('authUser')
					this.props.onSetAuthUser(null)
					if (this.props.location.pathname !== Routes.LOGIN_PAGE() &&
						this.props.location.pathname !== Routes.TERMS_AND_CONDITIONS_PAGE() &&
						!this.props.location.pathname.startsWith(Routes.ACCOUNT_MANAGEMENT_PAGE()) &&
						!this.props.location.pathname.startsWith(Routes.LOCATION_PAGE()) &&
						!this.props.location.pathname.startsWith(Routes.SIGN_UP_PAGE(''))) {
						if (this.props.userLogin) {
							this.props.history.push(Routes.LOGIN_PAGE(), { useEmail: true })
						} else {
							this.props.history.push(Routes.HOME_PAGE())
						}
					}
				}
			)
		}

		componentWillUnmount () {
			this.listener()
		}

		requestNotificationsPermissions = () => {
			Notification.requestPermission()
				.then(async permission => {
					if (permission === 'granted') {
						await this.props.firebase.getPushToken()
					}
				})
		}

		render () {
			return <Component {...this.props} />
		}
	}

	WithAuthentication.propTypes = {
		firebase: PropTypes.object,
		location: PropTypes.object,
		history: PropTypes.object,
		onSetAuthUser: PropTypes.func,
		userLogin: PropTypes.object
	}

	const mapStateToProps = state => ({
		userLogin: getUserLogin(state)
	})

	const mapDispatchToProps = {
		onSetAuthUser: authenticationActions.onSetAuthUser
	}

	return compose(
		withFirebase,
		connect(
			mapStateToProps,
			mapDispatchToProps,
		),
	)(WithAuthentication)
}

export default withAuthentication
