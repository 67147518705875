import i18next from 'i18next'
import {
	SET_AUTH_USER,
	SET_USER_LOGIN,
	UPDATE_USER_PASSWORD_REQUEST,
	UPDATE_USER_PASSWORD_SUCCESS,
	UPDATE_USER_PASSWORD_FAILURE,
	SET_PERMISSIONS
} from './action-types'
import API from '../../utils/api'

function onSetAuthUser (authUser) {
	return (dispatch) => {
		if (authUser?.settings?.locale) {
			i18next.changeLanguage(authUser.settings.locale)
		}
		dispatch({ type: SET_AUTH_USER, payload: authUser })
	}
}

function updateUserPassword (uid, userData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_PASSWORD_REQUEST })
		return API.updateUserPassword(uid, userData)
			.then(
				response => dispatch({
					type: UPDATE_USER_PASSWORD_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_USER_PASSWORD_FAILURE,
					payload: error
				})
			)
	}
}

function setUserLogin (userLogin) {
	return (dispatch) => {
		dispatch({ type: SET_USER_LOGIN, payload: userLogin })
	}
}

function setPermissions (permissions) {
	return (dispatch) => {
		dispatch({ type: SET_PERMISSIONS, payload: permissions })
	}
}

export {
	onSetAuthUser,
	setUserLogin,
	updateUserPassword,
	setPermissions
}
