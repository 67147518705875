import {
	FETCH_CHATS_REQUEST,
	FETCH_CHATS_SUCCESS,
	FETCH_CHATS_FAILURE,
	FETCH_MORE_CHATS_REQUEST,
	FETCH_MORE_CHATS_SUCCESS,
	FETCH_MORE_CHATS_FAILURE,
	FETCH_MESSAGES_REQUEST,
	FETCH_MESSAGES_SUCCESS,
	FETCH_MESSAGES_FAILURE,
	UPDATE_CHAT_SUCCESS,
	ADD_CHAT_SUCCESS,
	ADD_MESSAGE_SUCCESS,
	SEND_MESSAGE_REQUEST,
	SEND_MESSAGE_SUCCESS,
	SEND_MESSAGE_FAILURE,
	UPDATE_CHAT_COUNT_SUCCESS,
	FETCH_MORE_CONVERSATIONS_REQUEST,
	FETCH_CONVERSATIONS_REQUEST,
	FETCH_MORE_CONVERSATIONS_SUCCESS,
	FETCH_CONVERSATIONS_SUCCESS,
	FETCH_CONVERSATIONS_FAILURE,
	FETCH_MORE_CONVERSATIONS_FAILURE,
	FETCH_MORE_CONVERSATION_MESSAGES_REQUEST,
	FETCH_CONVERSATION_MESSAGES_REQUEST,
	FETCH_MORE_CONVERSATION_MESSAGES_SUCCESS,
	FETCH_CONVERSATION_MESSAGES_SUCCESS,
	FETCH_CONVERSATION_MESSAGES_FAILURE,
	FETCH_MORE_CONVERSATION_MESSAGES_FAILURE,
	CREATE_CONVERSATION_REQUEST,
	CREATE_CONVERSATION_SUCCESS,
	CREATE_CONVERSATION_FAILURE,
	CREATE_CONVERSATION_MESSAGE_REQUEST,
	CREATE_CONVERSATION_MESSAGE_SUCCESS,
	CREATE_CONVERSATION_MESSAGE_FAILURE,
	ADD_CONVERSATION_MESSAGE_SUCCESS,
	UPDATE_CONVERSATION_SUCCESS,
	ADD_CONVERSATION_SUCCESS,
	UPDATE_CONVERSATION_COUNT_SUCCESS
} from './action-types'
import API from '../../utils/api'

function updateChatCount (count) {
	return (dispatch) => {
		dispatch({ type: UPDATE_CHAT_COUNT_SUCCESS, payload: count })
	}
}

function updateChat (chat) {
	return (dispatch) => {
		dispatch({ type: UPDATE_CHAT_SUCCESS, payload: chat })
	}
}

function addChat (chat) {
	return (dispatch) => {
		dispatch({ type: ADD_CHAT_SUCCESS, payload: chat })
	}
}

function addMessage (message) {
	return (dispatch) => {
		dispatch({ type: ADD_MESSAGE_SUCCESS, payload: message })
	}
}

function sendMessage (message) {
	return (dispatch) => {
		dispatch({ type: SEND_MESSAGE_REQUEST, payload: message })
		return API.sendMessage(message)
			.then(
				response => dispatch({
					type: SEND_MESSAGE_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: SEND_MESSAGE_FAILURE,
					payload: error
				})
			)
	}
}

function fetchMessages (chatId) {
	return (dispatch) => {
		dispatch({ type: FETCH_MESSAGES_REQUEST })
		return API.getMessages(chatId)
			.then(
				response => dispatch({
					type: FETCH_MESSAGES_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_MESSAGES_FAILURE,
					payload: error
				})
			)
	}
}

function fetchChats (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId? FETCH_MORE_CHATS_REQUEST : FETCH_CHATS_REQUEST })
		return API.getChats(lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId? FETCH_MORE_CHATS_SUCCESS : FETCH_CHATS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId? FETCH_MORE_CHATS_FAILURE : FETCH_CHATS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchConversations (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_CONVERSATIONS_REQUEST : FETCH_CONVERSATIONS_REQUEST })
		return API.getConversations(lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONVERSATIONS_SUCCESS : FETCH_CONVERSATIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONVERSATIONS_FAILURE : FETCH_CONVERSATIONS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchConversationMessages (id, lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_CONVERSATION_MESSAGES_REQUEST : FETCH_CONVERSATION_MESSAGES_REQUEST })
		return API.getConversationMessages(id, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONVERSATION_MESSAGES_SUCCESS : FETCH_CONVERSATION_MESSAGES_SUCCESS,
					payload: {
						conversationId: id,
						...response
					}
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CONVERSATION_MESSAGES_FAILURE : FETCH_CONVERSATION_MESSAGES_FAILURE,
					payload: error
				})
			)
	}
}

function createConversation (conversationData) {
	return (dispatch) => {
		dispatch({ type: CREATE_CONVERSATION_REQUEST, payload: conversationData })
		return API.createConversation(conversationData)
			.then(
				response => dispatch({
					type: CREATE_CONVERSATION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: CREATE_CONVERSATION_FAILURE,
					payload: error
				})
			)
	}
}

function createConversationMessage (id, messageData) {
	return (dispatch) => {
		dispatch({ type: CREATE_CONVERSATION_MESSAGE_REQUEST, payload: { ...messageData, conversationId: id } })
		return API.createConversationMessage(id, messageData)
			.then(
				response => dispatch({
					type: CREATE_CONVERSATION_MESSAGE_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: CREATE_CONVERSATION_MESSAGE_FAILURE,
					payload: error
				})
			)
	}
}

function addConversationMessage (id, message) {
	return (dispatch) => {
		dispatch({ type: ADD_CONVERSATION_MESSAGE_SUCCESS, payload: { ...message, conversationId: id } })
	}
}

function updateConversation (conversation) {
	return (dispatch) => {
		dispatch({ type: UPDATE_CONVERSATION_SUCCESS, payload: conversation })
	}
}

function addConversation (conversation) {
	return (dispatch) => {
		dispatch({ type: ADD_CONVERSATION_SUCCESS, payload: conversation })
	}
}

function updateConversationCount (count) {
	return (dispatch) => {
		dispatch({ type: UPDATE_CONVERSATION_COUNT_SUCCESS, payload: count })
	}
}

export {
	fetchChats,
	fetchMessages,
	updateChat,
	addChat,
	addMessage,
	sendMessage,
	updateChatCount,
	fetchConversations,
	fetchConversationMessages,
	createConversation,
	createConversationMessage,
	addConversationMessage,
	updateConversation,
	addConversation,
	updateConversationCount
}
