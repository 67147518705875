export const BOOK_SHIPMENT_REQUEST = 'BOOK_SHIPMENT_REQUEST'
export const BOOK_SHIPMENT_SUCCESS = 'BOOK_SHIPMENT_SUCCESS'
export const BOOK_SHIPMENT_FAILURE = 'BOOK_SHIPMENT_FAILURE'
export const FETCH_ACTIVE_SHIPMENTS_REQUEST = 'FETCH_ACTIVE_SHIPMENTS_REQUEST'
export const FETCH_ACTIVE_SHIPMENTS_SUCCESS = 'FETCH_ACTIVE_SHIPMENTS_SUCCESS'
export const FETCH_ACTIVE_SHIPMENTS_FAILURE = 'FETCH_ACTIVE_SHIPMENTS_FAILURE'
export const FETCH_SCHEDULED_SHIPMENTS_REQUEST = 'FETCH_SCHEDULED_SHIPMENTS_REQUEST'
export const FETCH_SCHEDULED_SHIPMENTS_SUCCESS = 'FETCH_SCHEDULED_SHIPMENTS_SUCCESS'
export const FETCH_SCHEDULED_SHIPMENTS_FAILURE = 'FETCH_SCHEDULED_SHIPMENTS_FAILURE'
export const FETCH_REQUESTED_SHIPMENTS_REQUEST = 'FETCH_REQUESTED_SHIPMENTS_REQUEST'
export const FETCH_REQUESTED_SHIPMENTS_SUCCESS = 'FETCH_REQUESTED_SHIPMENTS_SUCCESS'
export const FETCH_REQUESTED_SHIPMENTS_FAILURE = 'FETCH_REQUESTED_SHIPMENTS_FAILURE'
export const FETCH_COMPLETED_SHIPMENTS_REQUEST = 'FETCH_COMPLETED_SHIPMENTS_REQUEST'
export const FETCH_COMPLETED_SHIPMENTS_SUCCESS = 'FETCH_COMPLETED_SHIPMENTS_SUCCESS'
export const FETCH_COMPLETED_SHIPMENTS_FAILURE = 'FETCH_COMPLETED_SHIPMENTS_FAILURE'
export const FETCH_CANCELLED_SHIPMENTS_REQUEST = 'FETCH_CANCELLED_SHIPMENTS_REQUEST'
export const FETCH_CANCELLED_SHIPMENTS_SUCCESS = 'FETCH_CANCELLED_SHIPMENTS_SUCCESS'
export const FETCH_CANCELLED_SHIPMENTS_FAILURE = 'FETCH_CANCELLED_SHIPMENTS_FAILURE'
export const FETCH_EXPIRED_SHIPMENTS_REQUEST = 'FETCH_EXPIRED_SHIPMENTS_REQUEST'
export const FETCH_EXPIRED_SHIPMENTS_SUCCESS = 'FETCH_EXPIRED_SHIPMENTS_SUCCESS'
export const FETCH_EXPIRED_SHIPMENTS_FAILURE = 'FETCH_EXPIRED_SHIPMENTS_FAILURE'
export const FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST = 'FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST'
export const FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS = 'FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS'
export const FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE = 'FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE'
export const FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST = 'FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST'
export const FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS = 'FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE = 'FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE'
export const FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST = 'FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST'
export const FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS = 'FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE = 'FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE'
export const FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST = 'FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST'
export const FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS = 'FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE = 'FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE'
export const FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST = 'FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST'
export const FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS = 'FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE = 'FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE'
export const FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST = 'FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST'
export const FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS = 'FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS'
export const FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE = 'FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE'
export const FETCH_SHIPMENT_REQUEST = 'FETCH_SHIPMENT_REQUEST'
export const FETCH_SHIPMENT_SUCCESS = 'FETCH_SHIPMENT_SUCCESS'
export const FETCH_SHIPMENT_FAILURE = 'FETCH_SHIPMENT_FAILURE'
export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT'
export const FETCH_BOOKING_REQUEST = 'FETCH_BOOKING_REQUEST'
export const FETCH_BOOKING_SUCCESS = 'FETCH_BOOKING_SUCCESS'
export const FETCH_BOOKING_FAILURE = 'FETCH_BOOKING_FAILURE'
export const FETCH_CARRIER_SHIPMENTS_REQUEST = 'FETCH_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_CARRIER_SHIPMENTS_FAILURE = 'FETCH_CARRIER_SHIPMENTS_FAILURE'
export const FETCH_MORE_CARRIER_SHIPMENTS_REQUEST = 'FETCH_MORE_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_MORE_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_MORE_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_MORE_CARRIER_SHIPMENTS_FAILURE = 'FETCH_MORE_CARRIER_SHIPMENTS_FAILURE'
export const FETCH_SCHEDULED_CARRIER_SHIPMENTS_REQUEST = 'FETCH_SCHEDULED_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_SCHEDULED_CARRIER_SHIPMENTS_FAILURE = 'FETCH_SCHEDULED_CARRIER_SHIPMENTS_FAILURE'
export const FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_REQUEST = 'FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_FAILURE = 'FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_FAILURE'
export const FETCH_ASSIGNED_CARRIER_SHIPMENTS_REQUEST = 'FETCH_ASSIGNED_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_ASSIGNED_CARRIER_SHIPMENTS_FAILURE = 'FETCH_ASSIGNED_CARRIER_SHIPMENTS_FAILURE'
export const FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_REQUEST = 'FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_FAILURE = 'FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_FAILURE'
export const FETCH_COMPLETED_CARRIER_SHIPMENTS_REQUEST = 'FETCH_COMPLETED_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_COMPLETED_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_COMPLETED_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_COMPLETED_CARRIER_SHIPMENTS_FAILURE = 'FETCH_COMPLETED_CARRIER_SHIPMENTS_FAILURE'
export const FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_REQUEST = 'FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_REQUEST'
export const FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_SUCCESS = 'FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_SUCCESS'
export const FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_FAILURE = 'FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_FAILURE'
export const ACCEPT_SHIPMENT_REQUEST = 'ACCEPT_SHIPMENT_REQUEST'
export const ACCEPT_SHIPMENT_SUCCESS = 'ACCEPT_SHIPMENT_SUCCESS'
export const ACCEPT_SHIPMENT_FAILURE = 'ACCEPT_SHIPMENT_FAILURE'
export const ASSIGN_DRIVER_TO_SHIPMENT_REQUEST = 'ASSIGN_DRIVER_TO_SHIPMENT_REQUEST'
export const ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS = 'ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS'
export const ASSIGN_DRIVER_TO_SHIPMENT_FAILURE = 'ASSIGN_DRIVER_TO_SHIPMENT_FAILURE'
export const ACCEPT_SHIPMENT_FOR_DRIVER_REQUEST = 'ACCEPT_SHIPMENT_FOR_DRIVER_REQUEST'
export const ACCEPT_SHIPMENT_FOR_DRIVER_SUCCESS = 'ACCEPT_SHIPMENT_FOR_DRIVER_SUCCESS'
export const ACCEPT_SHIPMENT_FOR_DRIVER_FAILURE = 'ACCEPT_SHIPMENT_FOR_DRIVER_FAILURE'
export const DECLINE_SHIPMENT_FOR_DRIVER_REQUEST = 'DECLINE_SHIPMENT_FOR_DRIVER_REQUEST'
export const DECLINE_SHIPMENT_FOR_DRIVER_SUCCESS = 'DECLINE_SHIPMENT_FOR_DRIVER_SUCCESS'
export const DECLINE_SHIPMENT_FOR_DRIVER_FAILURE = 'DECLINE_SHIPMENT_FOR_DRIVER_FAILURE'
export const CANCEL_SHIPMENT_REQUEST = 'CANCEL_SHIPMENT_REQUEST'
export const CANCEL_SHIPMENT_SUCCESS = 'CANCEL_SHIPMENT_SUCCESS'
export const CANCEL_SHIPMENT_FAILURE = 'CANCEL_SHIPMENT_FAILURE'
export const RENEW_SHIPMENT_REQUEST = 'RENEW_SHIPMENT_REQUEST'
export const RENEW_SHIPMENT_SUCCESS = 'RENEW_SHIPMENT_SUCCESS'
export const RENEW_SHIPMENT_FAILURE = 'RENEW_SHIPMENT_FAILURE'
export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST'
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS'
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE'
export const UPDATE_SHIPMENT_DRIVER_STATUS_REQUEST = 'UPDATE_SHIPMENT_DRIVER_STATUS_REQUEST'
export const UPDATE_SHIPMENT_DRIVER_STATUS_SUCCESS = 'UPDATE_SHIPMENT_DRIVER_STATUS_SUCCESS'
export const UPDATE_SHIPMENT_DRIVER_STATUS_FAILURE = 'UPDATE_SHIPMENT_DRIVER_STATUS_FAILURE'
export const FETCH_TRANSACTION_REQUEST = 'FETCH_TRANSACTION_REQUEST'
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS'
export const FETCH_TRANSACTION_FAILURE = 'FETCH_TRANSACTION_FAILURE'
export const UPDATE_TRANSACTION_REQUEST = 'UPDATE_TRANSACTION_REQUEST'
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS'
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE'
export const FETCH_LOADBOARD_REQUEST = 'FETCH_LOADBOARD_REQUEST'
export const FETCH_LOADBOARD_SUCCESS = 'FETCH_LOADBOARD_SUCCESS'
export const FETCH_LOADBOARD_FAILURE = 'FETCH_LOADBOARD_FAILURE'
export const SEARCH_SHIPMENTS_REQUEST = 'SEARCH_SHIPMENTS_REQUEST'
export const SEARCH_SHIPMENTS_SUCCESS = 'SEARCH_SHIPMENTS_SUCCESS'
export const SEARCH_SHIPMENTS_FAILURE = 'SEARCH_SHIPMENTS_FAILURE'
export const FETCH_SHIPPER_BOOKINGS_REQUEST = 'FETCH_SHIPPER_BOOKINGS_REQUEST'
export const FETCH_SHIPPER_BOOKINGS_SUCCESS = 'FETCH_SHIPPER_BOOKINGS_SUCCESS'
export const FETCH_SHIPPER_BOOKINGS_FAILURE = 'FETCH_SHIPPER_BOOKINGS_FAILURE'
export const FETCH_MORE_SHIPPER_BOOKINGS_REQUEST = 'FETCH_MORE_SHIPPER_BOOKINGS_REQUEST'
export const FETCH_MORE_SHIPPER_BOOKINGS_SUCCESS = 'FETCH_MORE_SHIPPER_BOOKINGS_SUCCESS'
export const FETCH_MORE_SHIPPER_BOOKINGS_FAILURE = 'FETCH_MORE_SHIPPER_BOOKINGS_FAILURE'
export const FETCH_ATTACHMENTS_REQUEST = 'FETCH_ATTACHMENTS_REQUEST'
export const FETCH_ATTACHMENTS_SUCCESS = 'FETCH_ATTACHMENTS_SUCCESS'
export const FETCH_ATTACHMENTS_FAILURE = 'FETCH_ATTACHMENTS_FAILURE'
export const MODIFY_REQUESTED_SHIPMENTS_REQUEST = 'MODIFY_REQUESTED_SHIPMENTS_REQUEST'
export const MODIFY_REQUESTED_SHIPMENTS_SUCCESS = 'MODIFY_REQUESTED_SHIPMENTS_SUCCESS'
export const MODIFY_REQUESTED_SHIPMENTS_FAILURE = 'MODIFY_REQUESTED_SHIPMENTS_FAILURE'
export const SEARCH_MORE_SHIPMENTS_REQUEST = 'SEARCH_MORE_SHIPMENTS_REQUEST'
export const SEARCH_MORE_SHIPMENTS_SUCCESS = 'SEARCH_MORE_SHIPMENTS_SUCCESS'
export const SEARCH_MORE_SHIPMENTS_FAILURE = 'SEARCH_MORE_SHIPMENTS_FAILURE'
export const UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST = 'UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST'
export const UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS = 'UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS'
export const UPDATE_SHIPMENT_FARE_AMOUNT_FAILURE = 'UPDATE_SHIPMENT_FARE_AMOUNT_FAILURE'
export const UPDATE_BOOKING_REQUEST = 'UPDATE_BOOKING_REQUEST'
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS'
export const UPDATE_BOOKING_FAILURE = 'UPDATE_BOOKING_FAILURE'
export const UPDATE_BOOKING_FARE_AMOUNT_REQUEST = 'UPDATE_BOOKING_FARE_AMOUNT_REQUEST'
export const UPDATE_BOOKING_FARE_AMOUNT_SUCCESS = 'UPDATE_BOOKING_FARE_AMOUNT_SUCCESS'
export const UPDATE_BOOKING_FARE_AMOUNT_FAILURE = 'UPDATE_BOOKING_FARE_AMOUNT_FAILURE'
export const UPDATE_SHIPMENT_DETAIL_REQUEST = 'UPDATE_SHIPMENT_DETAIL_REQUEST'
export const UPDATE_SHIPMENT_DETAIL_SUCCESS = 'UPDATE_SHIPMENT_DETAIL_SUCCESS'
export const UPDATE_SHIPMENT_DETAIL_FAILURE = 'UPDATE_SHIPMENT_DETAIL_FAILURE'
export const SEARCH_TRANSACTIONS_REQUEST = 'SEARCH_TRANSACTIONS_REQUEST'
export const SEARCH_TRANSACTIONS_SUCCESS = 'SEARCH_TRANSACTIONS_SUCCESS'
export const SEARCH_TRANSACTIONS_FAILURE = 'SEARCH_TRANSACTIONS_FAILURE'
export const UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST = 'UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST'
export const UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS = 'UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS'
export const UPDATE_SHIPMENT_VENDOR_AMOUNT_FAILURE = 'UPDATE_SHIPMENT_VENDOR_AMOUNT_FAILURE'
export const UPDATE_SHIPMENT_PAYMENT_REQUEST = 'UPDATE_SHIPMENT_PAYMENT_REQUEST'
export const UPDATE_SHIPMENT_PAYMENT_SUCCESS = 'UPDATE_SHIPMENT_PAYMENT_SUCCESS'
export const UPDATE_SHIPMENT_PAYMENT_FAILURE = 'UPDATE_SHIPMENT_PAYMENT_FAILURE'
export const CONFIRM_VENDOR_PRICING_REQUEST = 'CONFIRM_VENDOR_PRICING_REQUEST'
export const CONFIRM_VENDOR_PRICING_SUCCESS = 'CONFIRM_VENDOR_PRICING_SUCCESS'
export const CONFIRM_VENDOR_PRICING_FAILURE = 'CONFIRM_VENDOR_PRICING_FAILURE'
export const UPDATE_BOOKING = 'UPDATE_BOOKING'
export const SEARCH_BOOKINGS_REQUEST = 'SEARCH_BOOKINGS_REQUEST'
export const SEARCH_BOOKINGS_SUCCESS = 'SEARCH_BOOKINGS_SUCCESS'
export const SEARCH_BOOKINGS_FAILURE = 'SEARCH_BOOKINGS_FAILURE'
export const SEARCH_MORE_BOOKINGS_REQUEST = 'SEARCH_MORE_BOOKINGS_REQUEST'
export const SEARCH_MORE_BOOKINGS_SUCCESS = 'SEARCH_MORE_BOOKINGS_SUCCESS'
export const SEARCH_MORE_BOOKINGS_FAILURE = 'SEARCH_MORE_BOOKINGS_FAILURE'
