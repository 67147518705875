import {
	FETCH_DRIVERS_REQUEST,
	FETCH_DRIVERS_SUCCESS,
	FETCH_DRIVERS_FAILURE,
	ADD_DRIVER_REQUEST,
	ADD_DRIVER_SUCCESS,
	ADD_DRIVER_FAILURE,
	UPDATE_DRIVER_REQUEST,
	UPDATE_DRIVER_SUCCESS,
	UPDATE_DRIVER_FAILURE,
	FETCH_NEW_DRIVERS_REQUEST,
	FETCH_NEW_DRIVERS_SUCCESS,
	FETCH_NEW_DRIVERS_FAILURE,
	FETCH_APPROVED_DRIVERS_REQUEST,
	FETCH_APPROVED_DRIVERS_SUCCESS,
	FETCH_APPROVED_DRIVERS_FAILURE,
	FETCH_DECLINED_DRIVERS_REQUEST,
	FETCH_DECLINED_DRIVERS_SUCCESS,
	FETCH_DECLINED_DRIVERS_FAILURE,
	FETCH_FREE_DRIVERS_REQUEST,
	FETCH_FREE_DRIVERS_SUCCESS,
	FETCH_BUSY_DRIVERS_REQUEST,
	FETCH_BUSY_DRIVERS_SUCCESS,
	FETCH_BUSY_DRIVERS_FAILURE,
	FETCH_INACTIVE_DRIVERS_REQUEST,
	FETCH_INACTIVE_DRIVERS_SUCCESS,
	FETCH_INACTIVE_DRIVERS_FAILURE,
	FETCH_DRIVER_TASKS_REQUEST,
	FETCH_DRIVER_TASKS_SUCCESS,
	FETCH_DRIVER_TASKS_FAILURE,
	UPDATE_DRIVER_LOCATION_REQUEST,
	UPDATE_DRIVER_LOCATION_SUCCESS,
	UPDATE_DRIVER_LOCATION_FAILURE,
	UPDATE_DRIVER_STATUS_REQUEST,
	UPDATE_DRIVER_STATUS_SUCCESS,
	UPDATE_DRIVER_STATUS_FAILURE,
	UPDATE_DRIVER_LOCATION,
	FETCH_DRIVER_REQUEST,
	FETCH_DRIVER_SUCCESS,
	FETCH_DRIVER_FAILURE
} from './action-types'
import API from '../../utils/api'
import {
	PENDING_USER_STATUS,
	APPROVED_USER_STATUS,
	FREE_DRIVER_STATUS,
	BUSY_DRIVER_STATUS,
	INACTIVE_DRIVER_STATUS,
	DECLINED_USER_STATUS
} from '../../constants/strings'

function getAllDrivers (status, carrierId) {
	return (dispatch) => {
		dispatch({ type: FETCH_DRIVERS_REQUEST })
		return API.getDriversByStatus(status, carrierId)
			.then(
				response => dispatch({
					type: FETCH_DRIVERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_DRIVERS_FAILURE,
					payload: error
				})
			)
	}
}

function getNewDrivers () {
	return (dispatch) => {
		dispatch({ type: FETCH_NEW_DRIVERS_REQUEST })
		return API.getDriversByStatus(PENDING_USER_STATUS)
			.then(
				response => dispatch({
					type: FETCH_NEW_DRIVERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_NEW_DRIVERS_FAILURE,
					payload: error
				})
			)
	}
}

function getApprovedDrivers (carrierId) {
	return (dispatch) => {
		dispatch({ type: FETCH_APPROVED_DRIVERS_REQUEST })
		return API.getDriversByStatus(APPROVED_USER_STATUS, carrierId)
			.then(
				response => dispatch({
					type: FETCH_APPROVED_DRIVERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_APPROVED_DRIVERS_FAILURE,
					payload: error
				})
			)
	}
}

function getDeclinedDrivers (carrierId) {
	return (dispatch) => {
		dispatch({ type: FETCH_DECLINED_DRIVERS_REQUEST })
		return API.getDriversByStatus(DECLINED_USER_STATUS, carrierId)
			.then(
				response => dispatch({
					type: FETCH_DECLINED_DRIVERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_DECLINED_DRIVERS_FAILURE,
					payload: error
				})
			)
	}
}

function getFreeDrivers (carrierId) {
	return (dispatch) => {
		dispatch({ type: FETCH_FREE_DRIVERS_REQUEST })
		return API.getDriversByDriverStatus(FREE_DRIVER_STATUS, carrierId)
			.then(
				response => dispatch({
					type: FETCH_FREE_DRIVERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_APPROVED_DRIVERS_FAILURE,
					payload: error
				})
			)
	}
}

function getBusyDrivers () {
	return (dispatch) => {
		dispatch({ type: FETCH_BUSY_DRIVERS_REQUEST })
		return API.getDriversByDriverStatus(BUSY_DRIVER_STATUS)
			.then(
				response => dispatch({
					type: FETCH_BUSY_DRIVERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_BUSY_DRIVERS_FAILURE,
					payload: error
				})
			)
	}
}

function getInactiveDrivers () {
	return (dispatch) => {
		dispatch({ type: FETCH_INACTIVE_DRIVERS_REQUEST })
		return API.getDriversByDriverStatus(INACTIVE_DRIVER_STATUS)
			.then(
				response => dispatch({
					type: FETCH_INACTIVE_DRIVERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_INACTIVE_DRIVERS_FAILURE,
					payload: error
				})
			)
	}
}

function addDriver (driverData, carrierId) {
	return (dispatch) => {
		dispatch({ type: ADD_DRIVER_REQUEST })
		return API.addDriver(driverData, carrierId)
			.then(
				response => dispatch({
					type: ADD_DRIVER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_DRIVER_FAILURE,
					payload: error
				})
			)
	}
}

function updateDriver (id, driverData, carrierId) {
	return (dispatch) => {
		dispatch({ type: UPDATE_DRIVER_REQUEST })
		return API.updateDriver(id, driverData, carrierId)
			.then(
				response => dispatch({
					type: UPDATE_DRIVER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_DRIVER_FAILURE,
					payload: error
				})
			)
	}
}

function fetchDriver (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_DRIVER_REQUEST })
		return API.getDriver(id)
			.then(
				response => dispatch({
					type: FETCH_DRIVER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_DRIVER_FAILURE,
					payload: error
				})
			)
	}
}

function updateDriverLocation (id, driverData, carrierId) {
	return (dispatch) => {
		dispatch({ type: UPDATE_DRIVER_LOCATION_REQUEST })
		return API.updateDriverLocation(id, driverData, carrierId)
			.then(
				response => dispatch({
					type: UPDATE_DRIVER_LOCATION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_DRIVER_LOCATION_FAILURE,
					payload: error
				})
			)
	}
}

function updateDriverStatus (id, driverData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_DRIVER_STATUS_REQUEST })
		return API.updateDriverStatus(id, driverData)
			.then(
				response => dispatch({
					type: UPDATE_DRIVER_STATUS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_DRIVER_STATUS_FAILURE,
					payload: error
				})
			)
	}
}

function getDriverTasks (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_DRIVER_TASKS_REQUEST })
		return API.getDriverTasks(id)
			.then(
				response => dispatch({
					type: FETCH_DRIVER_TASKS_SUCCESS,
					payload: {
						...response,
						driverId: id
					}
				}),
				error => dispatch({
					type: FETCH_DRIVER_TASKS_FAILURE,
					payload: error
				})
			)
	}
}

function updateUserDriverLocation (location) {
	return (dispatch) => {
		dispatch({ type: UPDATE_DRIVER_LOCATION, payload: location })
	}
}

export {
	getAllDrivers,
	getNewDrivers,
	getApprovedDrivers,
	getDeclinedDrivers,
	getFreeDrivers,
	getBusyDrivers,
	getInactiveDrivers,
	addDriver,
	updateDriver,
	fetchDriver,
	getDriverTasks,
	updateDriverLocation,
	updateDriverStatus,
	updateUserDriverLocation
}
