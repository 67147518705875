import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Configs from './configs'

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: Configs.language.resources,
		fallbackLng: Configs.language.codes.DEFAULT,
		whitelist: Configs.language.list,
		nonExplicitWhitelist: true,
		keySeparator: false,
		interpolation: {
			escapeValue: false
		}
	})

/**
 * The following will change the language to DEFAULT if any language other than the whitelisted
 * ones (or language code variations such as, en-US,en-GB, etc) is set as default browser language.
 * This will allow the UI to be able to always show selected language correctly.
 */
const selectedLanguageIndex = Configs.language.list.findIndex(item => item === i18next.language)
if (selectedLanguageIndex < 0) {
	i18next.changeLanguage(Configs.language.codes.DEFAULT)
}

export default i18next
