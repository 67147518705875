import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import createRootReducer from './reducers'

export const history = createBrowserHistory()
const prevHistoryPush = history.push
let lastLocation = history.location

history.listen(location => {
	lastLocation = location
})
history.push = (pathname, state = {}) => {
	if (
		lastLocation === null ||
		pathname !==
		lastLocation.pathname + lastLocation.search + lastLocation.hash ||
		JSON.stringify(state) !== JSON.stringify(lastLocation.state)
	) {
		prevHistoryPush(pathname, state)
	}
}
export default function configureStore (preloadedState) {
	/**
	 * 	Redux DevTools browser extension helps debug/visualize redux state
	 */
	const composeEnhancers = process.env.NODE_ENV === 'development' ?
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose
	const store = createStore(
		createRootReducer(history),
		preloadedState,
		composeEnhancers(
			applyMiddleware(
				thunk,
				routerMiddleware(history)
			)
		)
	)
	return store
}
