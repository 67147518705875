const Routes = {
	HOME_PAGE: () => "/home",
	NEW_HOME_PAGE: () => "https://web.loopfreight.io/",
	SHIPPER_REG_PAGE: () => "https://web.loopfreight.io/signup/shipper",
	VENDOR_REG_PAGE: () => "https://web.loopfreight.io/signup/vendor",
	LOGIN_PAGE: (phoneNumber) =>
		`/login${phoneNumber ? `?phoneNumber=${phoneNumber}` : ""}`,
	SIGN_UP_PAGE: (phoneNumber, type) =>
		`/sign-up${type ? `?type=${type}` : ""}${
			phoneNumber ? `&phoneNumber=${phoneNumber}` : ""
		}`,
	DASHBOARD_PAGE: () => "/dashboard",
	CREATE_BOOKING_PAGE: (bookingId) =>
		`/create-booking${bookingId ? `/${bookingId}` : ""}`,
	OFFERS_PAGE: () => "/offers",
	SHIPMENT_DETAIL_PAGE: (id) => `/shipment/${id}`,
	BOOKING_DETAIL_PAGE: (id) => `/booking/${id}`,
	CONTACTS_PAGE: () => "/contacts",
	MANAGERS_PAGE: () => "/managers",
	DRIVERS_PAGE: () => "/drivers",
	TRUCKS_PAGE: () => "/trucks",
	ACCOUNT_PAGE: () => "/account",
	BILLING_PAGE: () => "/billing",
	ADMIN_HOME_PAGE: () => "/admin",
	ADMIN_LOGIN_PAGE: () => "/admin/login",
	ADMIN_USERS_PAGE: (userType) =>
		`/admin/users${userType ? `/${userType}` : ""}`,
	ADMIN_SHIPMENTS_PAGE: (status) =>
		`/admin/shipments${status ? `/${status}` : ""}`,
	ADMIN_DASHBOARD_PAGE: (type) => `/admin/dashboard${type ? `/${type}` : ""}`,
	ADMIN_RATES_PAGE: () => "/admin/rates",
	ADMIN_CONTRACTS_PAGE: (contractId) =>
		`/admin/contracts${contractId ? `?contractId=${contractId}` : ""}`,
	ADMIN_QUOTATIONS_PAGE: (contractId) =>
		`/admin/quotations${contractId ? `?contractId=${contractId}` : ""}`,
	ADMIN_QUOTATIONS_MAP_PAGE: () => "/admin/quotations-map",
	ADMIN_ACCOUNTING_PAGE: (tab, billId) =>
		`/admin/accounting${tab ? `/${tab}` : ""}${
			billId ? `?billId=${billId}` : ""
		}`,
	ADMIN_CREATE_BILL_PAGE: () => "/admin/create-bill",
	ADMIN_TRUCKS_PAGE: () => "/admin/trucks",
	ADMIN_LOCATIONS_PAGE: () => "/admin/locations",
	TRANSACTION_PAGE: (id) => `/transaction/${id}`,
	BILL_PAGE: (id) => `/bill/${id}`,
	ADMIN_BOOKINGS_PAGE: (status) =>
		`/admin/bookings${status ? `/${status}` : ""}`,
	TERMS_AND_CONDITIONS_PAGE: () => "/terms-and-conditions",
	ADMIN_USER_PAGE: (id) => `/admin/user/${id}`,
	ADMIN_MAP_PAGE: (truckId) =>
		`/admin-map${truckId ? `?truckId=${truckId}` : ""}`,
	STATS_PAGE: () => "/stats",
	NOTIFICATIONS_PAGE: () => "/notifications",
	SHIPPER_PAGE: () => "/shipper",
	SHIPPER_DASHBOARD_PAGE: () => "/shipper/dashboard",
	SHIPPER_BOOKINGS_PAGE: () => "/shipper/bookings",
	SHIPPER_SHIPMENTS_PAGE: (status) =>
		`/shipper/shipments${status ? `/${status}` : ""}`,
	SHIPPER_ACCOUNT_PAGE: () => "/shipper/account",
	SHIPPER_BOOKINGS_CALENDAR_VIEW_PAGE: () => "/shipper/calendar-view",
	SHIPPER_BILLING_PAGE: (tab, billId) =>
		`/shipper/billing${tab ? `/${tab}` : ""}${
			billId ? `?billId=${billId}` : ""
		}`,
	CARRIER_PAGE: () => "/carrier",
	CARRIER_MAP_PAGE: () => "/carrier/map",
	CARRIER_BILLING_PAGE: () => "/carrier/billing",
	CARRIER_ACCOUNT_PAGE: () => "/carrier/account",
	CARRIER_LIST_PAGE: () => "/carrier/list",
	CARRIER_DRIVERS_PAGE: () => "/carrier/drivers",
	CARRIER_TRUCKS_PAGE: () => "/carrier/trucks",
	SHIPPER_CONTRACTS_PAGE: (contractId) =>
		`/shipper/contracts${contractId ? `?contractId=${contractId}` : ""}`,
	SHIPPER_QUOTATIONS_PAGE: (contractId) =>
		`/shipper/quotations${contractId ? `?contractId=${contractId}` : ""}`,
	ADMIN_RATES_GRAPH_PAGE: () => "/admin/rates-graph",
	LOCATION_PAGE: (id) => `/location/${id ? id : ""}`,
	CONVERSATIONS_PAGE: (id) => `/conversations${id ? `/${id}` : ""}`,
	ADMIN_REPORTS_PAGE: (id) => `/admin/reports${id ? `/${id}` : ""}`,
	SHIPPER_REPORTS_PAGE: (id) => `/shipper/reports${id ? `/${id}` : ""}`,
	COMPANY_PAGE: (id) => `/company${id ? `/${id}` : ""}`,
	ADMIN_COMPANIES_PAGE: () => "/admin/companies",
	PROFILE_PAGE: (id) => `/profile${id ? `/${id}` : ""}`,
	ACCOUNT_MANAGEMENT_PAGE: () => "/account-management",
	CREATE_QUOTATION_PAGE: (id) => `/create-quotation${id ? `/${id}` : ""}`,
	CONTRACT_DETAIL_PAGE: (id) => `/contract/${id}`,
};

export { Routes };
