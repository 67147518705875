export const CREATE_ADMIN = '1'
export const CREATE_DISPATCHER = '2'
export const CREATE_SHIPPER = '3'
export const CREATE_CARRIER = '4'
export const ENABLE_SHIPPER = '5'
export const ENABLE_CARRIER = '6'
export const ENABLE_ADMIN = '7'
export const EDIT_SHIPPER = '8'
export const EDIT_CARRIER = '9'
export const EDIT_ADMIN = '10'
export const CREATE_BOOKING = '11'
export const EDIT_BOOKING = '12'
export const ACTIVATE_SHIPMENT = '13'
export const UPDATE_SHIPMENT_STATUS = '14'
export const UPDATE_SHIPMENT_DRIVER = '15'
export const UPDATE_SHIPMENT_TRUCK = '16'
export const REQUEST_VENDOR_PRICING = '17'
export const ACCEPT_VENDOR_PRICING = '18'
export const CREATE_SHIPMENT_ATTACHMENT = '19'
export const UPDATE_SHIPMENT_FARE_AMOUNT = '20'
export const UPDATE_SHIPMENT_VENDOR_AMOUNT = '21'
export const UPDATE_SHIPMENT_VENDOR_ADVANCE_AMOUNT = '22'
export const UPDATE_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE = '23'
export const UPDATE_SHIPMENT_CHARGE = '24'
export const VIEW_SHIPMENT_FARE_AMOUNT = '25'
export const VIEW_SHIPMENT_VENDOR_AMOUNT = '26'
export const VIEW_SHIPMENT_VENDOR_ADVANCE_AMOUNT = '27'
export const VIEW_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE = '28'
export const VIEW_SHIPMENT_CHARGE = '29'
export const BOOKING_CREATED_NOTIFICATION = '30'
export const BOOKING_CANCELLED_NOTIFICATION = '31'
export const SHIPMENT_UPDATE_NOTIFICATION = '32'
export const DAILY_KPI_NOTIFICATION = '33'
export const VIEW_OVERVIEW_DASHBOARD = '34'
export const VIEW_ALL_SIMPLE_DASHBOARD = '35'
export const VIEW_MARGINS_DASHBOARD = '36'
export const SEND_MESSAGE = '37'
export const CREATE_MANAGER = '38'
export const EDIT_MANAGER = '39'
export const UPDATE_FARE_HISTORY = '40'
export const CREATE_QUOTATIONS = '41'
export const EDIT_QUOTATIONS = '42'
export const VIEW_CONTRACTS = '43'

export const DEFAULT_ADMIN_PERMISSIONS = Object.freeze({
	[CREATE_DISPATCHER]: true,
	[CREATE_SHIPPER]: true,
	[CREATE_CARRIER]: true,
	[ENABLE_SHIPPER]: true,
	[ENABLE_CARRIER]: true,
	[EDIT_SHIPPER]: true,
	[EDIT_CARRIER]: true,
	[CREATE_BOOKING]: true,
	[EDIT_BOOKING]: true,
	[ACTIVATE_SHIPMENT]: true,
	[UPDATE_SHIPMENT_STATUS]: true,
	[UPDATE_SHIPMENT_TRUCK]: true,
	[UPDATE_SHIPMENT_DRIVER]: true,
	[REQUEST_VENDOR_PRICING]: true,
	[ACCEPT_VENDOR_PRICING]: true,
	[CREATE_SHIPMENT_ATTACHMENT]: true,
	[UPDATE_SHIPMENT_FARE_AMOUNT]: true,
	[UPDATE_SHIPMENT_VENDOR_AMOUNT]: true,
	[UPDATE_SHIPMENT_VENDOR_ADVANCE_AMOUNT]: true,
	[UPDATE_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE]: true,
	[UPDATE_SHIPMENT_CHARGE]: true,
	[VIEW_SHIPMENT_FARE_AMOUNT]: true,
	[VIEW_SHIPMENT_VENDOR_AMOUNT]: true,
	[VIEW_SHIPMENT_VENDOR_ADVANCE_AMOUNT]: true,
	[VIEW_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE]: true,
	[VIEW_SHIPMENT_CHARGE]: true,
	[BOOKING_CREATED_NOTIFICATION]: true,
	[DAILY_KPI_NOTIFICATION]: true,
	[VIEW_OVERVIEW_DASHBOARD]: true,
	[VIEW_ALL_SIMPLE_DASHBOARD]: true,
	[VIEW_MARGINS_DASHBOARD]: true,
	[SEND_MESSAGE]: true
})

export const DEFAULT_DISPATCHER_PERMISSIONS = Object.freeze({
	[CREATE_DISPATCHER]: false,
	[CREATE_SHIPPER]: true,
	[CREATE_CARRIER]: true,
	[ENABLE_SHIPPER]: true,
	[ENABLE_CARRIER]: true,
	[EDIT_SHIPPER]: true,
	[EDIT_CARRIER]: true,
	[CREATE_BOOKING]: true,
	[EDIT_BOOKING]: true,
	[ACTIVATE_SHIPMENT]: true,
	[UPDATE_SHIPMENT_STATUS]: true,
	[UPDATE_SHIPMENT_TRUCK]: true,
	[UPDATE_SHIPMENT_DRIVER]: true,
	[REQUEST_VENDOR_PRICING]: true,
	[ACCEPT_VENDOR_PRICING]: true,
	[CREATE_SHIPMENT_ATTACHMENT]: true,
	[UPDATE_SHIPMENT_FARE_AMOUNT]: true,
	[UPDATE_SHIPMENT_VENDOR_AMOUNT]: true,
	[UPDATE_SHIPMENT_VENDOR_ADVANCE_AMOUNT]: true,
	[UPDATE_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE]: true,
	[UPDATE_SHIPMENT_CHARGE]: true,
	[VIEW_SHIPMENT_FARE_AMOUNT]: true,
	[VIEW_SHIPMENT_VENDOR_AMOUNT]: true,
	[VIEW_SHIPMENT_VENDOR_ADVANCE_AMOUNT]: true,
	[VIEW_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE]: true,
	[VIEW_SHIPMENT_CHARGE]: true,
	[BOOKING_CREATED_NOTIFICATION]: true,
	[DAILY_KPI_NOTIFICATION]: true,
	[VIEW_OVERVIEW_DASHBOARD]: true,
	[VIEW_ALL_SIMPLE_DASHBOARD]: true,
	[VIEW_MARGINS_DASHBOARD]: true,
	[SEND_MESSAGE]: true
})

export const DEFAULT_DISPATCHER_AGENT_PERMISSIONS = Object.freeze({
	[CREATE_DISPATCHER]: false,
	[CREATE_SHIPPER]: false,
	[CREATE_CARRIER]: false,
	[ENABLE_SHIPPER]: false,
	[ENABLE_CARRIER]: false,
	[EDIT_SHIPPER]: false,
	[EDIT_CARRIER]: false,
	[CREATE_BOOKING]: false,
	[EDIT_BOOKING]: false,
	[ACTIVATE_SHIPMENT]: true,
	[UPDATE_SHIPMENT_STATUS]: true,
	[UPDATE_SHIPMENT_TRUCK]: true,
	[UPDATE_SHIPMENT_DRIVER]: true,
	[REQUEST_VENDOR_PRICING]: false,
	[ACCEPT_VENDOR_PRICING]: false,
	[CREATE_SHIPMENT_ATTACHMENT]: true,
	[UPDATE_SHIPMENT_FARE_AMOUNT]: false,
	[UPDATE_SHIPMENT_VENDOR_AMOUNT]: false,
	[UPDATE_SHIPMENT_VENDOR_ADVANCE_AMOUNT]: false,
	[UPDATE_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE]: false,
	[UPDATE_SHIPMENT_CHARGE]: false,
	[VIEW_SHIPMENT_FARE_AMOUNT]: true,
	[VIEW_SHIPMENT_VENDOR_AMOUNT]: false,
	[VIEW_SHIPMENT_VENDOR_ADVANCE_AMOUNT]: false,
	[VIEW_SHIPMENT_VENDOR_ADVANCE_PERCENTAGE]: false,
	[VIEW_SHIPMENT_CHARGE]: false,
	[BOOKING_CREATED_NOTIFICATION]: false,
	[DAILY_KPI_NOTIFICATION]: false,
	[VIEW_OVERVIEW_DASHBOARD]: false,
	[VIEW_ALL_SIMPLE_DASHBOARD]: false,
	[VIEW_MARGINS_DASHBOARD]: false,
	[SEND_MESSAGE]: true
})

export const DEFAULT_SHIPPER_PERMISSIONS = Object.freeze({
	[CREATE_MANAGER]: true,
	[EDIT_MANAGER]: true,
	[CREATE_BOOKING]: true,
	[EDIT_BOOKING]: true,
	[UPDATE_FARE_HISTORY]: true,
	[CREATE_QUOTATIONS]: true,
	[VIEW_SHIPMENT_FARE_AMOUNT]: true,
	[EDIT_QUOTATIONS]: true,
	[VIEW_CONTRACTS]: true,
	[BOOKING_CREATED_NOTIFICATION]: true,
	[BOOKING_CANCELLED_NOTIFICATION]: true,
	[SHIPMENT_UPDATE_NOTIFICATION]: true,
	[SEND_MESSAGE]: true
})

export const DEFAULT_SHIPPER_AGENT_PERMISSIONS = Object.freeze({
	[CREATE_MANAGER]: false,
	[EDIT_MANAGER]: false,
	[CREATE_BOOKING]: false,
	[EDIT_BOOKING]: false,
	[UPDATE_FARE_HISTORY]: false,
	[CREATE_QUOTATIONS]: false,
	[VIEW_SHIPMENT_FARE_AMOUNT]: true,
	[EDIT_QUOTATIONS]: false,
	[VIEW_CONTRACTS]: false,
	[BOOKING_CREATED_NOTIFICATION]: false,
	[BOOKING_CANCELLED_NOTIFICATION]: false,
	[SHIPMENT_UPDATE_NOTIFICATION]: false,
	[SEND_MESSAGE]: true
})

export const DEFAULT_SHIPPER_MANAGER_PERMISSIONS = Object.freeze({
	[CREATE_MANAGER]: false,
	[EDIT_MANAGER]: false,
	[CREATE_BOOKING]: true,
	[EDIT_BOOKING]: true,
	[UPDATE_FARE_HISTORY]: true,
	[CREATE_QUOTATIONS]: true,
	[VIEW_SHIPMENT_FARE_AMOUNT]: true,
	[EDIT_QUOTATIONS]: true,
	[VIEW_CONTRACTS]: true,
	[BOOKING_CREATED_NOTIFICATION]: true,
	[BOOKING_CANCELLED_NOTIFICATION]: true,
	[SHIPMENT_UPDATE_NOTIFICATION]: true,
	[SEND_MESSAGE]: true
})
