import { combineReducers } from 'redux'
import {
	FETCH_DRIVERS_REQUEST,
	FETCH_DRIVERS_SUCCESS,
	FETCH_DRIVERS_FAILURE,
	ADD_DRIVER_SUCCESS,
	UPDATE_DRIVER_SUCCESS,
	DELETE_DRIVER_SUCCESS,
	FETCH_NEW_DRIVERS_REQUEST,
	FETCH_NEW_DRIVERS_SUCCESS,
	FETCH_NEW_DRIVERS_FAILURE,
	FETCH_FREE_DRIVERS_REQUEST,
	FETCH_FREE_DRIVERS_SUCCESS,
	FETCH_FREE_DRIVERS_FAILURE,
	FETCH_BUSY_DRIVERS_REQUEST,
	FETCH_BUSY_DRIVERS_FAILURE,
	FETCH_BUSY_DRIVERS_SUCCESS,
	FETCH_INACTIVE_DRIVERS_REQUEST,
	FETCH_INACTIVE_DRIVERS_FAILURE,
	FETCH_INACTIVE_DRIVERS_SUCCESS,
	FETCH_APPROVED_DRIVERS_REQUEST,
	FETCH_APPROVED_DRIVERS_FAILURE,
	FETCH_APPROVED_DRIVERS_SUCCESS,
	FETCH_DECLINED_DRIVERS_REQUEST,
	FETCH_DECLINED_DRIVERS_SUCCESS,
	FETCH_DECLINED_DRIVERS_FAILURE,
	FETCH_DRIVER_TASKS_REQUEST,
	FETCH_DRIVER_TASKS_FAILURE,
	FETCH_DRIVER_TASKS_SUCCESS,
	UPDATE_DRIVER_STATUS_SUCCESS,
	UPDATE_DRIVER_LOCATION
} from './action-types'
import {
	ASSIGN_TO_TRUCK_SUCCESS,
	UNASSIGN_FROM_TRUCK_SUCCESS
} from '../trucks/action-types'
import {
	ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS
} from '../shipments/action-types'
import {
	APPROVED_USER_STATUS,
	FREE_DRIVER_STATUS,
	BUSY_DRIVER_STATUS,
	INACTIVE_DRIVER_STATUS
} from '../../constants/strings'

const allDrivers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_DRIVERS_REQUEST:
		case FETCH_DRIVERS_FAILURE:
			return state
		case FETCH_DRIVERS_SUCCESS:
			return payload.drivers
		case ADD_DRIVER_SUCCESS:
			return [ payload, ...state ]
		case DELETE_DRIVER_SUCCESS:
			return state.filter(driver => driver.id !== payload.id)
		case UPDATE_DRIVER_SUCCESS:
			return state.map(driver => driver.id === payload.id
				? payload
				: driver
			)
		case ASSIGN_TO_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: payload.truck
				} : driver
			)
		case UNASSIGN_FROM_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: null
				} : driver
			)
		default:
			return state
	}
}

const newDrivers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_NEW_DRIVERS_REQUEST:
		case FETCH_NEW_DRIVERS_FAILURE:
			return state
		case FETCH_NEW_DRIVERS_SUCCESS:
			return payload.drivers
		case ADD_DRIVER_SUCCESS:
			return [ payload, ...state ]
		case UPDATE_DRIVER_SUCCESS:
			return state.filter(driver => driver.id !== payload.id)
		case ASSIGN_TO_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: payload.truck
				} : driver
			)
		case UNASSIGN_FROM_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: null
				} : driver
			)
		default:
			return state
	}
}

const approvedDrivers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_APPROVED_DRIVERS_REQUEST:
		case FETCH_APPROVED_DRIVERS_FAILURE:
			return state
		case FETCH_APPROVED_DRIVERS_SUCCESS:
			return payload.drivers
		case UPDATE_DRIVER_SUCCESS:
			return state.map(driver => driver.id === payload.id && payload.status === APPROVED_USER_STATUS
				? payload
				: driver
			)
		case ASSIGN_TO_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: payload.truck
				} : driver
			)
		case UNASSIGN_FROM_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: null
				} : driver
			)
		default:
			return state
	}
}

const declinedDrivers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_DECLINED_DRIVERS_REQUEST:
		case FETCH_DECLINED_DRIVERS_FAILURE:
			return state
		case FETCH_DECLINED_DRIVERS_SUCCESS:
			return payload.drivers
		case UPDATE_DRIVER_SUCCESS:
			return state.map(driver => driver.id === payload.id && payload.status === APPROVED_USER_STATUS
				? payload
				: driver
			)
		default:
			return state
	}
}

const freeDrivers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_FREE_DRIVERS_REQUEST:
		case FETCH_FREE_DRIVERS_FAILURE:
			return state
		case FETCH_FREE_DRIVERS_SUCCESS:
			return payload.drivers
		case ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS:
			return state.map(driver => driver.id === payload.driverId ?
				{
					...driver,
					tasks: driver.tasks + 1
				}
				: driver
			)
		case UPDATE_DRIVER_STATUS_SUCCESS:
			if (payload.status !== FREE_DRIVER_STATUS) {
				return state.filter(driver => driver.id !== payload.id)
			} else {
				return [payload, ...state]
			}
		case ASSIGN_TO_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: payload.truck
				} : driver
			)
		case UNASSIGN_FROM_TRUCK_SUCCESS:
			return state.map(driver => driver.id === payload.driver.id ?
				{
					...driver,
					truck: null
				} : driver
			)
		default:
			return state
	}
}

const busyDrivers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_BUSY_DRIVERS_REQUEST:
		case FETCH_BUSY_DRIVERS_FAILURE:
			return state
		case FETCH_BUSY_DRIVERS_SUCCESS:
			return payload.drivers
		case UPDATE_DRIVER_STATUS_SUCCESS:
			if (payload.status !== BUSY_DRIVER_STATUS) {
				return state.filter(driver => driver.id !== payload.id)
			} else {
				return [payload, ...state]
			}
		default:
			return state
	}
}

const inactiveDrivers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_INACTIVE_DRIVERS_REQUEST:
		case FETCH_INACTIVE_DRIVERS_FAILURE:
			return state
		case FETCH_INACTIVE_DRIVERS_SUCCESS:
			return payload.drivers
		case UPDATE_DRIVER_STATUS_SUCCESS:
			if (payload.status !== INACTIVE_DRIVER_STATUS) {
				return state.filter(driver => driver.id !== payload.id)
			} else {
				return [payload, ...state]
			}
		default:
			return state
	}
}

const driverTasks = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_DRIVER_TASKS_REQUEST:
		case FETCH_DRIVER_TASKS_FAILURE:
			return state
		case FETCH_DRIVER_TASKS_SUCCESS:
			state[payload.driverId] = payload.shipments
			return {
				...state
			}
		default:
			return state
	}
}

const driverLocations = (state = {}, { type, payload }) => {
	switch (type) {
		case UPDATE_DRIVER_LOCATION: {
			const newState = { ...state }
			newState[payload.driverId] = payload
			return newState
		}
		default:
			return state
	}
}

// eslint-disable-next-line no-unused-vars
const isFetchingDrivers = (state = false, { type }) => {
	switch (type) {
		case FETCH_DRIVERS_REQUEST:
		case FETCH_NEW_DRIVERS_REQUEST:
		case FETCH_FREE_DRIVERS_REQUEST:
		case FETCH_BUSY_DRIVERS_REQUEST:
		case FETCH_INACTIVE_DRIVERS_REQUEST:
		case FETCH_APPROVED_DRIVERS_REQUEST:
		case FETCH_DECLINED_DRIVERS_REQUEST:
			return true
		default:
			return false
	}
}

const drivers = combineReducers({
	allDrivers,
	newDrivers,
	approvedDrivers,
	declinedDrivers,
	freeDrivers,
	busyDrivers,
	inactiveDrivers,
	driverTasks,
	driverLocations,
	isFetchingDrivers
})

export default drivers

export const getAllDrivers = state => state.allDrivers
export const getNewDrivers = state => state.newDrivers
export const getApprovedDrivers = state => state.approvedDrivers
export const getDeclinedDrivers = state => state.declinedDrivers
export const getFreeDrivers = state => state.freeDrivers
export const getBusyDrivers = state => state.busyDrivers
export const getInactiveDrivers = state => state.inactiveDrivers
export const getDriverTasks = state => state.driverTasks
export const getDriverLocations = state => Object.values(state.driverLocations)
export const getIsFetchingDrivers = state => state.isFetchingDrivers
export const getUnassignedDrivers = state => state.approvedDrivers.filter(driver => !driver.truck)
