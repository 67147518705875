import { combineReducers } from 'redux'
import {
	FETCH_TRUCKS_REQUEST,
	FETCH_TRUCKS_SUCCESS,
	FETCH_TRUCKS_FAILURE,
	ADD_TRUCK_SUCCESS,
	UPDATE_TRUCK_SUCCESS,
	DELETE_TRUCK_SUCCESS,
	FETCH_ACTIVATED_TRUCKS_REQUEST,
	FETCH_ACTIVATED_TRUCKS_SUCCESS,
	FETCH_ACTIVATED_TRUCKS_FAILURE,
	FETCH_DEACTIVATED_TRUCKS_REQUEST,
	FETCH_DEACTIVATED_TRUCKS_SUCCESS,
	FETCH_DEACTIVATED_TRUCKS_FAILURE,
	ASSIGN_TO_TRUCK_SUCCESS,
	UNASSIGN_FROM_TRUCK_SUCCESS,
	FETCH_MORE_TRUCKS_REQUEST,
	FETCH_MORE_TRUCKS_FAILURE,
	FETCH_MORE_TRUCKS_SUCCESS,
	FETCH_MORE_ACTIVATED_TRUCKS_FAILURE,
	FETCH_MORE_ACTIVATED_TRUCKS_REQUEST,
	FETCH_MORE_ACTIVATED_TRUCKS_SUCCESS,
	FETCH_MORE_DEACTIVATED_TRUCKS_FAILURE,
	FETCH_MORE_DEACTIVATED_TRUCKS_REQUEST,
	FETCH_MORE_DEACTIVATED_TRUCKS_SUCCESS,
	SEARCH_TRUCKS_FAILURE,
	SEARCH_TRUCKS_SUCCESS,
	SEARCH_TRUCKS_REQUEST,
	SEARCH_MORE_TRUCKS_FAILURE,
	SEARCH_MORE_TRUCKS_SUCCESS,
	SEARCH_MORE_TRUCKS_REQUEST,
	FETCH_AVAILABLE_TRUCKS_SUCCESS,
	FETCH_AVAILABLE_TRUCKS_REQUEST,
	FETCH_MORE_AVAILABLE_TRUCKS_SUCCESS,
	FETCH_UNAVAILABLE_TRUCKS_SUCCESS,
	FETCH_UNAVAILABLE_TRUCKS_REQUEST,
	FETCH_MORE_UNAVAILABLE_TRUCKS_SUCCESS,
	FETCH_MORE_AVAILABLE_TRUCKS_REQUEST,
	FETCH_MORE_UNAVAILABLE_TRUCKS_REQUEST,
	FETCH_AVAILABLE_TRUCKS_FAILURE,
	FETCH_UNAVAILABLE_TRUCKS_FAILURE,
	FETCH_MORE_UNAVAILABLE_TRUCKS_FAILURE,
	FETCH_MORE_AVAILABLE_TRUCKS_FAILURE
} from './action-types'
import {
	ACTIVATED_TRUCK_STATUS,
	DEACTIVATED_TRUCK_STATUS
} from '../../constants/strings'

const allTrucks = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_TRUCKS_REQUEST:
		case FETCH_TRUCKS_FAILURE:
		case FETCH_MORE_TRUCKS_REQUEST:
		case FETCH_MORE_TRUCKS_FAILURE:
			return state
		case FETCH_TRUCKS_SUCCESS:
			return payload.trucks
		case FETCH_MORE_TRUCKS_SUCCESS:
			return [...state, ...payload.trucks]
		case ADD_TRUCK_SUCCESS:
			return [ payload, ...state ]
		case DELETE_TRUCK_SUCCESS:
			return state.filter(truck => truck.id !== payload.id)
		case UPDATE_TRUCK_SUCCESS:
			return state.map(truck => truck.id === payload.id
				? payload
				: truck
			)
		case ASSIGN_TO_TRUCK_SUCCESS:
			return state.map(truck => truck.id === payload.truck.id ?
				{
					...truck,
					driver: payload.driver
				} : truck
			)
		case UNASSIGN_FROM_TRUCK_SUCCESS:
			return state.map(truck => truck.id === payload.truck.id ?
				{
					...truck,
					driver: null
				} : truck
			)
		default:
			return state
	}
}

const isFetchingTrucks = (state = false, { type }) => {
	switch (type) {
		case FETCH_TRUCKS_REQUEST:
		case FETCH_ACTIVATED_TRUCKS_REQUEST:
		case FETCH_DEACTIVATED_TRUCKS_REQUEST:
		case FETCH_AVAILABLE_TRUCKS_REQUEST:
		case FETCH_UNAVAILABLE_TRUCKS_REQUEST:
			return true
		case FETCH_TRUCKS_SUCCESS:
		case FETCH_TRUCKS_FAILURE:
		case FETCH_ACTIVATED_TRUCKS_SUCCESS:
		case FETCH_ACTIVATED_TRUCKS_FAILURE:
		case FETCH_DEACTIVATED_TRUCKS_SUCCESS:
		case FETCH_DEACTIVATED_TRUCKS_FAILURE:
		case FETCH_UNAVAILABLE_TRUCKS_FAILURE:
		case FETCH_UNAVAILABLE_TRUCKS_SUCCESS:
		case FETCH_AVAILABLE_TRUCKS_FAILURE:
		case FETCH_AVAILABLE_TRUCKS_SUCCESS:
			return false
		default:
			return state
	}
}
const isFetchingMoreTrucks = (state = false, { type }) => {
	switch (type) {
		case FETCH_MORE_TRUCKS_REQUEST:
		case FETCH_MORE_ACTIVATED_TRUCKS_REQUEST:
		case FETCH_MORE_DEACTIVATED_TRUCKS_REQUEST:
		case FETCH_MORE_AVAILABLE_TRUCKS_REQUEST:
		case FETCH_MORE_UNAVAILABLE_TRUCKS_REQUEST:
			return true
		case FETCH_MORE_TRUCKS_SUCCESS:
		case FETCH_MORE_TRUCKS_FAILURE:
		case FETCH_MORE_ACTIVATED_TRUCKS_SUCCESS:
		case FETCH_MORE_ACTIVATED_TRUCKS_FAILURE:
		case FETCH_MORE_DEACTIVATED_TRUCKS_SUCCESS:
		case FETCH_MORE_DEACTIVATED_TRUCKS_FAILURE:
		case FETCH_MORE_UNAVAILABLE_TRUCKS_FAILURE:
		case FETCH_MORE_UNAVAILABLE_TRUCKS_SUCCESS:
		case FETCH_MORE_AVAILABLE_TRUCKS_FAILURE:
		case FETCH_MORE_AVAILABLE_TRUCKS_SUCCESS:
			return false
		default:
			return state
	}
}

const activatedTrucks = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_ACTIVATED_TRUCKS_REQUEST:
		case FETCH_ACTIVATED_TRUCKS_FAILURE:
		case FETCH_MORE_ACTIVATED_TRUCKS_REQUEST:
		case FETCH_MORE_ACTIVATED_TRUCKS_FAILURE:
			return state
		case FETCH_ACTIVATED_TRUCKS_SUCCESS:
			return payload.trucks
		case FETCH_MORE_ACTIVATED_TRUCKS_SUCCESS:
			return [...state, ...payload.trucks]
		case UPDATE_TRUCK_SUCCESS:
			if (payload.status === ACTIVATED_TRUCK_STATUS) {
				return [ payload, ...state ]
			} else if (payload.status === DEACTIVATED_TRUCK_STATUS) {
				return state.filter(truck => truck.id !== payload.id)
			} else {
				return state
			}
		case DELETE_TRUCK_SUCCESS:
			return state.filter(truck => truck.id !== payload.id)
		case ASSIGN_TO_TRUCK_SUCCESS:
			return state.map(truck => truck.id === payload.truck.id ?
				{
					...truck,
					driver: payload.driver
				} : truck
			)
		case UNASSIGN_FROM_TRUCK_SUCCESS:
			return state.map(truck => truck.id === payload.truck.id ?
				{
					...truck,
					driver: null
				} : truck
			)
		default:
			return state
	}
}

const deactivatedTrucks = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_DEACTIVATED_TRUCKS_REQUEST:
		case FETCH_DEACTIVATED_TRUCKS_FAILURE:
		case FETCH_MORE_DEACTIVATED_TRUCKS_REQUEST:
		case FETCH_MORE_DEACTIVATED_TRUCKS_FAILURE:
			return state
		case FETCH_DEACTIVATED_TRUCKS_SUCCESS:
			return payload.trucks
		case FETCH_MORE_DEACTIVATED_TRUCKS_SUCCESS:
			return [...state, ...payload.trucks]
		case UPDATE_TRUCK_SUCCESS:
			if (payload.status === DEACTIVATED_TRUCK_STATUS) {
				return [ payload, ...state ]
			} else if (payload.status === ACTIVATED_TRUCK_STATUS) {
				return state.filter(truck => truck.id !== payload.id)
			} else {
				return state
			}
		case DELETE_TRUCK_SUCCESS:
			return state.filter(truck => truck.id !== payload.id)
		default:
			return state
	}
}

const matchingTrucks = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_TRUCKS_REQUEST:
		case SEARCH_TRUCKS_FAILURE:
			return []
		case SEARCH_TRUCKS_SUCCESS:
			return payload.trucks
		case SEARCH_MORE_TRUCKS_SUCCESS:
			return [...state, ...payload.trucks]
		default:
			return state
	}
}

// eslint-disable-next-line no-unused-vars
const isSearchingTrucks = (state = false, { type }) => {
	switch (type) {
		case SEARCH_TRUCKS_REQUEST:
			return true
		default:
			return false
	}
}

const isSearchingMoreTrucks = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_TRUCKS_REQUEST:
			return true
		case SEARCH_MORE_TRUCKS_FAILURE:
		case SEARCH_MORE_TRUCKS_SUCCESS:
			return false
		default:
			return state
	}
}

const availableTrucks = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_AVAILABLE_TRUCKS_REQUEST:
		case FETCH_AVAILABLE_TRUCKS_FAILURE:
			return state
		case FETCH_AVAILABLE_TRUCKS_SUCCESS:
			return payload.trucks
		case FETCH_MORE_AVAILABLE_TRUCKS_SUCCESS:
			return [...state, ...payload.trucks]
		default:
			return state
	}
}

const unavailableTrucks = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_UNAVAILABLE_TRUCKS_REQUEST:
		case FETCH_UNAVAILABLE_TRUCKS_FAILURE:
			return state
		case FETCH_UNAVAILABLE_TRUCKS_SUCCESS:
			return payload.trucks
		case FETCH_MORE_UNAVAILABLE_TRUCKS_SUCCESS:
			return [...state, ...payload.trucks]
		default:
			return state
	}
}

const trucks = combineReducers({
	allTrucks,
	activatedTrucks,
	deactivatedTrucks,
	isFetchingTrucks,
	isFetchingMoreTrucks,
	matchingTrucks,
	isSearchingTrucks,
	isSearchingMoreTrucks,
	availableTrucks,
	unavailableTrucks
})

export default trucks

export const getAllTrucks = state => state.allTrucks
export const getUnassignedTrucks = state => state.activatedTrucks.filter(truck => !truck.driver)
export const getActivatedTrucks = state => state.activatedTrucks
export const getDeactivatedTrucks = state => state.deactivatedTrucks
export const getIsFetchingMoreTrucks = state => state.isFetchingMoreTrucks
export const getIsFetchingTrucks = state => state.isFetchingTrucks
export const getMatchingTrucks = state => state.matchingTrucks
export const getIsSearchingTrucks = state => state.isSearchingTrucks
export const getIsSearchingMoreTrucks = state => state.isSearchingMoreTrucks
export const getAvailableTrucks = state => state.availableTrucks
export const getUnavailableTrucks = state => state.unavailableTrucks
