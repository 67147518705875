import { combineReducers } from 'redux'
import {
	FETCH_SHIPMENTS_REQUEST,
	FETCH_SHIPMENTS_SUCCESS,
	FETCH_SHIPMENTS_FAILURE,
	FETCH_MORE_SHIPMENTS_SUCCESS,
	FETCH_REQUESTED_SHIPMENTS_REQUEST,
	FETCH_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_REQUESTED_SHIPMENTS_FAILURE,
	FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_SCHEDULED_SHIPMENTS_REQUEST,
	FETCH_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_SCHEDULED_SHIPMENTS_FAILURE,
	FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_ASSIGNED_SHIPMENTS_REQUEST,
	FETCH_ASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_ASSIGNED_SHIPMENTS_FAILURE,
	FETCH_MORE_ASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_UNASSIGNED_SHIPMENTS_REQUEST,
	FETCH_UNASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_UNASSIGNED_SHIPMENTS_FAILURE,
	FETCH_MORE_UNASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_ACTIVE_SHIPMENTS_REQUEST,
	FETCH_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_ACTIVE_SHIPMENTS_FAILURE,
	FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_COMPLETED_SHIPMENTS_REQUEST,
	FETCH_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_COMPLETED_SHIPMENTS_FAILURE,
	FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_CANCELLED_SHIPMENTS_REQUEST,
	FETCH_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_CANCELLED_SHIPMENTS_FAILURE,
	FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_EXPIRED_SHIPMENTS_REQUEST,
	FETCH_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_EXPIRED_SHIPMENTS_FAILURE,
	FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_USERS_REQUEST,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAILURE,
	FETCH_MORE_USERS_SUCCESS,
	FETCH_SHIPPER_USERS_REQUEST,
	FETCH_SHIPPER_USERS_SUCCESS,
	FETCH_SHIPPER_USERS_FAILURE,
	FETCH_MORE_SHIPPER_USERS_SUCCESS,
	FETCH_CARRIER_USERS_REQUEST,
	FETCH_CARRIER_USERS_SUCCESS,
	FETCH_CARRIER_USERS_FAILURE,
	FETCH_MORE_CARRIER_USERS_SUCCESS,
	FETCH_DRIVER_USERS_REQUEST,
	FETCH_DRIVER_USERS_SUCCESS,
	FETCH_DRIVER_USERS_FAILURE,
	FETCH_MORE_DRIVER_USERS_SUCCESS,
	FETCH_RATES_REQUEST,
	FETCH_RATES_SUCCESS,
	FETCH_RATES_FAILURE,
	UPDATE_USER_SUCCESS,
	ADD_RATE_SUCCESS,
	UPDATE_RATE_SUCCESS,
	FETCH_CURRENT_RATE_REQUEST,
	FETCH_CURRENT_RATE_FAILURE,
	FETCH_CURRENT_RATE_SUCCESS,
	FETCH_TRANSACTIONS_REQUEST,
	FETCH_TRANSACTIONS_SUCCESS,
	FETCH_TRANSACTIONS_FAILURE,
	FETCH_MORE_TRANSACTIONS_SUCCESS,
	FETCH_BOOKINGS_REQUEST,
	FETCH_BOOKINGS_SUCCESS,
	FETCH_BOOKINGS_FAILURE,
	FETCH_MORE_BOOKINGS_SUCCESS,
	FETCH_REQUESTED_BOOKINGS_REQUEST,
	FETCH_REQUESTED_BOOKINGS_SUCCESS,
	FETCH_REQUESTED_BOOKINGS_FAILURE,
	FETCH_MORE_REQUESTED_BOOKINGS_SUCCESS,
	FETCH_ONGOING_BOOKINGS_REQUEST,
	FETCH_ONGOING_BOOKINGS_SUCCESS,
	FETCH_ONGOING_BOOKINGS_FAILURE,
	FETCH_MORE_ONGOING_BOOKINGS_SUCCESS,
	FETCH_CONTRACTED_RATES_REQUEST,
	FETCH_CONTRACTED_RATES_SUCCESS,
	FETCH_CONTRACTED_RATES_FAILURE,
	FETCH_BOUNDING_SHIPMENTS_REQUEST,
	FETCH_BOUNDING_SHIPMENTS_SUCCESS,
	FETCH_BOUNDING_SHIPMENTS_FAILURE,
	FETCH_GLOBAL_COUNTS_REQUEST,
	FETCH_GLOBAL_COUNTS_SUCCESS,
	FETCH_GLOBAL_COUNTS_FAILURE,
	FETCH_GLOBAL_MONTHLY_COUNTS_SUCCESS,
	FETCH_GLOBAL_WEEKLY_COUNTS_SUCCESS,
	FETCH_GLOBAL_HISTORY_REQUEST,
	FETCH_GLOBAL_HISTORY_SUCCESS,
	FETCH_GLOBAL_HISTORY_FAILURE,
	FETCH_GLOBAL_FARES_REQUEST,
	FETCH_GLOBAL_FARES_SUCCESS,
	FETCH_GLOBAL_FARES_FAILURE,
	FETCH_RATE_HISTORY_REQUEST,
	FETCH_RATE_HISTORY_SUCCESS,
	FETCH_RATE_HISTORY_FAILURE,
	OVERRIDE_SHIPMENT_STATUS_SUCCESS,
	FETCH_AGENT_USERS_SUCCESS,
	FETCH_AGENT_USERS_REQUEST,
	FETCH_AGENT_USERS_FAILURE,
	FETCH_DISPATCHER_USERS_SUCCESS,
	FETCH_DISPATCHER_USERS_REQUEST,
	FETCH_DISPATCHER_USERS_FAILURE,
	FETCH_MORE_DISPATCHER_USERS_SUCCESS,
	FETCH_MORE_AGENT_USERS_SUCCESS,
	FETCH_BOUNDING_USERS_REQUEST,
	FETCH_BOUNDING_USERS_SUCCESS,
	FETCH_BOUNDING_USERS_FAILURE,
	FETCH_MORE_RATES_SUCCESS,
	FETCH_MORE_CONTRACTED_RATES_SUCCESS,
	SEARCH_RATES_SUCCESS,
	SEARCH_RATES_REQUEST,
	SEARCH_RATES_FAILURE,
	FETCH_CONTRACTS_REQUEST,
	FETCH_CONTRACTS_SUCCESS,
	FETCH_CONTRACTS_FAILURE,
	FETCH_CONTRACT_SUCCESS,
	FETCH_MORE_CONTRACTS_SUCCESS,
	ADD_CONTRACT_SUCCESS,
	UPDATE_CONTRACT_SUCCESS,
	UPDATE_CONTRACT_STATUS_SUCCESS,
	FETCH_CONTRACT_REQUEST,
	FETCH_WATCHING_SHIPMENTS_REQUEST,
	FETCH_WATCHING_SHIPMENTS_SUCCESS,
	FETCH_WATCHING_SHIPMENTS_FAILURE,
	WATCH_SHIPMENT_SUCCESS,
	ASSIGN_SHIPMENT_REQUEST,
	ASSIGN_SHIPMENT_SUCCESS,
	MASS_ASSIGN_SHIPMENTS_REQUEST,
	MASS_ASSIGN_SHIPMENTS_SUCCESS,
	MASS_ASSIGN_SHIPMENTS_FAILURE,
	FETCH_ADMIN_ASSIGNED_SHIPMENTS_REQUEST,
	FETCH_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_ADMIN_ASSIGNED_SHIPMENTS_FAILURE,
	FETCH_PENDING_CONTRACTS_REQUEST,
	FETCH_PENDING_CONTRACTS_SUCCESS,
	FETCH_PENDING_CONTRACTS_FAILURE,
	FETCH_MORE_PENDING_CONTRACTS_SUCCESS,
	FETCH_BILLS_REQUEST,
	FETCH_BILLS_SUCCESS,
	FETCH_BILLS_FAILURE,
	FETCH_MORE_BILLS_SUCCESS,
	CREATE_BILL_SUCCESS,
	FETCH_CONTRACTED_SHIPPERS_REQUEST,
	FETCH_CONTRACTED_SHIPPERS_SUCCESS,
	FETCH_CONTRACTED_SHIPPERS_FAILURE,
	FETCH_SHIPPER_CONTRACT_RATES_REQUEST,
	FETCH_SHIPPER_CONTRACT_RATES_SUCCESS,
	FETCH_SHIPPER_CONTRACT_RATES_FAILURE,
	ADD_CHARGE_SUCCESS,
	REMOVE_CHARGE_SUCCESS,
	FETCH_ADMIN_NOTIFICATIONS_REQUEST,
	FETCH_ADMIN_NOTIFICATIONS_FAILURE,
	FETCH_ADMIN_NOTIFICATIONS_SUCCESS,
	FETCH_MORE_ADMIN_NOTIFICATIONS_FAILURE,
	FETCH_MORE_ADMIN_NOTIFICATIONS_REQUEST,
	FETCH_MORE_ADMIN_NOTIFICATIONS_SUCCESS,
	ADD_ADMIN_NOTIFICATION,
	UPDATE_ADMIN_NOTIFICATION,
	READ_ADMIN_NOTIFICATION,
	FETCH_SHIPPER_HISTORY_REQUEST,
	FETCH_SHIPPER_HISTORY_FAILURE,
	FETCH_SHIPPER_HISTORY_SUCCESS,
	FETCH_RECENTLY_ACTIVE_SHIPPERS_REQUEST,
	FETCH_RECENTLY_ACTIVE_SHIPPERS_FAILURE,
	FETCH_RECENTLY_ACTIVE_SHIPPERS_SUCCESS,
	FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS,
	FETCH_MORE_WATCHING_SHIPMENTS_SUCCESS,
	FETCH_REVENUE_SUCCESS,
	FETCH_CONTRACT_FAILURE,
	SEARCH_BILLS_SUCCESS,
	SEARCH_BILLS_FAILURE,
	SEARCH_BILLS_REQUEST,
	SEARCH_MORE_BILLS_REQUEST,
	SEARCH_MORE_BILLS_FAILURE,
	SEARCH_MORE_BILLS_SUCCESS,
	FETCH_ALL_COMPANIES_FAILURE,
	FETCH_ALL_COMPANIES_SUCCESS,
	FETCH_ALL_COMPANIES_REQUEST,
	FETCH_DASHBOARD_SUCCESS,
	FETCH_DASHBOARD_REQUEST,
	FETCH_DASHBOARD_FAILURE,
	UPDATE_USER_REQUEST,
	FETCH_REPORTS_REQUEST,
	FETCH_REPORTS_FAILURE,
	FETCH_REPORTS_SUCCESS,
	FETCH_MORE_REPORTS_SUCCESS,
	FETCH_MORE_REPORTS_REQUEST,
	FETCH_MORE_REPORTS_FAILURE,
	FETCH_ANNUAL_DASHBOARD_REQUEST,
	FETCH_ANNUAL_DASHBOARD_SUCCESS,
	FETCH_ANNUAL_DASHBOARD_FAILURE,
	FETCH_BILL_FAILURE,
	FETCH_BILL_REQUEST,
	FETCH_BILL_SUCCESS,
	DELETE_CONTRACT_SUCCESS,
	FETCH_USERS_DASHBOARD_SUCCESS,
	FETCH_USERS_DASHBOARD_REQUEST,
	FETCH_COMPANIES_SUCCESS,
	FETCH_MORE_COMPANIES_SUCCESS,
	FETCH_COMPANIES_REQUEST,
	FETCH_COMPANIES_FAILURE,
	FETCH_EVENTS_DASHBOARD_SUCCESS,
	FETCH_EVENTS_DASHBOARD_REQUEST,
	SEARCH_MORE_COMPANIES_SUCCESS,
	SEARCH_MORE_COMPANIES_REQUEST,
	SEARCH_MORE_COMPANIES_FAILURE,
	SEARCH_COMPANIES_SUCCESS,
	SEARCH_COMPANIES_REQUEST,
	SEARCH_COMPANIES_FAILURE,
	SEARCH_CONTRACTS_SUCCESS,
	SEARCH_MORE_CONTRACTS_SUCCESS,
	SEARCH_CONTRACTS_FAILURE,
	SEARCH_CONTRACTS_REQUEST,
	SEARCH_MORE_CONTRACTS_REQUEST,
	SEARCH_MORE_CONTRACTS_FAILURE,
	FETCH_BOOKING_SHIPMENTS_SUCCESS,
	DELETE_BILL_SUCCESS,
	ADD_CARRIER_TO_SHIPMENT_SUCCESS,
	LISTEN_TO_VENDOR_REQUESTS,
	UPDATE_VENDOR_REQUEST,
	FETCH_MONTHLY_OPERATIONS_ANALYTICS_REQUEST,
	FETCH_MONTHLY_OPERATIONS_ANALYTICS_SUCCESS,
	FETCH_MONTHLY_VENDOR_ANALYTICS_REQUEST,
	FETCH_MONTHLY_VENDOR_ANALYTICS_SUCCESS,
	FETCH_ANNUAL_TRENDS_ANALYTICS_SUCCESS,
	FETCH_ANNUAL_TRENDS_ANALYTICS_REQUEST
} from './action-types'
import {
	UPDATE_SHIPMENT,
	UPDATE_TRANSACTION_SUCCESS,
	UPDATE_TRANSACTION_REQUEST,
	SEARCH_SHIPMENTS_REQUEST,
	SEARCH_TRANSACTIONS_REQUEST,
	SEARCH_SHIPMENTS_SUCCESS,
	SEARCH_SHIPMENTS_FAILURE,
	SEARCH_TRANSACTIONS_SUCCESS,
	SEARCH_TRANSACTIONS_FAILURE,
	UPDATE_SHIPMENT_PAYMENT_SUCCESS,
	UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST,
	UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS,
	UPDATE_BOOKING
} from '../shipments/action-types'
import {
	UPDATE_STAT_COUNTS,
	FETCH_DAILY_SUMMARY_SUCCESS,
	FETCH_MONTHLY_GENERAL_ANALYTICS_SUCCESS,
	FETCH_MONTHLY_GENERAL_ANALYTICS_REQUEST
} from '../admin/action-types'
import {
	FETCH_TRUCKS_REQUEST,
	FETCH_TRUCKS_SUCCESS,
	FETCH_TRUCKS_FAILURE,
	SEARCH_TRUCKS_REQUEST,
	SEARCH_TRUCKS_SUCCESS,
	SEARCH_TRUCKS_FAILURE
} from '../trucks/action-types'
import {
	SEARCH_USERS_REQUEST,
	SEARCH_USERS_SUCCESS,
	SEARCH_USERS_FAILURE
} from '../user/action-types'
import {
	CONTRACT_ACTIVE_STATUS,
	CONTRACT_INACTIVE_STATUS,
	CONTRACT_PENDING_STATUS
} from '../../constants/strings'

const shipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment)
			return state.map(shipment => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					return matchedShipment
				}
				return shipment
			})
		}
		case FETCH_SHIPMENTS_REQUEST:
		case FETCH_SHIPMENTS_FAILURE:
			return state
		case FETCH_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
		case UPDATE_SHIPMENT:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						...payload
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const requestedShipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment)
			return state.map(shipment => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					return matchedShipment
				}
				return shipment
			})
		}
		case FETCH_REQUESTED_SHIPMENTS_REQUEST:
		case FETCH_REQUESTED_SHIPMENTS_FAILURE:
			return state
		case FETCH_REQUESTED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const scheduledShipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment)
			return state.map(shipment => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					return matchedShipment
				}
				return shipment
			})
		}
		case FETCH_SCHEDULED_SHIPMENTS_REQUEST:
		case FETCH_SCHEDULED_SHIPMENTS_FAILURE:
			return state
		case FETCH_SCHEDULED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const assignedShipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment)
			return state.map(shipment => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					return matchedShipment
				}
				return shipment
			})
		}
		case FETCH_ASSIGNED_SHIPMENTS_REQUEST:
		case FETCH_ASSIGNED_SHIPMENTS_FAILURE:
			return state
		case FETCH_ASSIGNED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_ASSIGNED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case ASSIGN_SHIPMENT_SUCCESS:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const unassignedShipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment)
			return state.map(shipment => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					return matchedShipment
				}
				return shipment
			})
		}
		case FETCH_UNASSIGNED_SHIPMENTS_REQUEST:
		case FETCH_UNASSIGNED_SHIPMENTS_FAILURE:
			return state
		case FETCH_UNASSIGNED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_UNASSIGNED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const activeShipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment)
			return state.map(shipment => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					return matchedShipment
				}
				return shipment
			})
		}
		case FETCH_ACTIVE_SHIPMENTS_REQUEST:
		case FETCH_ACTIVE_SHIPMENTS_FAILURE:
			return state
		case FETCH_ACTIVE_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const completedShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_COMPLETED_SHIPMENTS_REQUEST:
		case FETCH_COMPLETED_SHIPMENTS_FAILURE:
			return state
		case FETCH_COMPLETED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case ASSIGN_SHIPMENT_SUCCESS:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const cancelledShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CANCELLED_SHIPMENTS_REQUEST:
		case FETCH_CANCELLED_SHIPMENTS_FAILURE:
			return state
		case FETCH_CANCELLED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case ASSIGN_SHIPMENT_SUCCESS:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const expiredShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_EXPIRED_SHIPMENTS_REQUEST:
		case FETCH_EXPIRED_SHIPMENTS_FAILURE:
			return state
		case FETCH_EXPIRED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.filter(shipment => shipment.id !== payload.id)
		case UPDATE_SHIPMENT:
			return state.filter(shipment => shipment.id !== payload.id || shipment.status === payload.status)
		case ASSIGN_SHIPMENT_SUCCESS:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case ADD_CHARGE_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ?
				{ ...shipment, ...payload } :
				shipment
			)
		default:
			return state
	}
}

const watchingShipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment)
			return state.map(shipment => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					return matchedShipment
				}
				return shipment
			})
		}
		case FETCH_WATCHING_SHIPMENTS_FAILURE:
		case FETCH_WATCHING_SHIPMENTS_REQUEST:
			return state
		case FETCH_WATCHING_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_WATCHING_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
		case UPDATE_SHIPMENT:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (payload.assign) {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: {
							id: payload.uid
						}
					}
					: shipment)
			} else {
				return state.map(shipment => shipment.id === payload.id ?
					{
						...shipment,
						assignee: null
					}
					: shipment)
			}
		case WATCH_SHIPMENT_SUCCESS:
			if (payload.watch) {
				return [payload, ...state]
			} else {
				return state.filter(shipment => shipment.id !== payload.id)
			}
		default:
			return state
	}
}

const adminAssignedShipments = (state = [], { type, payload }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_SUCCESS: {
			const payloadShipments = []
			payload.shipments.map(
				payloadShipment => payloadShipments[payloadShipment.id] = payloadShipment
			)

			const newState = state.reduce((shipments, shipment) => {
				const matchedShipment = payloadShipments[shipment.id]
				if (matchedShipment) {
					if (matchedShipment.assignee && matchedShipment.assignee.id === payload.authUserId) {
						shipments.push(matchedShipment)
					}
				} else {
					shipments.push(shipment)
				}
				return shipments
			}, [])

			return newState
		}
		case FETCH_ADMIN_ASSIGNED_SHIPMENTS_REQUEST:
		case FETCH_ADMIN_ASSIGNED_SHIPMENTS_FAILURE:
			return state
		case FETCH_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS:
			return payload.shipments
		case FETCH_MORE_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS:
			return [...state, ...payload.shipments]
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
		case UPDATE_SHIPMENT:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
		case UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS:
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS:
		case ASSIGN_SHIPMENT_SUCCESS:
			return state.map(shipment => shipment.id === payload.id ? payload : shipment)
		case UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						fareAmount: payload.fareAmount
					}
				} else {
					return shipment
				}
			})
		case UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST:
			return state.map(shipment => {
				if (shipment.id === payload.id) {
					return {
						...shipment,
						vendorAmount: payload.vendorAmount
					}
				} else {
					return shipment
				}
			})
		case ASSIGN_SHIPMENT_REQUEST:
			if (!payload.assign) {
				return state.filter(shipment => shipment.id !== payload.id)
			}
			return state
		default:
			return state
	}
}

const users = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_USERS_REQUEST:
		case FETCH_USERS_FAILURE:
			return state
		case FETCH_USERS_SUCCESS:
			return payload.users
		case UPDATE_USER_SUCCESS:
			return state.map(user => user.id === payload.id
				? payload
				: user
			)
		case FETCH_MORE_USERS_SUCCESS:
			return [...state, ...payload.users]
		default:
			return state
	}
}

const shipperUsers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_USERS_REQUEST:
		case FETCH_SHIPPER_USERS_FAILURE:
			return state
		case FETCH_SHIPPER_USERS_SUCCESS:
			return payload.users
		case UPDATE_USER_SUCCESS:
			return state.map(user => user.id === payload.id
				? payload
				: user
			)
		case FETCH_MORE_SHIPPER_USERS_SUCCESS:
			return [...state, ...payload.users]
		default:
			return state
	}
}

const carrierUsers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CARRIER_USERS_REQUEST:
		case FETCH_CARRIER_USERS_FAILURE:
			return state
		case FETCH_CARRIER_USERS_SUCCESS:
			return payload.users
		case UPDATE_USER_REQUEST: {
			return state.map(user => {
				if (user.id === payload?.id) {
					if (!user.dispatcherIds) {
						user.dispatcherIds = []
					}
					const doesDispatcherIdExist = user.dispatcherIds.includes(payload.dispatcherId)
					if (doesDispatcherIdExist) {
						user.dispatcherIds = user.dispatcherIds.filter(dispatcherId => dispatcherId !== payload.dispatcherId)
					} else {
						user.dispatcherIds.push(payload.dispatcherId)
					}
					return user
				}
				return user
			})
		}
		case UPDATE_USER_SUCCESS:
			return state.map(user => user.id === payload.id
				? payload
				: user
			)
		case FETCH_MORE_CARRIER_USERS_SUCCESS:
			return [...state, ...payload.users]
		default:
			return state
	}
}

const driverUsers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_DRIVER_USERS_REQUEST:
		case FETCH_DRIVER_USERS_FAILURE:
			return state
		case FETCH_DRIVER_USERS_SUCCESS:
			return payload.users
		case UPDATE_USER_SUCCESS:
			return state.map(user => user.id === payload.id
				? payload
				: user
			)
		case FETCH_MORE_DRIVER_USERS_SUCCESS:
			return [...state, ...payload.users]
		default:
			return state
	}
}

const agentUsers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_AGENT_USERS_REQUEST:
		case FETCH_AGENT_USERS_FAILURE:
			return state
		case FETCH_AGENT_USERS_SUCCESS:
			return payload.users
		case UPDATE_USER_SUCCESS:
			return state.map(user => user.id === payload.id
				? payload
				: user
			)
		case FETCH_MORE_AGENT_USERS_SUCCESS:
			return [...state, ...payload.users]
		default:
			return state
	}
}

const dispatcherUsers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_DISPATCHER_USERS_REQUEST:
		case FETCH_DISPATCHER_USERS_FAILURE:
			return state
		case FETCH_DISPATCHER_USERS_SUCCESS:
			return payload.users
		case UPDATE_USER_SUCCESS:
			return state.map(user => user.id === payload.id
				? payload
				: user
			)
		case FETCH_MORE_DISPATCHER_USERS_SUCCESS:
			return [...state, ...payload.users]
		default:
			return state
	}
}

const reports = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_REPORTS_REQUEST:
		case FETCH_REPORTS_FAILURE:
			return state
		case FETCH_REPORTS_SUCCESS:
			return payload.reports
		case FETCH_MORE_REPORTS_SUCCESS:
			return [...state, ...payload.reports]
		default:
			return state
	}
}

const rates = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_RATES_REQUEST:
		case FETCH_RATES_FAILURE:
			return state
		case FETCH_RATES_SUCCESS:
			return payload.rates.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
		case FETCH_MORE_RATES_SUCCESS:
			return [...state, ...payload.rates].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
		case ADD_RATE_SUCCESS:
			if (!payload.shipperId) {
				if (payload.rates && Array.isArray(payload.rates)) {
					let newState = [...state]
					const addedRateIds = {}
					payload.rates.forEach(rate => {
						addedRateIds[rate.id] = true
					})
					newState = newState.filter(rate => !addedRateIds[rate.id])
					return [...payload.rates, ...newState]
				} else {
					return [payload, ...state]
				}
			} else {
				return state
			}
		case UPDATE_RATE_SUCCESS:
			if (!payload.shipperId) {
				const newState = state.map(rate => rate.id === payload.id
					? payload
					: rate
				)
				return newState.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
			} else {
				return state
			}
		default:
			return state
	}
}

const contractedRates = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CONTRACTED_RATES_REQUEST:
		case FETCH_CONTRACTED_RATES_FAILURE:
			return state
		case FETCH_CONTRACTED_RATES_SUCCESS:
			return payload.rates
		case FETCH_MORE_CONTRACTED_RATES_SUCCESS:
			return [...state, ...payload.rates]
		case ADD_RATE_SUCCESS:
			if (payload.shipperId) {
				return [payload, ...state]
			} else {
				return state
			}
		case UPDATE_RATE_SUCCESS:
			if (payload.shipperId) {
				return state.map(rate => rate.id === payload.id
					? payload
					: rate
				)
			} else {
				return state
			}
		default:
			return state
	}
}

const currentRate = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_CURRENT_RATE_REQUEST:
		case FETCH_CURRENT_RATE_FAILURE:
			return state
		case FETCH_CURRENT_RATE_SUCCESS:
			return payload
		case UPDATE_RATE_SUCCESS:
			if (payload.id === 'current') {
				return payload
			} else {
				return state
			}
		default:
			return state
	}
}

const transactions = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_TRANSACTIONS_REQUEST:
		case FETCH_TRANSACTIONS_FAILURE:
			return state
		case FETCH_TRANSACTIONS_SUCCESS:
			return payload.transactions
		case FETCH_MORE_TRANSACTIONS_SUCCESS:
			return [ ...state, ...payload.transactions ]
		case UPDATE_TRANSACTION_REQUEST:
			return state.map(transaction => {
				if (payload && transaction.id === payload.id) {
					if (payload.status && transaction.transaction) {
						transaction.transaction.status = payload.status
					}
					if (payload.paymentMethod && transaction.transaction) {
						transaction.transaction.paymentMethod = payload.paymentMethod
					}
					return transaction
				} else {
					return transaction
				}
			})
		case UPDATE_TRANSACTION_SUCCESS:
		case REMOVE_CHARGE_SUCCESS:
		case UPDATE_SHIPMENT_PAYMENT_SUCCESS:
		case ADD_CHARGE_SUCCESS:
			return state.map(transaction => transaction.id === payload.id ?
				{ ...transaction, ...payload } :
				transaction
			)
		default:
			return state
	}
}

const isFetchingTransactions = (state = false, { type }) => {
	switch (type) {
		case FETCH_TRANSACTIONS_REQUEST:
			return true
		case FETCH_TRANSACTIONS_FAILURE:
		case FETCH_TRANSACTIONS_SUCCESS:
			return false
		default:
			return state
	}
}

const bookings = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_BOOKINGS_REQUEST:
		case FETCH_BOOKINGS_FAILURE:
			return state
		case FETCH_BOOKINGS_SUCCESS:
			return payload.bookings
		case FETCH_MORE_BOOKINGS_SUCCESS:
			return [ ...state, ...payload.bookings ]
		case UPDATE_BOOKING:
			return state.map(booking => {
				if (booking.id === payload.id) {
					return Object.assign(booking, payload)
				} else {
					return booking
				}
			})
		case FETCH_BOOKING_SHIPMENTS_SUCCESS:
			return state.map(booking => {
				if (booking.id === payload.bookingId) {
					return {
						...booking,
						shipments: payload.shipments
					}
				} else {
					return booking
				}
			})
		case ADD_CARRIER_TO_SHIPMENT_SUCCESS:
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.map(booking => {
				if (booking.id === payload.bookingId && booking.shipments) {
					booking.shipments.map(shipment => shipment.id === payload.id ?
						payload : shipment
					)
				}
				return booking
			})
		default:
			return state
	}
}

const requestedBookings = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_REQUESTED_BOOKINGS_REQUEST:
		case FETCH_REQUESTED_BOOKINGS_FAILURE:
			return state
		case FETCH_REQUESTED_BOOKINGS_SUCCESS:
			return payload.bookings
		case FETCH_MORE_REQUESTED_BOOKINGS_SUCCESS:
			return [ ...state, ...payload.bookings ]
		case UPDATE_BOOKING:
			return state.map(booking => {
				if (booking.id === payload.id) {
					return Object.assign(booking, payload)
				} else {
					return booking
				}
			})
		case FETCH_BOOKING_SHIPMENTS_SUCCESS:
			return state.map(booking => {
				if (booking.id === payload.bookingId) {
					return {
						...booking,
						shipments: payload.shipments
					}
				} else {
					return booking
				}
			})
		case ADD_CARRIER_TO_SHIPMENT_SUCCESS:
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.map(booking => {
				if (booking.id === payload.bookingId && booking.shipments) {
					booking.shipments = booking.shipments.map(shipment => shipment.id === payload.id ?
						payload : shipment
					)
				}
				return booking
			})
		default:
			return state
	}
}
const ongoingBookings = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_ONGOING_BOOKINGS_REQUEST:
		case FETCH_ONGOING_BOOKINGS_FAILURE:
			return state
		case FETCH_ONGOING_BOOKINGS_SUCCESS:
			return payload.bookings
		case FETCH_MORE_ONGOING_BOOKINGS_SUCCESS:
			return [ ...state, ...payload.bookings ]
		case UPDATE_BOOKING:
			return state.map(booking => {
				if (booking.id === payload.id) {
					return Object.assign(booking, payload)
				} else {
					return booking
				}
			})
		case FETCH_BOOKING_SHIPMENTS_SUCCESS:
			return state.map(booking => {
				if (booking.id === payload.bookingId) {
					return {
						...booking,
						shipments: payload.shipments
					}
				} else {
					return booking
				}
			})
		case ADD_CARRIER_TO_SHIPMENT_SUCCESS:
		case OVERRIDE_SHIPMENT_STATUS_SUCCESS:
			return state.map(booking => {
				if (booking.id === payload.bookingId && booking.shipments) {
					booking.shipments = booking.shipments.map(shipment => shipment.id === payload.id ?
						payload : shipment
					)
				}
				return booking
			})
		default:
			return state
	}
}

const boundingShipments = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_BOUNDING_SHIPMENTS_REQUEST:
		case FETCH_BOUNDING_SHIPMENTS_FAILURE:
			return state
		case FETCH_BOUNDING_SHIPMENTS_SUCCESS:
			return payload.shipments
		default:
			return state
	}
}

const boundingUsers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_BOUNDING_USERS_REQUEST:
		case FETCH_BOUNDING_USERS_FAILURE:
			return state
		case FETCH_BOUNDING_USERS_SUCCESS:
			return payload.users
		default:
			return state
	}
}

// eslint-disable-next-line no-unused-vars
const isFetching = (state = true, { type }) => {
	switch (type) {
		case FETCH_REPORTS_REQUEST:
		case FETCH_TRANSACTIONS_REQUEST:
		case FETCH_RATES_REQUEST:
		case FETCH_SHIPMENTS_REQUEST:
		case FETCH_TRUCKS_REQUEST:
		case FETCH_USERS_REQUEST:
		case FETCH_SHIPPER_USERS_REQUEST:
		case FETCH_CARRIER_USERS_REQUEST:
		case FETCH_DRIVER_USERS_REQUEST:
		case FETCH_BOOKINGS_REQUEST:
		case FETCH_CONTRACTED_RATES_REQUEST:
		case FETCH_REQUESTED_SHIPMENTS_REQUEST:
		case FETCH_SCHEDULED_SHIPMENTS_REQUEST:
		case FETCH_ASSIGNED_SHIPMENTS_REQUEST:
		case FETCH_UNASSIGNED_SHIPMENTS_REQUEST:
		case FETCH_ACTIVE_SHIPMENTS_REQUEST:
		case FETCH_EXPIRED_SHIPMENTS_REQUEST:
		case FETCH_COMPLETED_SHIPMENTS_REQUEST:
		case FETCH_CANCELLED_SHIPMENTS_REQUEST:
		case FETCH_CONTRACTS_REQUEST:
		case FETCH_CONTRACT_REQUEST:
		case FETCH_AGENT_USERS_REQUEST:
		case FETCH_DISPATCHER_USERS_REQUEST:
		case SEARCH_RATES_REQUEST:
		case SEARCH_SHIPMENTS_REQUEST:
		case SEARCH_TRANSACTIONS_REQUEST:
		case SEARCH_TRUCKS_REQUEST:
		case SEARCH_USERS_REQUEST:
		case FETCH_PENDING_CONTRACTS_REQUEST:
		case FETCH_DASHBOARD_REQUEST:
		case FETCH_USERS_DASHBOARD_REQUEST:
		case FETCH_ADMIN_ASSIGNED_SHIPMENTS_REQUEST:
		case FETCH_ANNUAL_DASHBOARD_REQUEST:
		case FETCH_COMPANIES_REQUEST:
		case FETCH_MONTHLY_GENERAL_ANALYTICS_REQUEST:
		case FETCH_MONTHLY_OPERATIONS_ANALYTICS_REQUEST:
		case FETCH_MONTHLY_VENDOR_ANALYTICS_REQUEST:
		case FETCH_ANNUAL_TRENDS_ANALYTICS_REQUEST:
			return true
		case SEARCH_RATES_SUCCESS:
		case SEARCH_RATES_FAILURE:
		case SEARCH_SHIPMENTS_SUCCESS:
		case SEARCH_SHIPMENTS_FAILURE:
		case SEARCH_TRANSACTIONS_SUCCESS:
		case SEARCH_TRANSACTIONS_FAILURE:
		case SEARCH_TRUCKS_SUCCESS:
		case SEARCH_TRUCKS_FAILURE:
		case SEARCH_USERS_SUCCESS:
		case SEARCH_USERS_FAILURE:
		case FETCH_TRANSACTIONS_FAILURE:
		case FETCH_RATES_FAILURE:
		case FETCH_SHIPMENTS_FAILURE:
		case FETCH_TRUCKS_FAILURE:
		case FETCH_USERS_FAILURE:
		case FETCH_SHIPPER_USERS_FAILURE:
		case FETCH_CARRIER_USERS_FAILURE:
		case FETCH_DRIVER_USERS_FAILURE:
		case FETCH_BOOKINGS_FAILURE:
		case FETCH_CONTRACTED_RATES_FAILURE:
		case FETCH_REQUESTED_SHIPMENTS_FAILURE:
		case FETCH_SCHEDULED_SHIPMENTS_FAILURE:
		case FETCH_ASSIGNED_SHIPMENTS_FAILURE:
		case FETCH_UNASSIGNED_SHIPMENTS_FAILURE:
		case FETCH_ACTIVE_SHIPMENTS_FAILURE:
		case FETCH_EXPIRED_SHIPMENTS_FAILURE:
		case FETCH_COMPLETED_SHIPMENTS_FAILURE:
		case FETCH_CANCELLED_SHIPMENTS_FAILURE:
		case FETCH_CONTRACTS_FAILURE:
		case FETCH_CONTRACT_FAILURE:
		case FETCH_AGENT_USERS_FAILURE:
		case FETCH_DISPATCHER_USERS_FAILURE:
		case FETCH_TRANSACTIONS_SUCCESS:
		case FETCH_RATES_SUCCESS:
		case FETCH_SHIPMENTS_SUCCESS:
		case FETCH_TRUCKS_SUCCESS:
		case FETCH_USERS_SUCCESS:
		case FETCH_SHIPPER_USERS_SUCCESS:
		case FETCH_CARRIER_USERS_SUCCESS:
		case FETCH_DRIVER_USERS_SUCCESS:
		case FETCH_BOOKINGS_SUCCESS:
		case FETCH_CONTRACTED_RATES_SUCCESS:
		case FETCH_REQUESTED_SHIPMENTS_SUCCESS:
		case FETCH_SCHEDULED_SHIPMENTS_SUCCESS:
		case FETCH_ASSIGNED_SHIPMENTS_SUCCESS:
		case FETCH_UNASSIGNED_SHIPMENTS_SUCCESS:
		case FETCH_ACTIVE_SHIPMENTS_SUCCESS:
		case FETCH_EXPIRED_SHIPMENTS_SUCCESS:
		case FETCH_COMPLETED_SHIPMENTS_SUCCESS:
		case FETCH_CANCELLED_SHIPMENTS_SUCCESS:
		case FETCH_CONTRACTS_SUCCESS:
		case FETCH_CONTRACT_SUCCESS:
		case FETCH_AGENT_USERS_SUCCESS:
		case FETCH_DISPATCHER_USERS_SUCCESS:
		case FETCH_PENDING_CONTRACTS_SUCCESS:
		case FETCH_PENDING_CONTRACTS_FAILURE:
		case FETCH_DASHBOARD_SUCCESS:
		case FETCH_DASHBOARD_FAILURE:
		case FETCH_ADMIN_ASSIGNED_SHIPMENTS_SUCCESS:
		case FETCH_ADMIN_ASSIGNED_SHIPMENTS_FAILURE:
		case FETCH_ANNUAL_DASHBOARD_SUCCESS:
		case FETCH_ANNUAL_DASHBOARD_FAILURE:
		case FETCH_COMPANIES_SUCCESS:
		case FETCH_COMPANIES_FAILURE:
			return false
		default:
			return false
	}
}

const isFetchingMoreReports = (state = false, { type }) => {
	switch (type) {
		case FETCH_MORE_REPORTS_REQUEST:
			return true
		case FETCH_MORE_REPORTS_SUCCESS:
		case FETCH_MORE_REPORTS_FAILURE:
			return false
		default:
			return state
	}
}

const defaultGlobalCounts = {
	requestedShipmentsCount: 0,
	scheduledShipmentsCount: 0,
	activeShipmentsCount: 0,
	completedShipmentsCount: 0,
	cancelledShipmentsCount: 0,
	expiredShipmentsCount: 0,
	processingTransactionsCount: 0,
	paidTransactionsCount: 0,
	pendingTransactionsCount: 0,
	pendingContractsCount: 0,
	activeContractsCount: 0,
	cancelledTransactionsCount: 0,
	truckCount: 0,
	bookingsCount: 0,
	bookingItemTypes: {
		'metal': 0,
		'lumber': 0,
		'consumer-packaged-goods': 0,
		'garments-textiles': 0,
		'construction-materials': 0,
		'cement': 0,
		'jute': 0,
		'leather': 0,
		'poultry-feed': 0,
		'agriculture': 0
	},
	bookingTrucks: {
		'25t-40ft(trailer)': 0,
		'20t-20ft(trailer)': 0,
		'5t-23ft': 0,
		'5t-20ft(openTruck)': 0,
		'3t-18ft': 0,
		'3t-17.5ft(openTruck)': 0,
		'3t-17ft': 0,
		'3t-16ft': 0,
		'3t-16ft(openTruck)': 0,
		'3t-14ft': 0,
		'2t-13ft': 0,
		'2t-12ft': 0,
		'1.5t-14ft': 0,
		'1.5t-13.5ft': 0,
		'1.5t-13ft': 0,
		'1.5t-12.5ft': 0,
		'1.5t-12.5ft(openTruck)': 0,
		'1.5t-12ft': 0,
		'1.5t-12ft(openTruck)': 0,
		'1.5t-10ft': 0,
		'1t-11ft(openTruck)': 0,
		'1t-8ft': 0,
		'1t-7ft': 0,
		'1t-7ft(openTruck)': 0,
		'0.5t-10ft': 0,
		'0.5t-8ft': 0,
		'0.5t-7.5ft': 0,
		'0.5t-7ft': 0,
		'0.5t-7ft(openTruck)': 0
	}
}

const globalCounts = (state = defaultGlobalCounts, { type, payload }) => {
	switch (type) {
		case FETCH_GLOBAL_COUNTS_REQUEST:
		case FETCH_GLOBAL_COUNTS_FAILURE:
			return state
		case FETCH_GLOBAL_COUNTS_SUCCESS:
		case UPDATE_STAT_COUNTS:
			return payload
		default:
			return state
	}
}

const weeklyGlobalCounts = (state = defaultGlobalCounts, { type, payload }) => {
	switch (type) {
		case FETCH_GLOBAL_WEEKLY_COUNTS_SUCCESS:
			return payload
		default:
			return state
	}
}

const monthlyGlobalCounts = (state = defaultGlobalCounts, { type, payload }) => {
	switch (type) {
		case FETCH_GLOBAL_MONTHLY_COUNTS_SUCCESS:
			return payload
		default:
			return state
	}
}

const globalHistory = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_GLOBAL_HISTORY_REQUEST:
		case FETCH_GLOBAL_HISTORY_FAILURE:
			return state
		case FETCH_GLOBAL_HISTORY_SUCCESS:
			return payload.shipmentDataPoints
		default:
			return state
	}
}

const shipperHistories = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_HISTORY_REQUEST:
		case FETCH_SHIPPER_HISTORY_FAILURE:
			return state
		case FETCH_SHIPPER_HISTORY_SUCCESS: {
			if (!payload.id) {
				return state
			}
			const newState = { ...state }
			newState[payload.id] = payload.shipmentDataPoints
			return newState
		}
		default:
			return state
	}
}

const recentlyActiveShippers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_RECENTLY_ACTIVE_SHIPPERS_REQUEST:
		case FETCH_RECENTLY_ACTIVE_SHIPPERS_FAILURE:
			return state
		case FETCH_RECENTLY_ACTIVE_SHIPPERS_SUCCESS: {
			return payload.users
		}
		default:
			return state
	}
}

const defaultGlobalFares = {
	activeShipmentsFareTotal: 0,
	cancelledShipmentsFareTotal: 0,
	completedShipmentsFareTotal: 0,
	expiredShipmentsFareTotal: 0,
	requestedShipmentsFareTotal: 0,
	scheduledShipmentsFareTotal: 0
}

const globalFares = (state = defaultGlobalFares, { type, payload }) => {
	switch (type) {
		case FETCH_GLOBAL_FARES_REQUEST:
		case FETCH_GLOBAL_FARES_FAILURE:
			return state
		case FETCH_GLOBAL_FARES_SUCCESS:
			return payload
		default:
			return state
	}
}

const rateHistories = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_RATE_HISTORY_REQUEST:
		case FETCH_RATE_HISTORY_FAILURE:
			return state
		case FETCH_RATE_HISTORY_SUCCESS: {
			const newState = { ...state }
			newState[payload.id] = payload
			return newState
		}
		case UPDATE_RATE_SUCCESS: {
			const newState = { ...state }
			const rateHistory = newState[payload.id]
			if (rateHistory) {
				const date = new Date()
				const dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
				const dataPoint = rateHistory.rateDataPoints.data.find(data => data.x === dateStr)
				if (dataPoint) {
					dataPoint.y = payload.flatRate
				} else {
					rateHistory.rateDataPoints.data.push({
						x: dateStr,
						y: payload.flatRate
					})
				}
				return newState
			}
			return state
		}
		default:
			return state
	}
}

const matchingRates = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_RATES_SUCCESS:
			return payload.rates
		case SEARCH_RATES_FAILURE:
		case SEARCH_RATES_REQUEST:
			return state
		default:
			return state
	}
}

const isSearchingRates = (state = false, { type }) => {
	switch (type) {
		case SEARCH_RATES_REQUEST:
			return true
		case SEARCH_RATES_FAILURE:
		case SEARCH_RATES_SUCCESS:
			return false
		default:
			return state
	}
}

const matchingBills = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_BILLS_SUCCESS:
			return payload.bills
		case SEARCH_MORE_BILLS_SUCCESS:
			return [...state, ...payload.bills]
		case SEARCH_BILLS_FAILURE:
		case SEARCH_BILLS_REQUEST:
			return state
		default:
			return state
	}
}

const isSearchingBills = (state = false, { type }) => {
	switch (type) {
		case SEARCH_BILLS_REQUEST:
			return true
		case SEARCH_BILLS_FAILURE:
		case SEARCH_BILLS_SUCCESS:
			return false
		default:
			return state
	}
}

const isLoadingMoreSearchedBills = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_BILLS_REQUEST:
			return true
		case SEARCH_MORE_BILLS_FAILURE:
		case SEARCH_MORE_BILLS_SUCCESS:
			return false
		default:
			return state
	}
}

const contracts = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CONTRACTS_SUCCESS:
			return payload.contracts
		case FETCH_MORE_CONTRACTS_SUCCESS:
			return [...state, ...payload.contracts]
		case UPDATE_CONTRACT_SUCCESS:
		case UPDATE_CONTRACT_STATUS_SUCCESS:
			if (payload.status === CONTRACT_PENDING_STATUS) {
				return state.filter(contract => contract.id !== payload.id)
			} else {
				return state.map(contract => contract.id === payload.id ? payload : contract)
			}
		case ADD_CONTRACT_SUCCESS:
			if (payload.status === CONTRACT_ACTIVE_STATUS || payload.status === CONTRACT_INACTIVE_STATUS) {
				return [payload, ...state]
			} else {
				return state
			}
		case DELETE_CONTRACT_SUCCESS: {
			if (payload.status === CONTRACT_ACTIVE_STATUS || payload.status === CONTRACT_INACTIVE_STATUS) {
				return state.filter(contract => contract.id !== payload.id)
			} else {
				return state
			}
		}
		case FETCH_CONTRACT_SUCCESS:
			return state.map(contract => contract.id === payload.id ? payload : contract)
		case FETCH_CONTRACTS_REQUEST:
		case FETCH_CONTRACTS_FAILURE:
			return state
		default:
			return state
	}
}

const pendingContracts = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_PENDING_CONTRACTS_SUCCESS:
			return payload.contracts
		case FETCH_MORE_PENDING_CONTRACTS_SUCCESS:
			return [...state, ...payload.contracts]
		case UPDATE_CONTRACT_SUCCESS:
		case UPDATE_CONTRACT_STATUS_SUCCESS:
			if (payload.status === CONTRACT_ACTIVE_STATUS || payload.status === CONTRACT_INACTIVE_STATUS) {
				return state.filter(contract => contract.id !== payload.id)
			} else {
				return state.map(contract => contract.id === payload.id ? payload : contract)
			}
		case ADD_CONTRACT_SUCCESS:
			if (payload.status === CONTRACT_PENDING_STATUS) {
				return [payload, ...state]
			} else {
				return state
			}
		case DELETE_CONTRACT_SUCCESS: {
			if (payload.status === CONTRACT_PENDING_STATUS) {
				return state.filter(contract => contract.id !== payload.id)
			} else {
				return state
			}
		}
		case FETCH_PENDING_CONTRACTS_REQUEST:
		case FETCH_PENDING_CONTRACTS_FAILURE:
			return state
		default:
			return state
	}
}

const contractDetails = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_CONTRACT_REQUEST:
		case FETCH_CONTRACT_FAILURE:
			return state
		case FETCH_CONTRACT_SUCCESS: {
			const contract = state[payload.id] ? state[payload.id] : {}
			const newContract = Object.assign(contract, payload)
			const newState = { ...state }
			newState[payload.id] = newContract
			return newState
		}
		default:
			return state
	}
}

const isFetchingContract = (state = false, { type }) => {
	switch (type) {
		case FETCH_CONTRACT_REQUEST:
			return true
		case FETCH_CONTRACT_SUCCESS:
		case FETCH_CONTRACT_FAILURE:
			return false
		default:
			return state
	}
}

const bills = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_BILLS_SUCCESS:
			return payload.bills
		case FETCH_MORE_BILLS_SUCCESS:
			return [...state, ...payload.bills]
		case CREATE_BILL_SUCCESS:
			return [payload, ...state]
		case DELETE_BILL_SUCCESS:
			return state.filter(bill => bill.id !== payload.id)
		case FETCH_BILLS_REQUEST:
		case FETCH_BILLS_FAILURE:
			return state
		default:
			return state
	}
}

const bill = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_BILL_SUCCESS: {
			const bill = state[payload.id] ? state[payload.id] : {}
			const newBill = Object.assign(bill, payload)
			const newState = { ...state }
			newState[payload.id] = newBill
			return newState
		}
		case FETCH_BILL_REQUEST:
		case FETCH_BILL_FAILURE:
			return state
		default:
			return state
	}
}

const isFetchingBills = (state = false, { type }) => {
	switch (type) {
		case FETCH_BILLS_REQUEST:
			return true
		case FETCH_BILLS_FAILURE:
		case FETCH_BILLS_SUCCESS:
			return false
		default:
			return state
	}
}

const contractedShippers = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_CONTRACTED_SHIPPERS_REQUEST:
		case FETCH_CONTRACTED_SHIPPERS_FAILURE:
			return state
		case FETCH_CONTRACTED_SHIPPERS_SUCCESS:
			return payload.shippers
		default:
			return state
	}
}

const shipperContractRates = (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_CONTRACT_RATES_REQUEST:
		case FETCH_SHIPPER_CONTRACT_RATES_FAILURE:
			return state
		case FETCH_SHIPPER_CONTRACT_RATES_SUCCESS: {
			const newState = { ...state }
			newState[payload.shipperId] = payload.rates
			return newState
		}
		default:
			return state
	}
}

const notificationMap = {}
const notifications = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_ADMIN_NOTIFICATIONS_REQUEST:
		case FETCH_ADMIN_NOTIFICATIONS_FAILURE:
		case FETCH_MORE_ADMIN_NOTIFICATIONS_REQUEST:
		case FETCH_MORE_ADMIN_NOTIFICATIONS_FAILURE:
			return state
		case FETCH_ADMIN_NOTIFICATIONS_SUCCESS:
			payload.notifications.forEach(notification => {
				if (!notificationMap[notification.id]) {
					notificationMap[notification.id] = true
				}
			})
			return payload.notifications
		case ADD_ADMIN_NOTIFICATION:
			if (!notificationMap[payload.id]) {
				notificationMap[payload.id] = true
				return [payload, ...state]
			}
			return state
		case UPDATE_ADMIN_NOTIFICATION:
			return state.map(notification => notification.id === payload.id
				? payload
				: notification
			)
		case READ_ADMIN_NOTIFICATION:
			return state.map(notification => notification.id === payload.id
				? { ...payload, isRead: true }
				: notification
			)
		case FETCH_MORE_ADMIN_NOTIFICATIONS_SUCCESS:
			payload.notifications.forEach(notification => {
				if (!notificationMap[notification.id]) {
					notificationMap[notification.id] = true
				}
			})
			return [...state, ...payload.notifications]
		default:
			return state
	}
}

const isFetchingNotifications = (state = false, { type }) => {
	switch (type) {
		case FETCH_ADMIN_NOTIFICATIONS_REQUEST:
			return true
		case FETCH_ADMIN_NOTIFICATIONS_FAILURE:
		case FETCH_ADMIN_NOTIFICATIONS_SUCCESS:
			return false
		default:
			return state
	}
}

const isFetchingMoreNotifications = (state = false, { type }) => {
	switch (type) {
		case FETCH_MORE_ADMIN_NOTIFICATIONS_REQUEST:
			return true
		case FETCH_MORE_ADMIN_NOTIFICATIONS_FAILURE:
		case FETCH_MORE_ADMIN_NOTIFICATIONS_SUCCESS:
			return false
		default:
			return state
	}
}

const isMassAssigningShipments = (state = false, { type }) => {
	switch (type) {
		case MASS_ASSIGN_SHIPMENTS_REQUEST:
			return true
		case MASS_ASSIGN_SHIPMENTS_SUCCESS:
		case MASS_ASSIGN_SHIPMENTS_FAILURE:
			return false
		default:
			return state
	}
}

const defaultRevenue = {
	daily: {
		fareAmountDataPoints: [],
		vendorAmountDataPoints: [],
		profitDataPoints: []
	},
	week: {
		fareAmountDataPoints: [],
		vendorAmountDataPoints: [],
		profitDataPoints: []
	},
	month: {
		fareAmountDataPoints: [],
		vendorAmountDataPoints: [],
		profitDataPoints: []
	}
}

const revenueHistory = (state = defaultRevenue, { type, payload }) => {
	switch (type) {
		case FETCH_REVENUE_SUCCESS: {
			const newState = { ...state }
			const revenueHistory = {
				fareAmountDataPoints: payload.fareAmountDataPoints,
				vendorAmountDataPoints: payload.vendorAmountDataPoints,
				profitDataPoints: payload.profitDataPoints
			}
			if (payload.interval === 'week') {
				newState['week'] = revenueHistory
			} else if (payload.interval === 'month') {
				newState['month'] = revenueHistory
			} else {
				newState['daily'] = revenueHistory
			}
			return newState
		}
		default:
			return state
	}
}

const allCompanies = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_ALL_COMPANIES_REQUEST:
		case FETCH_ALL_COMPANIES_FAILURE:
			return state
		case FETCH_ALL_COMPANIES_SUCCESS:
			return payload.companies
		default:
			return state
	}
}

const companies = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_COMPANIES_SUCCESS:
			return payload.companies
		case FETCH_MORE_COMPANIES_SUCCESS:
			return [...state, ...payload.companies]
		default:
			return state
	}
}

const defaultDashboard = {
	routeCounts: [],
	loadingPointCounts: [],
	unloadingPointCounts: [],
	localRouteCounts: [],
	localLoadingPointCounts: [],
	localUnloadingPointCounts: [],
	truckTypeCounts: [],
	itemTypeCounts: [],
	shipperAmounts: [],
	dailyValues: {
		currentDay: {
			requestedShipments: 0,
			totalShipments: 0,
			revenue: 0.0,
			paymentReceived: 0.0,
			paymentOwed: 0.0,
			profit: 0.0
		},
		previousDay: {
			requestedShipments: 0,
			totalShipments: 0,
			revenue: 0.0,
			paymentReceived: 0.0,
			paymentOwed: 0.0,
			profit: 0.0
		}
	},
	fareAmountDataPoints: [],
	vendorAmountDataPoints: [],
	profitDataPoints: [],
	totalShipmentDataPoints: [],
	shipperDataPoints: [],
	monthlyValues: {
		distance: 0,
		averageRevenuePerShipper: 0.0,
		totalShipments: 0,
		totalCompletedShipments: 0,
		revenue: 0
	},
	averageEventTimes: {
		enrouteToPickUpTime: 0,
		enrouteToPickUpCount: 0,
		loadingTime: 0,
		loadingCount: 0,
		transitTime: 0,
		transitCount: 0,
		idleAtDropOffTime: 0,
		idleAtDropOffCount: 0,
		driverInsideFacilityTime: 0,
		driverInsideFacilityCount: 0,
		totalTime: 0
	},
	shipperAverageEventTimes: []
}
const date = new Date()
// Map of dashboard values where the key is the month value (indexed from 0, i.e. 0 = January, 1 = February, etc..)
const dashboard = (state = { [date.getMonth()]: defaultDashboard }, { type, payload }) => {
	switch (type) {
		case FETCH_DASHBOARD_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultDashboard
				return newState
			}
		}
		case FETCH_DASHBOARD_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}

const defaultDailyDashboard = {
	currentDay: {
		requestedShipments: 0,
		totalShipments: 0,
		cancelledShipments: 0,
		revenue: 0.0,
		paymentReceived: 0.0,
		paymentOwed: 0.0,
		profit: 0.0
	},
	previousDay: {
		requestedShipments: 0,
		totalShipments: 0,
		cancelledShipments: 0,
		revenue: 0.0,
		paymentReceived: 0.0,
		paymentOwed: 0.0,
		profit: 0.0
	}
}

const dailyDashboard = (state = defaultDailyDashboard, { type, payload }) => {
	switch (type) {
		case FETCH_DASHBOARD_SUCCESS: {
			return payload.dailyValues
		}
		default:
			return state
	}
}

const defaultAnnualDashboard = {
	monthlyData: [],
	shipperMonthlyData: []
}

const annualDashboard = (state = defaultAnnualDashboard, { type, payload }) => {
	switch (type) {
		case FETCH_ANNUAL_DASHBOARD_SUCCESS: {
			return payload
		}
		default:
			return state
	}
}

const defaultUsersDashboard = {
	userMonthlyValues: []
}
// Map of dashboard values where the key is the month value (indexed from 0, i.e. 0 = January, 1 = February, etc..)
const usersDashboard = (state = { [date.getMonth()]: defaultUsersDashboard }, { type, payload }) => {
	switch (type) {
		case FETCH_USERS_DASHBOARD_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultUsersDashboard
				return newState
			}
		}
		case FETCH_USERS_DASHBOARD_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}

const defaultEventsDashboard = {
	totalEventsUpdated: 0,
	totalTripsManaged: 0,
	totalAverageScore: 0.0,
	userEventStats: []
}
// Map of dashboard values where the key is the month value (indexed from 0, i.e. 0 = January, 1 = February, etc..)
const eventsDashboard = (state = { [date.getMonth()]: defaultEventsDashboard }, { type, payload }) => {
	switch (type) {
		case FETCH_EVENTS_DASHBOARD_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultEventsDashboard
				return newState
			}
		}
		case FETCH_EVENTS_DASHBOARD_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}

const matchingCompanies = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_COMPANIES_SUCCESS:
			return payload.companies
		case SEARCH_MORE_COMPANIES_SUCCESS:
			return [...state, ...payload.companies]
		case SEARCH_COMPANIES_FAILURE:
		case SEARCH_COMPANIES_REQUEST:
			return state
		default:
			return state
	}
}

const isSearchingCompanies = (state = false, { type }) => {
	switch (type) {
		case SEARCH_COMPANIES_REQUEST:
			return true
		case SEARCH_COMPANIES_FAILURE:
		case SEARCH_COMPANIES_SUCCESS:
			return false
		default:
			return state
	}
}

const isLoadingMoreSearchedCompanies = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_COMPANIES_REQUEST:
			return true
		case SEARCH_MORE_COMPANIES_FAILURE:
		case SEARCH_MORE_COMPANIES_SUCCESS:
			return false
		default:
			return state
	}
}

const matchingContracts = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_CONTRACTS_SUCCESS:
			return payload.contracts
		case SEARCH_MORE_CONTRACTS_SUCCESS:
			return [...state, ...payload.contracts]
		case SEARCH_CONTRACTS_FAILURE:
		case SEARCH_CONTRACTS_REQUEST:
			return state
		default:
			return state
	}
}

const isSearchingContracts = (state = false, { type }) => {
	switch (type) {
		case SEARCH_CONTRACTS_REQUEST:
			return true
		case SEARCH_CONTRACTS_FAILURE:
		case SEARCH_CONTRACTS_SUCCESS:
			return false
		default:
			return state
	}
}

const isLoadingMoreSearchedContracts = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_CONTRACTS_REQUEST:
			return true
		case SEARCH_MORE_CONTRACTS_FAILURE:
		case SEARCH_MORE_CONTRACTS_SUCCESS:
			return false
		default:
			return state
	}
}

const vendorRequests = (state = {}, { type, payload }) => {
	switch (type) {
		case LISTEN_TO_VENDOR_REQUESTS: {
			const newState = Object.assign(state, payload)
			return newState
		}
		case UPDATE_VENDOR_REQUEST: {
			const newState = { ...state }
			newState[payload.bookingId] = payload
			return newState
		}
		default:
			return state
	}
}

const defaultDailySummary = {
	totalOrders: {
		value: 0,
		percentage: 0
	},
	newOrders: {
		value: 0,
		percentage: 0
	},
	openOrders: {
		value: 0,
		percentage: 0
	},
	cancelledOrders: {
		value: 0,
		percentage: 0
	}
}
const dailySummary = (state = defaultDailySummary, { type, payload }) => {
	switch (type) {
		case FETCH_DAILY_SUMMARY_SUCCESS:
			return payload
		default:
			return state
	}
}

const defaultMonthlyGeneralAnalytics = {
	totalProfits: {
		value: 0,
		percentageChange: 0
	},
	totalVendorAmounts: {
		value: 0,
		percentageChange: 0
	},
	totalFareAmounts: {
		value: 0,
		percentageChange: 0
	},
	profitDataPoints: [],
	fareAmountDataPoints: [],
	vendorAmountDataPoints: [],
	shipmentDataPoints: [],
	itemTypeCounts: {},
	truckTypeCounts: {},
	companyAnalytics: {},
	routeCounts: [],
	loadingPointCounts: [],
	unloadingPointCounts: []
}

// Map of dashboard values where the key is the month value (indexed from 0, i.e. 0 = January, 1 = February, etc..)
const monthlyGeneralAnalytics = (state = { [new Date().getMonth()]: defaultMonthlyGeneralAnalytics }, { type, payload }) => {
	switch (type) {
		case FETCH_MONTHLY_GENERAL_ANALYTICS_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultMonthlyGeneralAnalytics
				return newState
			}
		}
		case FETCH_MONTHLY_GENERAL_ANALYTICS_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}

const defaultMonthlyOperationsAnalytics = {
	monthlyTotalOrders: {
		value: 0,
		percentageChange: 0
	},
	monthlyTotalSales: {
		value: 0,
		percentageChange: 0
	},
	monthlyTotalMargins: {
		value: 0,
		percentageChange: 0
	},
	userTypeCounts: {
		carrier: 0,
		carrier_driver: 0,
		shipper: 0
	},
	averageEventTimes: {
		enrouteToPickUpTime: 0,
		enrouteToPickUpCount: 0,
		loadingTime: 0,
		loadingCount: 0,
		transitTime: 0,
		transitCount: 0,
		idleAtDropOffTime: 0,
		idleAtDropOffCount: 0,
		driverInsideFacilityTime: 0,
		driverInsideFacilityCount: 0
	},
	shipperAverageEventTimes: { },
	dispatcherAnalytics: { }
}
// Map of dashboard values where the key is the month value (indexed from 0, i.e. 0 = January, 1 = February, etc..)
const monthlyOperationsAnalytics = (state = { [new Date().getMonth()]: defaultMonthlyOperationsAnalytics }, { type, payload }) => {
	switch (type) {
		case FETCH_MONTHLY_OPERATIONS_ANALYTICS_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultMonthlyOperationsAnalytics
				return newState
			}
		}
		case FETCH_MONTHLY_OPERATIONS_ANALYTICS_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}

const defaultMonthlyVendorAnalytics = {
	totalActivations: {
		value: 0,
		percentageChange: 0
	},
	totalBids: {
		value: 0,
		percentageChange: 0
	},
	vendorLocations: { },
	vendorAnalytics: { }
}
// Map of dashboard values where the key is the month value (indexed from 0, i.e. 0 = January, 1 = February, etc..)
const monthlyVendorAnalytics = (state = { [new Date().getMonth()]: defaultMonthlyVendorAnalytics }, { type, payload }) => {
	switch (type) {
		case FETCH_MONTHLY_VENDOR_ANALYTICS_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultMonthlyVendorAnalytics
				return newState
			}
		}
		case FETCH_MONTHLY_VENDOR_ANALYTICS_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}

const defaultAnnualTrendsAnalytics = {
	monthlyData: [],
	shipperMonthlyData: [],
	routeCounts: [],
	loadingPointCounts: [],
	unloadingPointCounts: []
}
const annualTrendsAnalytics = (state = defaultAnnualTrendsAnalytics, { type, payload }) => {
	switch (type) {
		case FETCH_ANNUAL_TRENDS_ANALYTICS_SUCCESS:
			return payload
		default:
			return state
	}
}

const admin = combineReducers({
	shipments,
	requestedShipments,
	scheduledShipments,
	assignedShipments,
	unassignedShipments,
	activeShipments,
	completedShipments,
	cancelledShipments,
	expiredShipments,
	watchingShipments,
	users,
	reports,
	shipperUsers,
	carrierUsers,
	driverUsers,
	agentUsers,
	dispatcherUsers,
	rates,
	contractedRates,
	currentRate,
	transactions,
	bookings,
	requestedBookings,
	ongoingBookings,
	isFetching,
	boundingShipments,
	globalCounts,
	globalHistory,
	globalFares,
	rateHistories,
	isFetchingTransactions,
	boundingUsers,
	matchingRates,
	isSearchingRates,
	contracts,
	adminAssignedShipments,
	pendingContracts,
	bills,
	bill,
	isFetchingBills,
	contractedShippers,
	shipperContractRates,
	notifications,
	isFetchingMoreReports,
	isFetchingNotifications,
	isFetchingMoreNotifications,
	shipperHistories,
	recentlyActiveShippers,
	weeklyGlobalCounts,
	monthlyGlobalCounts,
	isMassAssigningShipments,
	revenueHistory,
	matchingBills,
	isSearchingBills,
	isLoadingMoreSearchedBills,
	allCompanies,
	companies,
	dashboard,
	dailyDashboard,
	annualDashboard,
	usersDashboard,
	eventsDashboard,
	matchingCompanies,
	isSearchingCompanies,
	isLoadingMoreSearchedCompanies,
	matchingContracts,
	isSearchingContracts,
	isLoadingMoreSearchedContracts,
	vendorRequests,
	dailySummary,
	monthlyGeneralAnalytics,
	monthlyOperationsAnalytics,
	monthlyVendorAnalytics,
	annualTrendsAnalytics,
	contractDetails,
	isFetchingContract
})

export default admin

export const getShipments = state => state.shipments
export const getRequestedShipments = state => state.requestedShipments
export const getScheduledShipments = state => state.scheduledShipments
export const getAssignedShipments = state => state.assignedShipments
export const getUnassignedShipments = state => state.unassignedShipments
export const getActiveShipments = state => state.activeShipments
export const getCompletedShipments = state => state.completedShipments
export const getCancelledShipments = state => state.cancelledShipments
export const getExpiredShipments = state => state.expiredShipments
export const getWatchingShipments = state => state.watchingShipments
export const getAdminAssignedShipments = state => state.adminAssignedShipments
export const getUsers = state => state.users
export const getShipperUsers = state => state.shipperUsers
export const getCarrierUsers = state => state.carrierUsers
export const getDriverUsers = state => state.driverUsers
export const getAgentUsers = state => state.agentUsers
export const getDispatcherUsers = state => state.dispatcherUsers
export const getRates = state => state.rates
export const getReports = state => state.reports
export const getContractedRates = state => state.contractedRates
export const getCurrentRate = state => state.currentRate
export const getTransactions = state => state.transactions
export const getBookings = state => state.bookings
export const getRequestedBookings = state => state.requestedBookings
export const getOngoingBookings = state => state.ongoingBookings
export const getIsFetching = state => state.isFetching
export const getBoundingShipments = state => state.boundingShipments
export const getGlobalCounts = state => state.globalCounts
export const getWeeklyGlobalCounts = state => state.weeklyGlobalCounts
export const getMonthlyGlobalCounts = state => state.monthlyGlobalCounts
export const getGlobalHistory = state => state.globalHistory
export const getGlobalFares = state => state.globalFares
export const getRateHistories = state => state.rateHistories
export const getIsFetchingTransactions = state => state.isFetchingTransactions
export const getBoundingUsers = state => state.boundingUsers
export const getMatchingRates = state => state.matchingRates
export const getIsSearchingRates = state => state.isSearchingRates
export const getContracts = state => state.contracts
export const getPendingContracts = state => state.pendingContracts
export const getBills = state => state.bills
export const getMatchingBills = state => state.matchingBills
export const getIsSearchingBills = state => state.isSearchingBills
export const getIsLoadingMoreSearchedBills = state => state.isLoadingMoreSearchedBills
export const getIsFetchingBills = state => state.isFetchingBills
export const getContractedShippers = state => state.contractedShippers
export const getShipperContractRates = state => state.shipperContractRates
export const getAdminNotifications = state => state.notifications
export const getIsFetchingAdminNotifications = state => state.isFetchingNotifications
export const getIsFetchingMoreReports = state => state.isFetchingMoreReports
export const getIsFetchingMoreAdminNotifications = state => state.isFetchingMoreNotifications
export const getShipperHistories = state => state.shipperHistories
export const getRecentlyActiveShippers = state => state.recentlyActiveShippers
export const getIsMassAssigningShipments = state => state.isMassAssigningShipments
export const getRevenueHistory = state => state.revenueHistory
export const getAllCompanies = state => state.allCompanies
export const getCompanies = state => state.companies
export const getDashboard = state => state.dashboard
export const getDailyDashboard = state => state.dailyDashboard
export const getAnnualDashboard = state => state.annualDashboard
export const getBill = state => state.bill
export const getUsersDashboard = state => state.usersDashboard
export const getEventsDashboard = state => state.eventsDashboard
export const getMatchingCompanies = state => state.matchingCompanies
export const getIsSearchingCompanies = state => state.isSearchingCompanies
export const getIsLoadingMoreSearchedCompanies = state => state.isLoadingMoreSearchedCompanies
export const getMatchingContracts = state => state.matchingContracts
export const getIsSearchingContracts = state => state.isSearchingContracts
export const getIsLoadingMoreSearchedContracts = state => state.isLoadingMoreSearchedContracts
export const getVendorRequests = state => state.vendorRequests
export const getDailySummary = state => state.dailySummary
export const getMonthlyGeneralAnalytics = state => state.monthlyGeneralAnalytics
export const getMonthlyOperationsAnalytics = state => state.monthlyOperationsAnalytics
export const getMonthlyVendorAnalytics = state => state.monthlyVendorAnalytics
export const getAnnualTrendsAnalytics = state => state.annualTrendsAnalytics
export const getContractDetails = state => state.contractDetails
export const getIsFetchingContract = state => state.isFetchingContract
