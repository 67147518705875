import React from 'react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { api } from '../../../utils/api'
import Configs from '../../../configs'
import i18next from 'i18next'

export const LocaleContext = React.createContext({
	isMapsInitialized: false
})

const withLocale = Component => {
	class WithLocale extends React.Component {

		constructor (props) {
			super(props)
			this.state = {
				currentLocale: i18next.language,
				isMapsInitialized: false
			}
		}

		componentDidMount () {
			this.loadMaps()
			this.setLanguageHeader()
		}

		componentDidUpdate (prevProps, prevState) {
			if (i18next.language !== prevState.currentLocale) {
				this.setState({ currentLocale: i18next.language })
			}
			if (this.state.currentLocale !== prevState.currentLocale) {
				this.reloadMaps()
				this.setLanguageHeader()
			}
		}

		loadMaps = () => {
			const existingScript = document.getElementById('googleMaps')
			if (!existingScript) {
				let languageCode = ''

				const script = document.createElement('script')
				switch (i18next.language) {
					case Configs.language.codes.BN:
						languageCode = Configs.language.codes.BN
						break
					case Configs.language.codes.EN:
					default:
						languageCode = Configs.language.codes.EN
						break
				}
				if (!this.initMapsPromise) {
					this.initMapsPromise = new Promise(resolve => {
						window.resolveGoogleMapsPromise = () => {
							this.setState({ isMapsInitialized: true })
							resolve(window.google)
						}
					})
				}
				let scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places,geometry&callback=resolveGoogleMapsPromise`
				if (languageCode) {
					scriptUrl = `${scriptUrl}&language=${languageCode}`
				}
				script.src = scriptUrl
				script.id = 'googleMaps'
				document.body.appendChild(script)
			}
		}

		reloadMaps = () => {
			const existingScript = document.getElementById('googleMaps')
			window.google = undefined
			if (existingScript) {
				existingScript.remove()
			}
			this.loadMaps()
		}

		setLanguageHeader = () => {
			switch (i18next.language) {
				case Configs.language.codes.BN:
					api.setHeader('Accept-Language', Configs.language.codes.BN)
					break
				case Configs.language.codes.EN:
				default:
					api.setHeader('Accept-Language', Configs.language.codes.EN)
					break
			}
		}

		render () {
			return (
				<LocaleContext.Provider value={this.state}>
					<Component {...this.props} />
				</LocaleContext.Provider>
			)
		}
	}

	return compose(
		withTranslation()
	)(WithLocale)
}

export default withLocale
