export const FETCH_CHATS_REQUEST = 'FETCH_CHATS_REQUEST'
export const FETCH_CHATS_SUCCESS = 'FETCH_CHATS_SUCCESS'
export const FETCH_CHATS_FAILURE = 'FETCH_CHATS_FAILURE'
export const FETCH_MORE_CHATS_REQUEST = 'FETCH_MORE_CHATS_REQUEST'
export const FETCH_MORE_CHATS_SUCCESS = 'FETCH_MORE_CHATS_SUCCESS'
export const FETCH_MORE_CHATS_FAILURE = 'FETCH_MORE_CHATS_FAILURE'
export const ADD_CHAT_SUCCESS = 'ADD_CHAT_SUCCESS'
export const UPDATE_CHAT_SUCCESS = 'UPDATE_CHAT_SUCCESS'
export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST'
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS'
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE'
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE'
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'
export const UPDATE_CHAT_COUNT_SUCCESS = 'UPDATE_CHAT_COUNT_SUCCESS'
export const FETCH_CONVERSATIONS_REQUEST = 'FETCH_CONVERSATIONS_REQUEST'
export const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS'
export const FETCH_CONVERSATIONS_FAILURE = 'FETCH_CONVERSATIONS_FAILURE'
export const FETCH_MORE_CONVERSATIONS_REQUEST = 'FETCH_MORE_CONVERSATIONS_REQUEST'
export const FETCH_MORE_CONVERSATIONS_SUCCESS = 'FETCH_MORE_CONVERSATIONS_SUCCESS'
export const FETCH_MORE_CONVERSATIONS_FAILURE = 'FETCH_MORE_CONVERSATIONS_FAILURE'
export const FETCH_CONVERSATION_MESSAGES_REQUEST = 'FETCH_CONVERSATION_MESSAGES_REQUEST'
export const FETCH_CONVERSATION_MESSAGES_SUCCESS = 'FETCH_CONVERSATION_MESSAGES_SUCCESS'
export const FETCH_CONVERSATION_MESSAGES_FAILURE = 'FETCH_CONVERSATION_MESSAGES_FAILURE'
export const FETCH_MORE_CONVERSATION_MESSAGES_REQUEST = 'FETCH_MORE_CONVERSATION_MESSAGES_REQUEST'
export const FETCH_MORE_CONVERSATION_MESSAGES_SUCCESS = 'FETCH_MORE_CONVERSATION_MESSAGES_SUCCESS'
export const FETCH_MORE_CONVERSATION_MESSAGES_FAILURE = 'FETCH_MORE_CONVERSATION_MESSAGES_FAILURE'
export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST'
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAILURE = 'CREATE_CONVERSATION_FAILURE'
export const CREATE_CONVERSATION_MESSAGE_REQUEST = 'CREATE_CONVERSATION_MESSAGE_REQUEST'
export const CREATE_CONVERSATION_MESSAGE_SUCCESS = 'CREATE_CONVERSATION_MESSAGE_SUCCESS'
export const CREATE_CONVERSATION_MESSAGE_FAILURE = 'CREATE_CONVERSATION_MESSAGE_FAILURE'
export const UPDATE_CONVERSATION_COUNT_SUCCESS = 'UPDATE_CONVERSATION_COUNT_SUCCESS'
export const ADD_CONVERSATION_MESSAGE_SUCCESS = 'ADD_CONVERSATION_MESSAGE_SUCCESS'
export const UPDATE_CONVERSATION_SUCCESS = 'UPDATE_CONVERSATION_SUCCESS'
export const ADD_CONVERSATION_SUCCESS = 'ADD_CONVERSATION_SUCCESS'
