export const FETCH_TRUCKS_REQUEST = 'FETCH_TRUCKS_REQUEST'
export const FETCH_TRUCKS_SUCCESS = 'FETCH_TRUCKS_SUCCESS'
export const FETCH_TRUCKS_FAILURE = 'FETCH_TRUCKS_FAILURE'
export const FETCH_MORE_TRUCKS_REQUEST = 'FETCH_MORE_TRUCKS_REQUEST'
export const FETCH_MORE_TRUCKS_SUCCESS = 'FETCH_MORE_TRUCKS_SUCCESS'
export const FETCH_MORE_TRUCKS_FAILURE = 'FETCH_MORE_TRUCKS_FAILURE'
export const FETCH_ACTIVATED_TRUCKS_REQUEST = 'FETCH_ACTIVATED_TRUCKS_REQUEST'
export const FETCH_ACTIVATED_TRUCKS_SUCCESS = 'FETCH_ACTIVATED_TRUCKS_SUCCESS'
export const FETCH_ACTIVATED_TRUCKS_FAILURE = 'FETCH_ACTIVATED_TRUCKS_FAILURE'
export const FETCH_MORE_ACTIVATED_TRUCKS_REQUEST = 'FETCH_MORE_ACTIVATED_TRUCKS_REQUEST'
export const FETCH_MORE_ACTIVATED_TRUCKS_SUCCESS = 'FETCH_MORE_ACTIVATED_TRUCKS_SUCCESS'
export const FETCH_MORE_ACTIVATED_TRUCKS_FAILURE = 'FETCH_MORE_ACTIVATED_TRUCKS_FAILURE'
export const FETCH_DEACTIVATED_TRUCKS_REQUEST = 'FETCH_DEACTIVATED_TRUCKS_REQUEST'
export const FETCH_DEACTIVATED_TRUCKS_SUCCESS = 'FETCH_DEACTIVATED_TRUCKS_SUCCESS'
export const FETCH_DEACTIVATED_TRUCKS_FAILURE = 'FETCH_DEACTIVATED_TRUCKS_FAILURE'
export const FETCH_MORE_DEACTIVATED_TRUCKS_REQUEST = 'FETCH_MORE_DEACTIVATED_TRUCKS_REQUEST'
export const FETCH_MORE_DEACTIVATED_TRUCKS_SUCCESS = 'FETCH_MORE_DEACTIVATED_TRUCKS_SUCCESS'
export const FETCH_MORE_DEACTIVATED_TRUCKS_FAILURE = 'FETCH_MORE_DEACTIVATED_TRUCKS_FAILURE'
export const ADD_TRUCK_REQUEST = 'ADD_TRUCK_REQUEST'
export const ADD_TRUCK_SUCCESS = 'ADD_TRUCK_SUCCESS'
export const ADD_TRUCK_FAILURE = 'ADD_TRUCK_FAILURE'
export const DELETE_TRUCK_REQUEST = 'DELETE_TRUCK_REQUEST'
export const DELETE_TRUCK_SUCCESS = 'DELETE_TRUCK_SUCCESS'
export const DELETE_TRUCK_FAILURE = 'DELETE_TRUCK_FAILURE'
export const UPDATE_TRUCK_REQUEST = 'UPDATE_TRUCK_REQUEST'
export const UPDATE_TRUCK_SUCCESS = 'UPDATE_TRUCK_SUCCESS'
export const UPDATE_TRUCK_FAILURE = 'UPDATE_TRUCK_FAILURE'
export const ASSIGN_TO_TRUCK_REQUEST = 'ASSIGN_TO_TRUCK_REQUEST'
export const ASSIGN_TO_TRUCK_SUCCESS = 'ASSIGN_TO_TRUCK_SUCCESS'
export const ASSIGN_TO_TRUCK_FAILURE = 'ASSIGN_TO_TRUCK_FAILURE'
export const UNASSIGN_FROM_TRUCK_REQUEST = 'UNASSIGN_FROM_TRUCK_REQUEST'
export const UNASSIGN_FROM_TRUCK_SUCCESS = 'UNASSIGN_FROM_TRUCK_SUCCESS'
export const UNASSIGN_FROM_TRUCK_FAILURE = 'UNASSIGN_FROM_TRUCK_FAILURE'
export const SEARCH_TRUCKS_REQUEST = 'SEARCH_TRUCKS_REQUEST'
export const SEARCH_TRUCKS_SUCCESS = 'SEARCH_TRUCKS_SUCCESS'
export const SEARCH_TRUCKS_FAILURE = 'SEARCH_TRUCKS_FAILURE'
export const SEARCH_MORE_TRUCKS_REQUEST = 'SEARCH_MORE_TRUCKS_REQUEST'
export const SEARCH_MORE_TRUCKS_SUCCESS = 'SEARCH_MORE_TRUCKS_SUCCESS'
export const SEARCH_MORE_TRUCKS_FAILURE = 'SEARCH_MORE_TRUCKS_FAILURE'
export const FETCH_AVAILABLE_TRUCKS_REQUEST = 'FETCH_AVAILABLE_TRUCKS_REQUEST'
export const FETCH_AVAILABLE_TRUCKS_SUCCESS = 'FETCH_AVAILABLE_TRUCKS_SUCCESS'
export const FETCH_AVAILABLE_TRUCKS_FAILURE = 'FETCH_AVAILABLE_TRUCKS_FAILURE'
export const FETCH_MORE_AVAILABLE_TRUCKS_REQUEST = 'FETCH_MORE_AVAILABLE_TRUCKS_REQUEST'
export const FETCH_MORE_AVAILABLE_TRUCKS_SUCCESS = 'FETCH_MORE_AVAILABLE_TRUCKS_SUCCESS'
export const FETCH_MORE_AVAILABLE_TRUCKS_FAILURE = 'FETCH_MORE_AVAILABLE_TRUCKS_FAILURE'
export const FETCH_UNAVAILABLE_TRUCKS_REQUEST = 'FETCH_UNAVAILABLE_TRUCKS_REQUEST'
export const FETCH_UNAVAILABLE_TRUCKS_SUCCESS = 'FETCH_UNAVAILABLE_TRUCKS_SUCCESS'
export const FETCH_UNAVAILABLE_TRUCKS_FAILURE = 'FETCH_UNAVAILABLE_TRUCKS_FAILURE'
export const FETCH_MORE_UNAVAILABLE_TRUCKS_REQUEST = 'FETCH_MORE_UNAVAILABLE_TRUCKS_REQUEST'
export const FETCH_MORE_UNAVAILABLE_TRUCKS_SUCCESS = 'FETCH_MORE_UNAVAILABLE_TRUCKS_SUCCESS'
export const FETCH_MORE_UNAVAILABLE_TRUCKS_FAILURE = 'FETCH_MORE_UNAVAILABLE_TRUCKS_FAILURE'
