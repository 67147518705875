import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Routes } from '../../../constants/routes'
import { connect } from 'react-redux'
import { userActions } from '../../../redux-modules/user'
import { shipmentActions } from '../../../redux-modules/shipments'
import { truckActions } from '../../../redux-modules/trucks'
import { adminActions } from '../../../redux-modules/admin'
import {
	SHIPMENT_CATEGORY,
	TRUCK_CATEGORY,
	DRIVER_CATEGORY,
	CONTRACT_PENDING_STATUS,
	CONTRACT_ACTIVE_STATUS,
	CONTRACT_INACTIVE_STATUS,
	CONTRACT_EXPIRED_STATUS
} from '../../../constants/strings'

export const SearchContext = React.createContext()

const defaultSearchParams = {
	searchTerm: '',
	hasPerformedSearch: false,
	rateSearchParams: { truckType: null, from: true, to: true },
	userTypeParams: { shipper: false, carrier: false, carrier_driver: false, dispatcher: false },
	userStatusParams: { disabled: false },
	contractStatusParams: {
		active: false,
		inactive: false,
		expired: false
	},
	contractTypeParams: {
		per_tonne: false,
		per_trip: false
	},
	shipmentSearchParams: {
		active: false,
		scheduled: false,
		requested: false,
		completed: false,
		cancelled: false,
		expired: false
	},
	paymentStatusParams: {
		processing: false,
		pending: false,
		paid: false,
		cancelled: false
	},
	billStatusParams: {
		paid: false,
		waived: false,
		in_progress: false,
		due: false
	},
	paymentMethodParams: {
		credit_line: false,
		cash: false
	},
	dateRangeParams: {
		from: '',
		to: ''
	},
	tripDateRangeParams: {
		from: '',
		to: ''
	},
	transactionDateRangeParams: {
		from: '',
		to: ''
	},
	submissionDateRangeParams: {
		from: '',
		to: ''
	},
	shipmentCarrierSearchParams: {
		jobs: false,
		assigned: false,
		completed: false
	},
	truckSearchParams: {
		activated: false,
		deactivated: false
	},
	driverSearchParams: {
		approved: false,
		declined: false,
		inactive: false,
		busy: false,
		free: false
	},
	truckType: null,
	searchCategory: '',
	truckTypes: [],
	itemTypes: []
}

const withSearch = Component => {
	class WithSearch extends React.Component {

		constructor (props) {
			super(props)
			this.state = defaultSearchParams
		}

		componentDidUpdate (prevProps) {
			if (prevProps.location.pathname !== this.props.location.pathname) {
				this.clearSearch()
			}
		}

		getDateRangeInSeconds = (dateRangeParam) => {
			const { from , to } = dateRangeParam
			let dateRange = ''
			if (typeof from.valueOf() === 'number' && typeof to.valueOf() === 'number') {
				const fromDateInSecond = Math.round(from.valueOf()/1000)
				const toDateInSecond = Math.round(to.valueOf()/1000)
				dateRange = [fromDateInSecond, toDateInSecond].join(',')
			}
			return dateRange
		}

		serialize = (object) => this.getSearchableParams(object).join(',')

		getSearchableParams = (object) => {
			if (!object) return []
			return Object.keys(object).filter(key => object[key])
		}

		getTruckTypes = () => {
			return this.state.truckTypes
				.map(truckType => truckType.value)
				.join(',')
		}

		getItemTypes = () => {
			return this.state.itemTypes
				.map(itemType => itemType.value)
				.join(',')
		}

		onPerformSearch = (searchTerm, pageNumber = null) => {
			this.setState({ searchTerm, hasPerformedSearch: true })
			switch (this.props.location.pathname) {
				case Routes.ADMIN_SHIPMENTS_PAGE(): {
					const status = this.serialize(this.state.shipmentSearchParams)
					const truckTypes = this.getTruckTypes()
					const itemTypes = this.getItemTypes()
					const tripDateRange = this.getDateRangeInSeconds(this.state.tripDateRangeParams)
					const filters = { status, truckTypes, itemTypes, tripDateRange }
					this.props.searchShipments(searchTerm, filters, pageNumber)
					break
				}
				case Routes.ADMIN_USERS_PAGE(): {
					const type = this.serialize(this.state.userTypeParams)
					const { disabled } = this.state.userStatusParams
					this.props.searchUsers(searchTerm, type, disabled, pageNumber)
					break
				}
				case Routes.ADMIN_TRUCKS_PAGE(): {
					let truckType
					if (this.state.truckType) {
						truckType = this.state.truckType.value
					}
					this.props.searchTrucks(searchTerm, truckType, pageNumber)
					break
				}
				case Routes.ADMIN_COMPANIES_PAGE():
					this.props.searchCompanies(searchTerm, pageNumber)
					break
				case Routes.ADMIN_RATES_PAGE(): {
					const { truckType, from, to } = this.state.rateSearchParams
					let truckTypeValue
					if (truckType) {
						truckTypeValue = truckType.value
					}
					this.props.searchRates(searchTerm, truckTypeValue, from, to)
					break
				}
				case Routes.SHIPPER_PAGE(): {
					const status = this.serialize(this.state.shipmentSearchParams)
					const tripDateRange = this.getDateRangeInSeconds(this.state.tripDateRangeParams)
					const truckTypes = this.getTruckTypes()
					const itemTypes = this.getItemTypes()
					const filters = { status, tripDateRange, truckTypes, itemTypes }
					this.props.searchShipperShipments(searchTerm, pageNumber, filters)
					break
				}
				case Routes.SHIPPER_BOOKINGS_PAGE(): {
					const dateRange = this.getDateRangeInSeconds(this.state.dateRangeParams)
					const truckTypes = this.getTruckTypes()
					const itemTypes = this.getItemTypes()
					const filters = { dateRange, truckTypes, itemTypes }
					this.props.searchShipperBookings(searchTerm, pageNumber, filters)
					break
				}
				case Routes.ADMIN_HOME_PAGE():
				case Routes.ADMIN_BOOKINGS_PAGE('requested'):
				case Routes.ADMIN_BOOKINGS_PAGE('ongoing'):
				case Routes.ADMIN_BOOKINGS_PAGE(): {
					const dateRange = this.getDateRangeInSeconds(this.state.dateRangeParams)
					const truckTypes = this.getTruckTypes()
					const itemTypes = this.getItemTypes()
					const filters = { dateRange, truckTypes, itemTypes }
					this.props.searchBookings(searchTerm, pageNumber, filters)
					break
				}
				case Routes.ADMIN_CONTRACTS_PAGE(): {
					const types = this.serialize(this.state.contractTypeParams)
					let statuses = this.serialize(this.state.contractStatusParams)
					statuses = statuses ? statuses : `${CONTRACT_ACTIVE_STATUS},${CONTRACT_INACTIVE_STATUS},${CONTRACT_EXPIRED_STATUS}`
					const filters = { statuses, types }
					this.props.searchContracts(searchTerm, filters, pageNumber)
					break
				}
				case Routes.ADMIN_QUOTATIONS_PAGE(): {
					const types = this.serialize(this.state.contractTypeParams)
					const filters = { statuses: CONTRACT_PENDING_STATUS, types }
					this.props.searchContracts(searchTerm, filters, pageNumber)
					break
				}
				case Routes.SHIPPER_BILLING_PAGE('bills'): {
					const {
						tripDateRangeParams,
						submissionDateRangeParams,
						transactionDateRangeParams,
						billStatusParams
					} = this.state
					const tripDateRange = this.getDateRangeInSeconds(tripDateRangeParams)
					const transactionDateRange = this.getDateRangeInSeconds(transactionDateRangeParams)
					const submissionDateRange = this.getDateRangeInSeconds(submissionDateRangeParams)
					const statuses = this.serialize(billStatusParams)
					const filters = { submissionDateRange, transactionDateRange, tripDateRange, statuses }
					this.props.searchShipperBills(searchTerm, pageNumber, filters)
					break
				}
				case Routes.CARRIER_MAP_PAGE():
				case Routes.CARRIER_LIST_PAGE(): {
					switch (this.state.searchCategory) {
						case SHIPMENT_CATEGORY: {
							const status = this.getSearchableParams(this.state.shipmentCarrierSearchParams)
								.map(param => {
									if (param === 'jobs') {
										return 'scheduled'
									} else if (param === 'assigned') {
										return 'active'
									}
									return param
								}).join(',')
							this.props.searchCarrierShipments(searchTerm, null, status)
							break
						}
						case DRIVER_CATEGORY: {
							let status = []
							let driverStatus = []
							this.getSearchableParams(this.state.driverSearchParams)
								.forEach(param => {
									if (param === 'approved' || param === 'declined') {
										status.push(param)
									} else {
										driverStatus.push(param)
									}
								})
							this.props.searchCarrierDrivers(searchTerm, null, status.join(','), driverStatus.join(','))
							break
						}
						case TRUCK_CATEGORY: {
							const status = this.serialize(this.state.truckSearchParams)
							this.props.searchCarrierTrucks(searchTerm, null, status)
							break
						}
						default:
							break
					}
					break
				}
				default: {
					if (this.props.location.pathname.startsWith(Routes.ADMIN_SHIPMENTS_PAGE())) {
						const status = this.serialize(this.state.shipmentSearchParams)
						const truckTypes = this.getTruckTypes()
						const itemTypes = this.getItemTypes()
						const tripDateRange = this.getDateRangeInSeconds(this.state.tripDateRangeParams)
						const filters = { status, truckTypes, itemTypes, tripDateRange }
						this.props.searchShipments(searchTerm, filters, pageNumber)
					} else if (this.props.location.pathname.startsWith(Routes.SHIPPER_SHIPMENTS_PAGE(''))) {
						const status = this.serialize(this.state.shipmentSearchParams)
						const tripDateRange = this.getDateRangeInSeconds(this.state.tripDateRangeParams)
						const truckTypes = this.getTruckTypes()
						const itemTypes = this.getItemTypes()
						const filters = { status, tripDateRange, truckTypes, itemTypes }
						this.props.searchShipperShipments(searchTerm, pageNumber, filters)
					} else if (this.props.location.pathname.startsWith(Routes.ADMIN_ACCOUNTING_PAGE())) {
						if (this.props.location.pathname === Routes.ADMIN_ACCOUNTING_PAGE('billing')) {
							const {
								tripDateRangeParams,
								submissionDateRangeParams,
								transactionDateRangeParams,
								billStatusParams
							} = this.state
							const tripDateRange = this.getDateRangeInSeconds(tripDateRangeParams)
							const transactionDateRange = this.getDateRangeInSeconds(transactionDateRangeParams)
							const submissionDateRange = this.getDateRangeInSeconds(submissionDateRangeParams)
							const statuses = this.serialize(billStatusParams)
							const filters = { submissionDateRange, transactionDateRange, tripDateRange, statuses }
							this.props.searchBills(searchTerm, pageNumber, filters)
						} else {
							const shipmentStatus = this.serialize(this.state.shipmentSearchParams)
							const paymentStatus = this.serialize(this.state.paymentStatusParams)
							const paymentMethod = this.serialize(this.state.paymentMethodParams)
							const dateRange = this.getDateRangeInSeconds(this.state.tripDateRangeParams)
							this.props.searchTransactions(
								searchTerm,
								shipmentStatus,
								paymentStatus,
								paymentMethod,
								dateRange
							)
						}
					} else if (this.props.location.pathname.startsWith(Routes.ADMIN_USERS_PAGE())) {
						const type = this.serialize(this.state.userTypeParams)
						const { disabled } = this.state.userStatusParams
						this.props.searchUsers(searchTerm, type, disabled, pageNumber)
					}
					break
				}
			}
		}

		onSetSearchCategory = (searchCategory) => {
			this.setState({ searchCategory })
		}

		onSetSearchTerm = (searchTerm) => {
			this.setState({ searchTerm })
		}

		onSetRateSearchParameters = (rateSearchParams) => {
			this.setState({ rateSearchParams })
		}

		onSetUserTypeParameters = (userTypeParams) => {
			this.setState({ userTypeParams })
		}

		onSetUserStatusParams = (userStatusParams) => {
			this.setState({ userStatusParams })
		}

		onSetContractStatusParams = (contractStatusParams) => {
			this.setState({ contractStatusParams })
		}

		onSetContractTypeParams = (contractTypeParams) => {
			this.setState({ contractTypeParams })
		}

		onSetTruckType = (truckType) => {
			this.setState({ truckType })
		}

		onSetShipmentSearchParameters = (searchParams, index) => {
			switch (index) {
				case 0:
					this.setState({ shipmentSearchParams: searchParams })
					break
				case 1:
					this.setState({ paymentStatusParams: searchParams })
					break
				case 2:
					this.setState({ paymentMethodParams: searchParams })
					break
				default:
					break
			}
		}

		onSetShipmentCarrierSearchParameters = (searchParams) => {
			switch (this.state.searchCategory) {
				case SHIPMENT_CATEGORY:
					this.setState({ shipmentCarrierSearchParams: searchParams })
					break
				case DRIVER_CATEGORY:
					this.setState({ driverSearchParams: searchParams })
					break
				case TRUCK_CATEGORY:
					this.setState({ truckSearchParams: searchParams })
					break
				default:
					break
			}
		}

		onSetBillStatusParams = (billStatusParams) => {
			this.setState({ billStatusParams })
		}

		onSetDateRange = (date, stateName) => {
			this.setState({ dateRangeParams: { ...this.state.dateRangeParams, [stateName]: date } })
		}

		onSetTripDateRange = (date, stateName) => {
			this.setState({ tripDateRangeParams: { ...this.state.tripDateRangeParams, [stateName]: date } })
		}

		onSetSubmissionDateRange = (date, stateName) => {
			this.setState({ submissionDateRangeParams: { ...this.state.submissionDateRangeParams, [stateName]: date } })
		}

		onSetTransactionDateRange = (date, stateName) => {
			this.setState({ transactionDateRangeParams: { ...this.state.transactionDateRangeParams, [stateName]: date } })
		}

		clearSearch = () => {
			this.setState(defaultSearchParams)
		}

		onSetTruckTypes = (truckTypes) => {
			this.setState({ truckTypes })
		}

		onSetItemTypes = (itemTypes) => {
			this.setState({ itemTypes })
		}

		onSetHasPerformedSearch = (hasPerformedSearch) => {
			this.setState({ hasPerformedSearch })
		}

		render () {
			const {
				onPerformSearch,
				onSetRateSearchParameters,
				onSetUserTypeParameters,
				onSetShipmentSearchParameters,
				onSetTripDateRange,
				onSetSearchCategory,
				onSetShipmentCarrierSearchParameters,
				clearSearch,
				onSetSubmissionDateRange,
				onSetTransactionDateRange,
				onSetItemTypes,
				onSetTruckTypes,
				onSetDateRange,
				onSetSearchTerm,
				onSetBillStatusParams,
				onSetUserStatusParams,
				onSetTruckType,
				onSetContractStatusParams,
				onSetContractTypeParams,
				onSetHasPerformedSearch
			} = this
			return (
				<SearchContext.Provider value={{
					...this.state,
					onPerformSearch,
					onSetRateSearchParameters,
					onSetShipmentSearchParameters,
					onSetUserTypeParameters,
					onSetTripDateRange,
					onSetSearchCategory,
					onSetShipmentCarrierSearchParameters,
					clearSearch,
					onSetSubmissionDateRange,
					onSetTransactionDateRange,
					onSetItemTypes,
					onSetTruckTypes,
					onSetDateRange,
					onSetSearchTerm,
					onSetBillStatusParams,
					onSetUserStatusParams,
					onSetTruckType,
					onSetContractStatusParams,
					onSetContractTypeParams,
					onSetHasPerformedSearch
				}}
				>
					<Component {...this.props} />
				</SearchContext.Provider>
			)
		}
	}

	WithSearch.propTypes = {
		location: PropTypes.object,
		history: PropTypes.object,
		searchShipments: PropTypes.func,
		searchTransactions: PropTypes.func,
		searchUsers: PropTypes.func,
		searchTrucks: PropTypes.func,
		searchRates: PropTypes.func,
		searchShipperShipments: PropTypes.func,
		searchCarrierShipments: PropTypes.func,
		searchCarrierDrivers: PropTypes.func,
		searchCarrierTrucks: PropTypes.func,
		searchBills: PropTypes.func,
		searchShipperBookings: PropTypes.func,
		searchShipperBills: PropTypes.func,
		searchBookings: PropTypes.func,
		searchCompanies: PropTypes.func,
		searchContracts: PropTypes.func
	}

	const mapDispatchToProps = {
		searchShipments: shipmentActions.searchShipments,
		searchTransactions: shipmentActions.searchTransactions,
		searchUsers: userActions.searchUsers,
		searchTrucks: truckActions.searchTrucks,
		searchRates: adminActions.searchRates,
		searchShipperShipments: shipmentActions.searchShipperShipments,
		searchCarrierShipments: shipmentActions.searchCarrierShipments,
		searchShipperBookings: shipmentActions.searchShipperBookings,
		searchCarrierDrivers: userActions.searchDrivers,
		searchCarrierTrucks: truckActions.searchCarrierTrucks,
		searchBills: adminActions.searchBills,
		searchShipperBills: userActions.searchShipperBills,
		searchBookings: shipmentActions.searchBookings,
		searchCompanies: adminActions.searchCompanies,
		searchContracts: adminActions.searchContracts
	}

	return compose(
		withRouter,
		connect(null, mapDispatchToProps)
	)(WithSearch)
}

export default withSearch
