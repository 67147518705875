import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Routes } from "../../constants/routes";
import "./app.scss";
import withAuthentication from "../components/session/withAuthentication";
import withLocale from "../components/session/withLocale";
import withConversation from "../components/session/withConversation";
import withPermissions from "../components/session/withPermissions";
import withSearch from "../components/session/withSearch";
import { ToastContainer } from "react-toastify";
import { compose } from "recompose";
import "react-toastify/dist/ReactToastify.css";
// import * as Sentry from "@sentry/react";
import LoadingPage from "../pages/loading";
// import ErrorBoundary from "../components/error-boundary";

// Sentry.init({
// 	dsn: "https://855c8865887348f98515a0e97ba23f2d@o431355.ingest.sentry.io/5382209",
// 	environment: process.env.NODE_ENV,
// });

const HomePage = React.lazy(() => import("../pages/home"));
const LoginPage = React.lazy(() => import("../pages/login"));
const SignUpPage = React.lazy(() => import("../pages/sign-up"));
const CreateBookingPage = React.lazy(() => import("../pages/create-booking"));
const ShipmentDetailPage = React.lazy(() => import("../pages/shipment-detail"));
const CarrierPage = React.lazy(() => import("../pages/carrier"));
const AdminPage = React.lazy(() => import("../pages/admin"));
const AdminMapPage = React.lazy(() => import("../pages/admin-map"));
const ErrorPage = React.lazy(() => import("../pages/error"));
const BookingDetailPage = React.lazy(() => import("../pages/booking-detail"));
const AdminUserPage = React.lazy(() => import("../pages/admin-user"));
const TermsAndConditionsPage = React.lazy(() =>
	import("../pages/terms-and-conditions")
);
const StatsPage = React.lazy(() => import("../pages/stats"));
const TransactionPage = React.lazy(() => import("../pages/transaction"));
const BillPage = React.lazy(() => import("../pages/bill"));
const NotificationsPage = React.lazy(() => import("../pages/notifications"));
const ShipperPage = React.lazy(() => import("../pages/shipper"));
const LocationPage = React.lazy(() => import("../pages/location"));
const ConversationsPage = React.lazy(() => import("../pages/conversations"));
const CompanyPage = React.lazy(() => import("../pages/company"));
const ProfilePage = React.lazy(() => import("../pages/profile"));
const ResetPasswordPage = React.lazy(() => import("../pages/reset-password"));
const CreateQuotationPage = React.lazy(() =>
	import("../pages/create-quotation")
);
const ContractDetailPage = React.lazy(() => import("../pages/contract-detail"));

export class App extends Component {
	render() {
		return (
			// <Sentry.ErrorBoundary fallback={ErrorBoundary}>
			<main className="app">
				<React.Suspense fallback={<LoadingPage />}>
					<Switch>
						<Route exact path={"/"} component={HomePage} />
						<Route exact path={Routes.HOME_PAGE()} component={HomePage} />
						<Route exact path={Routes.LOGIN_PAGE()} component={LoginPage} />
						<Route
							path={Routes.ADMIN_USER_PAGE(":id")}
							component={AdminUserPage}
						/>
						<Route path={Routes.PROFILE_PAGE(":id?")} component={ProfilePage} />
						<Route path={Routes.COMPANY_PAGE(":id")} component={CompanyPage} />
						<Route path={Routes.ADMIN_HOME_PAGE()} component={AdminPage} />
						<Route path={Routes.ADMIN_MAP_PAGE()} component={AdminMapPage} />
						<Route
							path={Routes.TRANSACTION_PAGE(":id")}
							component={TransactionPage}
						/>
						<Route path={Routes.BILL_PAGE(":id")} component={BillPage} />
						<Route path={Routes.SIGN_UP_PAGE()} component={SignUpPage} />
						<Route path={Routes.SHIPPER_PAGE()} component={ShipperPage} />
						<Route
							path={Routes.SHIPPER_SHIPMENTS_PAGE(":status")}
							component={ShipperPage}
						/>
						<Route
							path={Routes.CREATE_BOOKING_PAGE(":id?")}
							component={CreateBookingPage}
						/>
						<Route
							path={Routes.SHIPMENT_DETAIL_PAGE(":id")}
							component={ShipmentDetailPage}
						/>
						<Route
							path={Routes.BOOKING_DETAIL_PAGE(":id")}
							component={BookingDetailPage}
						/>
						<Route path={Routes.CARRIER_PAGE()} component={CarrierPage} />
						<Route
							path={Routes.NOTIFICATIONS_PAGE()}
							component={NotificationsPage}
						/>
						<Route path={Routes.STATS_PAGE()} component={StatsPage} />
						<Route
							path={Routes.TERMS_AND_CONDITIONS_PAGE()}
							component={TermsAndConditionsPage}
						/>
						<Route
							path={Routes.LOCATION_PAGE(":id")}
							component={LocationPage}
						/>
						<Route
							exact
							path={Routes.CONVERSATIONS_PAGE()}
							component={ConversationsPage}
						/>
						<Route
							exact
							path={Routes.CONVERSATIONS_PAGE(":conversationId")}
							component={ConversationsPage}
						/>
						<Route
							path={Routes.ACCOUNT_MANAGEMENT_PAGE()}
							component={ResetPasswordPage}
						/>
						<Route
							exact
							path={Routes.CREATE_QUOTATION_PAGE(":id?")}
							component={CreateQuotationPage}
						/>
						<Route
							exact
							path={Routes.CONTRACT_DETAIL_PAGE(":id")}
							component={ContractDetailPage}
						/>
						<Route component={ErrorPage} />
					</Switch>
				</React.Suspense>
				<ToastContainer
					draggable
					newestOnTop
					closeOnClick
					pauseOnHover
					hideProgressBar
					pauseOnFocusLoss
					autoClose={3000}
					position="bottom-left"
				/>
			</main>
			// </Sentry.ErrorBoundary>
		);
	}
}

export default compose(
	withRouter,
	withLocale,
	withAuthentication,
	withPermissions,
	withConversation,
	withSearch
)(App);
