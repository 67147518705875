import { create } from 'apisauce'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import firebase from 'firebase/app'

const BASE_URL = process.env.REACT_APP_BACKEND_URL
const api = create({
	baseURL: BASE_URL
})
api.addMonitor(() => {
	const isOnline = navigator.onLine
	if (!isOnline) {
		toast.error(i18n.t('noInternetConnection'), { toastId: 502 })
	}
})

const PUBLIC_USERS_PATH = '/public/users'
const USERS_PATH = '/users'
const DRIVERS_PATH = '/drivers'
const TRUCKS_PATH = '/trucks'
const SHIPMENTS_PATH = '/shipments'
const TRANSACTIONS_PATH = '/transactions'
const BOOKINGS_PATH = '/bookings'
const DELIVERY_LOCATIONS_PATH = '/contacts'
const CARRIER_PATH = '/carrier'
const SHIPPER_PATH = '/shipper'
const LOCALITY_PATH = '/locality'
const RATES_PATH = '/rates'
const STATUS_PATH = '/status'
const ADMIN_PATH = '/admin'
const LOCATION_PATH = '/location'
const DISPATCHERS_PATH = '/dispatchers'
const LOADBOARD_PATH = '/loadboard'
const FILTER_PATH = '/filter'
const DRIVER_PATH = '/driver'
const BANKS_PATH = '/banks'
const SEARCH_PATH = '/search'
const ANALYTICS_PATH = '/analytics'
const MESSAGES_PATH = '/messages'
const CHATS_PATH = '/chats'
const HISTORY_PATH = '/history'
const COUNTS_PATH = '/counts'
const FARES_PATH = '/fares'
const ATTACHMENTS_PATH = '/attachments'
const NOTIFICATIONS_PATH = '/notifications'
const AGENTS_PATH = '/agents'
const CARRIERS_PATH = '/carriers'
const OPTIONS_PATH = '/options'
const TRUCK_TYPES_PATH = '/truck-types'
const CARRYING_ZONES_PATH = '/carrying-zones'
const WEIGHING_LOCATIONS_PATH = '/weighing-locations'
const ITEM_TYPES_PATH = '/item-types'
const EVENTS_PATH = '/events'
const NOTES_PATH = '/notes'
const CONTRACTS_PATH = '/contracts'
const WATCHING_PATH = '/watching'
const ASSIGNED_PATH = '/assigned'
const SUBSCRIBERS_PATH = '/subscribers'
const TRUCK_BRANDS_MODELS_PATH = '/truck-brands-models'
const BILLS_PATH = '/bills'
const VENDOR_REQUESTS_PATH = '/vendor-requests'
const LOCATIONS_PATH = '/locations'
const CONVERSATIONS_PATH = '/conversations'
const COMPANIES_PATH = '/companies'
const REPORTS_PATH = '/reports'
const DASHBOARD_PATH = '/dashboard'
const CONFIG_PATH = '/configs'

function processResponse (response, method, uri) {
	return new Promise((resolve, reject) => {
		if (response.status >= 200 && response.status < 400) {
			const returnValue = response.data
			/** Note: In case of success, the API calls currently do not respond with a message in data */
			resolve(returnValue)
		} else if (response.status === 401) {
			reject({ message: 'You are unauthenticated, please log in again.', shouldTriggerLogout: true })
		} else {
			if (response.data && response.data.message) {
				toast.error(response.data.message)
				switch (process.env.NODE_ENV) {
					case 'development':
					case 'test':
						console.error(`HTTP ${method} call to ${uri} failed with response code ${response.status}: ${JSON.stringify(response.data)}`)
						break
					default:
						break
				}
				reject({ ...response.data, shouldTriggerLogout: false, statusCode: response.status })
			} else {
				toast.error('An unexpected error occurred.')
				console.error(`HTTP ${method} call to ${uri} failed with response code ${response.status}`)
				reject({ message: 'An unexpected error occurred.', shouldTriggerLogout: false })
			}
		}
	})
}

const API = {
	getTruckBrandsModels: async () => {
		const path = `${OPTIONS_PATH}${TRUCK_BRANDS_MODELS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getTruckTypes: async (language) => {
		const path = `${OPTIONS_PATH}${TRUCK_TYPES_PATH}`
		const response = await api.get(path, {
			language
		})
		return processResponse(response, 'get', path)
	},

	getItemTypes: async () => {
		const path = `${OPTIONS_PATH}${ITEM_TYPES_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getBankOptions: async () => {
		const path = `${OPTIONS_PATH}${BANKS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	doesUserExist: async (phoneNumber) => {
		const path = `${PUBLIC_USERS_PATH}?phoneNumber=${phoneNumber}` // Workaround to prevent URL encoding
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	signUp: async (userData) => {
		const path = `${PUBLIC_USERS_PATH}`
		const response = await api.post(path, userData)
		return processResponse(response, 'post', path)
	},

	signUpDispatcher: async (userData) => {
		const path = `${ADMIN_PATH}${USERS_PATH}${DISPATCHERS_PATH}`
		const response = await api.post(path, userData)
		return processResponse(response, 'post', path)
	},

	signUpAgent: async (userData) => {
		const path = `${ADMIN_PATH}${USERS_PATH}${AGENTS_PATH}`
		const response = await api.post(path, userData)
		return processResponse(response, 'post', path)
	},

	signUpManager: async (id, userData) => {
		const path = `${COMPANIES_PATH}/${id}${USERS_PATH}`
		const response = await api.post(path, userData)
		return processResponse(response, 'post', path)
	},

	updateCompanyUser: async (id, uid, userData) => {
		const path = `${COMPANIES_PATH}/${id}${USERS_PATH}/${uid}`
		const response = await api.patch(path, userData)
		return processResponse(response, 'patch', path)
	},

	updateCompanyConfig: async (id, configData) => {
		const path = `${SHIPPER_PATH}${COMPANIES_PATH}/${id}${CONFIG_PATH}`
		const response = await api.patch(path, configData)
		return processResponse(response, 'patch', path)
	},

	getUser: async (id) => {
		const path = `${USERS_PATH}${id ? `/${id}` : ''}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getUserById: async (id) => {
		const path = `${ADMIN_PATH}${USERS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	updatePushToken: async (pushToken, oldPushToken) => {
		const path = `${USERS_PATH}`
		const response = await api.patch(path, {
			pushToken,
			oldPushToken
		})
		return processResponse(response, 'patch', path)
	},

	bookShipment: async (shipmentData, shipperId) => {
		const path = `${BOOKINGS_PATH}`
		const response = await api.post(path, shipmentData, {
			params: {
				shipperId
			}
		})
		return processResponse(response, 'post', path)
	},

	getBooking: async (id) => {
		const path = `${BOOKINGS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	modifyRequestedShipment: async (id, bookingData) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}/${id}${SHIPMENTS_PATH}`
		const response = await api.patch(path, bookingData)
		return processResponse(response, 'patch', path)
	},

	getBookings: async (lastDocumentId, { filter, desc }, assigned, status) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}`
		const response = await api.get(path,{
			lastDocumentId,
			filter,
			desc,
			assigned,
			status
		})
		return processResponse(response, 'get', path)
	},

	getBookingShipments: async (id) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}/${id}${SHIPMENTS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getShipperBookings: async (lastDocumentId, filters) => {
		const path = `${BOOKINGS_PATH}`
		const response = await api.get(path, {
			lastDocumentId,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	getShipments: async (status, lastDocumentId) => {
		const path = `${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			status,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getCarrierShipments: async (status, lastDocumentId) => {
		const path = `${CARRIER_PATH}${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			status,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getShipment: async (id) => {
		const path = `${SHIPMENTS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	acceptShipment: async (id, shipmentData, carrierId) => {
		const path = `${CARRIER_PATH}${SHIPMENTS_PATH}/${id}`
		const response = await api.patch(path, shipmentData, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'patch', path)
	},

	assignShipment: async (id, shipmentData, carrierId) => {
		const path = `${CARRIER_PATH}${SHIPMENTS_PATH}/${id}`
		const response = await api.patch(path, shipmentData, {
			params : {
				carrierId
			}
		})
		return processResponse(response, 'patch', path)
	},

	renewShipment: async (id, shipmentData) => {
		const path = `${SHIPPER_PATH}${SHIPMENTS_PATH}/${id}`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	updateFareAmount: async (id, fareAmount) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${id}/fare-amount`
		const response = await api.patch(path, { fareAmount })
		return processResponse(response, 'patch', path)
	},

	updateVendorAmount: async (shipmentId, vendorAmount) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${shipmentId}/vendor-amount`
		const response = await api.patch(path, { vendorAmount })
		return processResponse(response, 'patch', path)
	},

	updateVendorAdvancePayment: async (shipmentId, data) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${shipmentId}/vendor-advance-payment`
		const response = await api.patch(path, data)
		return processResponse(response, 'patch', path)
	},

	updateFareAdvancePayment: async (shipmentId, data) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${shipmentId}/fare-advance-payment`
		const response = await api.patch(path, data)
		return processResponse(response, 'patch', path)
	},

	updateExtraCost: async (shipmentId, data) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${shipmentId}/extra-cost`
		const response = await api.patch(path, data)
		return processResponse(response, 'patch', path)
	},

	updateBookingFareAmount: async (id, fareAmount) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}/${id}/fare-amount`
		const response = await api.patch(path, { fareAmount })
		return processResponse(response, 'patch', path)
	},

	cancelShipment: async (id, shipmentData) => {
		const path = `${SHIPPER_PATH}${SHIPMENTS_PATH}/${id}`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	cancelBooking: async (id, bookingData) => {
		const path = `${BOOKINGS_PATH}/${id}`
		const response = await api.patch(path, bookingData)
		return processResponse(response, 'patch', path)
	},

	getDeliveryLocations: async (userId) => {
		const path = `${DELIVERY_LOCATIONS_PATH}`
		const response = await api.get(path, {
			userId
		})
		return processResponse(response, 'get', path)
	},

	deleteDeliveryLocation: async (id) => {
		const path = `${DELIVERY_LOCATIONS_PATH}/${id}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	addDeliveryLocation: async (deliveryLocationData, companyId) => {
		const path = `${DELIVERY_LOCATIONS_PATH}`
		const response = await api.post(path, deliveryLocationData, {
			params: {
				companyId
			}
		})
		return processResponse(response, 'post', path)
	},

	updateDeliveryLocation: async (id, deliveryLocationData) => {
		const path = `${DELIVERY_LOCATIONS_PATH}/${id}`
		const response = await api.patch(path, deliveryLocationData)
		return processResponse(response, 'patch', path)
	},

	fetchNotifications: async (lastDocumentId) => {
		const path = `${NOTIFICATIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	fetchAdminNotifications: async (lastDocumentId) => {
		const path = `${ADMIN_PATH}${NOTIFICATIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	addDriver: async (driverData, carrierId) => {
		const path = `${CARRIER_PATH}${DRIVERS_PATH}`
		const response = await api.post(path, driverData, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'post', path)
	},

	updateDriver: async (id, driverData, carrierId) => {
		const path = `${CARRIER_PATH}${DRIVERS_PATH}/${id}`
		const response = await api.patch(path, driverData, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'patch', path)
	},

	updateDriverLocation: async (id, driverData, carrierId) => {
		const path = `${CARRIER_PATH}${DRIVERS_PATH}/${id}${LOCATION_PATH}`
		const response = await api.patch(path, driverData, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'patch', path)
	},

	updateDriverStatus: async (id, driverData) => {
		const path = `${CARRIER_PATH}${DRIVERS_PATH}/${id}${STATUS_PATH}`
		const response = await api.patch(path, driverData)
		return processResponse(response, 'patch', path)
	},

	updateShipmentDriverStatus: async (id, driverData, carrierId) => {
		const path = `${CARRIER_PATH}${SHIPMENTS_PATH}/${id}`
		const response = await api.patch(path, driverData, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'patch', path)
	},

	getDriversByStatus: async (status, carrierId) => {
		let path = `${CARRIER_PATH}${DRIVERS_PATH}`
		const response = await api.get(path, {
			status,
			carrierId
		})
		return processResponse(response, 'get', path)
	},

	getDriversByDriverStatus: async (driverStatus, carrierId, available, shipmentId) => {
		let path = `${CARRIER_PATH}${DRIVERS_PATH}`
		const response = await api.get(path, {
			driverStatus,
			carrierId,
			available,
			shipmentId
		})
		return processResponse(response, 'get', path)
	},

	addTruck: async (truckData, carrierId) => {
		let path = `${TRUCKS_PATH}`
		const response = await api.post(path, truckData, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'post', path)
	},

	getTrucks: async (status, type, carrierId, available, inUse, lastDocumentId) => {
		let path = `${TRUCKS_PATH}`
		const response = await api.get(path, {
			status,
			carrierId,
			type,
			available,
			inUse,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	updateTruck: async (id, truckData, carrierId) => {
		let path = `${TRUCKS_PATH}/${id}`
		const response = await api.patch(path, truckData, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'patch', path)
	},

	getTruckLocations: async (id) => {
		const path = `${ADMIN_PATH}${TRUCKS_PATH}/${id}${LOCATIONS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getRate: async (fromLocation, toLocations, truckType, shipperId) => {
		const path = `${RATES_PATH}`
		const requestParams = {
			from: `${fromLocation.latLng.lat},${fromLocation.latLng.lng}`,
			fromAddress: fromLocation.address,
			truckType,
			shipperId
		}
		toLocations.forEach((location, index) => {
			const toLatLngKey = `to${index !== 0 ? index : ''}`
			const toAddressKey = `toAddress${index !== 0 ? index  : ''}`
			requestParams[toLatLngKey] = `${location.latLng.lat},${location.latLng.lng}`
			requestParams[toAddressKey] = `${location.address}`
		})
		const response = await api.get(path, requestParams)
		return processResponse(response, 'get', path)
	},

	getDriverTasks: async (id) => {
		const path = `${CARRIER_PATH}${DRIVERS_PATH}/${id}${SHIPMENTS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getUsers: async (type, lastDocumentId, { filter, desc }, NELatLng, SWLatLng) => {
		const path = `${ADMIN_PATH}${USERS_PATH}`
		const response = await api.get(path, {
			type,
			lastDocumentId,
			filter,
			desc,
			NELatLng,
			SWLatLng
		})
		return processResponse(response, 'get', path)
	},

	updateUser: async (id, userData) => {
		const path = `${USERS_PATH}/${id}`
		const response = await api.patch(path, userData)
		return processResponse(response, 'patch', path)
	},

	getRates: async (type, from, to, truckType, lastDocumentId) => {
		const path = `${ADMIN_PATH}${RATES_PATH}`
		const response = await api.get(path, {
			type,
			from,
			to,
			truckType,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getAllShipments: async (lastDocumentId, status, NELatLng, SWLatLng, { filter, desc }={ }, userId, all, assigned) => {
		let path = `${ADMIN_PATH}${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			lastDocumentId,
			status,
			NELatLng,
			SWLatLng,
			filter,
			desc,
			userId,
			all,
			assigned
		})
		return processResponse(response, 'get', path)
	},

	getAllTransactions: async (lastDocumentId, { filter, desc, status }, isAssigned) => {
		let path = `${ADMIN_PATH}${TRANSACTIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId,
			filter,
			desc,
			status,
			isAssigned
		})
		return processResponse(response, 'get', path)
	},

	getShipperTransactions: async (lastDocumentId, shipperId) => {
		const path = `${SHIPPER_PATH}${TRANSACTIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId,
			shipperId
		})
		return processResponse(response, 'get', path)
	},

	getCarrierTransactions: async (lastDocumentId, carrierId) => {
		const path = `${CARRIER_PATH}${TRANSACTIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId,
			carrierId
		})
		return processResponse(response, 'get', path)
	},

	getCurrentRate: async () => {
		const path = `${ADMIN_PATH}${RATES_PATH}/current`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	updateRate: async (id, rateData) => {
		const path = `${ADMIN_PATH}${RATES_PATH}/${id}`
		const response = await api.patch(path, rateData)
		return processResponse(response, 'patch', path)
	},

	addRate: async (rateData) => {
		const path = `${ADMIN_PATH}${RATES_PATH}`
		const response = await api.post(path, rateData)
		return processResponse(response, 'post', path)
	},

	getLocality: async (latitude, longitude) => {
		const path = `${ADMIN_PATH}${LOCALITY_PATH}?latitude=${latitude}&longitude=${longitude}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getTransactionsByBookingId: async (bookingId) => {
		const path = `${TRANSACTIONS_PATH}`
		const response = await api.get(path, {
			bookingId
		})
		return processResponse(response, 'get', path)
	},

	getTransaction: async (id) => {
		const path = `${TRANSACTIONS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	updateTransaction: async (id, transactionData) => {
		const path = `${ADMIN_PATH}${TRANSACTIONS_PATH}/${id}`
		const response = await api.patch(path, transactionData)
		return processResponse(response, 'patch', path)
	},

	updateTransactionStatus: async (id, transactionData) => {
		const path = `${ADMIN_PATH}${TRANSACTIONS_PATH}/${id}/status`
		const response = await api.patch(path, transactionData)
		return processResponse(response, 'patch', path)
	},

	updateTransactionCharge: async (id, transactionData) => {
		const path = `${ADMIN_PATH}${TRANSACTIONS_PATH}/${id}/charge`
		const response = await api.patch(path, transactionData)
		return processResponse(response, 'patch', path)
	},

	updateTransactionPaymentMethod: async (id, transactionData) => {
		const path = `${ADMIN_PATH}${TRANSACTIONS_PATH}/${id}/payment-method`
		const response = await api.patch(path, transactionData)
		return processResponse(response, 'patch', path)
	},

	getLoadboard: async () => {
		const path = `${CARRIER_PATH}${LOADBOARD_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getFilter: async () => {
		const path = `${CARRIER_PATH}${FILTER_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	updateFilter: async (filterData) => {
		const path = `${CARRIER_PATH}${FILTER_PATH}`
		const response = await api.put(path, filterData)
		return processResponse(response, 'put', path)
	},

	addBankDetail: async (bankData, userId) => {
		const path = `${BANKS_PATH}`
		const response = await api.post(path, bankData, {
			params: {
				userId
			}
		})
		return processResponse(response, 'post', path)
	},

	updateBankDetail: async (accountId, bankData, userId) => {
		const path = `${BANKS_PATH}/${accountId}`
		const response = await api.patch(path, bankData, {
			params: {
				userId
			}
		}
		)
		return processResponse(response, 'patch', path)
	},

	getBankDetails: async (userId) => {
		const path = `${BANKS_PATH}`
		const response = await api.get(path, {
			userId
		})
		return processResponse(response, 'get', path)
	},

	getBankDetailById: async (id, userId) => {
		const path = `${BANKS_PATH}/${id}`
		const response = await api.get(path, {
			userId
		})
		return processResponse(response, 'get', path)
	},

	assignTruck: async (truckId, data, carrierId) => {
		const path = `${TRUCKS_PATH}/${truckId}${DRIVER_PATH}`
		const response = await api.patch(path, data, {
			params: {
				carrierId
			}
		})
		return processResponse(response, 'patch', path)
	},

	unassignTruck: async (truckId) => {
		const path = `${TRUCKS_PATH}/${truckId}${DRIVER_PATH}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	searchShipments: async (searchTerm, pageNumber, filters) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	searchTransactions: async (searchTerm, shipmentStatus, paymentStatus, paymentMethod, tripDateRange) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			status:shipmentStatus,
			paymentStatus,
			paymentMethod,
			tripDateRange
		})
		return processResponse(response, 'get', path)
	},

	searchBills: async (searchTerm, pageNumber, filters) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${BILLS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	searchShipperBills: async (searchTerm, pageNumber, filters) => {
		const path = `${SHIPPER_PATH}${SEARCH_PATH}${BILLS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	searchShipperCompanyLocations: async (searchTerm, pageNumber, filters) => {
		const path = `${SHIPPER_PATH}${SEARCH_PATH}${DELIVERY_LOCATIONS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	searchCarrierShipments: async (searchTerm, pageNumber, status) => {
		const path = `${CARRIER_PATH}${SEARCH_PATH}${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			status
		})
		return processResponse(response, 'get', path)
	},

	searchCarrierTrucks: async (searchTerm, pageNumber, status) => {
		const path = `${CARRIER_PATH}${SEARCH_PATH}${TRUCKS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			status
		})
		return processResponse(response, 'get', path)
	},

	getAvailableTrucks: async (inUse, lastDocumentId) => {
		const path = `${TRUCKS_PATH}`
		const response = await api.get(path, {
			inUse,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	searchCarrierDrivers: async (searchTerm, pageNumber, status, driverStatus) => {
		const path = `${CARRIER_PATH}${SEARCH_PATH}${DRIVERS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			status,
			driverStatus
		})
		return processResponse(response, 'get', path)
	},

	searchShipperShipments: async (searchTerm, pageNumber, filters, userId) => {
		const path = `${SHIPPER_PATH}${SEARCH_PATH}${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			userId,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	searchUsers: async (searchTerm, type, disabled, pageNumber) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${USERS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			disabled,
			type
		})
		return processResponse(response, 'get', path)
	},

	searchTrucks: async (searchTerm, truckType, pageNumber, carrierId, isInUse) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${TRUCKS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			truckType,
			pageNumber,
			carrierId,
			isInUse
		})
		return processResponse(response, 'get', path)
	},

	getShipperShipmentCountsAnalytics: async (shipperId) => {
		const path = `${SHIPPER_PATH}${ANALYTICS_PATH}`
		const response = await api.get(path, {
			shipperId
		})
		return processResponse(response, 'get', path)
	},

	getShipperShipmentHistoryAnalytics: async (interval) => {
		const path = `${SHIPPER_PATH}${ANALYTICS_PATH}${HISTORY_PATH}`
		const response = await api.get(path, {
			interval
		})
		return processResponse(response, 'get', path)
	},

	getCumulativeShipperShipmentCounts: async (interval, shipperId) => {
		const path = `${SHIPPER_PATH}${ANALYTICS_PATH}/cumulative`
		const response = await api.get(path, {
			interval,
			shipperId
		})
		return processResponse(response, 'get', path)
	},

	getCarrierCountsAnalytics: async () => {
		const path = `${CARRIER_PATH}${ANALYTICS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	sendMessage: async (messageData) => {
		const path = `${MESSAGES_PATH}`
		const response = await api.post(path, messageData)
		return processResponse(response, 'post', path)
	},

	getDriver: async (driverId) => {
		const path = `${CARRIER_PATH}${DRIVERS_PATH}/${driverId}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getShipmentCarrier: async (shipmentId) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${CARRIER_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getShipmentShipper: async (shipmentId) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${SHIPPER_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getShipmentDriver: async (shipmentId) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${DRIVER_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getShipmentChat: async (shipmentId) => {
		const path = `${MESSAGES_PATH}${CHATS_PATH}`
		const response = await api.get(path, {
			shipmentId
		})
		return processResponse(response, 'get', path)
	},

	getDriverChat: async (driverId) => {
		const path = `${MESSAGES_PATH}${CHATS_PATH}`
		const response = await api.get(path, {
			driverId
		})
		return processResponse(response, 'get', path)
	},

	getAdminChat: async (userId) => {
		const path = `${MESSAGES_PATH}${CHATS_PATH}`
		const response = await api.get(path, {
			userId
		})
		return processResponse(response, 'get', path)
	},

	getChats: async (lastDocumentId) => {
		const path = `${MESSAGES_PATH}${CHATS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getMessages: async (chatId) => {
		const path = `${MESSAGES_PATH}/${chatId}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getGlobalCounts: async () => {
		const path = `${ANALYTICS_PATH}${COUNTS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getGlobalFares: async () => {
		const path = `${ANALYTICS_PATH}${FARES_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getGlobalHistory: async (userId) => {
		const path = `${ANALYTICS_PATH}${HISTORY_PATH}`
		const response = await api.get(path, {
			userId
		})
		return processResponse(response, 'get', path)
	},

	getRatesHistory: async (id, days) => {
		const path = `${ANALYTICS_PATH}${HISTORY_PATH}${RATES_PATH}/${id}`
		const response = await api.get(path, {
			days
		})
		return processResponse(response, 'get', path)
	},

	overrideShipmentStatus: async (id, shipmentData) => {
		firebase.analytics().logEvent('override_shipment_status', { status: shipmentData.status, driverStatus: shipmentData.driverStatus })
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${id}${STATUS_PATH}`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	getAttachments: async (id) => {
		const path = `${SHIPMENTS_PATH}/${id}${ATTACHMENTS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getShipmentTruck: async (id) => {
		const path = `${SHIPMENTS_PATH}/${id}/truck`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getAvailableCarriers: async (shipmentId) => {
		const path = `${ADMIN_PATH}${USERS_PATH}${CARRIERS_PATH}`
		const response = await api.get(path, {
			shipmentId
		})
		return processResponse(response, 'get', path)
	},

	getEventNotes: async (shipmentId, eventId) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${EVENTS_PATH}/${eventId}${NOTES_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	addEventNote: async (shipmentId, eventId, note) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${EVENTS_PATH}/${eventId}${NOTES_PATH}`
		const response = await api.post(path, {
			note
		})
		return processResponse(response, 'post', path)
	},

	addBookingNote: async (bookingId, note) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}/${bookingId}${NOTES_PATH}`
		const response = await api.post(path, {
			note
		})
		return processResponse(response, 'post', path)
	},

	updateBooking: async (bookingId, bookingData) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}/${bookingId}`
		const response = await api.patch(path, bookingData)
		return processResponse(response, 'patch', path)
	},

	getBookingNotes: async (bookingId) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}/${bookingId}${NOTES_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	scheduleShipment: async (shipmentId, shipmentData) => {
		firebase.analytics().logEvent('update_shipment_status', { status: shipmentData.status })
		const path = `${SHIPMENTS_PATH}/${shipmentId}${STATUS_PATH}`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	addCarrierToShipment: async (shipmentId, shipmentData) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${CARRIER_PATH}`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	searchRates: async (searchTerm, truckType, from, to) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${RATES_PATH}`
		const response = await api.get(path, {
			searchTerm,
			truckType,
			from,
			to
		})
		return processResponse(response, 'get', path)
	},

	fetchContracts: async (status, lastDocumentId) => {
		const path = `${CONTRACTS_PATH}`
		const response = await api.get(path, {
			status,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	fetchShipperContracts: async (status, lastDocumentId) => {
		const path = `${SHIPPER_PATH}${CONTRACTS_PATH}`
		const response = await api.get(path, {
			status,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	fetchContract: async (id) => {
		const path = `${CONTRACTS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	fetchContractHistory: async (id) => {
		const path = `${CONTRACTS_PATH}/${id}${HISTORY_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	addContract: async (contractData) => {
		const path = `${CONTRACTS_PATH}`
		const response = await api.post(path, contractData)
		return processResponse(response, 'post', path)
	},

	updateContract: async (id, contractData) => {
		const path = `${CONTRACTS_PATH}/${id}`
		const response = await api.patch(path, contractData)
		return processResponse(response, 'patch', path)
	},

	updateContractStatus: async (id, status) => {
		const path = `${CONTRACTS_PATH}/${id}/status`
		const response = await api.patch(path, { status })
		return processResponse(response, 'patch', path)
	},

	createContractComment: async (id, content) => {
		const path = `${CONTRACTS_PATH}/${id}/comment`
		const response = await api.post(path, {
			content
		})
		return processResponse(response, 'post', path)
	},

	fetchContractPDF: async (id) => {
		const path = `${CONTRACTS_PATH}/${id}/pdf`
		const response = await api.get(path, {}, { responseType: 'blob' })
		return processResponse(response, 'get', path)
	},

	getRateInfo: async (id) => {
		const path = `${ADMIN_PATH}${RATES_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	watchShipment: async (id, shipmentData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${id}/watchers`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	getWatchingShipments: async (lastDocumentId) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}${WATCHING_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getCarryingZones: async () => {
		const path = `${OPTIONS_PATH}${CARRYING_ZONES_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	assignShipmentToAdmin: async (id, shipmentData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${id}/assignee`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	massAssignShipmentsToAdmin: async (id, assignData) => {
		const path = `${ADMIN_PATH}${USERS_PATH}/${id}/assignee`
		const response = await api.patch(path, assignData)
		return processResponse(response, 'patch', path)
	},

	getAssignedShipments: async (lastDocumentId) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}${ASSIGNED_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	exportShipments: async (inputData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/export`
		const response = await api.post(path, inputData, { responseType: 'blob' })
		return processResponse(response, 'post', path)
	},

	exportTrucks: async () => {
		const path = `${ADMIN_PATH}${TRUCKS_PATH}/export`
		const response = await api.post(path, undefined, { responseType: 'blob' })
		return processResponse(response, 'post', path)
	},

	getInvoice: async (id) => {
		const path = `${TRANSACTIONS_PATH}/${id}/invoice`
		const response = await api.get(path, {}, { responseType: 'blob' })
		return processResponse(response, 'get', path)
	},

	getBillPDF: async (id) => {
		const path = `${BILLS_PATH}/${id}/pdf`
		const response = await api.get(path, {}, { responseType: 'blob' })
		return processResponse(response, 'get', path)
	},

	deleteShipmentAttachment: async (shipmentId, attachmentId) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${shipmentId}${ATTACHMENTS_PATH}/${attachmentId}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	deleteUserAttachment: async (userId, attachmentId) => {
		const path = `${ADMIN_PATH}${USERS_PATH}/${userId}${ATTACHMENTS_PATH}/${attachmentId}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	updateUserPassword: async (userId, userData) => {
		const path = `${ADMIN_PATH}${USERS_PATH}/${userId}/password`
		const response = await api.patch(path, userData)
		return processResponse(response, 'patch', path)
	},

	getSubscribers: async (shipmentId) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${SUBSCRIBERS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	addSubscriber: async (shipmentId, subscriberData) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${SUBSCRIBERS_PATH}`
		const response = await api.post(path, subscriberData)
		return processResponse(response, 'post', path)
	},

	removeSubscriber: async (shipmentId, subscriberId) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${SUBSCRIBERS_PATH}/${subscriberId}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	addTruckToShipment: async (shipmentId, shipmentData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${shipmentId}/truck`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	addDriverToShipment: async (shipmentId, shipmentData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${shipmentId}${DRIVER_PATH}`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	createBill: async (billData) => {
		const path = `${BILLS_PATH}`
		const response = await api.post(path, billData)
		return processResponse(response, 'post', path)
	},

	fetchBills: async (status, lastDocumentId) => {
		const path = `${BILLS_PATH}`
		const response = await api.get(path, {
			status,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	fetchBill: async (billId) => {
		const path = `${BILLS_PATH}/${billId}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	deleteBill: async (billId) => {
		const path = `${ADMIN_PATH}${BILLS_PATH}/${billId}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	uploadLocation: async (key, locationData) => {
		const path = `${LOCATION_PATH}/${key}`
		const response = await api.post(path, locationData)
		return processResponse(response, 'post', path)
	},

	fetchShippersWithContracts: async () => {
		const path = `${CONTRACTS_PATH}/shippers`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	fetchShipperContractRates: async (shipperId) => {
		const path = `${CONTRACTS_PATH}/shippers/${shipperId}${RATES_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	updateShipmentDetails: async (id, shipmentData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${id}`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	updateShipmentInfo: async (id, shipmentData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${id}/info`
		const response = await api.patch(path, shipmentData)
		return processResponse(response, 'patch', path)
	},

	updateShipmentRating: async (id, ratingData) => {
		const path = `${ADMIN_PATH}${SHIPMENTS_PATH}/${id}/rating`
		const response = await api.patch(path, ratingData)
		return processResponse(response, 'patch', path)
	},

	getWeighingLocations: async () => {
		const path = `${OPTIONS_PATH}${WEIGHING_LOCATIONS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getRecentlyActiveShippers: async () => {
		const path = `${ANALYTICS_PATH}${SHIPPER_PATH}/recent`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	readAllUserNotifications: async () => {
		const path = `${NOTIFICATIONS_PATH}`
		const response = await api.post(path, {
			readAll: true
		})
		return processResponse(response, 'post', path)
	},

	getCumulativeShipmentCounts: async (interval) => {
		const path = `${ANALYTICS_PATH}/cumulative`
		const response = await api.get(path, {
			interval
		})
		return processResponse(response, 'get', path)
	},

	requestVendorPricing: async (vendorRequestData) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}${VENDOR_REQUESTS_PATH}`
		const response = await api.post(path, vendorRequestData)
		return processResponse(response, 'patch', path)
	},

	acceptVendorPrice: async (vendorRequestId, vendorRequestData) => {
		const path = `${ADMIN_PATH}${BOOKINGS_PATH}${VENDOR_REQUESTS_PATH}/${vendorRequestId}`
		const response = await api.patch(path, vendorRequestData)
		return processResponse(response, 'patch', path)
	},

	searchDrivers: async (searchTerm, pageNumber, status, driverStatus, uid) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${DRIVERS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			status,
			driverStatus,
			uid
		})
		return processResponse(response, 'get', path)
	},

	getLocations: async (lastDocumentId, searchTerm, shipperId) => {
		const path = `${LOCATIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId,
			searchTerm,
			shipperId
		})
		return processResponse(response, 'get', path)
	},

	updateLocation: async (id, locationData) => {
		const path = `${ADMIN_PATH}${LOCATIONS_PATH}/${id}`
		const response = await api.patch(path, locationData)
		return processResponse(response, 'patch', path)
	},

	deleteLocation: async (id) => {
		const path = `${ADMIN_PATH}${LOCATIONS_PATH}/${id}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	addLocation: async (locationData) => {
		const path = `${ADMIN_PATH}${LOCATIONS_PATH}`
		const response = await api.post(path, locationData)
		return processResponse(response, 'post', path)
	},

	preferCarrier: async (carrierId) => {
		const path = `${ADMIN_PATH}${USERS_PATH}/${carrierId}/vendor-preference`
		const response = await api.patch(path)
		return processResponse(response, 'patch', path)
	},

	getShipmentEvents: async (shipmentId) => {
		const path = `${SHIPMENTS_PATH}/${shipmentId}${EVENTS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getDriverEvents: async (driverId) => {
		const path = `${CARRIER_PATH}${DRIVERS_PATH}/${driverId}${EVENTS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getTruckEvents: async (truckId) => {
		const path = `${TRUCKS_PATH}/${truckId}${EVENTS_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	createConversation: async (conversationData) => {
		const path = `${CONVERSATIONS_PATH}`
		const response = await api.post(path, conversationData)
		return processResponse(response, 'post', path)
	},

	getConversation: async (id) => {
		const path = `${CONVERSATIONS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getConversations: async (lastDocumentId, receiverId) => {
		const path = `${CONVERSATIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId,
			receiverId
		})
		return processResponse(response, 'get', path)
	},

	getConversationMessages: async (id, lastDocumentId) => {
		const path = `${CONVERSATIONS_PATH}/${id}${MESSAGES_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	createConversationMessage: async (id, messageData) => {
		const path = `${CONVERSATIONS_PATH}/${id}${MESSAGES_PATH}`
		const response = await api.post(path, messageData)
		return processResponse(response, 'post', path)
	},

	getReceiver: async (receiverId) => {
		const path = `${CONVERSATIONS_PATH}/receiver/${receiverId}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	sendQuotationEmail: async (quotationId, emailData) => {
		const path = `${CONTRACTS_PATH}/${quotationId}/mail`
		const response = await api.post(path, emailData)
		return processResponse(response, 'post', path)
	},

	getCompany: async (id) => {
		const path = `${COMPANIES_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getBookingsByCompany: async (id, lastDocumentId) => {
		const path = `${COMPANIES_PATH}/${id}${BOOKINGS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getShipmentsByCompany: async (id, lastDocumentId) => {
		const path = `${COMPANIES_PATH}/${id}${SHIPMENTS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getUsersByCompany: async (id, lastDocumentId) => {
		const path = `${COMPANIES_PATH}/${id}${USERS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getLocationsByCompany: async (id, lastDocumentId) => {
		const path = `${COMPANIES_PATH}/${id}${DELIVERY_LOCATIONS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getCompanies: async (all, lastDocumentId) => {
		const path = `${ADMIN_PATH}${COMPANIES_PATH}`
		const response = await api.get(path, {
			all,
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	updateCompany: async (id, companyData) => {
		const path = `${ADMIN_PATH}${COMPANIES_PATH}/${id}`
		const response = await api.patch(path, companyData)
		return processResponse(response, 'patch', path)
	},

	createCompany: async (companyData) => {
		const path = `${ADMIN_PATH}${COMPANIES_PATH}`
		const response = await api.post(path, companyData)
		return processResponse(response, 'post', path)
	},

	getDashboard: async (all, month) => {
		const path = `${ANALYTICS_PATH}${DASHBOARD_PATH}`
		const response = await api.get(path, {
			all,
			month
		})
		return processResponse(response, 'get', path)
	},

	getShipperDashboard: async (all, month) => {
		const path = `${SHIPPER_PATH}${ANALYTICS_PATH}${DASHBOARD_PATH}`
		const response = await api.get(path, {
			all,
			month
		})
		return processResponse(response, 'get', path)
	},

	getAnnualDashboard: async () => {
		const path = `${ANALYTICS_PATH}${DASHBOARD_PATH}/annual`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getUsersDashboard: async (month) => {
		const path = `${ANALYTICS_PATH}${DASHBOARD_PATH}${USERS_PATH}`
		const response = await api.get(path, {
			month
		})
		return processResponse(response, 'get', path)
	},

	getEventsDashboard: async (month) => {
		const path = `${ANALYTICS_PATH}${DASHBOARD_PATH}${EVENTS_PATH}`
		const response = await api.get(path, {
			month
		})
		return processResponse(response, 'get', path)
	},

	getRevenueHistory: async (interval) => {
		const path = `${ANALYTICS_PATH}/revenue`
		const response = await api.get(path, {
			interval
		})
		return processResponse(response, 'get', path)
	},

	createReport: async (report) => {
		const path = `${ADMIN_PATH}${REPORTS_PATH}`
		const response = await api.post(path, {
			...report
		})
		return processResponse(response, 'post', path)
	},

	updateReport: async (id, name) => {
		const path = `${ADMIN_PATH}${REPORTS_PATH}/${id}`
		const response = await api.patch(path, { name })
		return processResponse(response, 'post', path)
	},

	getReports: async (lastDocumentId = null) => {
		const path = `${ADMIN_PATH}${REPORTS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getReport: async (id) => {
		const path = `${ADMIN_PATH}${REPORTS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	getCompanyReports: async (lastDocumentId = null) => {
		const path = `${SHIPPER_PATH}${REPORTS_PATH}`
		const response = await api.get(path, {
			lastDocumentId
		})
		return processResponse(response, 'get', path)
	},

	getCompanyReport: async (id) => {
		const path = `${SHIPPER_PATH}${REPORTS_PATH}/${id}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	deleteReport: async (id) => {
		const path = `${ADMIN_PATH}${REPORTS_PATH}/${id}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	getReportPDF: async (id) => {
		const path = `${ADMIN_PATH}${REPORTS_PATH}/${id}/pdf`
		const response = await api.get(path, {}, { responseType: 'blob' })
		return processResponse(response, 'get', path)
	},

	sendShipperReportEmail: async (id, emailData) => {
		const path = `${SHIPPER_PATH}${REPORTS_PATH}/${id}/mail`
		const response = await api.post(path, emailData)
		return processResponse(response, 'post', path)
	},

	getShipperReportPDF: async (id) => {
		const path = `${SHIPPER_PATH}${REPORTS_PATH}/${id}/pdf`
		const response = await api.get(path, {}, { responseType: 'blob' })
		return processResponse(response, 'get', path)
	},

	sendReportEmail: async (id, emailData) => {
		const path = `${ADMIN_PATH}${REPORTS_PATH}/${id}/mail`
		const response = await api.post(path, emailData)
		return processResponse(response, 'post', path)
	},

	deleteContract: async (id) => {
		const path = `${CONTRACTS_PATH}/${id}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	deleteNotification: async (id) => {
		const path = `${NOTIFICATIONS_PATH}/${id}`
		const response = await api.delete(path)
		return processResponse(response, 'delete', path)
	},

	updatePermissions: async (id, permissionData) => {
		const path = `${USERS_PATH}/${id}/permissions`
		const response = await api.patch(path, permissionData)
		return processResponse(response, 'patch', path)
	},

	getShipperLocationsDashboard: async (month) => {
		const path = `${SHIPPER_PATH}${ANALYTICS_PATH}${DASHBOARD_PATH}${LOCATIONS_PATH}`
		const response = await api.get(path, {
			month
		})
		return processResponse(response, 'get', path)
	},

	getConfig: async () => {
		const path = `${ADMIN_PATH}${CONFIG_PATH}`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	updateConfig: async (data) => {
		const path = `${ADMIN_PATH}${CONFIG_PATH}`
		const response = await api.patch(path, data)
		return processResponse(response, 'patch', path)
	},

	searchShipperBookings: async (searchTerm, pageNumber, filters) => {
		const path = `${SHIPPER_PATH}${SEARCH_PATH}${BOOKINGS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	searchBookings: async (searchTerm, pageNumber, filters) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${BOOKINGS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	searchCompanies: async (searchTerm, pageNumber) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${COMPANIES_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber
		})
		return processResponse(response, 'get', path)
	},

	searchContracts: async (searchTerm, filters, pageNumber) => {
		const path = `${ADMIN_PATH}${SEARCH_PATH}${CONTRACTS_PATH}`
		const response = await api.get(path, {
			searchTerm,
			pageNumber,
			...filters
		})
		return processResponse(response, 'get', path)
	},

	fetchDailySummary: async () => {
		const path = `${ANALYTICS_PATH}/daily`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	fetchMonthlyGeneralAnalytics: async (month) => {
		const path = `${ANALYTICS_PATH}/general`
		const response = await api.get(path, {
			month
		})
		return processResponse(response, 'get', path)
	},

	fetchMonthlyOperationsAnalytics: async (month) => {
		const path = `${ANALYTICS_PATH}/operations`
		const response = await api.get(path, {
			month
		})
		return processResponse(response, 'get', path)
	},

	fetchMonthlyVendorAnalytics: async (month) => {
		const path = `${ANALYTICS_PATH}/vendor`
		const response = await api.get(path, {
			month
		})
		return processResponse(response, 'get', path)
	},

	fetchAnnualTrendsAnalytics: async () => {
		const path = `${ANALYTICS_PATH}/trends`
		const response = await api.get(path)
		return processResponse(response, 'get', path)
	},

	updateVendorResponse: async (vendorRequestId, data) => {
		const path = `${CARRIER_PATH}${BOOKINGS_PATH}${VENDOR_REQUESTS_PATH}/${vendorRequestId}`
		const response = await api.patch(path, data)
		return processResponse(response, 'patch', path)
	}
}

export default API

export { api }
