export const SHIPPER_TYPE = 'shipper'
export const CARRIER_TYPE = 'carrier'
export const ADMIN_TYPE = 'admin'
export const DRIVER_TYPE = 'carrier_driver'
export const DISPATCHER_TYPE = 'dispatcher'
export const CONSUMER_TYPE = 'consumer'
export const AGENT_TYPE = 'agent'

export const AGENT_SUB_TYPE = 'agent'
export const MANAGER_SUB_TYPE = 'manager'

export const SHIPMENT_CATEGORY = 'shipment'
export const TRUCK_CATEGORY = 'truck'
export const DRIVER_CATEGORY = 'driver'

export const PENDING_USER_STATUS = 'pending'
export const APPROVED_USER_STATUS = 'approved'
export const DECLINED_USER_STATUS = 'declined'

export const FREE_DRIVER_STATUS = 'free'
export const BUSY_DRIVER_STATUS = 'busy'
export const INACTIVE_DRIVER_STATUS = 'inactive'

export const ACTIVATED_TRUCK_STATUS = 'activated'
export const DEACTIVATED_TRUCK_STATUS = 'deactivated'
export const PENDING_TRUCK_STATUS = 'pending'

export const ALL_SHIPMENT_STATUS = 'all'
export const ACTIVE_SHIPMENT_STATUS = 'active'
export const REQUESTED_SHIPMENT_STATUS = 'requested'
export const SCHEDULED_SHIPMENT_STATUS = 'scheduled'
export const ASSIGNED_SHIPMENT_STATUS = 'assigned'
export const UNASSIGNED_SHIPMENT_STATUS = 'unassigned'
export const COMPLETED_SHIPMENT_STATUS = 'completed'
export const CANCELLED_SHIPMENT_STATUS = 'cancelled'
export const EXPIRED_SHIPMENT_STATUS = 'expired'

export const REQUESTED_EVENT_TYPE = 'requested'
export const DISPATCHED_EVENT_TYPE = 'dispatched'
export const DRIVER_ASSIGNED_SHIPMENT_EVENT_TYPE = 'driver_assigned_shipment'
export const DRIVER_REQUESTED_EVENT_TYPE = 'driver_requested'
export const DRIVER_ACCEPTED_EVENT_TYPE = 'driver_accepted'
export const DRIVER_REJECTED_EVENT_TYPE = 'driver_rejected'
export const DRIVER_PICK_UP_EVENT_TYPE = 'driver_pick_up'
export const DRIVER_ENROUTE_EVENT_TYPE = 'driver_enroute'
export const DRIVER_DROP_OFF_EVENT_TYPE = 'driver_drop_off'
export const DRIVER_INSIDE_FACILITY_EVENT_TYPE = 'driver_inside_facility'
export const COMPLETED_EVENT_TYPE = 'completed'
export const CANCELLED_EVENT_TYPE = 'cancelled'
export const EXPIRED_EVENT_TYPE = 'expired'
export const COUNT_UPDATED_EVENT_TYPE = 'count_updated'
export const DRIVER_ASSIGNED_EVENT_TYPE = 'driver_assigned'
export const DRIVER_UNASSIGNED_EVENT_TYPE = 'driver_unassigned'
export const TRUCK_ASSIGNED_EVENT_TYPE = 'truck_assigned'
export const TRUCK_UNASSIGNED_EVENT_TYPE = 'truck_unassigned'
export const FARE_UPDATED_EVENT_TYPE = 'fare_updated'
export const STATUS_UPDATED_EVENT_TYPE = 'status_updated'
export const REQUESTED_SHIPMENT_ALERT_EVENT_TYPE = 'requested_shipment_alert'
export const ACTIVE_SHIPMENT_ALERT_EVENT_TYPE = 'active_shipment_alert'
export const BOOKING_FARE_RESPONDED_EVENT_TYPE = 'booking_fare_responded'
export const BOOKING_CREATED_EVENT_TYPE = 'booking_created'
export const VENDOR_RESPONDED_EVENT_TYPE = 'vendor_responded'
export const USER_VERIFIED_EVENT_TYPE = 'user_verified'
export const SHIPMENT_TRUCK_UPDATED_EVENT_TYPE = 'shipment_truck_updated'
export const SHIPMENT_DRIVER_UPDATED_EVENT_TYPE = 'shipment_driver_updated'
export const BOOKING_FARE_UPDATED_EVENT_TYPE = 'booking_fare_updated'
export const TRANSACTION_PROCESSING_EVENT_TYPE = 'transaction_processing'
export const USER_SIGN_UP_EVENT_TYPE = 'user_sign_up'
export const VENDOR_RATE_REQUESTED_EVENT_TYPE = 'vendor_rate_requested'
export const VENDOR_RATE_ACCEPTED_EVENT_TYPE = 'vendor_rate_accepted'
export const VENDOR_RESPONSE_COMPLETED_EVENT_TYPE = 'vendor_response_completed'
export const VENDOR_REQUEST_COMPLETED_EVENT_TYPE = 'vendor_request_completed'
export const QUOTATION_CREATED_EVENT_TYPE = 'quotation_created'
export const QUOTATION_ACTIVATED_EVENT_TYPE = 'quotation_activated'
export const CONTRACT_COMMENT_CREATED_EVENT_TYPE = 'contract_comment_created'
export const PERMISSIONS_UPDATED_EVENT_TYPE = 'permissions_updated'

export const APPROVED_STATUS = 'approved'
export const DECLINED_STATUS = 'declined'
export const PENDING_STATUS = 'pending'
export const PICK_UP_STATUS = 'pick_up'
export const ENROUTE_STATUS = 'enroute'
export const DROP_OFF_STATUS = 'drop_off'
export const INSIDE_FACILITY_STATUS = 'inside_facility'
export const COMPLETED_STATUS = 'completed'

export const PENDING_BANK_STATUS = 'pending'
export const APPROVED_BANK_STATUS = 'approved'
export const NOT_APPROVED_BANK_STATUS = 'not_approved'
export const VERIFIED_BANK_STATUS = 'verified'

export const REGULAR = 'regular'
export const FESTIVAL_EID_UL_ADHA = 'festival_eid_ul_adha'
export const FESTIVAL_EID_UL_FITR = 'festival_eid_ul_fitr'
export const FESTIVAL_DIWALI = 'festival_diwali'
export const FESTIVAL_DURGA_PUJA = 'festival_durga_puja'
export const FESTIVAL_KRISHNA_JANMASTAMI = 'festival_krishna_janmastami'
export const LOW_TRUCK_SUPPLY_STRIKE = 'low_truck_supply_strike'
export const LOW_TRUCK_SUPPLY_NATURAL_DISASTER = 'low_truck_supply_natural_disaster'
export const LOW_TRUCK_SUPPLY_POLITICAL_CRISIS = 'low_truck_supply_political_crisis'
export const LOW_TRUCK_DEMAND = 'low_truck_demand'
export const HIGH_TRUCK_SUPPLY = 'high_truck_supply'
export const HIGH_TRUCK_DEMAND = 'high_truck_demand'
export const ELECTIONS = 'elections'

export const CONTRACT_PENDING_STATUS = 'pending'
export const CONTRACT_ACTIVE_STATUS = 'active'
export const CONTRACT_INACTIVE_STATUS = 'inactive'
export const CONTRACT_EXPIRED_STATUS = 'expired'

export const TRANSACTION_PROCESSING_STATUS = 'processing'
export const TRANSACTION_PAID_STATUS = 'paid'
export const TRANSACTION_PENDING_STATUS ='pending'
export const TRANSACTION_CANCELLED_STATUS = 'cancelled'
export const COVERED_VANS = 'covered'
export const OPEN_TRUCKS = 'open'
export const TRAILERS = 'trailer'

export const PAYMENT_METHOD_CREDIT = 'credit_line'
export const PAYMENT_METHOD_CASH = 'cash'

export const BILL_STATUS = {
	PAID: 'paid',
	IN_PROGRESS: 'in_progress',
	WAIVED: 'waived'
}

export const CONTRACT_TYPE = {
	PER_TRIP: 'per_trip',
	PER_TONNE: 'per_tonne'
}

export const WEIGHT_UNIT = {
	TONNE: 't',
	LBS: 'lbs',
	KG: 'kg'
}

export const SHIPMENT_STATUS = {
	REQUESTED: 'requested',
	SCHEDULED: 'scheduled',
	ACTIVE: 'active',
	COMPLETED: 'completed',
	CANCELLED: 'cancelled',
	EXPIRED: 'expired'
}

export const CHARGE_TYPE = {
	DEMURRAGE: 'demurrage',
	CANCELLATION: 'cancellation',
	AIT: 'ait',
	VAT: 'vat',
	EXTRA: 'extra'
}

export const REPORT_TYPE = {
	SHIPMENT: 'shipment',
	COMPANY: 'company'
}

export const REPORT_STATUS_TYPE = {
	SHIPMENT_STATUS: 'shipment_status'
}

export const REPORT_GENERAL_CRITERIA = {
	SHIPPER_COMPANY: 'shipper_company',
	TRUCK_QUANTITY: 'truck_quantity'
}

export const REPORT_TRANSACTION_CRITERIA = {
	FARE_AMOUNT: 'fare_amount',
	VENDOR_AMOUNT: 'vendor_amount',
	PROFIT: 'profit'
}

export const REPORT_META = {
	COMPANY_ID: 'company_id',
	COMPANY: 'company',
	ROUTE_COUNTS: 'route_counts'
}

export const REPORT_TOTAL = {
	TOTAL_REQUESTED: 'total_requested',
	TOTAL_COMPLETED: 'total_completed',
	TOTAL_EXPENDITURE: 'total_expenditure',
	TOTAL_DISTANCE_IN_KM: 'total_distance_in_km',
	TOTAL_COST_PER_DISTANCE_IN_KM: 'total_cost_per_distance_in_km',
	TOTAL_CO2_EMISSION: 'total_co2_emission'
}

export const truckPreferences = [
	{ label: 'coveredVans', value: [COVERED_VANS] },
	{ label: 'openTrucks', value: [OPEN_TRUCKS] },
	{ label: 'trailer', value: [TRAILERS] },
	{ label: 'coveredVansAndOpenTrucks', value: [COVERED_VANS, OPEN_TRUCKS] },
	{ label: 'coveredVansAndTrailers', value: [COVERED_VANS, TRAILERS] },
	{ label: 'trailersAndOpenTrucks', value: [TRAILERS, OPEN_TRUCKS] },
	{ label: 'all', value: [COVERED_VANS, TRAILERS, OPEN_TRUCKS] }
]

export const PICK_UP_TIME_OPTIONS = [
	{ label: 'night', value: 0 },
	{ label: 'morning', value: 6 },
	{ label: 'afternoon', value: 12 },
	{ label: 'evening', value: 18 }
]

export const getTruckPreference = (truckPreference) => {
	const stringifiedTruckPreference = truckPreference.join('')
	switch (stringifiedTruckPreference) {
		case COVERED_VANS:
			return { label: 'coveredVans', value: [COVERED_VANS] }
		case OPEN_TRUCKS:
			return { label: 'openTrucks', value: [OPEN_TRUCKS] }
		case TRAILERS:
			return { label: 'trailer', value: [TRAILERS] }
		case `${COVERED_VANS}${OPEN_TRUCKS}`:
			return { label: 'coveredVansAndOpenTrucks', value: [COVERED_VANS, OPEN_TRUCKS] }
		case `${COVERED_VANS}${TRAILERS}`:
			return { label: 'coveredVansAndTrailers', value: [COVERED_VANS, TRAILERS] }
		case `${TRAILERS}${OPEN_TRUCKS}`:
			return { label: 'trailersAndOpenTrucks', value: [TRAILERS, OPEN_TRUCKS] }
		case `${COVERED_VANS}${TRAILERS}${OPEN_TRUCKS}`:
			return { label: 'all', value: [COVERED_VANS, TRAILERS, OPEN_TRUCKS] }
		default:
			return ''
	}
}

export const getBankStatus = (status) => {
	switch (status) {
		case 'pending':
			return 'pending'
		case 'not_approved':
			return 'notApproved'
		case 'approved':
			return 'approved'
		case 'verified':
			return 'verified'
		default:
			return status
	}
}

export const truckTypes = [
	'25t-40ft(trailer)',
	'20t-20ft(trailer)',
	'5t-23ft',
	'5t-20ft(openTruck)',
	'3t-18ft',
	'3t-17.5ft(openTruck)',
	'3t-17ft',
	'3t-16ft',
	'3t-16ft(openTruck)',
	'3t-14ft',
	'2t-13ft',
	'2t-12ft',
	'1.5t-14ft',
	'1.5t-13.5ft',
	'1.5t-13ft',
	'1.5t-12.5ft',
	'1.5t-12.5ft(openTruck)',
	'1.5t-12ft',
	'1.5t-12ft(openTruck)',
	'1.5t-10ft',
	'1t-11ft(openTruck)',
	'1t-8ft',
	'1t-7ft',
	'1t-7ft(openTruck)',
	'0.5t-10ft',
	'0.5t-8ft',
	'0.5t-7.5ft',
	'0.5t-7ft',
	'0.5t-7ft(openTruck)'
]

export const itemTypes = [
	'metal',
	'lumber',
	'consumer-packaged-goods',
	'garments-textiles',
	'construction-materials',
	'cement',
	'jute',
	'leather',
	'poultry-feed',
	'agriculture'
]

export const getUserType = (userType) => {
	switch (userType) {
		case 'shipper':
			return 'shipper'
		case 'carrier':
			return 'carrier'
		case 'carrier_driver':
			return 'driver'
		case 'dispatcher':
			return 'dispatcher'
		case 'manager':
			return 'manager'
		case 'agent':
			return 'agent'
		case 'consumer':
			return 'consumer'
		default:
			return userType
	}
}

export const getLegacyNotificationText = (userType, notification) => {
	if (userType === SHIPPER_TYPE) {
		switch (notification.type) {
			case REQUESTED_EVENT_TYPE:
				return 'shipmentRequested'
			case DISPATCHED_EVENT_TYPE:
				if (notification.driverId) {
					return 'shipperDispatchedDriverId'
				} else {
					return 'shipperDispatched'
				}
			case DRIVER_ACCEPTED_EVENT_TYPE:
				return 'driverAcceptedShipper'
			case DRIVER_PICK_UP_EVENT_TYPE:
				return 'driverPickUpShipper'
			case DRIVER_ENROUTE_EVENT_TYPE:
				return 'driverEnrouteShipper'
			case DRIVER_DROP_OFF_EVENT_TYPE:
				return 'driverDropShipper'
			case DRIVER_INSIDE_FACILITY_EVENT_TYPE:
				return 'driverInsideFacilityShipper'
			case CANCELLED_EVENT_TYPE:
				return 'driverCancelledShipper'
			case COMPLETED_EVENT_TYPE:
				return 'completedShipper'
			case EXPIRED_EVENT_TYPE:
				return 'expiredShipper'
			case FARE_UPDATED_EVENT_TYPE:
				return 'fareUpdated'
			case COUNT_UPDATED_EVENT_TYPE:
				return 'countUpdated'
			case BOOKING_FARE_UPDATED_EVENT_TYPE:
				return 'bookingFareUpdated'
			case SHIPMENT_DRIVER_UPDATED_EVENT_TYPE:
				return 'shipmentDriverUpdated'
			case SHIPMENT_TRUCK_UPDATED_EVENT_TYPE:
				return 'shipmentTruckUpdated'
			case BOOKING_CREATED_EVENT_TYPE:
				return 'shipperCreatedBooking'
			case QUOTATION_CREATED_EVENT_TYPE:
				return 'shipperQuotationCreated'
			case QUOTATION_ACTIVATED_EVENT_TYPE:
				return 'contractCreated'
			case USER_VERIFIED_EVENT_TYPE:
				return 'userVerified'
			case CONTRACT_COMMENT_CREATED_EVENT_TYPE:
				if (notification.metadata.author?.type === SHIPPER_TYPE) {
					return 'contractCommentCreated'
				}
				return 'authorCommentedOnAContract'
			case PERMISSIONS_UPDATED_EVENT_TYPE:
				return 'accessSettingsUpdated'
			default:
				return ''
		}
	} else if (userType === CARRIER_TYPE) {
		switch (notification.type) {
			case DISPATCHED_EVENT_TYPE:
				if (notification.driverId) {
					return 'carrierDispatchedDriverId'
				} else {
					return 'carrierDispatched'
				}
			case DRIVER_ACCEPTED_EVENT_TYPE:
				return 'driverAcceptedCarrier'
			case DRIVER_REJECTED_EVENT_TYPE:
				return 'driverRejectedCarrier'
			case DRIVER_PICK_UP_EVENT_TYPE:
				return 'driverPickUpCarrier'
			case DRIVER_ENROUTE_EVENT_TYPE:
				return 'driverEnrouteCarrier'
			case DRIVER_DROP_OFF_EVENT_TYPE:
				return 'driverDropCarrier'
			case DRIVER_INSIDE_FACILITY_EVENT_TYPE:
				return 'driverInsideFacilityShipper'
			case COMPLETED_EVENT_TYPE:
				return 'completedCarrier'
			case VENDOR_RATE_ACCEPTED_EVENT_TYPE:
				return 'vendorRateAccepted'
			case VENDOR_RATE_REQUESTED_EVENT_TYPE:
				return 'vendorRateRequested'
			case VENDOR_RESPONDED_EVENT_TYPE:
				return 'vendorRespondedCarrier'
			case USER_VERIFIED_EVENT_TYPE:
				return 'userVerified'
			case PERMISSIONS_UPDATED_EVENT_TYPE:
				return 'accessSettingsUpdated'
			default:
				return ''
		}
	} else {
		switch (notification.type) {
			case FARE_UPDATED_EVENT_TYPE:
				return 'fareUpdated'
			case STATUS_UPDATED_EVENT_TYPE:
				if (notification.driverStatus) {
					return 'driverStatusUpdated'
				}
				return 'statusUpdated'
			case REQUESTED_SHIPMENT_ALERT_EVENT_TYPE:
				return 'requestedShipmentStatusAlert'
			case ACTIVE_SHIPMENT_ALERT_EVENT_TYPE:
				return 'activeShipmentStatusAlert'
			case BOOKING_FARE_RESPONDED_EVENT_TYPE:
				if (notification.metadata.status === 'approved') {
					return 'bookingFareApproved'
				} else if (notification.metadata.status === 'rejected') {
					return 'bookingFareRejected'
				} else if (notification.metadata.status === 'negotiate') {
					return 'bookingFareNegotiated'
				} else {
					return 'bookingFareApproved'
				}
			case BOOKING_CREATED_EVENT_TYPE:
				return 'bookingMadeFor'
			case VENDOR_RESPONDED_EVENT_TYPE:
				return 'vendorResponded'
			case TRANSACTION_PROCESSING_EVENT_TYPE:
				return 'transactionNeedsProcessing'
			case USER_SIGN_UP_EVENT_TYPE:
				return 'userSignedUp'
			case DRIVER_ACCEPTED_EVENT_TYPE:
				return 'driverAcceptedAdmin'
			case DRIVER_PICK_UP_EVENT_TYPE:
				return 'driverPickUpShipper'
			case DRIVER_ENROUTE_EVENT_TYPE:
				return 'driverEnrouteShipper'
			case DRIVER_DROP_OFF_EVENT_TYPE:
				return 'driverDropShipper'
			case DRIVER_INSIDE_FACILITY_EVENT_TYPE:
				return 'driverInsideFacilityAdmin'
			case CANCELLED_EVENT_TYPE:
				return 'driverCancelledShipper'
			case COMPLETED_EVENT_TYPE:
				return 'completedShipper'
			case EXPIRED_EVENT_TYPE:
				return 'expiredShipper'
			case COUNT_UPDATED_EVENT_TYPE:
				return 'countUpdated'
			case BOOKING_FARE_UPDATED_EVENT_TYPE:
				return 'bookingFareUpdated'
			case SHIPMENT_DRIVER_UPDATED_EVENT_TYPE:
				return 'shipmentDriverUpdated'
			case SHIPMENT_TRUCK_UPDATED_EVENT_TYPE:
				return 'shipmentTruckUpdated'
			case VENDOR_REQUEST_COMPLETED_EVENT_TYPE:
				return 'vendorRequestCompleted'
			case VENDOR_RESPONSE_COMPLETED_EVENT_TYPE:
				return 'vendorResponseCompleted'
			case USER_VERIFIED_EVENT_TYPE:
				return 'userVerified'
			case QUOTATION_CREATED_EVENT_TYPE:
				return 'quotationCreated'
			case QUOTATION_ACTIVATED_EVENT_TYPE:
				return 'quotationActivated'
			case CONTRACT_COMMENT_CREATED_EVENT_TYPE:
				if (notification.metadata.author?.type === SHIPPER_TYPE) {
					return 'shipperCommentedOnAContract'
				}
				return 'contractCommentCreated'
			case PERMISSIONS_UPDATED_EVENT_TYPE:
				return 'accessSettingsUpdated'
			default:
				if (notification.metadata && notification.metadata.truckQuantity) {
					return 'bookingMadeFor'
				}
				return ''
		}
	}
}

export const getPrebuiltMessages = (role) => {
	switch (role) {
		case ADMIN_TYPE:
		case DISPATCHER_TYPE:
			return [
				'messageAdminDriverPickUp',
				'messageAdminDriverDropOff',
				'messageAdminShipmentLeft',
				'messageAdminHighwayJam',
				'messageAdminFollowUp',
				'messageAdminGood'
			]
		case CARRIER_TYPE:
			return [
				'messageCarrierPickUp',
				'messageCarrierDropOff',
				'messageCarrierShipmentLeft',
				'messageCarrierHighwayJam',
				'messageCarrierFollowUp'
			]
		case SHIPPER_TYPE:
			return [
				'messageShipperWhere',
				'messageShipperDelayed',
				'messageShipperPacking',
				'messageShipperIssues'
			]
		default:
			return []
	}
}

export const getItemTagColor = (itemTag) => {
	switch (itemTag) {
		case 'dry_load':
		case 'palletized':
		case 'loose':
			return '#13c047'
		case 'keep_refrigerated':
			return '#47dbf2'
		case 'handle_with_care':
		case 'high_priority':
			return '#f86110'
		case 'request_return_shipment':
			return '#a547f2'
		case 'dangerous_goods':
			return '#ff3232'
		case 'weighing_station':
			return 'red'
		case 'retail':
			return '#32addd'
		default:
			return '#ffffff'
	}
}

export const getItemTagName = (itemTag) => {
	switch (itemTag) {
		case 'dry_load':
			return 'dryLoad'
		case 'palletized':
			return 'palletized'
		case 'loose':
			return 'loose'
		case 'keep_refrigerated':
			return 'keepRefrigerated'
		case 'request_return_shipment':
			return 'requestReturnShipment'
		case 'handle_with_care':
			return 'handleWithCare'
		case 'high_priority':
			return 'highPriority'
		case 'dangerous_goods':
			return 'dangerousGoods'
		case 'weighing_station':
			return 'weighingStation'
		default:
			return itemTag
	}
}

export const getEventName = (eventType) => {
	switch (eventType) {
		case REQUESTED_EVENT_TYPE:
			return 'bookingRequested'
		case DISPATCHED_EVENT_TYPE:
			return 'bookingDispatched'
		case DRIVER_ACCEPTED_EVENT_TYPE:
			return 'driverAcceptedBooking'
		case DRIVER_PICK_UP_EVENT_TYPE:
			return 'driverArrivedAtPickUp'
		case DRIVER_ENROUTE_EVENT_TYPE:
			return 'driverEnrouteToDropoff'
		case DRIVER_DROP_OFF_EVENT_TYPE:
			return 'driverArrivedAtDropOff'
		case DRIVER_INSIDE_FACILITY_EVENT_TYPE:
			return 'driverInsideFacility'
		case COMPLETED_EVENT_TYPE:
			return 'bookingCompleted'
		default:
			return ''
	}
}

export const rateReasons = [
	{ label:'Regular', value: REGULAR },
	{ label:'Festival (Eid Ul Adha)', value: FESTIVAL_EID_UL_ADHA },
	{ label:'Festival (Eid Ul Fitr)', value: FESTIVAL_EID_UL_FITR },
	{ label:'Festival (Diwali)', value: FESTIVAL_DIWALI },
	{ label:'Festival (Durga Puja)', value: FESTIVAL_DURGA_PUJA },
	{ label:'Festival (Krishna Janmastami)', value: FESTIVAL_KRISHNA_JANMASTAMI },
	{ label:'Low Truck Supply (Strike)', value: LOW_TRUCK_SUPPLY_STRIKE },
	{ label:'Low Truck Supply (Natural Disaster)', value: LOW_TRUCK_SUPPLY_NATURAL_DISASTER },
	{ label:'Low Truck Supply (Political Crisis)', value: LOW_TRUCK_SUPPLY_POLITICAL_CRISIS },
	{ label:'High Truck Supply', value: HIGH_TRUCK_SUPPLY },
	{ label:'High Truck Demand', value: HIGH_TRUCK_DEMAND },
	{ label:'Low Truck Demand', value: LOW_TRUCK_DEMAND },
	{ label:'Elections', value: ELECTIONS }
]

export const getRateReasonLabel = (reason) => {
	switch (reason) {
		case REGULAR:
			return 'Regular'
		case FESTIVAL_EID_UL_ADHA:
			return 'Festival (Eid Ul Adha)'
		case FESTIVAL_EID_UL_FITR:
			return 'Festival (Eid Ul Fitr)'
		case FESTIVAL_DIWALI:
			return 'Festival (Diwali)'
		case FESTIVAL_DURGA_PUJA:
			return 'Festival (Durga Puja)'
		case FESTIVAL_KRISHNA_JANMASTAMI:
			return 'Festival (Krishna Janmastami)'
		case LOW_TRUCK_SUPPLY_STRIKE:
			return 'Low Truck Supply (Strike)'
		case LOW_TRUCK_SUPPLY_NATURAL_DISASTER:
			return 'Low Truck Supply (Natural Disaster)'
		case LOW_TRUCK_SUPPLY_POLITICAL_CRISIS:
			return 'Low Truck Supply (Political Crisis)'
		case HIGH_TRUCK_SUPPLY:
			return 'High Truck Supply'
		case HIGH_TRUCK_DEMAND:
			return 'High Truck Demand'
		case LOW_TRUCK_DEMAND:
			return 'Low Truck Demand'
		case ELECTIONS:
			return 'Elections'
		default:
			return reason
	}
}

export const shipmentGraphColors = {
	requested: '#0071CD',
	scheduled: '#0EA5E9',
	active: '#16A34A',
	completed: '#0D9488',
	cancelled: '#DC2626',
	expired: '#F97316'
}

export const weekdayOptions = [
	{ value: 'Saturday', label: 'Saturday' },
	{ value: 'Sunday', label: 'Sunday' },
	{ value: 'Monday', label: 'Monday' },
	{ value: 'Tuesday', label: 'Tuesday' },
	{ value: 'Wednesday', label: 'Wednesday' },
	{ value: 'Thursday', label: 'Thursday' },
	{ value: 'Friday', label: 'Friday' }
]

export const ReportMonthlyOptions = [
	{ value: 0, label: 'Last Day of Every Month' },
	{ value: 7, label: '7th of Every Month' }
]

export const paymentMethodOptions = [
	{ value: 'cash', label: 'Cash' },
	{ value: 'credit_line', label: 'Credit Line' }
]

export const graphColors = [
	'#111E6C',
	'#000080',
	'#0080FF',
	'#6593F5',
	'#73C2FB',
	'#57A0D3',
	'#89CFF0',
	'#95C8D8',
	'#1D2951',
	'#0E4D92',
	'#0F52BA',
	'#4C516D',
	'#7285A5',
	'#4682B4',
	'#588BAE',
	'#B0DFE5',
	'#003152',
	'#1034A6',
	'#008ECC',
	'#008081',
	'#4F97A3',
	'#81D8D0',
	'#7EF9FF',
	'#3FE0D0',
	'#109BF8'
]
