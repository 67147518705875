import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withFirebase } from '../firebase'
import { connect } from 'react-redux'
import { authenticationActions } from '../../../redux-modules/authentication'
import { ADMIN_TYPE, DISPATCHER_TYPE, SHIPPER_TYPE } from '../../../constants/strings'
import {
	DEFAULT_ADMIN_PERMISSIONS,
	DEFAULT_SHIPPER_PERMISSIONS,
	DEFAULT_DISPATCHER_PERMISSIONS
} from '../../../constants/permissions'

const withPermissions = Component => {
	class WithPermissions extends React.Component {

		componentDidMount () {
			this.props.firebase.onAuthUserListener((authUser, role, permissions) => {
				this.listenToPermissions(authUser.uid)
				if (permissions) {
					this.props.setPermissions(permissions)
				} else { // Set default permissions.
					let defaultPermissions = { }
					switch (role) {
						case DISPATCHER_TYPE:
							defaultPermissions = DEFAULT_DISPATCHER_PERMISSIONS
							break
						case ADMIN_TYPE:
							defaultPermissions = DEFAULT_ADMIN_PERMISSIONS
							break
						case SHIPPER_TYPE:
							defaultPermissions = DEFAULT_SHIPPER_PERMISSIONS
							break
						default:
							defaultPermissions = {}
							break
					}
					this.props.setPermissions(defaultPermissions)
				}
			})
		}

		componentWillUnmount () {
			this.props.firebase.detachPermissions()
		}

		listenToPermissions = (uid) => {
			const currentDate = new Date()
			this.props.firebase.detachPermissions()
			this.props.firebase.permissions(uid, doc => {
				const permissions = doc.data()
				if (permissions && permissions.updatedAt) {
					if (permissions.updatedAt.toDate().getTime() > currentDate.getTime()) {
						// Force refresh of ID token.
						this.props.firebase.onTokenRefresh(permissions => {
							if (permissions) {
								this.props.setPermissions(permissions)
							}
						})
						this.props.firebase.refreshToken()
					}
				}
			})
		}

		render () {
			return (
				<Component {...this.props} />
			)
		}
	}

	WithPermissions.propTypes = {
		firebase: PropTypes.object,
		setPermissions: PropTypes.func
	}

	const mapDispatchToProps = {
		setPermissions: authenticationActions.setPermissions
	}

	return compose(
		withRouter,
		withFirebase,
		connect(null, mapDispatchToProps)
	)(WithPermissions)
}

export default withPermissions
