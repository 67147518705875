import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import authentication, * as fromAuthentication from './redux-modules/authentication'
import user, * as userActions from './redux-modules/user'
import shipments, * as shipmentActions from './redux-modules/shipments'
import drivers, * as driverActions from './redux-modules/drivers'
import trucks, * as truckActions from './redux-modules/trucks'
import admin, * as adminActions from './redux-modules/admin'
import messaging, * as messageActions from './redux-modules/messages'

export default (history) => combineReducers({
	router: connectRouter(history),
	authentication,
	user,
	shipments,
	drivers,
	trucks,
	admin,
	messaging
})

// Authentication reducers
export const getAuthenticationUser = state => fromAuthentication.getAuthUser(state.authentication)
export const getUserLogin = state => fromAuthentication.getUserLogin(state.authentication)
export const getPermissions = state => fromAuthentication.getPermissions(state.authentication)

// Users reducers
export const getDeliveryLocations = state => userActions.getDeliveryLocations(state.user)
export const getIsFetchingDeliveryLocations = state => userActions.getIsFetchingDeliveryLocations(state.user)
export const getIsFetchingNotifications = state => userActions.getIsFetchingNotifications(state.user)
export const getIsFetchingMoreNotifications = state => userActions.getIsFetchingMoreNotifications(state.user)
export const getNotifications = state => userActions.getNotifications(state.user)
export const getIsNotificationsRead = state => userActions.getIsNotificationsRead(state.user)
export const getFilter = state => userActions.getFilter(state.user)
export const getCurrentUserBankDetails = state => userActions.getBankDetails(state.user)
export const getMatchingUsers = state => userActions.getMatchingUsers(state.user)
export const getIsSearchingUsers = state => userActions.getIsSearchingUsers(state.user)
export const getIsSearchingMoreUsers = state => userActions.getIsSearchingMoreUsers(state.user)
export const getShipmentCounts = state => userActions.getShipmentCounts(state.user)
export const getShipmentTotals = state => userActions.getShipmentTotals(state.user)
export const getShipmentHistories = state => userActions.getShipmentHistories(state.user)
export const getCarrierCounts = state => userActions.getCarrierCounts(state.user)
export const getOptions = state => userActions.getOptions(state.user)
export const getShipperContracts = state => userActions.getShipperContracts(state.user)
export const getShipperQuotations = state => userActions.getShipperQuotations(state.user)
export const getIsFetchingShipperContracts = state => userActions.getIsFetchingShipperContracts(state.user)
export const getAllShipperShipmentCounts = state => userActions.getAllShipperShipmentCounts(state.user)
export const getWeeklyShipperShipmentCounts = state => userActions.getWeeklyShipperShipmentCounts(state.user)
export const getMonthlyShipperShipmentCounts = state => userActions.getMonthlyShipperShipmentCounts(state.user)
export const getUnreadNotificationCount = state => userActions.getUnreadNotificationCount(state.user)
export const getLocations = state => userActions.getLocations(state.user)
export const getShipperDashboard = state => userActions.getDashboard(state.user)
export const getShipperDailyDashboard = state => userActions.getDailyDashboard(state.user)
export const getShipperLocationsDashboard = state => userActions.getLocationsDashboard(state.user)
export const getShipperBills = state => userActions.getShipperBills(state.user)
export const getMatchingShipperBills = state => userActions.getMatchingShipperBills(state.user)
export const getIsSearchingShipperBills = state => userActions.getIsSearchingShipperBills(state.user)
export const getIsLoadingMoreSearchedShipperBills = state => userActions.getIsLoadingMoreSearchedShipperBills(state.user)
export const getMatchingCompanyLocations = state => userActions.getMatchingCompanyLocations(state.user)
export const getIsSearchingCompanyLocations = state => userActions.getIsSearchingCompanyLocations(state.user)
export const getIsLoadingMoreSearchedCompanyLocations = state => userActions.getIsLoadingMoreSearchedCompanyLocations(state.user)
export const getShipmentsFilter = state => userActions.getShipmentsFilter(state.user)
export const getBookingsFilter = state => userActions.getBookingsFilter(state.user)
export const getNotificationsInfo = state => userActions.getNotificationsInfo(state.user)

// Shipments reducers
export const isBookingShipment = state => shipmentActions.getIsBookingShipment(state.shipments)
export const getActiveShipments = state => shipmentActions.getActiveShipments(state.shipments)
export const getScheduledShipments = state => shipmentActions.getScheduledShipments(state.shipments)
export const getRequestedShipments = state => shipmentActions.getRequestedShipments(state.shipments)
export const getCompletedShipments = state => shipmentActions.getCompletedShipments(state.shipments)
export const getCancelledShipments = state => shipmentActions.getCancelledShipments(state.shipments)
export const getExpiredShipments = state => shipmentActions.getExpiredShipments(state.shipments)
export const getIsFetchingShipments = state => shipmentActions.getIsFetchingShipments(state.shipments)
export const getIsFetchingShipment = state => shipmentActions.getIsFetchingShipment(state.shipments)
export const getShipmentDetails = state => shipmentActions.getShipmentDetails(state.shipments)
export const getRequestedCarrierShipments = state => shipmentActions.getRequestedCarrierShipments(state.shipments)
export const getScheduledCarrierShipments = state => shipmentActions.getScheduledCarrierShipments(state.shipments)
export const getAssignedCarrierShipments = state => shipmentActions.getAssignedCarrierShipments(state.shipments)
export const getCompletedCarrierShipments = state => shipmentActions.getCompletedCarrierShipments(state.shipments)
export const getIsFetchingBooking = state => shipmentActions.getIsFetchingBooking(state.shipments)
export const getBookingDetails = state => shipmentActions.getBookingDetails(state.shipments)
export const getLoadboardShipments = state => shipmentActions.getLoadboardShipments(state.shipments)
export const getMatchingShipments = state => shipmentActions.getMatchingShipments(state.shipments)
export const getIsSearchingShipments = state => shipmentActions.getIsSearchingShipments(state.shipments)
export const getIsSearchingMoreShipments = state => shipmentActions.getIsSearchingMoreShipments(state.shipments)
export const getShipperBookings = state => shipmentActions.getShipperBookings(state.shipments)
export const getIsFetchingShipperBookings = state => shipmentActions.getIsFetchingShipperBookings(state.shipments)
export const getIsModifyingRequestedShipments = state => shipmentActions.getIsModifyingRequestedShipments(state.shipments)
export const getTransaction = state => shipmentActions.getTransaction(state.shipments)
export const getIsUpdatingBooking = state => shipmentActions.getIsUpdatingBooking(state.shipments)
export const getMatchingTransactions = state => shipmentActions.getMatchingTransactions(state.shipments)
export const getIsSearchingTransactions = state => shipmentActions.getIsSearchingTransactions(state.shipments)
export const getMatchingBookings = state => shipmentActions.getMatchingBookings(state.shipments)
export const getIsSearchingBookings = state => shipmentActions.getIsSearchingBookings(state.shipments)
export const getIsSearchingMoreBookings = state => shipmentActions.getIsSearchingMoreBookings(state.shipments)

// Drivers reducers
export const getAllDrivers = state => driverActions.getAllDrivers(state.drivers)
export const getNewDrivers = state => driverActions.getNewDrivers(state.drivers)
export const getApprovedDrivers = state => driverActions.getApprovedDrivers(state.drivers)
export const getDeclinedDrivers = state => driverActions.getDeclinedDrivers(state.drivers)
export const getFreeDrivers = state => driverActions.getFreeDrivers(state.drivers)
export const getBusyDrivers = state => driverActions.getBusyDrivers(state.drivers)
export const getInactiveDrivers = state => driverActions.getInactiveDrivers(state.drivers)
export const getDriverTasks = state => driverActions.getDriverTasks(state.drivers)
export const getDriverLocations = state => driverActions.getDriverLocations(state.drivers)
export const getIsFetchingDrivers = state => driverActions.getIsFetchingDrivers(state.drivers)
export const getUnassignedDrivers = state => driverActions.getUnassignedDrivers(state.drivers)

// Trucks reducers
export const getAllTrucks = state => truckActions.getAllTrucks(state.trucks)
export const getActivatedTrucks = state => truckActions.getActivatedTrucks(state.trucks)
export const getDeactivatedTrucks = state => truckActions.getDeactivatedTrucks(state.trucks)
export const getUnassignedTrucks = state => truckActions.getUnassignedTrucks(state.trucks)
export const getIsFetchingMoreTrucks = state => truckActions.getIsFetchingMoreTrucks(state.trucks)
export const getIsFetchingTrucks = state => truckActions.getIsFetchingTrucks(state.trucks)
export const getMatchingTrucks = state => truckActions.getMatchingTrucks(state.trucks)
export const getIsSearchingTrucks = state => truckActions.getIsSearchingTrucks(state.trucks)
export const getIsSearchingMoreTrucks = state => truckActions.getIsSearchingMoreTrucks(state.trucks)
export const getAvailableTrucks = state => truckActions.getAvailableTrucks(state.trucks)
export const getUnavailableTrucks = state => truckActions.getUnavailableTrucks(state.trucks)

// Admin reducer
export const getAllShipments = state => adminActions.getShipments(state.admin)
export const getAllRequestedShipments = state => adminActions.getRequestedShipments(state.admin)
export const getAllScheduledShipments = state => adminActions.getScheduledShipments(state.admin)
export const getAllAssignedShipments = state => adminActions.getAssignedShipments(state.admin)
export const getAllUnassignedShipments = state => adminActions.getUnassignedShipments(state.admin)
export const getAllActiveShipments = state => adminActions.getActiveShipments(state.admin)
export const getAllCompletedShipments = state => adminActions.getCompletedShipments(state.admin)
export const getAllCancelledShipments = state => adminActions.getCancelledShipments(state.admin)
export const getAllExpiredShipments = state => adminActions.getExpiredShipments(state.admin)
export const getAllWatchingShipments = state => adminActions.getWatchingShipments(state.admin)
export const getAllAdminAssignedShipments = state => adminActions.getAdminAssignedShipments(state.admin)
export const getAllUsers = state => adminActions.getUsers(state.admin)
export const getAllShipperUsers = state => adminActions.getShipperUsers(state.admin)
export const getAllCarrierUsers = state => adminActions.getCarrierUsers(state.admin)
export const getAllDriverUsers = state => adminActions.getDriverUsers(state.admin)
export const getAllAgentUsers = state => adminActions.getAgentUsers(state.admin)
export const getAllDispatcherUsers = state => adminActions.getDispatcherUsers(state.admin)
export const getAllRates = state => adminActions.getRates(state.admin)
export const getCurrentRate = state => adminActions.getCurrentRate(state.admin)
export const getAllTransactions = state => adminActions.getTransactions(state.admin)
export const getIsFetching = state => adminActions.getIsFetching(state.admin)
export const getContractedRates = state => adminActions.getContractedRates(state.admin)
export const getAllBookings = state => adminActions.getBookings(state.admin)
export const getRequestedBookings = state => adminActions.getRequestedBookings(state.admin)
export const getOngoingBookings = state => adminActions.getOngoingBookings(state.admin)
export const getBoundingShipments = state => adminActions.getBoundingShipments(state.admin)
export const getGlobalCounts = state => adminActions.getGlobalCounts(state.admin)
export const getWeeklyGlobalCounts = state => adminActions.getWeeklyGlobalCounts(state.admin)
export const getMonthlyGlobalCounts = state => adminActions.getMonthlyGlobalCounts(state.admin)
export const getGlobalHistory = state => adminActions.getGlobalHistory(state.admin)
export const getGlobalFares = state => adminActions.getGlobalFares(state.admin)
export const getRateHistories = state => adminActions.getRateHistories(state.admin)
export const getIsFetchingTransactions = state => adminActions.getIsFetchingTransactions(state.admin)
export const getBoundingUsers = state => adminActions.getBoundingUsers(state.admin)
export const getMatchingRates = state => adminActions.getMatchingRates(state.admin)
export const getIsSearchingRates = state => adminActions.getIsSearchingRates(state.admin)
export const getContracts = state => adminActions.getContracts(state.admin)
export const getPendingContracts = state => adminActions.getPendingContracts(state.admin)
export const getBills = state => adminActions.getBills(state.admin)
export const getMatchingBills = state => adminActions.getMatchingBills(state.admin)
export const getIsSearchingBills = state => adminActions.getIsSearchingBills(state.admin)
export const getIsLoadingMoreSearchedBills = state => adminActions.getIsLoadingMoreSearchedBills(state.admin)
export const getIsFetchingBills = state => adminActions.getIsFetchingBills(state.admin)
export const getContractedShippers = state => adminActions.getContractedShippers(state.admin)
export const getShipperContractRates = state => adminActions.getShipperContractRates(state.admin)
export const getAdminNotifications = state => adminActions.getAdminNotifications(state.admin)
export const getIsFetchingAdminNotifications = state => adminActions.getIsFetchingAdminNotifications(state.admin)
export const getIsFetchingMoreAdminNotifications = state => adminActions.getIsFetchingMoreAdminNotifications(state.admin)
export const getShipperHistories = state => adminActions.getShipperHistories(state.admin)
export const getRecentlyActiveShippers = state => adminActions.getRecentlyActiveShippers(state.admin)
export const getIsMassAssigningShipments = state => adminActions.getIsMassAssigningShipments(state.admin)
export const getRevenueHistory = state => adminActions.getRevenueHistory(state.admin)
export const getAllCompanies = state => adminActions.getAllCompanies(state.admin)
export const getCompanies = state => adminActions.getCompanies(state.admin)
export const getDashboard = state => adminActions.getDashboard(state.admin)
export const getDailyDashboard = state => adminActions.getDailyDashboard(state.admin)
export const getIsFetchingMoreReports = state => adminActions.getIsFetchingMoreReports(state.messaging)
export const getAllReports = state => adminActions.getReports(state.admin)
export const getAnnualDashboard = state => adminActions.getAnnualDashboard(state.admin)
export const getBill = state => adminActions.getBill(state.admin)
export const getUsersDashboard = state => adminActions.getUsersDashboard(state.admin)
export const getEventsDashboard = state => adminActions.getEventsDashboard(state.admin)
export const getMatchingCompanies = state => adminActions.getMatchingCompanies(state.admin)
export const getIsSearchingCompanies = state => adminActions.getIsSearchingCompanies(state.admin)
export const getIsLoadingMoreSearchedCompanies = state => adminActions.getIsLoadingMoreSearchedCompanies(state.admin)
export const getMatchingContracts = state => adminActions.getMatchingContracts(state.admin)
export const getIsSearchingContracts = state => adminActions.getIsSearchingContracts(state.admin)
export const getIsLoadingMoreSearchedContracts = state => adminActions.getIsLoadingMoreSearchedContracts(state.admin)
export const getVendorRequests = state => adminActions.getVendorRequests(state.admin)
export const getDailySummary = state => adminActions.getDailySummary(state.admin)
export const getMonthlyGeneralAnalytics = state => adminActions.getMonthlyGeneralAnalytics(state.admin)
export const getMonthlyOperationsAnalytics = state => adminActions.getMonthlyOperationsAnalytics(state.admin)
export const getContractDetails = state => adminActions.getContractDetails(state.admin)
export const getIsFetchingContract = state => adminActions.getIsFetchingContract(state.admin)
export const getMonthlyVendorAnalytics = state => adminActions.getMonthlyVendorAnalytics(state.admin)
export const getAnnualTrendsAnalytics = state => adminActions.getAnnualTrendsAnalytics(state.admin)

// Message reducers
export const getMessages = state => messageActions.getMessages(state.messaging)
export const getChats = state => messageActions.getChats(state.messaging)
export const getIsChatsRead = state => messageActions.getIsChatsRead(state.messaging)
export const getIsFetchingChats = state => messageActions.getIsFetchingChats(state.messaging)
export const getIsFetchingMoreChats = state => messageActions.getIsFetchingMoreChats(state.messaging)
export const getIsConversationsRead = state => messageActions.getIsConversationsRead(state.messaging)
export const getConversations = state => messageActions.getConversations(state.messaging)
export const getConversationMessages = state => messageActions.getConversationMessages(state.messaging)
