import {
	FETCH_DELIVERY_LOCATIONS_REQUEST,
	FETCH_DELIVERY_LOCATIONS_SUCCESS,
	FETCH_DELIVERY_LOCATIONS_FAILURE,
	ADD_NOTIFICATION,
	CLEAR_NOTIFICATIONS,
	UPDATE_NOTIFICATION,
	READ_NOTIFICATION,
	UPDATE_FILTER_REQUEST,
	UPDATE_FILTER_SUCCESS,
	UPDATE_FILTER_FAILURE,
	FETCH_FILTER_REQUEST,
	FETCH_FILTER_SUCCESS,
	FETCH_FILTER_FAILURE,
	ADD_BANK_DETAIL_REQUEST,
	ADD_BANK_DETAIL_SUCCESS,
	ADD_BANK_DETAIL_FAILURE,
	FETCH_BANK_DETAILS_REQUEST,
	FETCH_BANK_DETAILS_SUCCESS,
	FETCH_BANK_DETAILS_FAILURE,
	FETCH_BANK_DETAIL_REQUEST,
	FETCH_BANK_DETAIL_SUCCESS,
	FETCH_BANK_DETAIL_FAILURE,
	UPDATE_BANK_DETAIL_FAILURE,
	UPDATE_BANK_DETAIL_REQUEST,
	UPDATE_BANK_DETAIL_SUCCESS,
	SEARCH_USERS_REQUEST,
	SEARCH_USERS_SUCCESS,
	SEARCH_USERS_FAILURE,
	FETCH_SHIPPER_SHIPMENT_COUNTS_REQUEST,
	FETCH_SHIPPER_SHIPMENT_COUNTS_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_COUNTS_FAILURE,
	FETCH_SHIPPER_SHIPMENT_HISTORY_REQUEST,
	FETCH_SHIPPER_SHIPMENT_HISTORY_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_HISTORY_FAILURE,
	FETCH_CARRIER_COUNTS_REQUEST,
	FETCH_CARRIER_COUNTS_SUCCESS,
	FETCH_CARRIER_COUNTS_FAILURE,
	UPDATE_NOTIFICATION_COUNT_SUCCESS,
	FETCH_NOTIFICATIONS_REQUEST,
	FETCH_NOTIFICATIONS_FAILURE,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_MORE_NOTIFICATIONS_FAILURE,
	FETCH_MORE_NOTIFICATIONS_REQUEST,
	FETCH_MORE_NOTIFICATIONS_SUCCESS,
	FETCH_TRANSACTIONS_REQUEST,
	FETCH_TRANSACTIONS_FAILURE,
	FETCH_TRANSACTIONS_SUCCESS,
	FETCH_MORE_TRANSACTIONS_FAILURE,
	FETCH_MORE_TRANSACTIONS_REQUEST,
	FETCH_MORE_TRANSACTIONS_SUCCESS,
	FETCH_ITEM_TYPES_FAILURE,
	FETCH_ITEM_TYPES_REQUEST,
	FETCH_ITEM_TYPES_SUCCESS,
	FETCH_TRUCK_TYPES_SUCCESS,
	FETCH_TRUCK_TYPES_FAILURE,
	FETCH_TRUCK_TYPES_REQUEST,
	FETCH_BANK_OPTIONS_SUCCESS,
	FETCH_BANK_OPTIONS_FAILURE,
	FETCH_BANK_OPTIONS_REQUEST,
	FETCH_TRUCK_BRANDS_MODELS_FAILURE,
	FETCH_TRUCK_BRANDS_MODELS_REQUEST,
	FETCH_TRUCK_BRANDS_MODELS_SUCCESS,
	FETCH_SHIPPER_CONTRACTS_FAILURE,
	FETCH_SHIPPER_CONTRACTS_REQUEST,
	FETCH_SHIPPER_CONTRACTS_SUCCESS,
	FETCH_MORE_SHIPPER_CONTRACTS_FAILURE,
	FETCH_MORE_SHIPPER_CONTRACTS_REQUEST,
	FETCH_MORE_SHIPPER_CONTRACTS_SUCCESS,
	UPDATE_USER_STATS,
	FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_FAILURE,
	FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_REQUEST,
	FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_FAILURE,
	FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_REQUEST,
	FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_REQUEST,
	FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_FAILURE,
	FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_SUCCESS,
	FETCH_LOCATIONS_REQUEST,
	FETCH_LOCATIONS_SUCCESS,
	FETCH_LOCATIONS_FAILURE,
	FETCH_MORE_LOCATIONS_REQUEST,
	FETCH_MORE_LOCATIONS_SUCCESS,
	FETCH_MORE_LOCATIONS_FAILURE,
	READ_ALL_NOTIFICATIONS_REQUEST,
	READ_ALL_NOTIFICATIONS_FAILURE,
	READ_ALL_NOTIFICATIONS_SUCCESS,
	DELETE_NOTIFICATION_SUCCESS,
	DELETE_NOTIFICATION_FAILURE,
	DELETE_NOTIFICATION_REQUEST,
	FETCH_DASHBOARD_REQUEST,
	FETCH_DASHBOARD_SUCCESS,
	FETCH_DASHBOARD_FAILURE,
	FETCH_LOCATIONS_DASHBOARD_REQUEST,
	FETCH_LOCATIONS_DASHBOARD_SUCCESS,
	FETCH_LOCATIONS_DASHBOARD_FAILURE,
	FETCH_SHIPPER_QUOTATIONS_SUCCESS,
	FETCH_SHIPPER_QUOTATIONS_REQUEST,
	FETCH_SHIPPER_QUOTATIONS_FAILURE,
	FETCH_MORE_SHIPPER_QUOTATIONS_FAILURE,
	FETCH_MORE_SHIPPER_QUOTATIONS_REQUEST,
	FETCH_MORE_SHIPPER_QUOTATIONS_SUCCESS,
	ADD_SHIPPER_QUOTATION_REQUEST,
	ADD_SHIPPER_QUOTATION_FAILURE,
	ADD_SHIPPER_QUOTATION_SUCCESS,
	FETCH_SHIPPER_BILLS_SUCCESS,
	FETCH_SHIPPER_BILLS_REQUEST,
	FETCH_SHIPPER_BILLS_FAILURE,
	FETCH_MORE_SHIPPER_BILLS_FAILURE,
	FETCH_MORE_SHIPPER_BILLS_REQUEST,
	FETCH_MORE_SHIPPER_BILLS_SUCCESS,
	SEARCH_MORE_SHIPPER_BILLS_FAILURE,
	SEARCH_MORE_SHIPPER_BILLS_REQUEST,
	SEARCH_MORE_SHIPPER_BILLS_SUCCESS,
	SEARCH_SHIPPER_BILLS_FAILURE,
	SEARCH_SHIPPER_BILLS_REQUEST,
	SEARCH_SHIPPER_BILLS_SUCCESS,
	SEARCH_COMPANY_LOCATIONS_FAILURE,
	SEARCH_COMPANY_LOCATIONS_REQUEST,
	SEARCH_COMPANY_LOCATIONS_SUCCESS,
	SEARCH_MORE_COMPANY_LOCATIONS_FAILURE,
	SEARCH_MORE_COMPANY_LOCATIONS_REQUEST,
	SEARCH_MORE_COMPANY_LOCATIONS_SUCCESS,
	SEARCH_MORE_USERS_FAILURE,
	SEARCH_MORE_USERS_REQUEST,
	SEARCH_MORE_USERS_SUCCESS,
	UPDATE_SHIPMENTS_FILTER,
	UPDATE_BOOKINGS_FILTER,
	UPDATE_NOTIFICATIONS_INFO_SUCCESS
} from './action-types'
import API from '../../utils/api'
import { CONTRACT_ACTIVE_STATUS, CONTRACT_PENDING_STATUS } from '../../constants/strings'
import { FETCH_CONTRACT_FAILURE, FETCH_CONTRACT_REQUEST, FETCH_CONTRACT_SUCCESS } from '../admin/action-types'

function getDeliveryLocations (userId) {
	return (dispatch) => {
		dispatch({ type: FETCH_DELIVERY_LOCATIONS_REQUEST })
		return API.getDeliveryLocations(userId)
			.then(
				response => dispatch({
					type: FETCH_DELIVERY_LOCATIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_DELIVERY_LOCATIONS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchNotifications (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId? FETCH_MORE_NOTIFICATIONS_REQUEST : FETCH_NOTIFICATIONS_REQUEST })
		return API.fetchNotifications(lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId? FETCH_MORE_NOTIFICATIONS_SUCCESS : FETCH_NOTIFICATIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId? FETCH_MORE_NOTIFICATIONS_FAILURE : FETCH_NOTIFICATIONS_FAILURE,
					payload: error
				})
			)
	}
}

function updateNotificationCount (count) {
	return (dispatch) => {
		dispatch({ type: UPDATE_NOTIFICATION_COUNT_SUCCESS, payload: count })
	}
}

function updateNotificationsInfo (info) {
	return (dispatch) => {
		dispatch({ type: UPDATE_NOTIFICATIONS_INFO_SUCCESS, payload: info })
	}
}

function addNotification (notification) {
	return (dispatch) => {
		dispatch({ type: ADD_NOTIFICATION, payload: notification })
	}
}

function clearNotifications () {
	return (dispatch) => {
		dispatch({ type: CLEAR_NOTIFICATIONS })
	}
}

function updateNotification (notification) {
	return (dispatch) => {
		dispatch({ type: UPDATE_NOTIFICATION, payload: notification })
	}
}

function readNotification (notification) {
	return (dispatch) => {
		dispatch({ type: READ_NOTIFICATION, payload: notification })
	}
}

function updateFilter (filter) {
	return (dispatch) => {
		dispatch({ type: UPDATE_FILTER_REQUEST })
		return API.updateFilter(filter)
			.then(
				response => dispatch({
					type: UPDATE_FILTER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_FILTER_FAILURE,
					payload: error
				})
			)
	}
}

function getFilter () {
	return (dispatch) => {
		dispatch({ type: FETCH_FILTER_REQUEST })
		return API.getFilter()
			.then(
				response => dispatch({
					type: FETCH_FILTER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_FILTER_FAILURE,
					payload: error
				})
			)
	}
}

function getBankDetails (userId) {
	return (dispatch) => {
		dispatch({ type: FETCH_BANK_DETAILS_REQUEST })
		return API.getBankDetails(userId)
			.then(
				response => dispatch({
					type: FETCH_BANK_DETAILS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_BANK_DETAILS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBankDetail (id, userId) {
	return (dispatch) => {
		dispatch({ type: FETCH_BANK_DETAIL_REQUEST })
		return API.getBankDetailById(id, userId)
			.then(
				response => dispatch({
					type: FETCH_BANK_DETAIL_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_BANK_DETAIL_FAILURE,
					payload: error
				})
			)
	}
}

function addBankDetail (bankData, userId) {
	return (dispatch) => {
		dispatch({ type: ADD_BANK_DETAIL_REQUEST })
		return API.addBankDetail(bankData, userId)
			.then(
				response => dispatch({
					type: ADD_BANK_DETAIL_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_BANK_DETAIL_FAILURE,
					payload: error
				})
			)
	}
}

function updateBankDetail (accountId, bankData, userId) {
	return (dispatch) => {
		dispatch({ type: UPDATE_BANK_DETAIL_REQUEST })
		return API.updateBankDetail(accountId, bankData, userId)
			.then(
				response => dispatch({
					type: UPDATE_BANK_DETAIL_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_BANK_DETAIL_FAILURE,
					payload: error
				})
			)
	}
}

function searchUsers (searchTerm, type, disabled, pageNumber) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_USERS_REQUEST : SEARCH_USERS_REQUEST })
		return API.searchUsers(searchTerm, type, disabled, pageNumber)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_USERS_SUCCESS : SEARCH_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_USERS_FAILURE : SEARCH_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function searchDrivers (searchTerm, pageNumber, status, driverStatus) {
	return (dispatch) => {
		dispatch({ type: SEARCH_USERS_REQUEST })
		return API.searchCarrierDrivers(searchTerm, pageNumber, status, driverStatus)
			.then(
				response => dispatch({
					type: SEARCH_USERS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: SEARCH_USERS_FAILURE,
					payload: error
				})
			)
	}
}

function getShipmentCounts (shipperId) {
	return (dispatch) => {
		dispatch({ type: FETCH_SHIPPER_SHIPMENT_COUNTS_REQUEST })
		return API.getShipperShipmentCountsAnalytics(shipperId)
			.then(
				response => dispatch({
					type: FETCH_SHIPPER_SHIPMENT_COUNTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_SHIPPER_SHIPMENT_COUNTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCumulativeShipperShipmentCounts (interval, shipperId) {
	return (dispatch) => {
		dispatch({ type: interval === 'week' ? FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_REQUEST : interval === 'month' ? FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_REQUEST : FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_REQUEST })
		return API.getCumulativeShipperShipmentCounts(interval, shipperId)
			.then(
				response => dispatch({
					type: interval === 'week' ? FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_SUCCESS : interval === 'month' ? FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_SUCCESS : FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: interval === 'week' ? FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_FAILURE : interval === 'month' ? FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_FAILURE : FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_FAILURE,
					payload: error
				})
			)
	}
}

function getShipmentHistory (interval) {
	return (dispatch) => {
		dispatch({ type: FETCH_SHIPPER_SHIPMENT_HISTORY_REQUEST })
		return API.getShipperShipmentHistoryAnalytics(interval)
			.then(
				response => dispatch({
					type: FETCH_SHIPPER_SHIPMENT_HISTORY_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_SHIPPER_SHIPMENT_HISTORY_FAILURE,
					payload: error
				})
			)
	}
}

function getCarrierCounts () {
	return (dispatch) => {
		dispatch({ type: FETCH_CARRIER_COUNTS_REQUEST })
		return API.getCarrierCountsAnalytics()
			.then(
				response => dispatch({
					type: FETCH_CARRIER_COUNTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_CARRIER_COUNTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperTransactions (lastDocumentId, sortInfo, userId) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_TRANSACTIONS_REQUEST }) : dispatch({ type: FETCH_TRANSACTIONS_REQUEST })
		return API.getShipperTransactions(lastDocumentId, userId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_TRANSACTIONS_SUCCESS : FETCH_TRANSACTIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_TRANSACTIONS_FAILURE : FETCH_TRANSACTIONS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCarrierTransactions (lastDocumentId, sortInfo, userId) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_TRANSACTIONS_REQUEST }) : dispatch({ type: FETCH_TRANSACTIONS_REQUEST })
		return API.getCarrierTransactions(lastDocumentId, userId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_TRANSACTIONS_SUCCESS : FETCH_TRANSACTIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_TRANSACTIONS_FAILURE : FETCH_TRANSACTIONS_FAILURE,
					payload: error
				})
			)
	}
}

function getTruckBrandsModels () {
	return (dispatch) => {
		dispatch({ type: FETCH_TRUCK_BRANDS_MODELS_REQUEST })
		return API.getTruckBrandsModels()
			.then(response => dispatch({
				type: FETCH_TRUCK_BRANDS_MODELS_SUCCESS,
				payload: response
			}))
			.catch(error => dispatch({
				type: FETCH_TRUCK_BRANDS_MODELS_FAILURE,
				payload: error
			}))
	}
}

function getTruckTypes (language) {
	return (dispatch) => {
		dispatch({ type: FETCH_TRUCK_TYPES_REQUEST })
		return API.getTruckTypes(language)
			.then(response => dispatch({
				type: FETCH_TRUCK_TYPES_SUCCESS,
				payload: response
			}))
			.catch(error => dispatch({
				type: FETCH_TRUCK_TYPES_FAILURE,
				payload: error
			}))
	}
}

function getItemTypes () {
	return (dispatch) => {
		dispatch({ type: FETCH_ITEM_TYPES_REQUEST })
		return API.getItemTypes()
			.then(response => dispatch({
				type: FETCH_ITEM_TYPES_SUCCESS,
				payload: response
			}))
			.catch(error => dispatch({
				type: FETCH_ITEM_TYPES_FAILURE,
				payload: error
			}))
	}
}

function getBankOptions () {
	return (dispatch) => {
		dispatch({ type: FETCH_BANK_OPTIONS_REQUEST })
		return API.getBankOptions()
			.then(response => dispatch({
				type: FETCH_BANK_OPTIONS_SUCCESS,
				payload: response
			}))
			.catch(error => dispatch({
				type: FETCH_BANK_OPTIONS_FAILURE,
				payload: error
			}))
	}
}

function addShipperQuotation (quotationData) {
	return (dispatch) => {
		dispatch({ type: ADD_SHIPPER_QUOTATION_REQUEST })
		return API.addContract(quotationData)
			.then(
				response => dispatch({
					type: ADD_SHIPPER_QUOTATION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ADD_SHIPPER_QUOTATION_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperContracts (lastDocumentId) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_SHIPPER_CONTRACTS_REQUEST }) : dispatch({ type: FETCH_SHIPPER_CONTRACTS_REQUEST })
		return API.fetchShipperContracts(CONTRACT_ACTIVE_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_CONTRACTS_SUCCESS: FETCH_SHIPPER_CONTRACTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_CONTRACTS_FAILURE : FETCH_SHIPPER_CONTRACTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchContract (contractId) {
	return (dispatch) => {
		dispatch({ type: FETCH_CONTRACT_REQUEST })
		return API.fetchContract(contractId)
			.then(
				response => dispatch({
					type: FETCH_CONTRACT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_CONTRACT_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperQuotations (lastDocumentId) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_SHIPPER_QUOTATIONS_REQUEST }) : dispatch({ type: FETCH_SHIPPER_QUOTATIONS_REQUEST })
		return API.fetchShipperContracts(CONTRACT_PENDING_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_QUOTATIONS_SUCCESS: FETCH_SHIPPER_QUOTATIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_QUOTATIONS_FAILURE : FETCH_SHIPPER_QUOTATIONS_FAILURE,
					payload: error
				})
			)
	}
}

function updateUserStats (count) {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_STATS, payload: count })
	}
}

function fetchLocations (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_LOCATIONS_REQUEST : FETCH_LOCATIONS_REQUEST })
		return API.getLocations(lastDocumentId)
			.then(response => dispatch({
				type: lastDocumentId ? FETCH_MORE_LOCATIONS_SUCCESS : FETCH_LOCATIONS_SUCCESS,
				payload: response
			}))
			.catch(error => dispatch({
				type: lastDocumentId ? FETCH_MORE_LOCATIONS_FAILURE : FETCH_LOCATIONS_FAILURE,
				payload: error
			}))
	}
}

function deleteNotification (id) {
	return (dispatch) => {
		dispatch({ type: DELETE_NOTIFICATION_REQUEST, payload: { id } })
		return API.deleteNotification(id)
			.then(response => dispatch({
				type: DELETE_NOTIFICATION_SUCCESS,
				payload: response
			}))
			.catch(error => dispatch({
				type: DELETE_NOTIFICATION_FAILURE,
				payload: error
			}))
	}
}

function readAllNotifications () {
	return (dispatch) => {
		dispatch({ type: READ_ALL_NOTIFICATIONS_REQUEST })
		return API.readAllUserNotifications()
			.then(response => dispatch({
				type: READ_ALL_NOTIFICATIONS_SUCCESS,
				payload: response
			}))
			.catch(error => dispatch({
				type: READ_ALL_NOTIFICATIONS_FAILURE,
				payload: error
			}))
	}
}

function fetchDashboard (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_DASHBOARD_REQUEST, payload: { month } })
		return API.getShipperDashboard(false, month)
			.then(
				response => dispatch({
					type: FETCH_DASHBOARD_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_DASHBOARD_FAILURE,
					payload: error
				})
			)
	}
}

function fetchLocationsDashboard (month) {
	return (dispatch) => {
		dispatch({ type: FETCH_LOCATIONS_DASHBOARD_REQUEST, payload: { month } })
		return API.getShipperLocationsDashboard(month)
			.then(
				response => dispatch({
					type: FETCH_LOCATIONS_DASHBOARD_SUCCESS,
					payload: {
						...response,
						month
					}
				}),
				error => dispatch({
					type: FETCH_LOCATIONS_DASHBOARD_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperBills (status, lastDocumentId) {
	return (dispatch) => {
		lastDocumentId ? dispatch({ type: FETCH_MORE_SHIPPER_BILLS_REQUEST }) : dispatch({ type: FETCH_SHIPPER_BILLS_REQUEST })
		return API.fetchBills(status, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_BILLS_SUCCESS: FETCH_SHIPPER_BILLS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_BILLS_FAILURE : FETCH_SHIPPER_BILLS_FAILURE,
					payload: error
				})
			)
	}
}

function searchShipperBills (searchTerm, pageNumber, filters) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_SHIPPER_BILLS_REQUEST : SEARCH_SHIPPER_BILLS_REQUEST })
		return API.searchShipperBills(searchTerm, pageNumber, filters)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_SHIPPER_BILLS_SUCCESS : SEARCH_SHIPPER_BILLS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_SHIPPER_BILLS_FAILURE : SEARCH_SHIPPER_BILLS_FAILURE,
					payload: error
				})
			)
	}
}

function searchShipperCompanyLocations (searchTerm, filters, pageNumber) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_COMPANY_LOCATIONS_REQUEST : SEARCH_COMPANY_LOCATIONS_REQUEST })
		return API.searchShipperCompanyLocations(searchTerm, pageNumber, filters)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_COMPANY_LOCATIONS_SUCCESS : SEARCH_COMPANY_LOCATIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_COMPANY_LOCATIONS_FAILURE : SEARCH_COMPANY_LOCATIONS_FAILURE,
					payload: error
				})
			)
	}
}

function updateShipmentFilter (filter) {
	return (dispatch) => {
		dispatch({ type: UPDATE_SHIPMENTS_FILTER, payload: filter })
	}
}

function updateBookingsFilter (filter) {
	return (dispatch) => {
		dispatch({ type: UPDATE_BOOKINGS_FILTER, payload: filter })
	}
}

export {
	fetchShipperTransactions,
	fetchCarrierTransactions,
	getDeliveryLocations,
	addNotification,
	fetchNotifications,
	clearNotifications,
	updateNotification,
	readNotification,
	updateNotificationCount,
	updateNotificationsInfo,
	updateFilter,
	getFilter,
	addBankDetail,
	getBankDetails,
	fetchBankDetail,
	updateBankDetail,
	searchUsers,
	getShipmentCounts,
	getShipmentHistory,
	getCarrierCounts,
	getTruckTypes,
	getItemTypes,
	getBankOptions,
	searchDrivers,
	getTruckBrandsModels,
	addShipperQuotation,
	fetchShipperContracts,
	fetchShipperQuotations,
	fetchContract,
	updateUserStats,
	fetchCumulativeShipperShipmentCounts,
	fetchLocations,
	deleteNotification,
	readAllNotifications,
	fetchDashboard,
	fetchLocationsDashboard,
	fetchShipperBills,
	searchShipperBills,
	searchShipperCompanyLocations,
	updateShipmentFilter,
	updateBookingsFilter
}
