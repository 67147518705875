/* eslint-disable require-atomic-updates */
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/storage'
import 'firebase/analytics'
import API, { api } from '../../../utils/api'
import {
	SHIPPER_TYPE,
	CARRIER_TYPE,
	ADMIN_TYPE,
	DISPATCHER_TYPE
} from '../../../constants/strings'
import uuidv4 from 'uuid/v4'

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

class Firebase {
	constructor () {
		app.initializeApp(config)
		this.auth = app.auth()
		this.auth.useDeviceLanguage()
		this.db = app.firestore()
		this.db.enablePersistence({ synchronizeTabs: true })
			.catch(err => {
				console.error(err)
			})
		this.registerMessaging()
		this.storage = app.storage()
		if (config.measurementId) {
			app.analytics()
		}
	}

	registerMessaging = () => {
		if (app.messaging.isSupported()) {
			this.messaging = app.messaging()
			this.messaging.usePublicVapidKey(process.env.REACT_APP_PUSH_KEY_PAIR)
			navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js?messagingSenderId=${process.env.REACT_APP_MESSAGING_SENDER_ID}`)
				.then((registration) => {
					this.messaging.useServiceWorker(registration)
				})
			this.messaging.onTokenRefresh(() => {
				this.getPushToken()
			})
			this.messaging.onMessage(payload => {
				console.log(payload)
			})
		}
	}

	getPushToken = async () => {
		try {
			const currentToken = await this.messaging.getToken()
			const cachedToken = localStorage.getItem('pushToken')
			if (currentToken !== cachedToken) {
				await API.updatePushToken(currentToken, cachedToken)
				localStorage.setItem('pushToken', currentToken)
			}
		} catch (e) {
			console.error(e)
		}
	}

	getCurrentUserId = () => {
		const authUser = JSON.parse(localStorage.getItem('authUser'))
		if (!this.auth.currentUser) return undefined
		let currentUserId = this.auth.currentUser.uid
		// Reassign the user ID if the current user is a manager account.
		if (authUser && authUser.managerId === currentUserId) {
			currentUserId = authUser.id
		}
		return currentUserId
	}

	requestReauthorizer = async (request) => {
		if (this.auth.currentUser) {
			const token = await this.auth.currentUser.getIdToken()
			request.headers['Authorization'] = token
			return request
		}
	}

	onAuthUserListener = (next, fallback) => {
		this.auth.onAuthStateChanged(async authUser => {
			if (authUser) {
				app.analytics().setUserId(authUser.uid)
				try {
					const tokenResult = await this.auth.currentUser.getIdTokenResult()
					api.setHeader('Authorization', tokenResult.token)
					api.addAsyncRequestTransform(this.requestReauthorizer)
					if (tokenResult.claims.shipper) {
						next(authUser, SHIPPER_TYPE, tokenResult.claims.permissions)
					} else if (tokenResult.claims.carrier) {
						next(authUser, CARRIER_TYPE, tokenResult.claims.permissions)
					} else if (tokenResult.claims.manager && tokenResult.claims.shipperId) { // Deprecated. Scenario should not occur, leaving here for safety.
						next(authUser, SHIPPER_TYPE, tokenResult.claims.permissions)
					} else if (tokenResult.claims.manager && tokenResult.claims.carrierId) { // Deprecated. Scenario should not occur, leaving here for safety.
						next(authUser, CARRIER_TYPE, tokenResult.claims.permissions)
					} else if (tokenResult.claims.admin) {
						next(authUser, ADMIN_TYPE, tokenResult.claims.permissions)
					} else if (tokenResult.claims.dispatcher) {
						next(authUser, DISPATCHER_TYPE, tokenResult.claims.permissions)
					} else {
						if (fallback) {
							fallback()
						}
						return
					}
				} catch (e) {
					if (fallback) {
						fallback()
					}
				}
			} else {
				api.setHeader('Authorization', null)
				if (fallback) {
					fallback()
				}
			}
		})
	}

	signInWithPhoneNumber = (phoneNumber, appVerifier) =>
		this.auth.signInWithPhoneNumber(phoneNumber, appVerifier)

	signInWithEmailPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password)

	signOut = () => this.auth.signOut()

	handleResetPassword = (actionCode) =>
		this.auth.verifyPasswordResetCode(actionCode)

	confirmPasswordReset = (actionCode, newPassword) =>
		this.auth.confirmPasswordReset(actionCode, newPassword)

	sendPasswordResetEmail = (email) =>
		this.auth.sendPasswordResetEmail(email)

	refreshToken = () => {
		if (this.auth.currentUser) {
			this.auth.currentUser.getIdToken(true)
		}
	}

	onTokenRefresh = (next) => {
		this.unsubscribeTokenChangeListener = this.auth.onIdTokenChanged(async () => {
			if (this.auth.currentUser) {
				const tokenResult = await this.auth.currentUser.getIdTokenResult()
				next(tokenResult.claims.permissions)
				this.unsubscribeTokenChangeListener()
			}
		})
	}

	permissions = (uid, snapshot) => {
		this.unsubscribePermissions = this.db.collection('users')
			.doc(uid)
			.collection('info')
			.doc('permissions')
			.onSnapshot({
				includeMetadataChanges: true
			}, doc => {
				if (snapshot) {
					snapshot(doc)
				}
			})
	}

	detachPermissions = () => {
		if (this.unsubscribePermissions) {
			this.unsubscribePermissions()
		}
	}

	updatedBookings = (snapshots) => {
		this.unsubscribeUpdatedBookings = this.db.collection('bookings')
			.where('updatedAt', '>=', new Date())
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	detachUpdatedBookings = () => {
		if (this.unsubscribeUpdatedBookings) {
			this.unsubscribeUpdatedBookings()
		}
	}

	updatedShipments = (snapshots) => {
		this.unsubscribeUpdatedShipments = this.db.collection('shipments')
			.where('updatedAt', '>=', new Date())
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	detachUpdatedShipments = () => {
		if (this.unsubscribeUpdatedShipments) {
			this.unsubscribeUpdatedShipments()
		}
	}

	userNotifications = (snapshot) => {
		this.unsubscribeUserNotifications = this.db.collection('user-notifications')
			.doc(this.getCurrentUserId())
			.onSnapshot(docSnapshot => {
				if (snapshot) {
					snapshot(docSnapshot)
				}
			})
	}

	detachUserNotifications = () => {
		if (this.unsubscribeUserNotifications) {
			this.unsubscribeUserNotifications()
		}
	}

	notifications = (snapshots) => {
		this.unsubscribeNotifications = this.db.collection('user-notifications')
			.doc(this.getCurrentUserId())
			.collection('notifications')
			.where('isRead', '==', false)
			.where('createdAt', '>=', new Date())
			.orderBy('createdAt', 'desc')
			.limit(50)
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	readNotification = (notification) => {
		if (notification.isRead) {
			return
		}
		this.db.collection('user-notifications')
			.doc(this.getCurrentUserId())
			.collection('notifications')
			.doc(notification.id)
			.set({ isRead: true }, { merge: true })
	}

	detachNotifications = () => {
		if (this.unsubscribeNotifications) {
			this.unsubscribeNotifications()
		}
	}

	adminStats = (snapshot) => {
		this.unsubscribeAdminStats = this.db.collection('stats')
			.doc('totals')
			.onSnapshot(docSnapshot => {
				if (snapshot) {
					snapshot(docSnapshot)
				}
			})
	}

	detachAdminStats = () => {
		if (this.unsubscribeAdminStats) {
			this.unsubscribeAdminStats()
		}
	}

	userStats = (snapshot) => {
		this.unsubscribeUserStats = this.db.collection('users')
			.doc(this.getCurrentUserId())
			.collection('stats')
			.doc('totals')
			.onSnapshot(docSnapshot => {
				if (snapshot) {
					snapshot(docSnapshot)
				}
			})
	}

	detachUserStats = () => {
		if (this.unsubscribeUserStats) {
			this.unsubscribeUserStats()
		}
	}

	detachUserNotifications = () => {
		if (this.unsubscribeUserNotifications) {
			this.unsubscribeUserNotifications()
		}
	}

	adminNotifications = (snapshots) => {
		this.unsubscribeAdminNotifications = this.db.collection('admin-notifications')
			.where('isRead', '==', false)
			.where('createdAt', '>=', new Date())
			.orderBy('createdAt', 'desc')
			.limit(50)
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	readAdminNotification = (notification) => {
		if (notification.isRead) {
			return
		}
		this.db.collection('admin-notifications')
			.doc(notification.id)
			.update({
				isRead: true
			})
	}

	detachAdminNotifications = () => {
		if (this.unsubscribeAdminNotifications) {
			this.unsubscribeAdminNotifications()
		}
	}

	events = (role, snapshots) => {
		let queryKey
		if (role === SHIPPER_TYPE) {
			queryKey = 'shipperId'
		} else if (role === CARRIER_TYPE) {
			queryKey = 'carrierId'
		} else {
			return
		}
		this.unsubscribeEventListener = this.db.collection('events')
			.where(queryKey, '==', this.getCurrentUserId())
			.where('createdAt', '>=', new Date())
			.orderBy('createdAt')
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	detachEvents = () => {
		if (this.unsubscribeEventListener) {
			this.unsubscribeEventListener()
		}
	}

	driverLocations = (snapshots) => {
		this.unsubscribeDriverLocations = this.db.collection('carrier-locations')
			.doc(this.getCurrentUserId())
			.collection('drivers')
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	detachDriverLocations = () => this.unsubscribeDriverLocations()

	driverLocation = (id, snapshot) => {
		this.unsubscribeDriverLocation = this.db.collection('driver-locations')
			.doc(id)
			.collection('history')
			.orderBy('createdAt', 'desc')
			.limit(1)
			.onSnapshot(querySnapshot => {
				if (snapshot) {
					snapshot(querySnapshot)
				}
			})
	}

	detachDriverLocation = () => {
		if (this.unsubscribeDriverLocation) {
			this.unsubscribeDriverLocation()
		}
	}

	shipmentDriverLocations = (ids, snapshot) => {
		this.unsubscribeShipmentDriverLocations = []
		ids.forEach(id => {
			this.unsubscribeShipmentDriverLocations.push(
				this.db.collection('driver-locations')
					.doc(id)
					.collection('history')
					.orderBy('createdAt', 'desc')
					.limit(1)
					.onSnapshot(querySnapshot => {
						if (snapshot) {
							snapshot(id, querySnapshot)
						}
					})
			)
		})
	}

	detachShipmentDriverLocations = () => {
		if (!this.unsubscribeShipmentDriverLocations) {
			return
		}
		this.unsubscribeShipmentDriverLocations.forEach(unsubscribe => unsubscribe())
	}

	shipment = (id, snapshot) => {
		this.unsubscribeShipment = this.db.collection('shipments').doc(id)
			.onSnapshot({
				includeMetadataChanges: true
			}, doc => {
				if (snapshot) {
					snapshot(doc)
				}
			})
	}

	detachShipment = () => {
		if (this.unsubscribeShipment) {
			this.unsubscribeShipment()
		}
	}

	userConversation = (uid, snapshot) => {
		this.unsubscribeUserConversation = this.db.collection('user-conversations')
			.doc(uid)
			.onSnapshot(docSnapshot => {
				if (snapshot) {
					snapshot(docSnapshot)
				}
			})
	}

	detachUserConversation = () => {
		if (this.unsubscribeUserConversation) {
			this.unsubscribeUserConversation()
		}
	}

	getConversationMessageId = (uid) => {
		return this.db.collection('user-conversations')
			.doc(uid)
			.collection('messages')
			.doc()
			.id
	}

	conversationMessages = (id, snapshot) => {
		if (!this.unsubscribeConversationMessages) {
			this.unsubscribeConversationMessages = {}
		}
		this.detachConversationMessages(id)
		this.unsubscribeConversationMessages[id] = this.db.collection('conversations')
			.doc(id)
			.collection('messages')
			.where('createdAt', '>=', new Date())
			.orderBy('createdAt', 'desc')
			.onSnapshot(querySnapshot => {
				if (snapshot) {
					snapshot(querySnapshot)
				}
			})
	}

	detachConversationMessages = (id) => {
		if (this.unsubscribeConversationMessages) {
			if (this.unsubscribeConversationMessages[id]) {
				this.unsubscribeConversationMessages[id]()
			}
		}
	}

	userConversations = (uid, snapshot) => {
		this.unsubscribeUserConversations = this.db.collection('user-conversations')
			.doc(uid)
			.collection('conversations')
			.where('updatedAt', '>=', new Date())
			.orderBy('updatedAt', 'desc')
			.onSnapshot(querySnapshot => {
				if (snapshot) {
					snapshot(querySnapshot)
				}
			})
	}

	detachUserConversations = () => {
		if (this.unsubscribeUserConversations) {
			this.unsubscribeUserConversations()
		}
	}

	readConversation = (uid, conversation) => {
		if (!conversation || !conversation.id) return
		conversation.isRead[uid] = true
		this.db.collection('user-conversations')
			.doc(uid)
			.collection('conversations')
			.doc(conversation.id)
			.update({
				isRead: {
					[uid]: true
				}
			})
	}

	bookingFareHistory = (bookingId, snapshot) => {
		this.detachBookingFareHistory()
		this.unsubscribeBookingFareHistory = this.db.collection('bookings')
			.doc(bookingId)
			.collection('fare-history')
			.orderBy('createdAt', 'asc')
			.onSnapshot(querySnapshot => {
				if (snapshot) {
					snapshot(querySnapshot)
				}
			})
	}

	detachBookingFareHistory = () => {
		if (this.unsubscribeBookingFareHistory) {
			this.unsubscribeBookingFareHistory()
		}
	}

	activeVendorRequest = (bookingId, snapshots) => {
		if (!this.unsubscribeActiveVendorRequest) {
			this.unsubscribeActiveVendorRequest = { }
		}
		this.unsubscribeActiveVendorRequest[bookingId] = this.db.collection('vendor-requests')
			.where('bookingId', '==', bookingId)
			.orderBy('createdAt', 'desc')
			.limit(1)
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	detachActiveVendorRequest = (bookingId) => {
		if (this.unsubscribeActiveVendorRequest && this.unsubscribeActiveVendorRequest[bookingId]) {
			this.unsubscribeActiveVendorRequest[bookingId]()
		}
	}

	trucks = (snapshot) => {
		this.unsubscribeTrucks = this.db.collection('trucks')
			.where('isGpsEnabled', '==', true)
			.orderBy('updatedAt', 'desc')
			.limit(300)
			.onSnapshot(querySnapshot => {
				if (snapshot) {
					snapshot(querySnapshot)
				}
			})
	}

	detachTrucks = () => {
		if (this.unsubscribeTrucks) {
			this.unsubscribeTrucks()
		}
	}

	truck = (id, snapshot) => {
		this.unsubscribeTruck = this.db.collection('trucks')
			.doc(id)
			.onSnapshot({
				includeMetadataChanges: true
			}, doc => {
				if (snapshot) {
					snapshot(doc)
				}
			})
	}

	detachTruck = () => {
		if (this.unsubscribeTruck) {
			this.unsubscribeTruck()
		}
	}

	uploadUserFile = (userId, file) => {
		const storageRef = this.storage.ref()
		const ref = storageRef.child(`user-attachments/${userId}/user_file_${uuidv4()}`)
		return ref.put(file, {
			customMetadata: {
				uploaderId: this.getCurrentUserId(),
				title: file.name
			}
		})
	}

	uploadShipmentFile = (shipmentId, file) => {
		const storageRef = this.storage.ref()
		const ref = storageRef.child(`attachments/${shipmentId}/shipment_file_${uuidv4()}`)
		return ref.put(file, {
			customMetadata: {
				uploaderId: this.getCurrentUserId(),
				title: file.name,
				description: file.description
			}
		})
	}

	uploadPublicFile = (userId, file) => {
		const storageRef = this.storage.ref()
		const ref = storageRef.child(`public/${userId}/user_file_${uuidv4()}`)
		return ref.put(file, {
			customMetadata: {
				uploaderId: this.getCurrentUserId(),
				title: file.name
			}
		})
	}

	contractComments = (contractId, snapshots) => {
		this.unsubscribeContractComments = this.db
			.collection('contracts')
			.doc(contractId)
			.collection('comments')
			.orderBy('createdAt')
			.onSnapshot(querySnapshot => {
				if (snapshots) {
					snapshots(querySnapshot)
				}
			})
	}

	detachContractComments = () => {
		if (this.unsubscribeContractComments) {
			this.unsubscribeContractComments()
		}
	}
}

export default Firebase
