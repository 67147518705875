import { combineReducers } from 'redux'
import {
	FETCH_DELIVERY_LOCATIONS_REQUEST,
	FETCH_DELIVERY_LOCATIONS_SUCCESS,
	FETCH_DELIVERY_LOCATIONS_FAILURE,
	ADD_NOTIFICATION,
	CLEAR_NOTIFICATIONS,
	UPDATE_NOTIFICATION,
	READ_NOTIFICATION,
	UPDATE_FILTER_SUCCESS,
	FETCH_FILTER_REQUEST,
	FETCH_FILTER_SUCCESS,
	FETCH_FILTER_FAILURE,
	ADD_BANK_DETAIL_FAILURE,
	ADD_BANK_DETAIL_REQUEST,
	ADD_BANK_DETAIL_SUCCESS,
	FETCH_BANK_DETAILS_FAILURE,
	FETCH_BANK_DETAILS_REQUEST,
	FETCH_BANK_DETAILS_SUCCESS,
	UPDATE_BANK_DETAIL_FAILURE,
	UPDATE_BANK_DETAIL_REQUEST,
	UPDATE_BANK_DETAIL_SUCCESS,
	SEARCH_USERS_REQUEST,
	SEARCH_USERS_SUCCESS,
	SEARCH_USERS_FAILURE,
	FETCH_SHIPPER_SHIPMENT_COUNTS_REQUEST,
	FETCH_SHIPPER_SHIPMENT_COUNTS_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_COUNTS_FAILURE,
	FETCH_SHIPPER_SHIPMENT_HISTORY_REQUEST,
	FETCH_SHIPPER_SHIPMENT_HISTORY_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_HISTORY_FAILURE,
	FETCH_CARRIER_COUNTS_REQUEST,
	FETCH_CARRIER_COUNTS_SUCCESS,
	FETCH_CARRIER_COUNTS_FAILURE,
	UPDATE_NOTIFICATION_COUNT_SUCCESS,
	FETCH_NOTIFICATIONS_FAILURE,
	FETCH_NOTIFICATIONS_REQUEST,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_MORE_NOTIFICATIONS_FAILURE,
	FETCH_MORE_NOTIFICATIONS_REQUEST,
	FETCH_MORE_NOTIFICATIONS_SUCCESS,
	FETCH_ITEM_TYPES_FAILURE,
	FETCH_ITEM_TYPES_REQUEST,
	FETCH_ITEM_TYPES_SUCCESS,
	FETCH_TRUCK_TYPES_SUCCESS,
	FETCH_TRUCK_TYPES_FAILURE,
	FETCH_TRUCK_TYPES_REQUEST,
	FETCH_BANK_OPTIONS_SUCCESS,
	FETCH_BANK_OPTIONS_FAILURE,
	FETCH_BANK_OPTIONS_REQUEST,
	FETCH_TRUCK_BRANDS_MODELS_SUCCESS,
	FETCH_TRUCK_BRANDS_MODELS_REQUEST,
	FETCH_TRUCK_BRANDS_MODELS_FAILURE,
	FETCH_SHIPPER_CONTRACTS_FAILURE,
	FETCH_SHIPPER_CONTRACTS_REQUEST,
	FETCH_SHIPPER_CONTRACTS_SUCCESS,
	UPDATE_USER_STATS,
	FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_SUCCESS,
	FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_SUCCESS,
	FETCH_LOCATIONS_SUCCESS,
	FETCH_MORE_LOCATIONS_SUCCESS,
	DELETE_NOTIFICATION_REQUEST,
	READ_ALL_NOTIFICATIONS_REQUEST,
	FETCH_DASHBOARD_REQUEST,
	FETCH_DASHBOARD_SUCCESS,
	FETCH_LOCATIONS_DASHBOARD_REQUEST,
	FETCH_LOCATIONS_DASHBOARD_SUCCESS,
	FETCH_SHIPPER_QUOTATIONS_FAILURE,
	FETCH_SHIPPER_QUOTATIONS_REQUEST,
	FETCH_SHIPPER_QUOTATIONS_SUCCESS,
	ADD_SHIPPER_QUOTATION_SUCCESS,
	FETCH_MORE_SHIPPER_QUOTATIONS_SUCCESS,
	FETCH_MORE_SHIPPER_CONTRACTS_SUCCESS,
	FETCH_SHIPPER_BILLS_FAILURE,
	FETCH_SHIPPER_BILLS_REQUEST,
	FETCH_SHIPPER_BILLS_SUCCESS,
	SEARCH_SHIPPER_BILLS_SUCCESS,
	SEARCH_SHIPPER_BILLS_FAILURE,
	SEARCH_SHIPPER_BILLS_REQUEST,
	SEARCH_MORE_SHIPPER_BILLS_SUCCESS,
	SEARCH_MORE_SHIPPER_BILLS_REQUEST,
	SEARCH_MORE_SHIPPER_BILLS_FAILURE,
	SEARCH_COMPANY_LOCATIONS_SUCCESS,
	SEARCH_MORE_COMPANY_LOCATIONS_SUCCESS,
	SEARCH_COMPANY_LOCATIONS_FAILURE,
	SEARCH_COMPANY_LOCATIONS_REQUEST,
	SEARCH_MORE_COMPANY_LOCATIONS_REQUEST,
	SEARCH_MORE_COMPANY_LOCATIONS_FAILURE,
	SEARCH_MORE_USERS_SUCCESS,
	SEARCH_MORE_USERS_FAILURE,
	SEARCH_MORE_USERS_REQUEST,
	UPDATE_SHIPMENTS_FILTER,
	UPDATE_BOOKINGS_FILTER,
	UPDATE_NOTIFICATIONS_INFO_SUCCESS
} from './action-types'
import { ADD_LOCATION_SUCCESS, UPDATE_LOCATION_SUCCESS, DELETE_LOCATION_SUCCESS, DELETE_LOCATION_REQUEST, UPDATE_LOCATION_REQUEST } from '../admin/action-types'

const unreadNotificationCount = (state = 0, { type, payload }) => {
	switch (type) {
		case UPDATE_NOTIFICATION_COUNT_SUCCESS:
			return payload
		case READ_NOTIFICATION:
			if (state > 0) {
				return state - 1
			}
			return state
		default:
			return state
	}
}

const notificationsInfo = (state = {}, { type, payload }) => {
	switch (type) {
		case UPDATE_NOTIFICATIONS_INFO_SUCCESS:
			return payload
		default:
			return state
	}
}

const deliveryLocations = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_DELIVERY_LOCATIONS_REQUEST:
		case FETCH_DELIVERY_LOCATIONS_FAILURE:
			return state
		case FETCH_DELIVERY_LOCATIONS_SUCCESS:
			return payload.contacts
		default:
			return state
	}
}

const bankDetails = (state = [], { type, payload }) => {
	switch (type) {
		case ADD_BANK_DETAIL_REQUEST:
		case ADD_BANK_DETAIL_FAILURE:
		case FETCH_BANK_DETAILS_REQUEST:
		case FETCH_BANK_DETAILS_FAILURE:
		case UPDATE_BANK_DETAIL_FAILURE:
		case UPDATE_BANK_DETAIL_REQUEST:
			return state
		case FETCH_BANK_DETAILS_SUCCESS:
			return payload.banks
		case ADD_BANK_DETAIL_SUCCESS:
			return [ payload, ...state ]
		case UPDATE_BANK_DETAIL_SUCCESS:
			return state.map(bankDetail => bankDetail.id === payload.id
				? payload
				:  payload.default? { ...bankDetail , default: false } : { ...bankDetail }
			)
		default:
			return state
	}
}

const isFetchingDeliveryLocations = (state = false, { type }) => {
	switch (type) {
		case FETCH_DELIVERY_LOCATIONS_REQUEST:
			return true
		case FETCH_DELIVERY_LOCATIONS_SUCCESS:
		case FETCH_DELIVERY_LOCATIONS_FAILURE:
			return false
		default:
			return state
	}
}

const notificationMap = {}
const notifications = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_NOTIFICATIONS_REQUEST:
		case FETCH_NOTIFICATIONS_FAILURE:
		case FETCH_MORE_NOTIFICATIONS_REQUEST:
		case FETCH_MORE_NOTIFICATIONS_FAILURE:
			return state
		case FETCH_NOTIFICATIONS_SUCCESS:
			payload.notifications.forEach(notification => {
				if (!notificationMap[notification.id]) {
					notificationMap[notification.id] = true
				}
			})
			return payload.notifications
		case ADD_NOTIFICATION:
			if (!notificationMap[payload.id]) {
				notificationMap[payload.id] = true
				return [payload, ...state]
			}
			return state
		case CLEAR_NOTIFICATIONS:
			return []
		case UPDATE_NOTIFICATION:
			return state.map(notification => notification.id === payload.id
				? payload
				: notification
			)
		case READ_NOTIFICATION:
			return state.map(notification => notification.id === payload.id
				? { ...payload, isRead: true }
				: notification
			)
		case FETCH_MORE_NOTIFICATIONS_SUCCESS:
			payload.notifications.forEach(notification => {
				if (!notificationMap[notification.id]) {
					notificationMap[notification.id] = true
				}
			})
			return [...state, ...payload.notifications]
		case DELETE_NOTIFICATION_REQUEST:
			return state.filter(notification => notification.id !== payload.id)
		case READ_ALL_NOTIFICATIONS_REQUEST:
			return state.map(notification => {
				return {
					...notification,
					isRead: true
				}
			})
		default:
			return state
	}
}

const isFetchingNotifications = (state = false, { type }) => {
	switch (type) {
		case FETCH_NOTIFICATIONS_REQUEST:
			return true
		case FETCH_NOTIFICATIONS_FAILURE:
		case FETCH_NOTIFICATIONS_SUCCESS:
			return false
		default:
			return state
	}
}

const isFetchingMoreNotifications = (state = false, { type }) => {
	switch (type) {
		case FETCH_MORE_NOTIFICATIONS_REQUEST:
			return true
		case FETCH_MORE_NOTIFICATIONS_FAILURE:
		case FETCH_MORE_NOTIFICATIONS_SUCCESS:
			return false
		default:
			return state
	}
}

const filter = (state = null, { type, payload }) => {
	switch (type) {
		case FETCH_FILTER_REQUEST:
		case FETCH_FILTER_FAILURE:
			return state
		case FETCH_FILTER_SUCCESS:
		case UPDATE_FILTER_SUCCESS:
			return payload
		default:
			return state
	}
}

const matchingUsers = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_USERS_REQUEST:
		case SEARCH_USERS_FAILURE:
			return []
		case SEARCH_USERS_SUCCESS:
			return payload.users
		case SEARCH_MORE_USERS_SUCCESS:
			return [...state, ...payload.users]
		default:
			return state
	}
}

// eslint-disable-next-line no-unused-vars
const isSearchingUsers = (state = false, { type }) => {
	switch (type) {
		case SEARCH_USERS_REQUEST:
			return true
		default:
			return false
	}
}

const isSearchingMoreUsers = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_USERS_REQUEST:
			return true
		case SEARCH_MORE_USERS_FAILURE:
		case SEARCH_MORE_USERS_SUCCESS:
			return false
		default:
			return state
	}
}

const defaultShipmentCounts = {
	requestedShipmentsCount: 0,
	scheduledShipmentsCount: 0,
	activeShipmentsCount: 0,
	completedShipmentsCount: 0,
	cancelledShipmentsCount: 0,
	expiredShipmentsCount: 0,
	bookingItemTypes: {
		'metal': 0,
		'lumber': 0,
		'consumer-packaged-goods': 0,
		'garments-textiles': 0,
		'construction-materials': 0,
		'cement': 0,
		'jute': 0,
		'leather': 0,
		'poultry-feed': 0,
		'agriculture': 0
	},
	bookingTrucks: {
		'25t-40ft(trailer)': 0,
		'20t-20ft(trailer)': 0,
		'5t-23ft': 0,
		'5t-20ft(openTruck)': 0,
		'3t-18ft': 0,
		'3t-17.5ft(openTruck)': 0,
		'3t-17ft': 0,
		'3t-16ft': 0,
		'3t-16ft(openTruck)': 0,
		'3t-14ft': 0,
		'2t-13ft': 0,
		'2t-12ft': 0,
		'1.5t-14ft': 0,
		'1.5t-13.5ft': 0,
		'1.5t-13ft': 0,
		'1.5t-12.5ft': 0,
		'1.5t-12.5ft(openTruck)': 0,
		'1.5t-12ft': 0,
		'1.5t-12ft(openTruck)': 0,
		'1.5t-10ft': 0,
		'1t-11ft(openTruck)': 0,
		'1t-8ft': 0,
		'1t-7ft': 0,
		'1t-7ft(openTruck)': 0,
		'0.5t-10ft': 0,
		'0.5t-8ft': 0,
		'0.5t-7.5ft': 0,
		'0.5t-7ft': 0,
		'0.5t-7ft(openTruck)': 0
	},
	watchingShipmentsCount: 0,
	assignedShipmentsCount: 0
}
const shipmentCounts = (state = defaultShipmentCounts, { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_SHIPMENT_COUNTS_FAILURE:
		case FETCH_SHIPPER_SHIPMENT_COUNTS_REQUEST:
			return state
		case FETCH_SHIPPER_SHIPMENT_COUNTS_SUCCESS:
			return payload.counts
		case UPDATE_USER_STATS:
			return Object.assign(state, payload)
		default:
			return state
	}
}

const defaultShipmentTotals = {
	requestedShipmentsFareTotal: 0,
	scheduledShipmentsFareTotal: 0,
	activeShipmentsFareTotal: 0,
	completedShipmentsFareTotal: 0,
	cancelledShipmentsFareTotal: 0,
	expiredShipmentsFareTotal: 0
}
const shipmentTotals = (state = defaultShipmentTotals, { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_SHIPMENT_COUNTS_FAILURE:
		case FETCH_SHIPPER_SHIPMENT_COUNTS_REQUEST:
			return state
		case FETCH_SHIPPER_SHIPMENT_COUNTS_SUCCESS:
			return payload.totals
		default:
			return state
	}
}

const allShipperShipmentCounts = (state = defaultShipmentTotals, { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_SHIPMENT_ALL_COUNTS_SUCCESS:
			return payload
		default:
			return state
	}
}

const weeklyShipperShipmentCounts = (state = defaultShipmentTotals, { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_SHIPMENT_WEEKLY_COUNTS_SUCCESS:
			return payload
		default:
			return state
	}
}

const monthlyShipperShipmentCounts = (state = defaultShipmentTotals, { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_SHIPMENT_MONTHLY_COUNTS_SUCCESS:
			return payload
		default:
			return state
	}
}

const shipmentHistories = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_SHIPMENT_HISTORY_FAILURE:
		case FETCH_SHIPPER_SHIPMENT_HISTORY_REQUEST:
			return state
		case FETCH_SHIPPER_SHIPMENT_HISTORY_SUCCESS:
			return payload.shipmentDataPoints
		default:
			return state
	}
}

const defaultCarrierCounts = {
	assignedTruckCount: 0,
	busyDriverCount: 0,
	driverCount: 0,
	freeDriverCount: 0,
	inactiveDriverCount: 0,
	truckCount: 0,
	unassignedTruckCount: 0,
	trucks: {}
}
const carrierCounts = (state = defaultCarrierCounts, { type, payload }) => {
	switch (type) {
		case FETCH_CARRIER_COUNTS_FAILURE:
		case FETCH_CARRIER_COUNTS_REQUEST:
			return state
		case FETCH_CARRIER_COUNTS_SUCCESS:
			return payload
		default:
			return state
	}
}

const initialOptions = {
	truckTypes: [],
	trailerTypes: [],
	itemTypes: [],
	banks: [],
	truckBrandsModels: []
}

const options = (state = initialOptions, { type, payload }) => {
	switch (type) {
		case FETCH_TRUCK_TYPES_REQUEST:
		case FETCH_ITEM_TYPES_REQUEST:
		case FETCH_BANK_OPTIONS_REQUEST:
		case FETCH_TRUCK_TYPES_FAILURE:
		case FETCH_ITEM_TYPES_FAILURE:
		case FETCH_BANK_OPTIONS_FAILURE:
		case FETCH_TRUCK_BRANDS_MODELS_REQUEST:
		case FETCH_TRUCK_BRANDS_MODELS_FAILURE:
			return state
		case FETCH_TRUCK_TYPES_SUCCESS:
			return {
				...state,
				truckTypes: payload.truckTypes,
				trailerTypes: payload.trailerTypes
			}
		case FETCH_TRUCK_BRANDS_MODELS_SUCCESS:
			return {
				...state,
				truckBrandsModels: payload.truckBrandsModels
			}
		case FETCH_ITEM_TYPES_SUCCESS:
			return {
				...state,
				itemTypes: payload.itemTypes
			}
		case FETCH_BANK_OPTIONS_SUCCESS:
			return {
				...state,
				banks: payload.banks
			}
		default:
			return state
	}
}

const shipperBills = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_BILLS_REQUEST:
		case FETCH_SHIPPER_BILLS_FAILURE:
			return state
		case FETCH_SHIPPER_BILLS_SUCCESS:
			return payload.bills
		default:
			return state
	}
}

const shipperContracts = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_CONTRACTS_REQUEST:
		case FETCH_SHIPPER_CONTRACTS_FAILURE:
			return state
		case FETCH_SHIPPER_CONTRACTS_SUCCESS:
			return payload.contracts
		case FETCH_MORE_SHIPPER_CONTRACTS_SUCCESS:
			return [...state, ...payload.contracts]
		default:
			return state
	}
}

const shipperQuotations = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_SHIPPER_QUOTATIONS_REQUEST:
		case FETCH_SHIPPER_QUOTATIONS_FAILURE:
			return state
		case FETCH_SHIPPER_QUOTATIONS_SUCCESS:
			return payload.contracts
		case FETCH_MORE_SHIPPER_QUOTATIONS_SUCCESS:
			return [...state, ...payload.contracts]
		case ADD_SHIPPER_QUOTATION_SUCCESS:
			return [payload, ...state]
		default:
			return state
	}
}

const isFetchingShipperContracts = (state = true, { type }) => {
	switch (type) {
		case FETCH_SHIPPER_CONTRACTS_REQUEST:
		case FETCH_SHIPPER_QUOTATIONS_REQUEST:
			return true
		case FETCH_SHIPPER_CONTRACTS_SUCCESS:
		case FETCH_SHIPPER_CONTRACTS_FAILURE:
		case FETCH_SHIPPER_QUOTATIONS_SUCCESS:
		case FETCH_SHIPPER_QUOTATIONS_FAILURE:
			return false
		default:
			return state
	}
}

const locations = (state = [], { type, payload }) => {
	switch (type) {
		case FETCH_LOCATIONS_SUCCESS:
			return payload.locations
		case FETCH_MORE_LOCATIONS_SUCCESS:
			return [...state, ...payload.locations]
		case ADD_LOCATION_SUCCESS:
			return [payload, ...state]
		case UPDATE_LOCATION_REQUEST:
			return state.map(location => location.id === payload.id
				? { ...location, label: payload.label }
				: location
			)
		case UPDATE_LOCATION_SUCCESS:
			return state.map(location => location.id === payload.id
				? payload
				: location
			)
		case DELETE_LOCATION_REQUEST:
		case DELETE_LOCATION_SUCCESS:
			return state.filter(location => location.id !== payload.id)
		default:
			return state
	}
}

const defaultDashboard = {
	routeCounts: [],
	loadingPointCounts: [],
	unloadingPointCounts: [],
	localRouteCounts: [],
	localLoadingPointCounts: [],
	localUnloadingPointCounts: [],
	truckTypeCounts: [],
	itemTypeCounts: [],
	shipperAmounts: [],
	dailyValues: {
		currentDay: {
			requestedShipments: 0,
			totalShipments: 0,
			revenue: 0.0,
			paymentReceived: 0.0,
			paymentOwed: 0.0,
			profit: 0.0
		},
		previousDay: {
			requestedShipments: 0,
			totalShipments: 0,
			revenue: 0.0,
			paymentReceived: 0.0,
			paymentOwed: 0.0,
			profit: 0.0
		}
	},
	fareAmountDataPoints: [],
	vendorAmountDataPoints: [],
	profitDataPoints: [],
	totalShipmentDataPoints: [],
	shipperDataPoints: [],
	monthlyValues: {
		distance: 0,
		averageRevenuePerShipper: 0.0,
		totalShipments: 0,
		totalCompletedShipments: 0,
		revenue: 0
	},
	averageEventTimes: {
		enrouteToPickUpTime: 0,
		enrouteToPickUpCount: 0,
		loadingTime: 0,
		loadingCount: 0,
		transitTime: 0,
		transitCount: 0,
		idleAtDropOffTime: 0,
		idleAtDropOffCount: 0,
		driverInsideFacilityTime: 0,
		driverInsideFacilityCount: 0,
		totalTime: 0
	},
	shipperAverageEventTimes: []
}
const date = new Date()
// Map of dashboard values where the key is the month value (indexed from 0, i.e. 0 = January, 1 = February, etc..)
const dashboard = (state = { [date.getMonth()]: defaultDashboard }, { type, payload }) => {
	switch (type) {
		case FETCH_DASHBOARD_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultDashboard
				return newState
			}
		}
		case FETCH_DASHBOARD_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}

const defaultLocationsDashboard = {
	loadingPoints: [],
	unloadingPoints: [],
	shipperLoadingPoints: [],
	shipperUnloadingPoints: []
}
const locationsDashboard = (state = { [date.getMonth()]: defaultLocationsDashboard }, { type, payload }) => {
	switch (type) {
		case FETCH_LOCATIONS_DASHBOARD_REQUEST: {
			if (state[payload.month]) {
				return state
			} else {
				const newState = { ...state }
				newState[payload.month] = defaultDashboard
				return newState
			}
		}
		case FETCH_LOCATIONS_DASHBOARD_SUCCESS: {
			const newState = { ...state }
			newState[payload.month] = payload
			return newState
		}
		default:
			return state
	}
}


const defaultDailyDashboard = {
	currentDay: {
		requestedShipments: 0,
		totalShipments: 0,
		cancelledShipments: 0,
		revenue: 0.0,
		paymentReceived: 0.0,
		paymentOwed: 0.0,
		profit: 0.0
	},
	previousDay: {
		requestedShipments: 0,
		totalShipments: 0,
		cancelledShipments: 0,
		revenue: 0.0,
		paymentReceived: 0.0,
		paymentOwed: 0.0,
		profit: 0.0
	}
}
const dailyDashboard = (state = defaultDailyDashboard, { type, payload }) => {
	switch (type) {
		case FETCH_DASHBOARD_SUCCESS: {
			return payload.dailyValues
		}
		default:
			return state
	}
}

const matchingShipperBills = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_SHIPPER_BILLS_SUCCESS:
			return payload.bills
		case SEARCH_MORE_SHIPPER_BILLS_SUCCESS:
			return [...state, ...payload.bills]
		case SEARCH_SHIPPER_BILLS_FAILURE:
		case SEARCH_SHIPPER_BILLS_REQUEST:
			return state
		default:
			return state
	}
}

const isSearchingShipperBills = (state = false, { type }) => {
	switch (type) {
		case SEARCH_SHIPPER_BILLS_REQUEST:
			return true
		case SEARCH_SHIPPER_BILLS_FAILURE:
		case SEARCH_SHIPPER_BILLS_SUCCESS:
			return false
		default:
			return state
	}
}

const isLoadingMoreSearchedShipperBills = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_SHIPPER_BILLS_REQUEST:
			return true
		case SEARCH_MORE_SHIPPER_BILLS_FAILURE:
		case SEARCH_MORE_SHIPPER_BILLS_SUCCESS:
			return false
		default:
			return state
	}
}

const matchingCompanyLocations = (state = [], { type, payload }) => {
	switch (type) {
		case SEARCH_COMPANY_LOCATIONS_SUCCESS:
			return payload.contacts
		case SEARCH_MORE_COMPANY_LOCATIONS_SUCCESS:
			return [...state, ...payload.contacts]
		case SEARCH_COMPANY_LOCATIONS_FAILURE:
		case SEARCH_COMPANY_LOCATIONS_REQUEST:
			return state
		default:
			return state
	}
}

const isSearchingCompanyLocations = (state = false, { type }) => {
	switch (type) {
		case SEARCH_COMPANY_LOCATIONS_REQUEST:
			return true
		case SEARCH_COMPANY_LOCATIONS_FAILURE:
		case SEARCH_COMPANY_LOCATIONS_SUCCESS:
			return false
		default:
			return state
	}
}

const isLoadingMoreSearchedCompanyLocations = (state = false, { type }) => {
	switch (type) {
		case SEARCH_MORE_COMPANY_LOCATIONS_REQUEST:
			return true
		case SEARCH_MORE_COMPANY_LOCATIONS_FAILURE:
		case SEARCH_MORE_COMPANY_LOCATIONS_SUCCESS:
			return false
		default:
			return state
	}
}

const defaultShipmentsFilter = {
	tags: localStorage.getItem('tags') === 'true',
	driver: localStorage.getItem('driver') === 'true',
	truckType: localStorage.getItem('truckType') === 'true',
	plateNumber: localStorage.getItem('plateNumber') === 'true',
	assignee: localStorage.getItem('assignee') === 'true'
}
if (localStorage.getItem('truckType') === null) {
	localStorage.setItem('truckType', 'true')
}
defaultShipmentsFilter.bids = localStorage.getItem('bids') === 'true'
if (localStorage.getItem('fareAmount') === null) {
	localStorage.setItem('fareAmount', 'true')
}
defaultShipmentsFilter.fareAmount = localStorage.getItem('fareAmount') === 'true'
if (localStorage.getItem('vendorAmount') === null) {
	localStorage.setItem('vendorAmount', 'true')
}
defaultShipmentsFilter.vendorAmount = localStorage.getItem('vendorAmount') === 'true'
const shipmentsFilter = (state = defaultShipmentsFilter, { type, payload }) => {
	switch (type) {
		case UPDATE_SHIPMENTS_FILTER:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}

const defaultBookingsFilter = {
	assignedBookings: localStorage.getItem('assignedBookings') === 'true',
	truckType: localStorage.getItem('truckType') === 'true'
}
if (localStorage.getItem('truckType') === null) {
	localStorage.setItem('truckType', 'true')
}
if (localStorage.getItem('bids') === null) {
	localStorage.setItem('bids', 'true')
}
defaultBookingsFilter.bids = localStorage.getItem('bids') === 'true'
const bookingsFilter = (state = defaultBookingsFilter, { type, payload }) => {
	switch (type) {
		case UPDATE_BOOKINGS_FILTER:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}

const user = combineReducers({
	deliveryLocations,
	isFetchingDeliveryLocations,
	isFetchingNotifications,
	isFetchingMoreNotifications,
	notifications,
	unreadNotificationCount,
	notificationsInfo,
	filter,
	bankDetails,
	matchingUsers,
	isSearchingUsers,
	shipmentCounts,
	shipmentTotals,
	shipmentHistories,
	carrierCounts,
	options,
	isFetchingShipperContracts,
	shipperContracts,
	shipperQuotations,
	allShipperShipmentCounts,
	weeklyShipperShipmentCounts,
	monthlyShipperShipmentCounts,
	locations,
	dashboard,
	dailyDashboard,
	locationsDashboard,
	shipperBills,
	matchingShipperBills,
	isSearchingShipperBills,
	isLoadingMoreSearchedShipperBills,
	matchingCompanyLocations,
	isSearchingCompanyLocations,
	isLoadingMoreSearchedCompanyLocations,
	isSearchingMoreUsers,
	shipmentsFilter,
	bookingsFilter
})

export default user

export const getDeliveryLocations = state => state.deliveryLocations
export const getIsFetchingDeliveryLocations = state =>  state.isFetchingDeliveryLocations
export const getIsFetchingNotifications = state =>  state.isFetchingNotifications
export const getIsFetchingMoreNotifications = state =>  state.isFetchingMoreNotifications
export const getNotifications = state => state.notifications
export const getIsNotificationsRead = state => state.unreadNotificationCount === 0
export const getUnreadNotificationCount = state => state.unreadNotificationCount
export const getFilter = state => state.filter
export const getBankDetails = state => state.bankDetails
export const getMatchingUsers = state => state.matchingUsers
export const getIsSearchingUsers = state => state.isSearchingUsers
export const getIsSearchingMoreUsers = state => state.isSearchingMoreUsers
export const getShipmentCounts = state => state.shipmentCounts
export const getShipmentTotals = state => state.shipmentTotals
export const getShipmentHistories = state => state.shipmentHistories
export const getCarrierCounts = state => state.carrierCounts
export const getOptions = state => state.options
export const getShipperContracts = state => state.shipperContracts
export const getShipperQuotations = state => state.shipperQuotations
export const getIsFetchingShipperContracts = state => state.isFetchingShipperContracts
export const getAllShipperShipmentCounts = state => state.allShipperShipmentCounts
export const getWeeklyShipperShipmentCounts = state => state.weeklyShipperShipmentCounts
export const getMonthlyShipperShipmentCounts = state => state.monthlyShipperShipmentCounts
export const getLocations = state => state.locations
export const getDashboard = state => state.dashboard
export const getLocationsDashboard = state => state.locationsDashboard
export const getDailyDashboard = state => state.dailyDashboard
export const getShipperBills = state => state.shipperBills
export const getMatchingShipperBills = state => state.matchingShipperBills
export const getIsSearchingShipperBills = state => state.isSearchingShipperBills
export const getIsLoadingMoreSearchedShipperBills = state => state.isLoadingMoreSearchedShipperBills
export const getMatchingCompanyLocations = state => state.matchingCompanyLocations
export const getIsSearchingCompanyLocations = state => state.isSearchingCompanyLocations
export const getIsLoadingMoreSearchedCompanyLocations = state => state.isLoadingMoreSearchedCompanyLocations
export const getShipmentsFilter = state => state.shipmentsFilter
export const getBookingsFilter = state => state.bookingsFilter
export const getNotificationsInfo = state => state.notificationsInfo
