import {
	BOOK_SHIPMENT_REQUEST,
	BOOK_SHIPMENT_SUCCESS,
	BOOK_SHIPMENT_FAILURE,
	FETCH_ACTIVE_SHIPMENTS_REQUEST,
	FETCH_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_ACTIVE_SHIPMENTS_FAILURE,
	FETCH_SCHEDULED_SHIPMENTS_REQUEST,
	FETCH_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_SCHEDULED_SHIPMENTS_FAILURE,
	FETCH_REQUESTED_SHIPMENTS_REQUEST,
	FETCH_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_REQUESTED_SHIPMENTS_FAILURE,
	FETCH_COMPLETED_SHIPMENTS_REQUEST,
	FETCH_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_COMPLETED_SHIPMENTS_FAILURE,
	FETCH_CANCELLED_SHIPMENTS_REQUEST,
	FETCH_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_CANCELLED_SHIPMENTS_FAILURE,
	FETCH_EXPIRED_SHIPMENTS_REQUEST,
	FETCH_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_EXPIRED_SHIPMENTS_FAILURE,
	FETCH_SHIPMENT_REQUEST,
	FETCH_SHIPMENT_SUCCESS,
	FETCH_SHIPMENT_FAILURE,
	FETCH_CARRIER_SHIPMENTS_REQUEST,
	FETCH_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_CARRIER_SHIPMENTS_FAILURE,
	ASSIGN_DRIVER_TO_SHIPMENT_REQUEST,
	ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS,
	ASSIGN_DRIVER_TO_SHIPMENT_FAILURE,
	FETCH_ASSIGNED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_ASSIGNED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_SCHEDULED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_SCHEDULED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_COMPLETED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_COMPLETED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_COMPLETED_CARRIER_SHIPMENTS_FAILURE,
	ACCEPT_SHIPMENT_REQUEST,
	ACCEPT_SHIPMENT_SUCCESS,
	ACCEPT_SHIPMENT_FAILURE,
	FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_REQUEST,
	FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_FAILURE,
	FETCH_MORE_CARRIER_SHIPMENTS_REQUEST,
	FETCH_MORE_CARRIER_SHIPMENTS_SUCCESS,
	FETCH_MORE_CARRIER_SHIPMENTS_FAILURE,
	FETCH_BOOKING_REQUEST,
	FETCH_BOOKING_SUCCESS,
	FETCH_BOOKING_FAILURE,
	CANCEL_SHIPMENT_REQUEST,
	CANCEL_SHIPMENT_SUCCESS,
	CANCEL_SHIPMENT_FAILURE,
	CANCEL_BOOKING_REQUEST,
	CANCEL_BOOKING_SUCCESS,
	CANCEL_BOOKING_FAILURE,
	UPDATE_SHIPMENT,
	ACCEPT_SHIPMENT_FOR_DRIVER_REQUEST,
	ACCEPT_SHIPMENT_FOR_DRIVER_SUCCESS,
	ACCEPT_SHIPMENT_FOR_DRIVER_FAILURE,
	DECLINE_SHIPMENT_FOR_DRIVER_REQUEST,
	DECLINE_SHIPMENT_FOR_DRIVER_SUCCESS,
	DECLINE_SHIPMENT_FOR_DRIVER_FAILURE,
	UPDATE_SHIPMENT_DRIVER_STATUS_REQUEST,
	UPDATE_SHIPMENT_DRIVER_STATUS_SUCCESS,
	UPDATE_SHIPMENT_DRIVER_STATUS_FAILURE,
	FETCH_TRANSACTION_REQUEST,
	FETCH_TRANSACTION_SUCCESS,
	FETCH_TRANSACTION_FAILURE,
	UPDATE_TRANSACTION_REQUEST,
	UPDATE_TRANSACTION_SUCCESS,
	UPDATE_TRANSACTION_FAILURE,
	FETCH_LOADBOARD_REQUEST,
	FETCH_LOADBOARD_SUCCESS,
	FETCH_LOADBOARD_FAILURE,
	SEARCH_SHIPMENTS_REQUEST,
	SEARCH_SHIPMENTS_SUCCESS,
	SEARCH_SHIPMENTS_FAILURE,
	FETCH_SHIPPER_BOOKINGS_REQUEST,
	FETCH_SHIPPER_BOOKINGS_SUCCESS,
	FETCH_SHIPPER_BOOKINGS_FAILURE,
	FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST,
	FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS,
	FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE,
	FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST,
	FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS,
	FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE,
	FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST,
	FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS,
	FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE,
	FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST,
	FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS,
	FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE,
	FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST,
	FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS,
	FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE,
	FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST,
	FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS,
	FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE,
	FETCH_MORE_SHIPPER_BOOKINGS_REQUEST,
	FETCH_MORE_SHIPPER_BOOKINGS_SUCCESS,
	FETCH_MORE_SHIPPER_BOOKINGS_FAILURE,
	FETCH_ATTACHMENTS_REQUEST,
	FETCH_ATTACHMENTS_SUCCESS,
	FETCH_ATTACHMENTS_FAILURE,
	MODIFY_REQUESTED_SHIPMENTS_REQUEST,
	MODIFY_REQUESTED_SHIPMENTS_SUCCESS,
	MODIFY_REQUESTED_SHIPMENTS_FAILURE,
	SEARCH_MORE_SHIPMENTS_FAILURE,
	SEARCH_MORE_SHIPMENTS_REQUEST,
	SEARCH_MORE_SHIPMENTS_SUCCESS,
	RENEW_SHIPMENT_FAILURE,
	RENEW_SHIPMENT_REQUEST,
	RENEW_SHIPMENT_SUCCESS,
	UPDATE_SHIPMENT_FARE_AMOUNT_FAILURE,
	UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST,
	UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS,
	UPDATE_BOOKING_FAILURE,
	UPDATE_BOOKING_REQUEST,
	UPDATE_BOOKING_SUCCESS,
	UPDATE_BOOKING_FARE_AMOUNT_FAILURE,
	UPDATE_BOOKING_FARE_AMOUNT_REQUEST,
	UPDATE_BOOKING_FARE_AMOUNT_SUCCESS,
	UPDATE_SHIPMENT_DETAIL_REQUEST,
	UPDATE_SHIPMENT_DETAIL_SUCCESS,
	UPDATE_SHIPMENT_DETAIL_FAILURE,
	SEARCH_TRANSACTIONS_FAILURE,
	SEARCH_TRANSACTIONS_REQUEST,
	SEARCH_TRANSACTIONS_SUCCESS,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS,
	UPDATE_SHIPMENT_VENDOR_AMOUNT_FAILURE,
	UPDATE_SHIPMENT_PAYMENT_REQUEST,
	UPDATE_SHIPMENT_PAYMENT_SUCCESS,
	UPDATE_SHIPMENT_PAYMENT_FAILURE,
	CONFIRM_VENDOR_PRICING_REQUEST,
	CONFIRM_VENDOR_PRICING_SUCCESS,
	CONFIRM_VENDOR_PRICING_FAILURE,
	UPDATE_BOOKING,
	SEARCH_BOOKINGS_FAILURE,
	SEARCH_BOOKINGS_REQUEST,
	SEARCH_BOOKINGS_SUCCESS,
	SEARCH_MORE_BOOKINGS_FAILURE,
	SEARCH_MORE_BOOKINGS_REQUEST,
	SEARCH_MORE_BOOKINGS_SUCCESS
} from './action-types'
import API from '../../utils/api'
import {
	ACTIVE_SHIPMENT_STATUS,
	SCHEDULED_SHIPMENT_STATUS,
	REQUESTED_SHIPMENT_STATUS,
	COMPLETED_SHIPMENT_STATUS,
	CANCELLED_SHIPMENT_STATUS,
	EXPIRED_SHIPMENT_STATUS
} from '../../constants/strings'

function bookShipment (shipmentData, shipperId) {
	return (dispatch) => {
		dispatch({ type: BOOK_SHIPMENT_REQUEST })
		return API.bookShipment(shipmentData, shipperId)
			.then(
				response => dispatch({
					type: BOOK_SHIPMENT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: BOOK_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function fetchBooking (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_BOOKING_REQUEST })
		return API.getBooking(id)
			.then(
				response => dispatch({
					type: FETCH_BOOKING_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_BOOKING_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipperBookings (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_SHIPPER_BOOKINGS_REQUEST : FETCH_SHIPPER_BOOKINGS_REQUEST })
		return API.getShipperBookings(lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_BOOKINGS_SUCCESS : FETCH_SHIPPER_BOOKINGS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SHIPPER_BOOKINGS_FAILURE : FETCH_SHIPPER_BOOKINGS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchActiveShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_ACTIVE_SHIPMENTS_REQUEST : FETCH_ACTIVE_SHIPMENTS_REQUEST })
		return API.getShipments(ACTIVE_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_ACTIVE_SHIPMENTS_SUCCESS : FETCH_ACTIVE_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_ACTIVE_SHIPMENTS_FAILURE : FETCH_ACTIVE_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchScheduledShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_SCHEDULED_SHIPMENTS_REQUEST : FETCH_SCHEDULED_SHIPMENTS_REQUEST })
		return API.getShipments(SCHEDULED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_SCHEDULED_SHIPMENTS_SUCCESS : FETCH_SCHEDULED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SCHEDULED_SHIPMENTS_FAILURE : FETCH_SCHEDULED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchRequestedShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_REQUESTED_SHIPMENTS_REQUEST : FETCH_REQUESTED_SHIPMENTS_REQUEST })
		return API.getShipments(REQUESTED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_REQUESTED_SHIPMENTS_SUCCESS : FETCH_REQUESTED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_REQUESTED_SHIPMENTS_FAILURE : FETCH_REQUESTED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCompletedShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_COMPLETED_SHIPMENTS_REQUEST : FETCH_COMPLETED_SHIPMENTS_REQUEST })
		return API.getShipments(COMPLETED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_COMPLETED_SHIPMENTS_SUCCESS : FETCH_COMPLETED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_COMPLETED_SHIPMENTS_FAILURE : FETCH_COMPLETED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCancelledShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_CANCELLED_SHIPMENTS_REQUEST : FETCH_CANCELLED_SHIPMENTS_REQUEST })
		return API.getShipments(CANCELLED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_CANCELLED_SHIPMENTS_SUCCESS : FETCH_CANCELLED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CANCELLED_SHIPMENTS_FAILURE : FETCH_CANCELLED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchExpiredShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_EXPIRED_SHIPMENTS_REQUEST : FETCH_EXPIRED_SHIPMENTS_REQUEST })
		return API.getShipments(EXPIRED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_EXPIRED_SHIPMENTS_SUCCESS : FETCH_EXPIRED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_EXPIRED_SHIPMENTS_FAILURE : FETCH_EXPIRED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchShipment (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_SHIPMENT_REQUEST })
		return API.getShipment(id)
			.then(
				response => dispatch({
					type: FETCH_SHIPMENT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function fetchRequestedCarrierShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_CARRIER_SHIPMENTS_REQUEST : FETCH_CARRIER_SHIPMENTS_REQUEST })
		return API.getCarrierShipments(REQUESTED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_CARRIER_SHIPMENTS_SUCCESS : FETCH_CARRIER_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_CARRIER_SHIPMENTS_FAILURE : FETCH_CARRIER_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchScheduledCarrierShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_REQUEST : FETCH_SCHEDULED_CARRIER_SHIPMENTS_REQUEST })
		return API.getCarrierShipments(SCHEDULED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS : FETCH_SCHEDULED_CARRIER_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_SCHEDULED_CARRIER_SHIPMENTS_FAILURE : FETCH_SCHEDULED_CARRIER_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAssignedCarrierShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_REQUEST : FETCH_ASSIGNED_CARRIER_SHIPMENTS_REQUEST })
		return API.getCarrierShipments(ACTIVE_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS : FETCH_ASSIGNED_CARRIER_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_ASSIGNED_CARRIER_SHIPMENTS_FAILURE : FETCH_ASSIGNED_CARRIER_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchCompletedCarrierShipments (lastDocumentId) {
	return (dispatch) => {
		dispatch({ type: lastDocumentId ? FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_REQUEST : FETCH_COMPLETED_CARRIER_SHIPMENTS_REQUEST })
		return API.getCarrierShipments(COMPLETED_SHIPMENT_STATUS, lastDocumentId)
			.then(
				response => dispatch({
					type: lastDocumentId ? FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_SUCCESS : FETCH_COMPLETED_CARRIER_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: lastDocumentId ? FETCH_MORE_COMPLETED_CARRIER_SHIPMENTS_FAILURE : FETCH_COMPLETED_CARRIER_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function acceptShipment (id, shipmentData, carrierId) {
	return (dispatch) => {
		dispatch({ type: ACCEPT_SHIPMENT_REQUEST })
		return API.acceptShipment(id, shipmentData, carrierId)
			.then(
				response => dispatch({
					type: ACCEPT_SHIPMENT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ACCEPT_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function assignShipment (id, shipmentData, carrierId) {
	return (dispatch) => {
		dispatch({ type: ASSIGN_DRIVER_TO_SHIPMENT_REQUEST })
		return API.assignShipment(id, shipmentData, carrierId)
			.then(
				(response) => dispatch({
					type: ASSIGN_DRIVER_TO_SHIPMENT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ASSIGN_DRIVER_TO_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function approveShipmentForDriver (id, shipmentData, carrierId) {
	return (dispatch) => {
		dispatch({ type: ACCEPT_SHIPMENT_FOR_DRIVER_REQUEST })
		return API.updateShipmentDriverStatus(id, shipmentData, carrierId)
			.then(
				(response) => dispatch({
					type: ACCEPT_SHIPMENT_FOR_DRIVER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: ACCEPT_SHIPMENT_FOR_DRIVER_FAILURE,
					payload: error
				})
			)
	}
}

function declineShipmentForDriver (id, shipmentData, carrierId) {
	return (dispatch) => {
		dispatch({ type: DECLINE_SHIPMENT_FOR_DRIVER_REQUEST })
		return API.updateShipmentDriverStatus(id, shipmentData, carrierId)
			.then(
				(response) => dispatch({
					type: DECLINE_SHIPMENT_FOR_DRIVER_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: DECLINE_SHIPMENT_FOR_DRIVER_FAILURE,
					payload: error
				})
			)
	}
}

function updateShipmentDriverStatus (id, shipmentData, carrierId) {
	return (dispatch) => {
		dispatch({ type: UPDATE_SHIPMENT_DRIVER_STATUS_REQUEST })
		return API.updateShipmentDriverStatus(id, shipmentData, carrierId)
			.then(
				response => dispatch({
					type: UPDATE_SHIPMENT_DRIVER_STATUS_SUCCESS,
					payload: response.shipment
				})
			)
			.catch(
				error => dispatch({
					type: UPDATE_SHIPMENT_DRIVER_STATUS_FAILURE,
					payload: error
				})
			)
	}
}

function renewShipment (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: RENEW_SHIPMENT_REQUEST })
		return API.renewShipment(id, shipmentData)
			.then(
				response => dispatch({
					type: RENEW_SHIPMENT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: RENEW_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function cancelShipment (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: CANCEL_SHIPMENT_REQUEST })
		return API.cancelShipment(id, shipmentData)
			.then(
				response => dispatch({
					type: CANCEL_SHIPMENT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: CANCEL_SHIPMENT_FAILURE,
					payload: error
				})
			)
	}
}

function modifyRequestedShipments (bookingId, shipmentData) {
	return (dispatch) => {
		dispatch({ type: MODIFY_REQUESTED_SHIPMENTS_REQUEST })
		return API.modifyRequestedShipment(bookingId, shipmentData)
			.then(
				response => dispatch({
					type: MODIFY_REQUESTED_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: MODIFY_REQUESTED_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function cancelBooking (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: CANCEL_BOOKING_REQUEST })
		return API.cancelBooking(id, shipmentData)
			.then(
				response => dispatch({
					type: CANCEL_BOOKING_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: CANCEL_BOOKING_FAILURE,
					payload: error
				})
			)
	}
}

function fetchTransactionsByBookingId (bookingId) {
	return (dispatch) => {
		dispatch({ type: FETCH_TRANSACTION_REQUEST })
		return API.getTransactionByBookingId(bookingId)
			.then(
				response => dispatch({
					type: FETCH_TRANSACTION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_TRANSACTION_FAILURE,
					payload: error
				})
			)
	}
}

function fetchTransaction (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_TRANSACTION_REQUEST })
		return API.getTransaction(id)
			.then(
				response => dispatch({
					type: FETCH_TRANSACTION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_TRANSACTION_FAILURE,
					payload: error
				})
			)
	}
}

function updateTransactionStatus (id, transactionData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_TRANSACTION_REQUEST, payload: { id, status: transactionData.status } })
		return API.updateTransactionStatus(id, transactionData)
			.then(
				response => dispatch({
					type: UPDATE_TRANSACTION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_TRANSACTION_FAILURE,
					payload: error
				})
			)
	}
}

function updateTransactionPaymentMethod (id, transactionData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_TRANSACTION_REQUEST, payload: { id, paymentMethod: transactionData.paymentMethod } })
		return API.updateTransactionPaymentMethod(id, transactionData)
			.then(
				response => dispatch({
					type: UPDATE_TRANSACTION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_TRANSACTION_FAILURE,
					payload: error
				})
			)
	}
}

function updateTransaction (id, transactionData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_TRANSACTION_REQUEST, payload: { id, ...transactionData } })
		return API.updateTransaction(id, transactionData)
			.then(
				response => dispatch({
					type: UPDATE_TRANSACTION_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_TRANSACTION_FAILURE,
					payload: error
				})
			)
	}
}

function updateShipmentItem (shipment) {
	return (dispatch) => {
		dispatch({ type: UPDATE_SHIPMENT, payload: shipment })
	}
}

function updateBookingItem (booking) {
	return (dispatch) => {
		dispatch({ type: UPDATE_BOOKING, payload: booking })
	}
}

function updateShipmentFareAmount (shipmentId, newFareAmount) {
	return (dispatch) => {
		dispatch({ type: UPDATE_SHIPMENT_FARE_AMOUNT_REQUEST, payload: { id: shipmentId, fareAmount: newFareAmount } })
		return API.updateFareAmount(shipmentId, newFareAmount)
			.then(
				response => dispatch({
					type: UPDATE_SHIPMENT_FARE_AMOUNT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_SHIPMENT_FARE_AMOUNT_FAILURE,
					payload: error
				})
			)
	}
}

function updateBookingFareAmount (bookingId, newFareAmount) {
	return (dispatch) => {
		dispatch({ type: UPDATE_BOOKING_FARE_AMOUNT_REQUEST })
		return API.updateBookingFareAmount(bookingId, newFareAmount)
			.then(
				response => dispatch({
					type: UPDATE_BOOKING_FARE_AMOUNT_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_BOOKING_FARE_AMOUNT_FAILURE,
					payload: error
				})
			)
	}
}

function fetchLoadboardShipments () {
	return (dispatch) => {
		dispatch({ type: FETCH_LOADBOARD_REQUEST })
		return API.getLoadboard()
			.then(
				response => dispatch({
					type: FETCH_LOADBOARD_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_LOADBOARD_FAILURE,
					payload: error
				})
			)
	}
}

function searchShipments (searchTerm, filters, pageNumber) {
	return (dispatch) => {
		dispatch({ type: pageNumber? SEARCH_MORE_SHIPMENTS_REQUEST : SEARCH_SHIPMENTS_REQUEST })
		return API.searchShipments(searchTerm, pageNumber, filters)
			.then(
				response => dispatch({
					type: pageNumber? SEARCH_MORE_SHIPMENTS_SUCCESS : SEARCH_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber? SEARCH_MORE_SHIPMENTS_FAILURE : SEARCH_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function searchTransactions (searchTerm, shipmentStatus, paymentStatus, paymentMethod, tripDateRange) {
	return (dispatch) => {
		dispatch({ type: SEARCH_TRANSACTIONS_REQUEST })
		return API.searchTransactions(searchTerm, shipmentStatus, paymentStatus, paymentMethod, tripDateRange)
			.then(
				response => dispatch({
					type: SEARCH_TRANSACTIONS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: SEARCH_TRANSACTIONS_FAILURE,
					payload: error
				})
			)
	}
}

function searchShipperShipments (searchTerm, pageNumber, filters) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_SHIPMENTS_REQUEST : SEARCH_SHIPMENTS_REQUEST })
		return API.searchShipperShipments(searchTerm, pageNumber, filters)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_SHIPMENTS_SUCCESS : SEARCH_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_SHIPMENTS_FAILURE : SEARCH_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function searchCarrierShipments (searchTerm, pageNumber, searchParams) {
	return (dispatch) => {
		dispatch({ type: pageNumber ? SEARCH_MORE_SHIPMENTS_REQUEST : SEARCH_SHIPMENTS_REQUEST })
		return API.searchCarrierShipments(searchTerm, pageNumber, searchParams)
			.then(
				response => dispatch({
					type: pageNumber ? SEARCH_MORE_SHIPMENTS_SUCCESS : SEARCH_SHIPMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber ? SEARCH_MORE_SHIPMENTS_FAILURE : SEARCH_SHIPMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function fetchAttachments (id) {
	return (dispatch) => {
		dispatch({ type: FETCH_ATTACHMENTS_REQUEST })
		return API.getAttachments(id)
			.then(
				response => dispatch({
					type: FETCH_ATTACHMENTS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: FETCH_ATTACHMENTS_FAILURE,
					payload: error
				})
			)
	}
}

function updateBooking (id, bookingData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_BOOKING_REQUEST })
		return API.updateBooking(id, bookingData)
			.then(
				response => dispatch({
					type: UPDATE_BOOKING_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_BOOKING_FAILURE,
					payload: error
				})
			)
	}
}

function updateShipmentDetails (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_SHIPMENT_DETAIL_REQUEST, payload: { ...shipmentData, id } })
		return API.updateShipmentDetails(id, shipmentData)
			.then(
				response => dispatch({
					type: UPDATE_SHIPMENT_DETAIL_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_SHIPMENT_DETAIL_FAILURE,
					payload: error
				})
			)
	}
}

function updateShipmentInfo (id, shipmentData) {
	return (dispatch) => {
		dispatch({ type: UPDATE_SHIPMENT_DETAIL_REQUEST, payload: { ...shipmentData, id } })
		return API.updateShipmentInfo(id, shipmentData)
			.then(
				response => dispatch({
					type: UPDATE_SHIPMENT_DETAIL_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: UPDATE_SHIPMENT_DETAIL_FAILURE,
					payload: error
				})
			)
	}
}

const updateShipmentVendorAmount = (shipmentId, vendorAmount) => (dispatch) => {
	dispatch({ type: UPDATE_SHIPMENT_VENDOR_AMOUNT_REQUEST, payload: { id: shipmentId, vendorAmount } })
	return API.updateVendorAmount(shipmentId, vendorAmount)
		.then(
			response => Promise.resolve(dispatch({
				type: UPDATE_SHIPMENT_VENDOR_AMOUNT_SUCCESS,
				payload: response
			})),
			error => Promise.reject(dispatch({
				type: UPDATE_SHIPMENT_VENDOR_AMOUNT_FAILURE,
				payload: error
			}))
		)
}

const updateShipmentVendorAdvancePayment = (shipmentId, vendorAdvancePayment, date) => (dispatch) => {
	dispatch({ type: UPDATE_SHIPMENT_PAYMENT_REQUEST, payload: { id: shipmentId, vendorAdvancePayment } })
	return API.updateVendorAdvancePayment(shipmentId, { vendorAdvancePayment, date })
		.then(
			response => Promise.resolve(dispatch({
				type: UPDATE_SHIPMENT_PAYMENT_SUCCESS,
				payload: response
			})),
			error => Promise.reject(dispatch({
				type: UPDATE_SHIPMENT_PAYMENT_FAILURE,
				payload: error
			}))
		)
}

const updateShipmentFareAdvancePayment = (shipmentId, fareAdvancePayment, date) => (dispatch) => {
	dispatch({ type: UPDATE_SHIPMENT_PAYMENT_REQUEST, payload: { id: shipmentId, fareAdvancePayment } })
	return API.updateFareAdvancePayment(shipmentId, { fareAdvancePayment, date })
		.then(
			response => dispatch({
				type: UPDATE_SHIPMENT_PAYMENT_SUCCESS,
				payload: response
			}),
			error => dispatch({
				type: UPDATE_SHIPMENT_PAYMENT_FAILURE,
				payload: error
			})
		)
}

const updateShipmentExtraCost = (shipmentId, extraCost, date, extraCostNote) => (dispatch) => {
	dispatch({ type: UPDATE_SHIPMENT_PAYMENT_REQUEST, payload: { id: shipmentId, extraCost } })
	return API.updateExtraCost(shipmentId, { extraCost, extraCostNote, date })
		.then(
			response => dispatch({
				type: UPDATE_SHIPMENT_PAYMENT_SUCCESS,
				payload: response
			}),
			error => dispatch({
				type: UPDATE_SHIPMENT_PAYMENT_FAILURE,
				payload: error
			})
		)
}

const acceptVendorPricing = (vendorRequestId, vendorPhoneNumber, truckQuantity, bookingId) => (dispatch) => {
	dispatch({ type: CONFIRM_VENDOR_PRICING_REQUEST, payload: { id: bookingId, truckQuantity } })
	return API.acceptVendorPrice(vendorRequestId, { vendorPhoneNumber, truckQuantity })
		.then(
			response => dispatch({
				type: CONFIRM_VENDOR_PRICING_SUCCESS,
				payload: response
			}),
			error => dispatch({
				type: CONFIRM_VENDOR_PRICING_FAILURE,
				payload: {
					...error,
					id: bookingId,
					truckQuantity
				}
			})
		)
}

function searchBookings (searchTerm, pageNumber, filters) {
	return (dispatch) => {
		dispatch({ type: pageNumber?  SEARCH_MORE_BOOKINGS_REQUEST : SEARCH_BOOKINGS_REQUEST })
		return API.searchBookings(searchTerm, pageNumber, filters)
			.then(
				response => dispatch({
					type: pageNumber? SEARCH_MORE_BOOKINGS_SUCCESS : SEARCH_BOOKINGS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber? SEARCH_MORE_BOOKINGS_FAILURE : SEARCH_BOOKINGS_FAILURE,
					payload: error
				})
			)
	}
}

function searchShipperBookings (searchTerm, pageNumber, filters) {
	return (dispatch) => {
		dispatch({ type: pageNumber?  SEARCH_MORE_BOOKINGS_REQUEST : SEARCH_BOOKINGS_REQUEST })
		return API.searchShipperBookings(searchTerm, pageNumber, filters)
			.then(
				response => dispatch({
					type: pageNumber? SEARCH_MORE_BOOKINGS_SUCCESS : SEARCH_BOOKINGS_SUCCESS,
					payload: response
				}),
				error => dispatch({
					type: pageNumber? SEARCH_MORE_BOOKINGS_FAILURE : SEARCH_BOOKINGS_FAILURE,
					payload: error
				})
			)
	}
}

export {
	bookShipment,
	fetchBooking,
	fetchActiveShipments,
	fetchScheduledShipments,
	fetchRequestedShipments,
	fetchCompletedShipments,
	fetchCancelledShipments,
	fetchExpiredShipments,
	fetchShipment,
	fetchRequestedCarrierShipments,
	fetchScheduledCarrierShipments,
	fetchAssignedCarrierShipments,
	fetchCompletedCarrierShipments,
	acceptShipment,
	assignShipment,
	cancelShipment,
	renewShipment,
	cancelBooking,
	updateShipmentItem,
	updateBookingItem,
	approveShipmentForDriver,
	declineShipmentForDriver,
	updateShipmentDriverStatus,
	fetchTransactionsByBookingId,
	fetchTransaction,
	fetchLoadboardShipments,
	updateTransactionStatus,
	searchShipments,
	searchShipperShipments,
	searchCarrierShipments,
	fetchShipperBookings,
	fetchAttachments,
	updateShipmentFareAmount,
	modifyRequestedShipments,
	updateBooking,
	updateBookingFareAmount,
	updateTransaction,
	updateShipmentDetails,
	updateShipmentInfo,
	searchTransactions,
	updateTransactionPaymentMethod,
	updateShipmentVendorAmount,
	updateShipmentVendorAdvancePayment,
	updateShipmentFareAdvancePayment,
	updateShipmentExtraCost,
	acceptVendorPricing,
	searchShipperBookings,
	searchBookings
}
